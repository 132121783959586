import {
  AssetListForSelection,
  AssetTaxonomyStepData,
} from '../../../../../../types/asset-list';
import React, { useCallback, useMemo, useState } from 'react';
import {
  invertNicheFields,
  performNicheFieldInversionIfNeeded,
} from '../utils';

import { AssetAttributes } from '../../../../../../types/asset';
import { EMPTY_ARRAY } from '../../../../../../shared/helpers';
import { NicheFields } from '../../../../../../types/form';
import NicheFieldsSelectDialog from '../components/NicheFieldsSelectorDialog';

type NicheFieldsSelectorProps = {
  open: ({ nicheFields, excludeMode }) => void;
  close: () => void;
  isOpen: boolean;
  nicheFields: NicheFields;
  assetList: AssetAttributes[] | typeof EMPTY_ARRAY;
  onClickSelectAll: () => void;
  onClickReset: () => void;
  onToggleNicheField: ({
    code,
    selected,
  }: {
    code: string;
    selected: boolean;
  }) => void;
  onClickSave: () => void;
};

const NicheFieldsSelectorContext =
  React.createContext<NicheFieldsSelectorProps>({} as NicheFieldsSelectorProps);

type NicheFieldsSelectorProviderProps = {
  children: React.ReactNode;
  assetList: AssetAttributes[] | typeof EMPTY_ARRAY;
  onSave: (nicheFields: NicheFields, excludeMode: boolean) => void;
};

function NicheFieldsSelectorProvider({
  children,
  assetList,
  onSave,
}: NicheFieldsSelectorProviderProps) {
  const [nicheFields, setNicheFields] = useState<NicheFields>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const open = useCallback(({ nicheFields, excludeMode }) => {
    if (excludeMode) {
      const fields = invertNicheFields(nicheFields, assetList);
      setNicheFields(fields);
    } else {
      setNicheFields(nicheFields);
    }
    setIsOpen(true);
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onClickSelectAll = useCallback(() => {
    setNicheFields(assetList.map((a: AssetAttributes) => a.code3));
  }, []);

  const onClickReset = useCallback(() => {
    setNicheFields([]);
  }, []);

  const onToggleNicheField = useCallback(({ code, selected }) => {
    if (selected) {
      setNicheFields((prev) => prev.filter((nf) => nf !== code));
    } else {
      setNicheFields((prev) => [...prev, code]);
    }
  }, []);

  const onClickSave = useCallback(() => {
    const { fields, excludeMode } = performNicheFieldInversionIfNeeded(
      nicheFields,
      assetList,
    );

    onSave(fields, excludeMode);
    close();
  }, [nicheFields]);

  const value = useMemo(
    () => ({
      open,
      close,
      isOpen,
      assetList,
      nicheFields,
      onClickReset,
      onClickSelectAll,
      onToggleNicheField,
      onClickSave,
    }),
    [
      open,
      close,
      isOpen,
      assetList,
      nicheFields,
      onClickReset,
      onClickSelectAll,
      onToggleNicheField,
      onClickSave,
    ],
  );

  return (
    <NicheFieldsSelectorContext.Provider value={value}>
      <>
        <NicheFieldsSelectDialog />
        {children}
      </>
    </NicheFieldsSelectorContext.Provider>
  );
}

function useNicheFieldsSelector() {
  const context = React.useContext(NicheFieldsSelectorContext);

  if (context === undefined) {
    throw new Error(
      'useNicheFieldsSelector must be used within a NicheFieldsSelectorProvider',
    );
  }

  return context;
}
export { NicheFieldsSelectorProvider, useNicheFieldsSelector };
