import { Floorplan } from '../types/floorplan';
import { RoomProps } from '../types/room';
import { getLastUpdated } from '../shared/logTools';
import { setSnackMessage } from './ui';

export const onSaveFloor =
  ({ newFloorName, prevFloorName }) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();

    const { client, survey } = getState().firestore.data;

    const clientDocRef = firestore.collection('clients').doc(client.clientId);
    const surveyDocRef = clientDocRef.collection('surveys').doc(survey.id);

    if (!survey) {
      throw new Error('Survey does not exist');
    }
    // check if the floor assigned to the room is a new one, if so add it to the floors array
    const existingFloors = survey.roomSchedule?.floors || [];

    const floorsWithOldRemoved = existingFloors.filter(
      (f) => f !== prevFloorName,
    );

    const floorsWithNewAdded = [...floorsWithOldRemoved, newFloorName];

    const existingRooms = survey.roomSchedule?.data || [];
    const existingFloorPlans = survey.floorPlans || [];

    const roomScheduleDataArrayUpdated = existingRooms.map(
      (room: RoomProps) => {
        if (room.floor === prevFloorName) {
          return {
            ...room,
            floor: newFloorName,
          };
        }
        return room;
      },
    );

    const floorPlansDataUpdated = existingFloorPlans.map(
      (floorPlan: Floorplan) => {
        if (floorPlan.floor === prevFloorName) {
          return {
            ...floorPlan,
            floor: newFloorName,
          };
        }
        return floorPlan;
      },
    );

    const batch = firestore.batch();

    const roomsOnFloor = await surveyDocRef
      .collection('rooms')
      .where('floor', '==', prevFloorName)
      .get();

    if (roomsOnFloor.size > 0) {
      roomsOnFloor.docs.forEach((room) => {
        batch.update(room.ref, { floor: newFloorName });
      });
    }

    batch.update(surveyDocRef, {
      'roomSchedule.data': roomScheduleDataArrayUpdated,
      'roomSchedule.floors': floorsWithNewAdded,
      floorPlans: floorPlansDataUpdated,
      ...getLastUpdated(),
    });

    batch.commit();

    dispatch(
      setSnackMessage({ message: `${newFloorName} saved successfully` }),
    );
  };
