import 'dayjs/locale/en-gb';
import 'fontsource-open-sans/300-normal.css';
import 'fontsource-roboto/300-normal.css';
import 'fontsource-roboto/400-normal.css';
import 'fontsource-roboto/500-normal.css';
import 'fontsource-roboto/700-italic.css';
import 'fontsource-roboto/700-normal.css';

import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { IKContext } from 'imagekitio-react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { ModalProvider } from './components/Modal';
import React from 'react';
import Root from './components/Root';
import { BrowserRouter as Router } from 'react-router-dom';
import Store from './config/store';
import { newBrandingTheme } from './config/theme';

// import ReloadPrompt from './components/ReloadPrompt';

export default function App() {
  return (
    <Store>
      <ThemedApp />
    </Store>
  );
}
const ThemedApp = () => {
  const themeToUse = newBrandingTheme;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themeToUse}>
          <ModalProvider>
            <Router>
              <IKContext urlEndpoint="https://ik.imagekit.io/asseticom/">
                <React.StrictMode>
                  <Root />
                  {/* <ReloadPrompt /> */}
                </React.StrictMode>
              </IKContext>
            </Router>
          </ModalProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </LocalizationProvider>
  );
};
