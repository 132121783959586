import { Box, Input, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { colours } from '../config/theme';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({}));

export default function AssetAttribute({
  value,
  label,
  onChanged,
  prefix = '',
  suffix = '',
  validationRegex = /.*/,
}) {
  const classes = useStyles();
  const initialValueType = typeof value;
  const [currentVal, setCurrentVal] = useState(value);
  const [prevVal, setPrevVal] = useState(value);
  const isEditable = typeof onChanged === 'function';

  const onEnd = (event) => {
    const val = event.target.value;
    const Regex = new RegExp(validationRegex);
    if (!Regex.test(val)) {
      return setCurrentVal(prevVal);
    }
    setCurrentVal(`${prefix}${val}${suffix}`);
    if (isEditable) {
      onChanged(initialValueType === 'number' ? Number(val) : val);
    }
    return true;
  };

  useEffect(() => {
    setCurrentVal(`${prefix}${value}${suffix}`);
  }, [prefix, value, suffix]);

  const onFocus = useCallback(
    (event) => {
      setPrevVal(currentVal);
      setCurrentVal('');
    },
    [currentVal],
  );

  return (
    <Box
      px={3}
      display="flex"
      flexDirection="column"
      alignItems="center"
      textAlign="center"
    >
      <Typography align="center" color="secondary" variant="caption">
        {label}
      </Typography>
      <Input
        onChange={(event) => {
          setCurrentVal(event.target.value);
        }}
        color="secondary"
        value={currentVal}
        padding="none"
        disabled={!isEditable}
        inputProps={{
          style: {
            textAlign: 'center',
            margin: 0,
            padding: 0,
            color: colours.medium,
            fontWeight: 500,
          },
        }}
        align="center"
        disableUnderline
        onBlur={onEnd}
        onFocus={onFocus}
      />
    </Box>
  );
}

AssetAttribute.defaultProps = {
  onChanged: undefined,
  prefix: '',
  suffix: '',
  validationRegex: undefined,
};

AssetAttribute.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  label: PropTypes.string.isRequired,
  onChanged: PropTypes.func,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  validationRegex: PropTypes.instanceOf(RegExp),
};
