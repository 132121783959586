import { Box, Link, Typography } from '@mui/material';
import React, { useMemo } from 'react';

import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isEmpty } from 'react-redux-firebase';
import { useSelector } from 'react-redux';

const RoleSelect = ({ children }) => {
  const profile = useSelector((state) => state.firebase.profile);
  const roles = useMemo(
    () =>
      profile.isLoaded && !profile.isEmpty
        ? Object.keys(profile.token.claims.roles)
        : null,
    [profile],
  );
  if (isEmpty(roles)) {
    return null;
  }
  const rolesList = roles.map((role) => {
    return (
      <React.Fragment key={role}>
        <Box mb={1} key={role}>
          <Link to={`/${role}`} component={NavLink} underline="hover">
            {role}
          </Link>
        </Box>
      </React.Fragment>
    );
  });
  return (
    <>
      {children}
      <Box
        mt={2}
        display="flex"
        flexDirection="column"
        align="center"
        justifyContent="center"
      >
        <Typography variant="body2" color="secondary" align="center" paragraph>
          {roles.length > 1
            ? 'You have access to the following portals:'
            : 'Click the link below to take you back to your portal:'}
        </Typography>
        {rolesList}
      </Box>
    </>
  );
};

RoleSelect.defaultProps = {
  children: null,
};

RoleSelect.propTypes = {
  children: PropTypes.node,
};

export default RoleSelect;
