import { Button } from '@mui/material';

type FormFieldAddOptionButtonProps = {
  onAddOption: () => void;
};

export default function FormFieldAddOptionButton({
  onAddOption,
}: FormFieldAddOptionButtonProps) {
  return (
    <Button color="primary" onClick={onAddOption}>
      Add option
    </Button>
  );
}
