import { Redirect, Route } from 'react-router-dom';

import PropTypes from 'prop-types';
import { ROUTES_MAP } from '../config/routes';
import { getFirebase } from 'react-redux-firebase';
import useAuthChecker from '../hooks/useAuthChecker';
import useRedirect from '../hooks/useRedirect';

const RouteGuard = ({ children, auth, path, redirect, ...rest }) => {
  const { isAuthed, isChecking, noRoles } = useAuthChecker(auth);
  const redirectTo = useRedirect(redirect);
  const firebase = getFirebase();

  if (isChecking) {
    // console.log(path, 'RouteGuard isChecking status...');
    return null;
  }

  if (noRoles) {
    firebase.logout();
    return <Redirect to={ROUTES_MAP.LOGIN.path} />;
  }

  if (isAuthed) {
    // console.log(path, 'RouteGuard isAuthed so show children');
    return <Route {...rest} render={() => children} />;
  }

  if (!isAuthed && redirectTo) {
    // console.log(path, 'RouteGuard is NOT Authed so redirect to', redirectTo);
    return <Redirect to={redirectTo} />;
  }

  return null;
};

RouteGuard.defaultProps = {
  auth: null,
  redirect: null,
  children: null,
};

RouteGuard.propTypes = {
  children: PropTypes.node,
  auth: PropTypes.string,
  path: PropTypes.string.isRequired,
  redirect: PropTypes.string,
};

export default RouteGuard;
