import * as Sentry from '@sentry/react';

import { Box, Button, Typography } from '@mui/material';

import PropTypes from 'prop-types';
import React from 'react';

const ErrorBoundaryFallback = () => (
  <Box p={2}>
    <DefaultErrorBoundaryContent />
  </Box>
);
const DefaultErrorBoundaryContent = (message) => (
  <>
    <Typography variant="caption" color="error">
      There has been an error displaying this component.
      <br />A diagnostic report has been sent to us and we are investigating.
      <br />
      We&apos;re sorry for the inconvenience.
    </Typography>
  </>
);

const ErrorBoundaryContent = ({ message }) => (
  <>
    <Typography paragraph color="error">
      {message}
    </Typography>
    <Button
      onClick={() => window.location.reload()}
      color="primary"
      variant="outlined"
    >
      Reload Page
    </Button>
  </>
);

ErrorBoundaryContent.propTypes = {
  message: PropTypes.string.isRequired,
};

const ErrorBoundary = ({ children, showDialog = false, message = '' }) => {
  return (
    <Sentry.ErrorBoundary
      showDialog={!!showDialog}
      fallback={
        message ? (
          <ErrorBoundaryContent message={message} />
        ) : (
          <ErrorBoundaryFallback />
        )
      }
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

ErrorBoundary.defaultProps = {
  showDialog: false,
  message: '',
};

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  showDialog: PropTypes.bool,
  message: PropTypes.string,
};

export default ErrorBoundary;
