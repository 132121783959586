import { createContext, useState } from 'react';

import { AppState } from '../../../../config/store';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import { Paper } from '@mui/material';
import PhotoScheduleReports from './report-types/photo-schedule/form';
import SpreadsheetReports from './report-types/spreadsheet/form';
import { useSelector } from 'react-redux';

export type AssetReportType =
  | 'PHOTO_SCHEDULE'
  | 'ASSET_DATA'
  | 'LIFE_CYCLE'
  | 'OPERATIONAL_CARBON'
  | 'EMBODIED_CARBON'
  | 'OPERATIONAL_ENERGY'
  | 'MAINTENANCE_PLANNER';

type CreateAssetReportsContextType = {
  reportType: AssetReportType | undefined;
  changeReportType: (type: AssetReportType) => void;
};

export const CreateAssetReportsContext =
  createContext<CreateAssetReportsContextType>(
    {} as CreateAssetReportsContextType,
  );

function CreateAssetReports() {
  const [reportType, setReportType] = useState<AssetReportType>('ASSET_DATA');

  const changeReportType = (type: AssetReportType) => {
    setReportType(type);
  };

  const client = useSelector((state: AppState) => state.firestore.data.client);

  return client ? (
    <CreateAssetReportsContext.Provider
      value={{ changeReportType, reportType }}
    >
      <ErrorBoundary>
        <Paper sx={{ width: '100%' }} id="main-content" elevation={2}>
          {reportType === 'PHOTO_SCHEDULE' && <PhotoScheduleReports />}
          {reportType === 'ASSET_DATA' && <SpreadsheetReports />}
          {reportType === 'LIFE_CYCLE' && <SpreadsheetReports />}
          {reportType === 'OPERATIONAL_CARBON' && <SpreadsheetReports />}
          {reportType === 'EMBODIED_CARBON' && <SpreadsheetReports />}
          {reportType === 'OPERATIONAL_ENERGY' && <SpreadsheetReports />}
          {reportType === 'MAINTENANCE_PLANNER' && <SpreadsheetReports />}
        </Paper>
      </ErrorBoundary>
    </CreateAssetReportsContext.Provider>
  ) : null;
}

export default CreateAssetReports;
