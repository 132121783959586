import { Paper, Stack } from '@mui/material';
import {
  RIGHT_ALIGN_BODY,
  RIGHT_ALIGN_HEADER,
} from '../../../../config/constants';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import CreatedTableCell from '../../../../components/CreatedTableCell';
import { EMPTY_ARRAY } from '../../../../shared/helpers';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import Table from '../../../../components/Table';
import TableActionButton from '../../../../components/TableActionButton';
import { handlePlural } from '../../../../shared/utilities';
import { onSelectedSiteDelete } from '../../../../state/site';
import { useCallback } from 'react';
import { useModal } from '../../../../components/Modal';

const columns = [
  { accessorKey: 'name', header: 'Name' },
  { accessorKey: 'location', header: 'Location' },
  {
    accessorKey: 'created',
    header: 'Created',
    Cell: CreatedTableCell,
  },
  {
    accessorKey: 'buildings',
    header: 'Buildings',
    ...RIGHT_ALIGN_HEADER,
    ...RIGHT_ALIGN_BODY,
  },
  {
    accessorKey: 'surveys',
    header: 'Surveys',
    ...RIGHT_ALIGN_HEADER,
    ...RIGHT_ALIGN_BODY,
  },
  {
    accessorKey: 'assets',
    header: 'Assets',
    ...RIGHT_ALIGN_HEADER,
    ...RIGHT_ALIGN_BODY,
  },
  {
    accessorKey: 'partials',
    header: 'Partials',
    ...RIGHT_ALIGN_HEADER,
    ...RIGHT_ALIGN_BODY,
  },
  {
    accessorKey: 'flagged',
    header: 'Flagged',
    ...RIGHT_ALIGN_HEADER,
    ...RIGHT_ALIGN_BODY,
  },
];

const View = () => {
  const location = useLocation();
  const history = useHistory();
  const { clientId } = useParams();
  const dispatch = useDispatch();
  const { showModal } = useModal();

  const sites = useSelector(
    (state) => state.firestore.ordered.sites || EMPTY_ARRAY,
  );

  // const showConfirmDialogForSite = async (site) => {
  //   const numBuildings = site.buildingsNumCreated - site.buildingsNumDeleted;

  //   const messages = [
  //     `Are you sure you want to delete ${site.name}?`,
  //     numBuildings > 0
  //       ? `This will also delete ${handlePlural(
  //           numBuildings,
  //           'buildings',
  //           true,
  //         )}.`
  //       : '',
  //     'You will not affect any surveys carried out at this site, they should be deleted individually if required.',
  //   ];

  //   const deleteConfirmed = await showModal({
  //     title: 'Please Confirm',
  //     buttons: {
  //       confirm: [{ text: 'Yes', value: true }],
  //       cancel: [{ text: 'No' }],
  //     },
  //     messages,
  //   });
  //   if (deleteConfirmed) {
  //     dispatch(onSelectedSiteDelete(site));
  //   }
  // };

  const onSiteDelete = (site) => {
    console.log('site', site);
    dispatch(onSelectedSiteDelete({ site }));
  };

  const rows = sites.map((site) => {
    const {
      id,
      name,
      postCode,
      created,
      buildingsNumCreated,
      buildingsNumDeleted,
      surveysNumCreated,
      surveysNumDeleted,
      assetsNumCreated,
      assetsNumDeleted,
      assetsNumFlagged,
      partialsNumCreated,
      partialsNumDeleted,
    } = site;
    return {
      id,
      name,
      created,
      location: postCode,
      surveys: surveysNumCreated - surveysNumDeleted,
      buildings: buildingsNumCreated - buildingsNumDeleted,
      assets: assetsNumCreated - assetsNumDeleted,
      flagged: assetsNumFlagged || 0,
      partials: partialsNumCreated - partialsNumDeleted,
    };
  });

  const onClickedEditSite = ({ id }) => {
    history.push(`${location.pathname}/${id}`);
  };

  const actionButtons = useCallback(({ row, closeMenu }) => {
    const buttons = [];

    const rowData = row.original;

    buttons.push(
      <TableActionButton
        key="Edit"
        text="Edit"
        colour="blue"
        onClick={() => closeMenu(onClickedEditSite(rowData))}
      />,
    );

    buttons.push(
      <TableActionButton
        key="Delete"
        text="Delete"
        colour="red"
        onClick={() => closeMenu(onSiteDelete(rowData))}
      />,
    );

    return (
      <Stack direction="column" p={1}>
        {buttons}
      </Stack>
    );
  }, []);

  return (
    <Paper sx={{ width: '100%' }} id="main-content">
      <ErrorBoundary>
        <Table
          rows={rows}
          columns={columns}
          onClickRow={onClickedEditSite}
          tableProps={{
            enableRowActions: true,
            positionActionsColumn: 'first',
            renderRowActionMenuItems: actionButtons,
          }}
        />
      </ErrorBoundary>
    </Paper>
  );
};

export default View;
