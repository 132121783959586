import React, { useCallback, useMemo, useState } from 'react';

import { FormField } from '../../../../../../types/form';
import FormFieldsCopyFromPreviousDialog from '../components/FormFieldsCopyFromPreviousDialog';
import { SurveyProps } from '../../../../../../types/survey';

type FieldsCopierProps = {
  open: () => void;
  close: () => void;
  isOpen: boolean;
  onClickSave: (survey: SurveyProps) => void;
};

const FieldsCopierContext = React.createContext<FieldsCopierProps>(
  {} as FieldsCopierProps,
);

type FieldsCopierProviderProps = {
  children: React.ReactNode;
  onSelect: (survey: SurveyProps) => void;
};

function FieldsCopierProvider({
  children,
  onSelect,
}: FieldsCopierProviderProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const open = useCallback(() => {
    setIsOpen(true);
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onClickSave = useCallback((survey: SurveyProps) => {
    onSelect(survey);
    close();
  }, []);

  const value = useMemo(
    () => ({
      open,
      close,
      isOpen,
      onClickSave,
    }),
    [open, close, isOpen, onClickSave],
  );

  return (
    <FieldsCopierContext.Provider value={value}>
      <>
        <FormFieldsCopyFromPreviousDialog fields={[]} />
        {children}
      </>
    </FieldsCopierContext.Provider>
  );
}

function useFieldsCopier() {
  const context = React.useContext(FieldsCopierContext);

  if (context === undefined) {
    throw new Error(
      'useFieldsCopier must be used within a FieldsModalProvider',
    );
  }

  return context;
}
export { FieldsCopierProvider, useFieldsCopier };
