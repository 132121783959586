import useAuthChecker from '../hooks/useAuthChecker';

type ComponentGuardProps = {
  auth?: string;
  children: React.ReactNode;
  label?: string;
};

const ComponentGuard = ({ children, auth, label }: ComponentGuardProps) => {
  const { isAuthed, isChecking } = useAuthChecker(auth, label);

  if (isChecking) {
    return null;
  }

  if (isAuthed) {
    return <>{children}</>;
  }

  return null;
};

export default ComponentGuard;
