// import { getLastUpdated } from '../shared/logTools';
import {
  getRandomHyphenatedStringFromArray,
  getRandomNumberBetween,
  getStorageForPath,
  isUrl,
} from '../shared/utilities';

import { PATHS } from '../config/constants';
import { getLastUpdated } from '../shared/logTools';
import { setSnackMessage } from './ui';

export const addNewClient =
  ({ formData }) =>
  async (dispatch, getState, { getFirebase }) => {
    try {
      const firestore = getFirebase().firestore();
      const logoStorage = getStorageForPath(PATHS.STORAGE.IMAGE_LOGO);
      const backgroundStorage = getStorageForPath(
        PATHS.STORAGE.IMAGE_BACKGROUND,
      );

      // upload any images
      const dateNow = Date.now();
      const imagePath = `${formData.clientId}/${dateNow}.jpeg`;
      let logoImageUrl;
      let backgroundImageUrl;
      const clientData = { ...formData };
      const { clientId } = clientData;

      // only upload base 64 file and not a url
      if (formData.logoImage && !isUrl(formData.logoImage)) {
        try {
          const logoRef = logoStorage.ref(`${imagePath}`);
          await logoRef.putString(formData.logoImage, 'data_url');
          logoImageUrl = await logoRef.getDownloadURL();
          clientData.logoImage = logoImageUrl;
        } catch (error) {
          console.error(error);
        }
      }

      if (formData.backgroundImage && !isUrl(formData.backgroundImage)) {
        try {
          const bgRef = backgroundStorage.ref(`${imagePath}`);
          await bgRef.putString(formData.backgroundImage, 'data_url');
          backgroundImageUrl = await bgRef.getDownloadURL();
          clientData.backgroundImage = backgroundImageUrl;
        } catch (error) {
          console.error(error);
        }
      }
      clientData.id = clientId;
      // assign the created date
      clientData.created = Date.now();
      // initialise with 0 for stats
      clientData.deleted = 0;
      clientData.assetListsNumCreated = 0;
      clientData.assetListsNumDeleted = 0;
      clientData.sitesNumCreated = 0;
      clientData.sitesNumDeleted = 0;
      clientData.buildingsNumCreated = 0;
      clientData.buildingsNumDeleted = 0;
      clientData.surveysNumCreated = 0;
      clientData.surveysNumDeleted = 0;
      clientData.usersNumCreated = 0;
      clientData.usersNumDeleted = 0;
      clientData.assetsNumCreated = 0;
      clientData.assetsNumDeleted = 0;
      clientData.partialsNumCreated = 0;
      clientData.partialsNumDeleted = 0;
      clientData.exportsNumCreated = 0;
      clientData.exportsNumDeleted = 0;
      clientData.assetsNumFlagged = 0;

      // create admin users
      const clientDocRef = await firestore.collection('clients').doc(clientId);
      clientDocRef.set({ ...clientData, ...getLastUpdated() });

      dispatch(setSnackMessage({ message: 'New client added' }));
      return { id: clientId };
    } catch (error) {
      console.error(error);
      return { error };
    }
  };

// USED FOR ADDING TEST DATA
const logos = [
  'https://www.freelogodesign.org/Content/img/logo-samples/theater.png',
  'https://static.rfstat.com/renderforest/images/v2/logo-homepage/gradient_2.png',
  'https://www.nasa.gov/sites/default/files/thumbnails/image/nasa-logo-web-rgb.png',
  'https://cdn.logo.com/hotlink-ok/logo-social-sq.png',
  'https://techcrunch.com/wp-content/uploads/2018/07/logo-2.png?w=300',
  'https://1000logos.net/wp-content/uploads/2018/06/Brighton-Hove-Albion-Logo.png',
  'https://www.logogenie.net/download/preview/medium/7994893',
];
const backgrounds = [
  'https://www.robinsnursery.co.uk/wp-content/uploads/2016/11/landscape-152502_1280.png',
  'https://lh3.googleusercontent.com/proxy/-9Ha3rXV2e76tYZjefV3R9AiDRJrwYHKB20qeo2senv4j3o17O_8Gqkr-DUc8SxNwmoykw8nfgC3LGxE4nNTOtlqSI1cR_gxUGzYGCSSjcJGJS2ChgT39opFHZESulON-PcA_5bjHKnF',
  'https://www.colourcalendars.com/images/product/landscape-front.png',
  'https://eaca.eu/wp-content/uploads/2017/04/header-1.png',
  'https://www.zembula.com/wp-content/uploads/2019/10/header-background-01.png',
  'https://www.capitalcardiology.com/wp-content/uploads/2018/08/header-afibawarenessmonth.png',
  'https://www.templarbit.com/images/blog/templarbit-security-headers-de6a6bfc.jpg',
];

const getTestClientData = (profile) => {
  const randomString = getRandomHyphenatedStringFromArray([
    'client',
    'portal',
    getRandomNumberBetween(0, 500),
  ]);
  return {
    address1: `${getRandomNumberBetween(1, 300)} Client Street`,
    address2: 'Client Town',
    backgroundImage:
      backgrounds[getRandomNumberBetween(0, backgrounds.length - 1)],
    bankAccountNumber: 12345678,
    bankName: 'Client Bank',
    bankSortCode: '55-34-44',
    category: 'Education',
    companyName: 'Client Company',
    companyNumber: 12345678,
    county: 'Greater London',
    keyContactEmail: 'test@test.com',
    keyContactName: 'Tester McTestalot',
    logoImage: logos[getRandomNumberBetween(0, logos.length - 1)],
    owner: 'Amir Nabil',
    portalAdmins: [
      {
        nameFirst: profile.nameFirst,
        nameLast: profile.nameLast,
        email: profile.email,
      },
    ],

    name: randomString,
    postCode: 'TS1 ES2',
    townCity: 'London',
    clientId: randomString,
  };
};

export const addNewTestClient =
  () =>
  async (dispatch, getState, { getFirebase }) => {
    const { profile } = getState().firebase;

    const formData = getTestClientData(profile);
    dispatch(addNewClient({ formData }));
  };
