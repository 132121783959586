import { Box, Button, Dialog } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import CardTitle from '../routes/client-portal/surveys/edit/asset/components/CardTitle';
import PropTypes from 'prop-types';
import { colours } from '../config/theme';
import { makeStyles } from '@mui/styles';
import { useAssetList } from '../hooks/useAssetList';
import { useSelector } from 'react-redux';
import { useVirtualizer } from '@tanstack/react-virtual';

const useStyles = makeStyles((theme) => ({
  dialog: {
    backgroundColor: 'transparent',
  },
}));

const ITEM_HEIGHT = 80;

const AssetList = ({ data = [], onSelected }) => {
  const parentRef = React.useRef();

  // The virtualizer
  const rowVirtualizer = useVirtualizer({
    count: data.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => ITEM_HEIGHT,
    overscan: 5,
  });

  return (
    <div
      ref={parentRef}
      style={{
        height: `80%`,
        width: `100vw`,
        maxWidth: '400px',
        overflow: 'auto',
      }}
    >
      <div
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
          width: '100%',
          position: 'relative',
        }}
      >
        {rowVirtualizer.getVirtualItems().map((virtualItem) => {
          return (
            <div
              key={virtualItem.key}
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: `${virtualItem.size}px`,
                transform: `translateY(${virtualItem.start}px)`,
              }}
            >
              <Box
                p={2}
                height={ITEM_HEIGHT}
                display="flex"
                alignItems="center"
                borderBottom={`1px solid ${colours.grey5}`}
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  onClick={() => onSelected(data[virtualItem.index].text)}
                >
                  {data[virtualItem.index].text}
                </Button>
              </Box>
            </div>
          );
        })}
      </div>
    </div>
  );
};

AssetList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSelected: PropTypes.func.isRequired,
};

const TEST_DATA =
  'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.'.split(
    ' ',
  );

const AssetTypeChanger = ({ onSelected, forceChoice, ...props }) => {
  const classes = useStyles();
  const [level, setLevel] = useState(0);
  const [list, setList] = useState([]);
  const [progress, setProgress] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(forceChoice);

  const client = useSelector((state) => state.firestore.data.client);

  const assetListId = useSelector(
    (state) => state.firestore.data.survey?.assetListId,
  );

  const assetList = useAssetList(assetListId);

  const handleDialogClose = (_, reason) => {
    if (!forceChoice) {
      setDialogOpen(false);
    }
  };
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const merged = useMemo(() => {
    if (!assetList) {
      return null;
    }
    return assetList.map((item) => {
      return {
        level0: `${item.code0} - ${item.label0}`,
        level1: `${item.code1} - ${item.label1}`,
        level2: `${item.code2} - ${item.label2}`,
        level3: `${item.code3} - ${item.label3}`,
      };
    });
  }, [assetList]);

  const nrmLevelList = useMemo(() => {
    if (!list || level < 0 || level > 3) {
      return [];
    }
    const l = `level${level}`;
    return Array.from(new Set(list.map((item) => item[l]))).map((i, index) => ({
      id: index,
      text: i,
      colour: 'secondary',
      lower: i.toLowerCase(),
    }));
  }, [list, level]);

  useEffect(() => {
    setProgress([]);
    setLevel(0);
    setList(merged);
  }, [merged]);

  const onAssetSelectedFromList = useCallback(
    async (assetItem) => {
      if (level < 3) {
        const newList = list.filter((item) => {
          return item[`level${level}`] === assetItem;
        });
        setList(newList);
        setProgress([...progress, { [level]: assetItem }]);
        const nextLevel = level + 1;
        setLevel(nextLevel);
      } else {
        const selected = assetList.find((i) => {
          return String(i.code3) === assetItem.split(' ')[0];
        });

        onSelected(selected);
        setDialogOpen(false);
        setProgress([]);
        setLevel(0);
        setList(merged);
      }
    },
    [level, list, progress],
  );

  const onBackSelected = useCallback(() => {
    const prevLevel = Math.max(0, level - 2);
    const prevItem = progress[prevLevel][prevLevel];
    const prevList =
      level === 1
        ? merged
        : merged.filter((item) => {
            return item[`level${prevLevel}`] === prevItem;
          });
    setProgress([...progress.slice(0, -1)]);
    setLevel((lev) => lev - 1);
    setList(prevList);
  }, [level, merged]);

  if (!assetList) {
    return null;
  }

  return (
    <>
      <Button color="secondary" onClick={handleDialogOpen}>
        Change Asset Type
      </Button>
      <Dialog
        fullScreen={false}
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.dialog}
      >
        {dialogOpen ? (
          <>
            <CardTitle text="Asset List" />
            <Box
              p={2}
              justifyContent="center"
              display="flex"
              borderBottom={`1px solid ${colours.grey5}`}
            >
              <Button
                disabled={level <= 0}
                variant="contained"
                color="secondary"
                onClick={onBackSelected}
              >
                Up a Level
              </Button>
            </Box>
            <AssetList
              data={nrmLevelList}
              onSelected={onAssetSelectedFromList}
            />
          </>
        ) : null}
      </Dialog>
    </>
  );
};

AssetTypeChanger.defaultProps = {
  onSelected: () => null,
};

AssetTypeChanger.propTypes = {
  onSelected: PropTypes.func,
};

export default AssetTypeChanger;
