import { Box, Grid, Hidden, Typography } from '@mui/material';

import BreadcrumbProgress from '../routes/client-portal/surveys/components/BreadcrumbProgress';
import Image from './Image';
import { NavLink } from 'react-router-dom';
import PrettyParam from './PrettyParam';
import WeveBeenBusyBar from './WeveBeenBusyBar';
import { isLoaded } from 'react-redux-firebase';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(1),
  },
  breadcrumbContainer: {
    minHeight: 60,
  },

  welcome: (props) => ({
    fontWeight: theme.typography.fontWeightLight,
    fontSize: 18,
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
      fontSize: 26,
    },
  }),
  currentSegment: {
    fontSize: 18,

    color: theme.palette.secondary.dark,
    pointerEvents: 'none',
    fontWeight: theme.typography.fontWeightBold,
    textDecoration: 'none',
    userSelect: 'none',
    textTransform: 'capitalize',
    display: 'block',
  },
  logo: {
    borderRadius: 5,
  },
}));

const ClientHeader = () => {
  const classes = useStyles();
  const pathParams = useSelector((state) => state.params.pathParams);
  const pathSegments = useSelector((state) => state.params.pathSegments);
  const client = useSelector((state) => state.firestore.data.client);
  const profile = useSelector((state) => state.firebase.profile);

  const isAtRoot = pathSegments.length === 1;
  const currentSegment = isAtRoot ? 'Dashboard' : pathParams.slice(-1)[0];

  return !isLoaded(client) ? null : (
    <Grid container className={classes.container} alignItems="flex-start">
      <Grid item xs={12}>
        <WeveBeenBusyBar />
      </Grid>
      <Grid item xs={isAtRoot ? 8 : 12}>
        <Box display="flex" alignItems="center">
          <Hidden smDown>
            {client?.logoImage ? (
              <NavLink to={`/${client.clientId}`}>
                <Box marginRight={2}>
                  <Image
                    width={100}
                    src={client.logoImage}
                    alt="Go to dashboard"
                    className={classes.logo}
                  />
                </Box>
              </NavLink>
            ) : null}
          </Hidden>
          <Box
            display="flex"
            flexDirection="column"
            className={classes.breadcrumbContainer}
          >
            <BreadcrumbProgress />
            <Typography
              component="h1"
              variant="h1"
              sx={{ fontWeight: 400, textTransform: 'capitalize' }}
            >
              <PrettyParam id={currentSegment} />
            </Typography>
          </Box>
        </Box>
      </Grid>
      {/* {isAtRoot ? (
        <Grid item xs={4}>
          <Typography className={classes.welcome}>
            Welcome back, {profile.nameFirst}
          </Typography>
        </Grid>
      ) : null} */}
    </Grid>
  );
};

ClientHeader.defaultProps = {};

ClientHeader.propTypes = {};

export default ClientHeader;
