import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useRef, useState } from 'react';

import BackButton from '../../../components/Form/BackButton';
import ButtonsContainer from '../../../components/Form/ButtonsContainer';
import DynamicIcon from '../../../components/DynamicIcon';
import ErrorText from '../../../components/Form/ErrorText';
import NextButton from '../../../components/Form/NextButton';
import PropTypes from 'prop-types';
import SectionTitle from '../../../components/Form/SectionTitle';
import { checkForUserByEmail } from '../../../state/user';
import { clientPropTypes } from '../../../shared/propTypes';
import { emailRegex } from '../../../shared/utilities';
import { useDispatch } from 'react-redux';

const defaultValues = {
  email: '',
  nameFirst: '',
  nameLast: '',
};

const Admins = ({ formData, onSubmit, onBack, onUpdate }) => {
  const {
    register,
    reset,
    getValues,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: 'onBlur',
  });

  const {
    formState: { errors: errorsConfirm },
    setError: setErrorConfirm,
    clearErrors: clearErrorsConfirm,
  } = useForm();

  const [existingUser, setExistingUser] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const addNewButton = useRef();

  const dispatch = useDispatch();

  useEffect(
    () => () => {
      clearErrorsConfirm();
      reset(defaultValues);
    },
    [formData.portalAdmins, reset, clearErrorsConfirm, addNewButton],
  );

  const removeAdmin = (index) => {
    const withRemoved = formData.portalAdmins.filter((admin, i) => i !== index);
    onUpdate({ portalAdmins: withRemoved });
  };

  const checkForm = (event) => {
    event.preventDefault();
    if (formData.portalAdmins?.length) {
      onSubmit(formData);
    } else {
      setErrorConfirm('atLeastOne', {
        type: 'manual',
        message: 'At least one Portal Admin is required',
      });
    }
  };

  const declineAddNewAdmin = async (data) => {
    setDialogOpen(false);
    reset(defaultValues);
  };

  const checkIfUserExists = async (email) => {
    const user = await dispatch(checkForUserByEmail({ email }));
    if (user) {
      addNewButton.current.focus();
      setExistingUser(user.data());
      return setDialogOpen(true);
    }
    return true;
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const removeDuplicates = (portalAdmins) => {
    return portalAdmins.filter(
      (admin, index, array) =>
        array.findIndex((innerAdmin) => innerAdmin.email === admin.email) ===
        index,
    );
  };

  const addNewAdmin = async (data) => {
    const withAdded = formData.portalAdmins
      ? [...formData.portalAdmins, data]
      : [data];
    onUpdate({ portalAdmins: removeDuplicates(withAdded) });
  };

  const handleAddExistingUser = () => {
    const withExistingUserAdded =
      formData.portalAdmins && formData.portalAdmins.length
        ? [...formData.portalAdmins, existingUser]
        : [existingUser];
    onUpdate({ portalAdmins: removeDuplicates(withExistingUserAdded) });
    setDialogOpen(false);
  };

  return (
    <Box p={2}>
      <SectionTitle title="Add New Portal Admin" marginTop={0} />
      <form onSubmit={handleSubmit(addNewAdmin)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Controller
              control={control}
              rules={{
                required: true,
                pattern: {
                  value: emailRegex,
                  message: 'Enter a valid e-mail address',
                },
                validate: {
                  exists: checkIfUserExists,
                },
              }}
              name="email"
              render={({ field }) => (
                <TextField
                  {...field}
                  margin="normal"
                  id="email"
                  label="Email Address"
                  variant="outlined"
                  type="email"
                  fullWidth
                />
              )}
            />
            {errors.email && <ErrorText />}
          </Grid>
          <Grid item xs={12} sm={4}>
            <Controller
              control={control}
              rules={{ required: true }}
              name="nameFirst"
              render={({ field }) => (
                <TextField
                  {...field}
                  margin="normal"
                  fullWidth
                  id="nameFirst"
                  label="First Name"
                  variant="outlined"
                />
              )}
            />
            {errors.nameFirst && <ErrorText />}
          </Grid>

          <Grid item xs={12} sm={4}>
            <Controller
              control={control}
              rules={{ required: true }}
              name="nameLast"
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  margin="normal"
                  id="nameLast"
                  label="Last Name"
                  variant="outlined"
                />
              )}
            />
            {errors.nameLast && <ErrorText />}
          </Grid>
        </Grid>
        <Box
          mt={1}
          mb={3}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Button
            color="secondary"
            variant="contained"
            type="submit"
            ref={addNewButton}
          >
            Add New User
          </Button>

          {errorsConfirm.atLeastOne && (
            <Box mt={2}>
              <ErrorText text={errorsConfirm.atLeastOne.message} />
            </Box>
          )}
        </Box>
      </form>
      <form onSubmit={checkForm}>
        {formData.portalAdmins?.length ? (
          <Box>
            <SectionTitle title="Assigned Admins" />
            <Grid container>
              <Grid item xs={3} />
              <Grid item xs={6}>
                {formData.portalAdmins.map((admin, index) => {
                  return (
                    <Box
                      my={2}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      key={admin.email}
                    >
                      <Box>
                        <Typography variant="h6">{`${admin.nameFirst} ${admin.nameLast}`}</Typography>
                        <Typography variant="subtitle1">{`${admin.email}`}</Typography>
                      </Box>
                      <IconButton
                        onClick={() => removeAdmin(index)}
                        size="large"
                      >
                        <DynamicIcon
                          name="bin"
                          colour="secondary"
                          size="20px"
                        />
                      </IconButton>
                    </Box>
                  );
                })}
              </Grid>
              <Grid item xs={3} />
            </Grid>
          </Box>
        ) : null}
        <ButtonsContainer>
          <BackButton onBack={() => onBack(formData)} />
          <NextButton />
        </ButtonsContainer>
      </form>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">User already exists</DialogTitle>
        <DialogContent>
          {existingUser && (
            <Typography>
              We already have a user with the email address{' '}
              <b>{existingUser.email}</b>
            </Typography>
          )}
          <Typography>Would you like to add them as a portal admin?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={declineAddNewAdmin} color="primary">
            No
          </Button>
          <Button onClick={handleAddExistingUser} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

Admins.defaultProps = {
  formData: {},
  onBack: () => null,
  onUpdate: () => null,
};

Admins.propTypes = {
  formData: PropTypes.shape(clientPropTypes),
  onBack: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onUpdate: PropTypes.func,
};

export default Admins;
