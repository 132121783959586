import 'yet-another-react-lightbox/plugins/counter.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import 'yet-another-react-lightbox/styles.css';

import Lightbox, {
  ControllerRef,
  useController,
} from 'yet-another-react-lightbox';
import React, { useEffect, useState } from 'react';

import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import Counter from 'yet-another-react-lightbox/plugins/counter';
import DeleteIcon from '@mui/icons-material/Delete';
import Download from 'yet-another-react-lightbox/plugins/download';
import { ImageProps } from '../types/image';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';

function getImagePath(url: string, width: number, height: number) {
  const FIREBASE_PREFIX = 'https://firebasestorage.googleapis.com:443';
  if (url.startsWith(FIREBASE_PREFIX)) {
    const urlParsed = url.replace(FIREBASE_PREFIX, '');
    return `https://ik.imagekit.io/asseticom/tr:w-${width},h-${height}/${urlParsed}`;
  }
  return url;
}

const breakpoints = [4320, 2160, 1080, 640, 384, 256, 128];

interface ImageLightBox {
  selectedImageIndex: number;
  images?: ImageProps[];
  limit: number;
  onAddFiles?: (files: FileList | null) => void;
  onDeleteFile?: (index: number) => void;
  onReplaceFile?: (index: number) => void;
  onClose: () => void;
}

type ProcessedImage = ImageProps & {
  isSelected?: boolean;
};

export default function ImageLightBox({
  selectedImageIndex = -1,
  images = [],
  limit = 5,
  onAddFiles,
  onDeleteFile,
  onReplaceFile,
  onClose,
}: ImageLightBox) {
  const thumbnailsRef = React.useRef(null);
  const zoomRef = React.useRef(null);
  const lightBoxIndexRef = React.useRef(-1);
  const lightBoxRef = React.useRef<ControllerRef>(null);

  const slides = images?.map((image) => {
    const width = image.width * 4;
    const height = image.height * 4;
    return {
      src: getImagePath(image.uri, width, height),
      width,
      height,
      download: image.uri,
      downloadFilename: `${image.id}.jpg`,
      srcSet: breakpoints.map((breakpoint) => {
        const breakpointHeight = Math.round((height / width) * breakpoint);
        return {
          src: getImagePath(image.uri, breakpoint, breakpointHeight),
          width: breakpoint,
          height: breakpointHeight,
        };
      }),
    };
  });

  const handleSingleDelete = () => {
    const currentIndex = lightBoxRef.current?.getLightboxState().currentIndex;
    typeof currentIndex === 'number' && onDeleteFile?.(currentIndex);
  };

  const handleSingleReplace = () => {
    const currentIndex = lightBoxRef.current?.getLightboxState().currentIndex;
    typeof currentIndex === 'number' && onReplaceFile?.(currentIndex);
  };

  useEffect(() => {
    if (selectedImageIndex < 0) {
      lightBoxRef.current?.close();
    }
  }, [selectedImageIndex]);

  return (
    <Lightbox
      controller={{ ref: lightBoxRef }}
      on={{
        view: ({ index }) => (lightBoxIndexRef.current = index),
      }}
      open={selectedImageIndex >= 0}
      close={() => {
        onClose?.();
      }}
      noScroll={{ disabled: true }}
      slides={slides}
      index={selectedImageIndex}
      plugins={[Download, Zoom, Counter, Thumbnails]}
      toolbar={{
        buttons: [
          onAddFiles && images.length < limit && (
            <button key="my-add-button" className="yarl__button">
              <label style={{ cursor: 'pointer' }}>
                <AddAPhotoIcon key="my-add-button" />
                <input
                  type="file"
                  hidden
                  onChange={(e) => onAddFiles?.(e.target.files)}
                />
              </label>
            </button>
          ),
          onReplaceFile && (
            <button
              key="my-change-button"
              type="button"
              className="yarl__button"
            >
              <label style={{ cursor: 'pointer' }}>
                <ChangeCircleIcon key="my-change-button" />
                <input
                  type="file"
                  hidden
                  onChange={(e) => {
                    handleSingleReplace();
                    onAddFiles?.(e.target.files);
                  }}
                />
              </label>
            </button>
          ),
          onDeleteFile && (
            <button
              key="my-delete-button"
              type="button"
              className="yarl__button"
              onClick={handleSingleDelete}
            >
              <DeleteIcon key="my-delete-button" />
            </button>
          ),
          'download',
          'zoom',
          'thumbnails',
          'close',
        ],
      }}
      zoom={{ ref: zoomRef }}
      counter={{
        separator: ' of ',
        container: {
          style: {
            left: 0,
            right: 0,
            display: 'block',
            bottom: 0,
            textAlign: 'center',
            top: 'unset',
            fontSize: '20px',
          },
        },
      }}
      thumbnails={{
        ref: thumbnailsRef,
        position: 'bottom',
        width: 100,
        height: 100,
        border: 0,
        borderRadius: 5,
        padding: 0,
        gap: 10,
        imageFit: 'cover',
        vignette: true,
        showToggle: true,
      }}
    />
  );
}
