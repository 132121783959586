import { Box } from '@mui/material';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

const Settings = () => {
  return (
    <Box>
      <h1>Client Settings : Under Development</h1>
    </Box>
  );
};

export default Settings;
