import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

import {
  Box,
  CssBaseline,
  Alert as MuiAlert,
  Snackbar,
  Typography,
} from '@mui/material';
import {
  Redirect,
  Route,
  Switch,
  matchPath,
  useLocation,
} from 'react-router-dom';
import { isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';

import LoadingOverlay from './LoadingOverlay';
import Login from '../routes/login';
import MainContent from './MainContent/MainContent';
import NotAuthed from '../routes/not-authed';
import PasswordReset from '../routes/password-reset';
import { ROUTES_MAP } from '../config/routes';
import SSO from '../routes/sso';
import SideNav from './SideNav';
import TopToolbar from './TopToolbar';
import { isViewingClientPortal } from '../shared/utilities';
import { setParams } from '../state/params';
import { setSnackMessage } from '../state/ui';
import { setUserObj } from '../shared/logTools';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const getLastPathItem = (path) => path.substring(path.lastIndexOf('/') + 1);
const getPathParts = (path) => path.split('/').slice(1);

const getClosestMatch = (routeMatches, urlParts) => {
  return routeMatches
    .map((routeMatch) => {
      const pathParts = getPathParts(routeMatch.match.path);
      const matches = pathParts.reduce((accumulator, part, index) => {
        if (urlParts[index] === part) {
          return accumulator + 1;
        }
        return accumulator;
      }, 0);
      return { routeMatch, matches };
    })
    .sort((a, b) => a.matches - b.matches)
    .pop();
};

const Root = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  const ui = useSelector((state) => state.ui);
  const profile = useSelector((state) => state.firebase.profile);
  const auth = useSelector((state) => state.firebase.auth);
  const currentPortalUserRole = useSelector(
    (state) => state.firestore.data.currentPortalUserRole,
  );

  const clientId = useSelector((state) => state.params.ids.clientId);

  const loggedIn = profile.isLoaded && !profile.isEmpty;

  const roleLoaded = useMemo(
    () => isLoaded(currentPortalUserRole),
    [auth, currentPortalUserRole, isLoaded, isEmpty],
  );

  const doc = useMemo(
    () =>
      `${auth.uid}_${isViewingClientPortal(clientId) ? clientId : 'asseticom'}`,
    [auth, clientId],
  );

  useEffect(() => {
    setUserObj(auth);
  }, [auth]);

  const rolesArray = useMemo(() => {
    if (auth.uid) {
      return [
        {
          collection: 'roles',
          doc,
          storeAs: 'currentPortalUserRole',
        },
      ];
    } else {
      [];
    }
  }, [auth.uid, doc]);

  useFirestoreConnect(rolesArray);

  useEffect(() => {
    const { pathname } = location;
    const pathSegments = getPathParts(pathname);

    const routeMatches = Object.keys(ROUTES_MAP)
      .map((route) => {
        return {
          match: matchPath(pathname, {
            exact: true,
            strict: true,
            path: ROUTES_MAP[route].path,
          }),
          route,
        };
      })
      .filter((result) => result.match?.isExact);

    const closestMatch = getClosestMatch(routeMatches, pathSegments);

    if (closestMatch?.routeMatch) {
      const { route } = closestMatch.routeMatch;

      document.title = ROUTES_MAP[route].title;

      const pathParams = getPathParts(closestMatch.routeMatch.match.path).map(
        (param) => param.replace(':', ''),
      );

      dispatch(
        setParams({
          ids: closestMatch.routeMatch.match.params,
          pathSegments,
          pathParams,
        }),
      );
    }
  }, [location]);

  const handleSnackClose = () => {
    dispatch(setSnackMessage({ message: '' }));
  };

  let content;

  if (loggedIn && roleLoaded) {
    content = (
      <Box display="flex">
        <TopToolbar />
        <SideNav />
        <MainContent />
      </Box>
    );
  } else if (loggedIn && !roleLoaded) {
    content = <LoadingOverlay open />;
  }

  return (
    <CssBaseline>
      <Snackbar
        ClickAwayListenerProps={{ mouseEvent: false }}
        anchorOrigin={{
          vertical: ui.snack.vertical || 'top',
          horizontal: ui.snack.horizontal || 'right',
        }}
        open={!!ui.snack.message}
        autoHideDuration={ui.snack.duration || 5000}
        onClose={handleSnackClose}
      >
        <div>
          <Alert
            onClose={handleSnackClose}
            sx={{ color: 'white' }}
            severity={ui.snack.type || 'success'}
          >
            <Typography sx={{ color: 'white' }} color="primary">
              {ui.snack.message}
            </Typography>
          </Alert>
        </div>
      </Snackbar>
      <LoadingOverlay open={!!ui.loadingMessage}>
        {ui.loadingMessage && (
          <Typography sx={{ ml: 2 }} variant="h5" color="inherit">
            {ui.loadingMessage}
          </Typography>
        )}
      </LoadingOverlay>
      {profile.isLoaded && (
        <Box>
          <ScrollToTop />
          <Switch>
            <Route {...ROUTES_MAP.PASSWORD_RESET}>
              {loggedIn ? <Redirect to="/" /> : <PasswordReset />}
            </Route>
            <Route {...ROUTES_MAP.LOGIN}>
              <Login />
            </Route>
            <Route {...ROUTES_MAP.SSO}>
              <SSO />
            </Route>
            <Route {...ROUTES_MAP.NOT_AUTHED}>
              <NotAuthed />
            </Route>
            <Route path="*">
              {loggedIn ? content : <Redirect to="/login" />}
            </Route>
          </Switch>
        </Box>
      )}
      {!profile.isLoaded && <LoadingOverlay open />}
    </CssBaseline>
  );
};

export default Root;
