import { Box, Button, Paper, Stack, Typography } from '@mui/material';
import { parseXLSX, readURLAsBinary } from '../../shared/utilities';
import { useRef, useState } from 'react';

import FileUploaderCard from '../../components/FileUploaderCard';
import { onSFG20Submitted } from '../../state/asset-list';
import { useAppDispatch } from '../../config/store';

const SFG20_COLUMNS = [
  'maintenanceCode',
  'title',
  'skillSet',
  'skillSetFull',
  'uom',
  'annualTiming',
  'hours1',
  'hours2',
  'days1',
  'days3',
  'weeks1',
  'weeks2',
  'weeks4',
  'weeks6',
  'months1',
  'months2',
  'months3',
  'months4',
  'months6',
  'months12',
  'months13',
  'months14',
  'months18',
  'months24',
  'months26',
  'months30',
  'months36',
  'months48',
  'months60',
  'months120',
  'years1',
  'years2',
  'years3',
  'years4',
  'years5',
  'years6',
  'years7',
  'years8',
  'years10',
  'years12',
  'years15',
  'years20',
  'years25',
  'users0',
];

const InternalTools = () => {
  const [fileData, setFileData] = useState(null);
  const fileUploaderRef = useRef(null);
  const dispatch = useAppDispatch();

  const setFile = async (file) => {
    const { name } = file;
    const data = await readURLAsBinary(file);
    const sfg20Parsed = await parseXLSX(data, SFG20_COLUMNS, 0, false, true);
    setFileData({ name, data: sfg20Parsed });
  };

  const onClickedRemove = () => {
    fileUploaderRef.current?.clear();
    setFileData(null);
  };

  const submitFile = () => {
    console.log('File data:', fileData);
    dispatch(
      onSFG20Submitted({ fileName: fileData.name, data: fileData.data }),
    );
  };

  return (
    <Paper sx={{ padding: 2 }}>
      <Typography color="secondary">
        Add your SFG20 file below and click submit when ready...
      </Typography>
      <Box my={2}>
        <FileUploaderCard
          title="SFG20 Uploader"
          ref={fileUploaderRef}
          onChange={(file) => setFile(file)}
          onRemove={onClickedRemove}
          fileName={fileData?.name}
        />
      </Box>
      {fileData ? (
        <Box my={2}>
          <Typography color="secondary">
            {fileData.data.rows.length} rows will be imported
          </Typography>
        </Box>
      ) : null}
      <Stack justifyContent="flex-end" flexDirection="row">
        <Button variant="outlined" color="secondary" onClick={submitFile}>
          Submit
        </Button>
      </Stack>
    </Paper>
  );
};

export default InternalTools;
