import { Box, Grid, Paper, Typography } from '@mui/material';
import {
  documentIdMatch,
  reportTypeToString,
  statusToString,
  statuses,
} from '../../../../shared/utilities';
import { useEffect, useState } from 'react';

import { AppState } from '../../../../config/store';
import { EMPTY_ARRAY } from '../../../../shared/helpers';
import LastUpdatedTableCell from '../../../../components/LastUpdatedTableCell';
import { Statuses } from '../../../../types/status';
import Table from '../../../../components/Table';
import TableActionButton from '../../../../components/TableActionButton';
import { UrlParams } from '../../../../types/urlParams';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

const TypeDescription = ({
  label,
  value,
}: {
  label: string;
  value: string;
}) => {
  return (
    <Box>
      <Typography variant="subtitle2">
        <Typography variant="caption">{label}: </Typography>
        {value}
      </Typography>
    </Box>
  );
};

const historyColumns = [
  {
    accessorKey: 'date',
    header: 'Date',
    Cell: LastUpdatedTableCell,
  },
  {
    accessorKey: 'reportType',
    header: 'Report Type',
  },
  {
    accessorKey: 'type',
    header: 'Data Source',
    Cell: ({ row }) => {
      const { siteName, buildingName, surveyName } = row.original;
      return (
        <>
          {siteName && (
            <TypeDescription
              label="Site"
              value={row.original.siteName as string}
            />
          )}
          {buildingName && (
            <TypeDescription
              label="Building"
              value={row.original.buildingName as string}
            />
          )}
          {surveyName && (
            <TypeDescription
              label="Survey"
              value={row.original.surveyName as string}
            />
          )}
        </>
      );
    },
  },
  {
    accessorKey: 'assets',
    header: 'Asset Count',
  },
  {
    accessorKey: 'status',
    header: 'Export Status',
  },
];

const ExportHistory = () => {
  const { clientId } = useParams<UrlParams>();
  const client = useSelector((state: AppState) => state.firestore.data.client);
  const exports = useSelector(
    (state: AppState) => state.firestore.ordered.exports || EMPTY_ARRAY,
  );
  const [exportRows, setExportRows] = useState([]);

  useFirestoreConnect([
    {
      collection: 'clients',
      doc: clientId,
      subcollections: [{ collection: 'exports' }],
      storeAs: 'exports',
      limit: 10,
      orderBy: ['created', 'desc'],
    },
  ]);

  useEffect(() => {
    if (exports.length) {
      setExportRows(
        exports.map((row) => {
          const {
            created,
            siteName,
            buildingName,
            surveyName,
            fileURL,
            totalRows,
            reportType,
          } = row;
          return {
            id: created,
            date: created,
            reportType: reportTypeToString(reportType),
            status: fileURL
              ? statusToString(statuses.COMPLETED)
              : statusToString(statuses.IN_PROGRESS),

            siteName,
            buildingName,
            surveyName,
            assets: totalRows,
            fileURL: fileURL,
          };
        }),
      );
    }
  }, [exports]);

  const actionButtons = ({ row }) => {
    const rowData = row.original;

    if (rowData.status !== statusToString(Statuses.COMPLETED)) {
      return [];
    }

    return [
      <TableActionButton
        key="Download"
        text="Download"
        colour="secondary"
        onClick={() => {
          window.open(rowData.fileURL, '_blank');
        }}
      />,
    ];
  };

  return !documentIdMatch(clientId, client) || !exports.length ? null : (
    <Grid item xs={12}>
      <Paper>
        <Box p={2}>
          <Typography variant="subtitle2">Recent Exports</Typography>
        </Box>

        <Table
          rows={exportRows}
          columns={historyColumns}
          tableProps={{
            enableFilters: false,
            enableSorting: false,
            enableTopToolbar: false,
            enableBottomToolbar: false,
            enablePagination: false,
            renderRowActions: actionButtons,
            enableRowActions: true,
            positionActionsColumn: 'first',
          }}
        />
      </Paper>
    </Grid>
  );
};

export default ExportHistory;
