import {
  Box,
  Button,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useForm, useFormState } from 'react-hook-form';
import {
  NEW_PARTIAL_ID,
  getEmptyPartial,
  onPartialSubmit,
  onSelectedPartialDelete,
} from '../../../../../../state/asset';
import {
  addIdsToItemsIfNeeded,
  getMainImage,
  getPrevUrlSection,
  getRandomID,
  readURLAsData,
} from '../../../../../../shared/utilities';
import { useCallback, useEffect, useState } from 'react';

import CardTitle from './CardTitle';
import ContextAttribute from '../../../../../../components/ContextAttribute';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import ErrorText from '../../../../../../components/Form/ErrorText';
import Grid from '@mui/material/Grid';
import ImageGalleryGrid from '../../../../../../components/ImageGalleryGrid';
import ImageGrid from './ImageGrid';
import SliderWithInput from '../../../../../../components/Form/SliderWithInput';
import { colours } from '../../../../../../config/theme';
import { getImageOrPlaceholder } from '../../../../../../components/DataTable';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useModal } from '../../../../../../components/Modal';

const useStyles = makeStyles((theme) => ({
  partialImageInList: {
    width: '100%',
  },
  partialImageInDetails: {
    objectFit: 'cover',
    width: '100%',
    maxHeight: 300,
  },
  imageHolder: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    marginRight: theme.spacing(2),
    borderRadius: '25%',
    width: 50,
    height: 50,
    backgroundColor: colours.lightGrey,
    '&& img': {
      width: 50,
      height: 50,
      borderRadius: '25%',
      objectFit: 'cover',
    },
  },
}));

const defaultValues = {
  description: '',
  costPercentage: 0,
  nextRenewal: 0,
  reccurs: false,
  reccursPeriod: 0,
  images: [],
};

function getPartialsWithoutDeleted(partials) {
  return Object.keys(partials)
    .sort((a, b) => {
      return partials[a].created - partials[b].created;
    })
    .filter((id) => partials[id].deleted === 0);
}

const Partials = ({ asset }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { showModal } = useModal();
  const [partials, setPartials] = useState(asset.partials || {});
  const [partialsList, setPartialsList] = useState([]);
  const [selectedPartial, setSelectedPartial] = useState({});
  const [selectedPartialIndex, setSelectedPartialIndex] = useState(0);

  const partialAddMode = !!partialsList.find((id) => id === NEW_PARTIAL_ID);

  const { control, reset, handleSubmit, watch, setValue } = useForm({
    defaultValues,
  });

  const { isDirty, errors } = useFormState({ control });

  const partialId = partialsList[selectedPartialIndex];

  useEffect(() => {
    setPartials(asset.partials || {});
  }, [asset.partials]);

  const onSubmit = (formData) => {
    dispatch(
      onPartialSubmit({
        formData,
        asset,
        updated: isDirty,
        partialId,
      }),
    );
  };

  const onClickDeletePartial = async () => {
    const deleteConfirmed = await showModal({
      title: 'Please Confirm',
      buttons: {
        confirm: [{ text: 'Yes', value: true }],
        cancel: [{ text: 'No' }],
      },
      messages: ['Are you sure you want to delete this partial renewal?'],
    });
    if (deleteConfirmed) {
      if (partialId === NEW_PARTIAL_ID) {
        setPartials((prev) => {
          const copyOfPrev = { ...prev };
          delete copyOfPrev[NEW_PARTIAL_ID];
          return copyOfPrev;
        });
      } else {
        dispatch(onSelectedPartialDelete({ asset, partialId }));
      }

      setSelectedPartialIndex(0);
    }
  };

  const costPercentageWatcher = watch('costPercentage');
  const reccursWatcher = watch('reccurs');

  useEffect(() => {
    if (asset && partials) {
      const withoutDeleted = getPartialsWithoutDeleted(partials);

      if (withoutDeleted.length) {
        setPartialsList(withoutDeleted);
        const selected = partials[withoutDeleted[selectedPartialIndex]];

        setSelectedPartial(selected);
        reset(
          {
            ...defaultValues,
            ...selected,
            images: addIdsToItemsIfNeeded(selected.images, { asset, selected }),
          },
          { errors: true },
        );
      } else {
        setPartialsList([]);
        reset({ ...defaultValues }, { errors: true });
      }
    }
  }, [selectedPartialIndex, asset, partials]);

  const renewalCost = (
    (asset.values.Quantity *
      (asset.attributes.costPerUnit * costPercentageWatcher) *
      (asset.attributes.uplift + 1)) /
    100
  ).toFixed(2);

  const onClickedAddNewPartial = () => {
    setSelectedPartialIndex(0);
    setPartials((prev) => ({
      ...prev,
      [NEW_PARTIAL_ID]: { ...getEmptyPartial(), id: NEW_PARTIAL_ID },
    }));
  };

  return (
    <Grid container spacing={2}>
      <ErrorBoundary>
        <Grid item xs={12} sm={4}>
          <Paper>
            <CardTitle
              text="List"
              action={
                partialsList.length ? (
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={onClickedAddNewPartial}
                    disabled={partialAddMode}
                  >
                    Add a Partial Renewal
                  </Button>
                ) : null
              }
            />

            <Box px={1}>
              {partialsList.length ? (
                <List component="nav" aria-label="secondary mailbox folders">
                  {partialsList.map((id, index) => {
                    const partialData = partials[id];
                    // could be undefined briefly just after a new partial is deleted
                    return partialData ? (
                      <ListItem
                        key={id}
                        button
                        selected={index === selectedPartialIndex}
                        onClick={() => setSelectedPartialIndex(index)}
                      >
                        <ListItemAvatar>
                          <Box display="flex" alignItems="center">
                            <Box className={classes.imageHolder}>
                              {getImageOrPlaceholder(getMainImage(partialData))}
                            </Box>
                          </Box>
                        </ListItemAvatar>
                        <ListItemText primary={partialData.description} />
                      </ListItem>
                    ) : null;
                  })}
                </List>
              ) : (
                <Box py={2} px={1}>
                  <Typography variant="body2" color="secondary">
                    No partial renewals added to this asset
                  </Typography>
                </Box>
              )}
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Paper>
            <CardTitle text="Details" />
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box p={2}>
                <Grid container spacing={4} p={2}>
                  <Grid item xs={12}>
                    <Paper>
                      <CardTitle text="Images" />
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          p: 1,
                        }}
                      >
                        <ImageGalleryGrid
                          images={selectedPartial.images}
                          onUpdate={(updatedImages) => {
                            setValue('images', updatedImages, {
                              shouldDirty: true,
                            });
                          }}
                        />
                      </Box>
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name="description"
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          multiline
                          fullWidth
                          minRows={4}
                          error={!!errors.description}
                          type="text"
                          id="description"
                          label="Description"
                          variant="outlined"
                        />
                      )}
                    />
                    {errors.description && <ErrorText />}
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <ContextAttribute
                          noPadding
                          type="Renewal Cost"
                          // (quantity * costPerUnit * (uplift + 1)).toFixed(2);
                          value={`£${renewalCost}`}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Box mb={2}>
                          <InputLabel htmlFor="costPercentage">
                            Renewal Percentage
                          </InputLabel>
                        </Box>

                        <Controller
                          control={control}
                          name="costPercentage"
                          id="costPercentage"
                          error={!!errors.renewalPercentage}
                          rules={{
                            required: true,
                          }}
                          render={({ field }) => (
                            <SliderWithInput
                              {...field}
                              step={1}
                              min={1}
                              max={100}
                              suffix="%"
                            />
                          )}
                        />
                        {errors.costPercentage && <ErrorText />}
                      </Grid>
                      <Grid item xs={12}>
                        <Box mb={2}>
                          <InputLabel htmlFor="nextRenewal">
                            Next Renewal
                          </InputLabel>
                        </Box>

                        <Controller
                          name="nextRenewal"
                          id="nextRenewal"
                          control={control}
                          error={!!errors.nextRenewal}
                          rules={{
                            required: true,
                          }}
                          render={({ field }) => (
                            <SliderWithInput
                              {...field}
                              step={1}
                              min={1}
                              max={50}
                              suffix="Years"
                            />
                          )}
                        />
                        {errors.nextRenewal && <ErrorText />}
                      </Grid>
                      <Grid item xs={12}>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <InputLabel htmlFor="reccurs">
                            Renewal Reccurs
                          </InputLabel>
                          <Controller
                            name="reccurs"
                            id="reccurs"
                            control={control}
                            error={!!errors.reccurs}
                            rules={{
                              required: false,
                            }}
                            render={({ field }) => (
                              <Switch
                                {...field}
                                checked={field.value}
                                color="primary"
                                inputProps={{
                                  'aria-label': 'secondary checkbox',
                                }}
                              />
                            )}
                          />
                        </Box>

                        {errors.reccurs && <ErrorText />}
                      </Grid>
                      {!!reccursWatcher && (
                        <Grid item xs={12}>
                          <Controller
                            name="reccursPeriod"
                            id="reccursPeriod"
                            control={control}
                            error={!!errors.reccursPeriod}
                            rules={{
                              required: false,
                            }}
                            render={({ field }) => (
                              <SliderWithInput
                                {...field}
                                step={1}
                                min={1}
                                max={20}
                                suffix="Years"
                              />
                            )}
                          />
                          {errors.reccursPeriod && <ErrorText />}
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-between" mt={2}>
                      <Button
                        disabled={!partialsList.length}
                        color="secondary"
                        variant="outlined"
                        onClick={onClickDeletePartial}
                      >
                        Delete Partial
                      </Button>

                      <Button variant="contained" color="primary" type="submit">
                        Save Partial Details
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </form>
          </Paper>
        </Grid>
      </ErrorBoundary>
    </Grid>
  );
};

export default Partials;
