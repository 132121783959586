import { Button } from '@mui/material';
import PropTypes from 'prop-types';

const BackButton = ({ disabled, onBack, hidden }) => {
  return (
    <Button
      style={{ opacity: hidden ? 0 : 1 }}
      variant="outlined"
      color="secondary"
      disabled={disabled}
      onClick={onBack}
      hidden={hidden}
    >
      Back
    </Button>
  );
};

BackButton.propTypes = {
  disabled: PropTypes.bool,
  onBack: PropTypes.func,
  hidden: PropTypes.bool,
};

BackButton.defaultProps = {
  disabled: false,
  onBack: () => null,
  hidden: false,
};

export default BackButton;
