import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const NextButton = ({ text, disabled = false }) => {
  return (
    <Button
      variant="contained"
      color="primary"
      type="submit"
      disabled={disabled}
    >
      {text || 'Next'}
    </Button>
  );
};

NextButton.propTypes = {
  text: PropTypes.string,
  disabled: PropTypes.bool,
};

NextButton.defaultProps = {
  text: '',
  disabled: false,
};

export default NextButton;
