import {
  Box,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Stack,
} from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import AssetAttribute from '../../../../../../components/AssetAttribute';
import { FORM_OPTIONS_NAME } from '../../../../../../config/constants';
import FormFields from '../../../edit/asset/components/Formfields';
import PropTypes from 'prop-types';
import { getSafeTextFromText } from '../../../../../../shared/utilities';

const DEFAULT_ATTRIBUTES = { expectedLife: 100, costPerUnit: 10, uplift: 0.2 };

const Attributes = ({ onChangeAttribute }) => {
  const { control } = useFormContext();

  const allFields = useWatch({
    control,
  });
  const unitWatcher = allFields[FORM_OPTIONS_NAME].unit;
  const costWatcher = allFields[FORM_OPTIONS_NAME].cost;
  const upliftWatcher = allFields[FORM_OPTIONS_NAME].uplift;

  return unitWatcher || costWatcher ? (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-around"
      p={2}
      borderBottom="1px solid lightgrey"
    >
      {unitWatcher && <AssetAttribute label="Unit" value="m2" />}
      {costWatcher && (
        <AssetAttribute
          prefix="£"
          label="Cost Per Unit"
          onChanged={(value) => {
            onChangeAttribute('costPerUnit', value);
          }}
          value={DEFAULT_ATTRIBUTES.costPerUnit}
          validationRegex={/^\d+(\.?\d+)?$/}
        />
      )}
      {costWatcher && upliftWatcher && (
        <AssetAttribute
          onChanged={(value) => {
            onChangeAttribute('uplift', value / 100);
          }}
          label="Uplift"
          suffix="%"
          value={DEFAULT_ATTRIBUTES.uplift * 100}
          validationRegex={/^\d+$/}
        />
      )}
    </Box>
  ) : null;
};

const FormSetupPreview = ({ formFields }) => {
  const [showPreview, setShowPreview] = useState(false);
  const [attributes, setAttributes] = useState(DEFAULT_ATTRIBUTES);

  const fieldsForPreview = useMemo(
    () =>
      formFields.map((field) => {
        return {
          ...field,
          id: getSafeTextFromText(field.label),
          // hard code required to false so can continue without having to
          // change the preview form
          required: false,
        };
      }),
    [formFields],
  );

  const onChangeAttribute = useCallback(
    (prop, value) => {
      setAttributes({ ...attributes, [prop]: value });
    },
    [attributes],
  );

  return (
    <Box mt={4}>
      <Stack direction="row" justifyContent="center" mb={2}>
        <FormControlLabel
          label="Show Form Preview"
          control={
            <Checkbox
              color="primary"
              checked={showPreview}
              onChange={() => setShowPreview(!showPreview)}
            />
          }
        />
      </Stack>
      {showPreview ? (
        <>
          <Divider variant="middle">
            <Chip variant="outlined" label="Form Preview" color="primary" />
          </Divider>
          <Paper elevation={1}>
            <Box mt={2} border="1px solid lightgray" borderRadius={2}>
              <Attributes onChangeAttribute={onChangeAttribute} />
              <Grid container spacing={2} p={2} pt={0}>
                <FormFields
                  formFields={fieldsForPreview}
                  attributes={attributes}
                  linked
                />
              </Grid>
            </Box>
          </Paper>
        </>
      ) : null}
    </Box>
  );
};

FormSetupPreview.propTypes = {
  formFields: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

export default FormSetupPreview;
