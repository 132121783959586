import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { FORMFIELD_DEFAULTS } from '../../../../config/constants';
import Steps from '../steps';
import { makeStyles } from '@mui/styles';
import { onSurveySubmitted } from '../../../../state/survey';
import { resetFormData } from '../../../../state/survey-form';
import { setLoading } from '../../../../state/ui';
import { useAppDispatch } from '../../../../config/store';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(2),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  loadingMessage: {
    marginLeft: theme.spacing(2),
  },
}));

const INITIAL_SURVEY_DATA = {
  formFields: FORMFIELD_DEFAULTS,
};

const Add = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [dialogText, setDialogText] = useState('');
  const [saveError, setSaveError] = useState(false);
  const history = useHistory();
  const { clientId } = useParams();

  useEffect(() => {
    dispatch(resetFormData(INITIAL_SURVEY_DATA));
  }, [INITIAL_SURVEY_DATA]);

  const handleSubmit = async () => {
    dispatch(
      setLoading({
        loadingMessage: 'Creating survey, please wait...',
      }),
    );

    const result = await dispatch(onSurveySubmitted({ surveyId: null }));
    dispatch(setLoading(false));
    setSaveError(false);
    if (result.id) {
      setDialogText(`Survey created: ${result.data.name}`);
    } else if (result.error) {
      // must be an error
      setSaveError(true);
      setDialogText(result.error?.message);
    }
  };

  const handleDialogClose = () => {
    if (!saveError) {
      history.push(`/${clientId}/surveys`);
    } else {
      setDialogText('');
    }
  };

  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <Box>
            <Steps onSubmit={handleSubmit} />
          </Box>
        </Grid>
      </Grid>
      <Dialog
        open={!!dialogText}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {saveError ? 'Error' : 'Success'}
        </DialogTitle>
        <DialogContent>
          <Typography>{dialogText}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Add;
