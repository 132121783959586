import { Button, Stack } from '@mui/material';

import { CreateSpreadsheetReportData } from '../../../shared/types';
import PreviewTable from './PreviewTable';
import { useForm } from 'react-hook-form';

interface PreviewStep {
  handleBack: () => void;
  handleNext: () => void;
  data: Partial<CreateSpreadsheetReportData>;
}

export default function PreviewStep({
  handleBack,
  handleNext,
  data,
}: PreviewStep) {
  const { handleSubmit } = useForm<CreateSpreadsheetReportData>();

  return (
    <form onSubmit={handleSubmit(handleNext)}>
      <Stack sx={{ mt: 2 }}>
        <PreviewTable rows={data.assetData} />
      </Stack>
      <Stack
        direction={'row'}
        spacing={2}
        justifyContent="space-between"
        mt={2}
      >
        <Button variant="outlined" onClick={handleBack}>
          Back
        </Button>
        <Button variant="contained" type="submit">
          Finish
        </Button>
      </Stack>
    </form>
  );
}
