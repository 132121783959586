import {
  Box,
  Button,
  Dialog,
  Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';

import Image from './Image';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    backgroundColor: 'transparent',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
}));

type ImageZoomerProps = {
  onDelete?: () => null;
  onReplace?: (file: File) => null;
  alt?: string;
  src: string;
  width?: number;
  height?: number;
  style?: React.CSSProperties;
};

const ImageZoomer = ({
  onDelete,
  onReplace,
  alt,
  src,
  width,
  height,
  style,
}: ImageZoomerProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const bigScreen = useMediaQuery(theme.breakpoints.up('md'));
  const iHeight = bigScreen ? window.innerHeight : undefined;
  const iWidth = bigScreen ? undefined : window.innerWidth;
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };
  return (
    <>
      <Image
        alt={alt}
        src={src}
        width={width}
        height={height}
        style={style}
        onClick={handleDialogOpen}
      />
      <Dialog
        fullScreen={bigScreen}
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.dialog}
      >
        {dialogOpen && (
          <>
            <Image
              alt={alt}
              src={src}
              height={iHeight}
              width={iWidth}
              onClick={handleDialogClose}
              style={{ objectFit: 'contain', height: '100%' }}
            />
            <Box
              position="absolute"
              height={50}
              bottom={0}
              left={0}
              right={0}
              style={{ backgroundColor: '#00000066' }}
              alignItems="center"
              justifyContent="space-evenly"
              display="flex"
              p={2}
            >
              <Button variant="contained" color="secondary" onClick={onDelete}>
                Delete
              </Button>
              <Button
                variant="contained"
                color="primary"
                component="label"
                htmlFor="assetImage"
              >
                Replace
                <input
                  type="file"
                  hidden
                  name="assetImage"
                  id="assetImage"
                  onChange={(event) => {
                    const file = event?.target?.files?.[0];
                    file && onReplace?.(file);
                  }}
                />
              </Button>
            </Box>
          </>
        )}
      </Dialog>
    </>
  );
};

export default ImageZoomer;
