import { FormField, NicheFields } from '../../../../../../types/form';
import React, { useCallback, useMemo, useState } from 'react';

import { FORM_FIELDS_NAME } from '../../../../../../config/constants';
import NicheFieldsCopyDialog from '../components/NicheFieldsCopierDialog';
import NicheFieldsSelectDialog from '../components/NicheFieldsSelectorDialog';
import { useFormContext } from 'react-hook-form';

type NicheFieldsCopierProps = {
  open: () => void;
  close: () => void;
  isOpen: boolean;
  onClickSave: (nicheFields: NicheFields) => void;
  enabled: boolean;
};

const NicheFieldsCopierContext = React.createContext<NicheFieldsCopierProps>(
  {} as NicheFieldsCopierProps,
);

type NicheFieldsCopierProviderProps = {
  children: React.ReactNode;
  index: number;
  onSelect: (nicheFields: NicheFields) => void;
};

function NicheFieldsCopierProvider({
  children,
  index,
  onSelect,
}: NicheFieldsCopierProviderProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { getValues } = useFormContext();
  const { [FORM_FIELDS_NAME]: formFields } = getValues();

  const fieldsToCopyFrom = useMemo(() => {
    return formFields.filter(
      (f: FormField, idx: number) => !f.base && index !== idx,
    );
  }, [formFields, index]);

  const open = useCallback(() => {
    setIsOpen(true);
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onClickSave = useCallback((nicheFields: NicheFields) => {
    onSelect(nicheFields);
    close();
  }, []);

  const enabled = fieldsToCopyFrom.length > 0;

  const value = useMemo(
    () => ({
      open,
      close,
      isOpen,
      onClickSave,
      enabled,
    }),
    [open, close, isOpen, onClickSave, enabled],
  );

  return (
    <NicheFieldsCopierContext.Provider value={value}>
      <>
        <NicheFieldsCopyDialog fields={fieldsToCopyFrom} />
        {children}
      </>
    </NicheFieldsCopierContext.Provider>
  );
}

function useNicheFieldsCopier() {
  const context = React.useContext(NicheFieldsCopierContext);

  if (context === undefined) {
    throw new Error(
      'useNicheFieldsModal must be used within a NicheFieldsModalProvider',
    );
  }

  return context;
}
export { NicheFieldsCopierProvider, useNicheFieldsCopier };
