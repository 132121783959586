import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { FixedSizeList } from 'react-window';
import { useNicheFieldsCopier } from '../hooks/useNicheFieldsCopier';

export default function NicheFieldsCopierDialog({ fields }) {
  const { isOpen, close, onClickSave } = useNicheFieldsCopier();

  if (!isOpen) {
    return null;
  }

  return (
    <Dialog open={isOpen} onClose={close}>
      <DialogTitle id="scroll-dialog-title">
        <Typography>Copy Niche Fields</Typography>
        <IconButton
          onClick={close}
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers sx={{ padding: 0 }}>
        <Box
          sx={{
            width: '100%',
            height: 400,
          }}
        >
          <FixedSizeList
            height={400}
            width={400}
            itemSize={80}
            itemCount={fields.length}
            overscanCount={5}
            itemData={{
              fields,
              onClick: onClickSave,
            }}
          >
            {Row}
          </FixedSizeList>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

function Row({ index, style, data }) {
  const { fields } = data;
  const field = fields[index];
  const numNicheFieldsSelected = field.nicheFields.length;

  return (
    <ListItem style={style} key={index} component="div" disablePadding>
      <ListItemButton divider onClick={() => data.onClick(field.nicheFields)}>
        <ListItemText
          primary={`Field Label: ${field.label}`}
          secondary={`${numNicheFieldsSelected} asset types selected`}
        />
      </ListItemButton>
    </ListItem>
  );
}
