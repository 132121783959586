import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import AccessibleSwitcher from '../../../../../../components/AccessibleSwitcher';
import DynamicIcon from '../../../../../../components/DynamicIcon';
import { FORM_FIELDS_NAME } from '../../../../../../config/constants';
import { FormValues } from '..';

type FormFieldControlsProps = {
  index: number;
  required: boolean;
  base: boolean;
  disabled: boolean;
  hidden: boolean;
  onDelete: () => void;
};

export default function FormFieldControls({
  index,
  required,
  disabled,
  base,
  hidden,
  onDelete,
}: FormFieldControlsProps) {
  const { control } = useFormContext<FormValues>();
  return (
    <Grid item container xs={12}>
      <Box
        mt={1}
        display="flex"
        flex={1}
        flexDirection="row"
        flex-wrap="wrap"
        alignItems="flex-end"
        justifyContent="space-between"
      >
        <Box>
          <Controller
            defaultValue={required}
            name={`${FORM_FIELDS_NAME}.${index}.required`}
            control={control}
            render={({ field }) => (
              <FormControlLabel
                label="Required"
                control={
                  <Checkbox
                    {...field}
                    color="primary"
                    checked={!!field.value}
                  />
                }
              />
            )}
          />
          <Controller
            defaultValue={hidden}
            name={`${FORM_FIELDS_NAME}.${index}.hidden`}
            control={control}
            render={({ field }) => (
              <FormControlLabel
                label="Hidden"
                control={
                  <Checkbox
                    {...field}
                    color="primary"
                    checked={!!field.value}
                  />
                }
              />
            )}
          />
        </Box>
        {base ? (
          <Controller
            defaultValue={disabled}
            name={`${FORM_FIELDS_NAME}.${index}.disabled`}
            control={control}
            render={({ field }) => {
              return (
                <Button
                  sx={{
                    color:
                      field.value === true ? 'success.main' : 'secondary.main',
                  }}
                  style={{ pointerEvents: 'all' }}
                  onClick={() => {
                    field.onChange(!field.value);
                  }}
                >
                  {field.value === true ? 'Enable' : 'Disable'}
                </Button>
              );
            }}
          />
        ) : (
          <IconButton onClick={onDelete} size="large" sx={{ borderRadius: 0 }}>
            <AccessibleSwitcher
              accessibleChild={<Typography>Delete Form Field</Typography>}
            >
              <DynamicIcon name="bin" colour="secondary" size={20} />
            </AccessibleSwitcher>
          </IconButton>
        )}
      </Box>
    </Grid>
  );
}
