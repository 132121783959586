import 'leaflet/dist/leaflet.css';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { addNewTestUser, onUserSubmit } from '../../../../state/user';

import DebugOnly from '../../../../components/DebugOnly';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import UserForm from '../components/UserForm';
import { useDispatch } from 'react-redux';
import { useState } from 'react';

const Add = () => {
  const [dialogText, setDialogText] = useState({ title: null, email: null });

  const dispatch = useDispatch();

  const onAcknowledge = () => {
    setDialogText({});
  };

  const handleUserSubmit = async (formData) => {
    const { user } = await dispatch(onUserSubmit({ formData }));
    if (user) {
      setDialogText({ title: `User created`, email: user.email });
    } else {
      // must be an error
    }
  };

  const addTestUserData = async ({ user }) => {
    const result = await dispatch(addNewTestUser());

    if (result.user) {
      const { nameFirst, nameLast, email } = result.user;
      setDialogText({
        title: `Test User [ ${nameFirst} ${nameLast} ] created`,
        email,
      });
    } else {
      // must be an error
    }
  };

  return (
    <Grid container spacing={2}>
      <ErrorBoundary>
        <Grid item xs={12}>
          <DebugOnly>
            <Button
              variant="outlined"
              color="secondary"
              onClick={addTestUserData}
            >
              Add Test User Data
            </Button>
          </DebugOnly>
          <UserForm onSubmit={handleUserSubmit} />
        </Grid>
        <Dialog
          open={!!dialogText.title}
          onClose={onAcknowledge}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{dialogText.title}</DialogTitle>
          <DialogContent>
            <Typography>
              An email will be sent to <b>{dialogText.email}</b> with
              instructions on how to access your portal.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={onAcknowledge} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </ErrorBoundary>
    </Grid>
  );
};

export default Add;
