import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { addNewClient, addNewTestClient } from '../../../state/client';
import { useEffect, useState } from 'react';

import Admins from './Admins';
import Confirm from './Confirm';
import DebugOnly from '../../../components/DebugOnly';
import Images from './Images';
import Location from './Location';
import Overview from './Overview';
import TitleBar from '../../../components/MainContent/TitleBar';
import { makeStyles } from '@mui/styles';
import { setLoading } from '../../../state/ui';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(2),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
}));

function getSteps() {
  return ['Overview', 'Images', 'Location', 'Admins', 'Confirm'];
}

const Addclient = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const steps = getSteps();
  const history = useHistory();

  const handleSubmit = async () => {
    dispatch(
      setLoading({
        loadingMessage: 'Creating client portal, please wait...',
      }),
    );
    const result = await dispatch(addNewClient({ formData }));
    if (result.id) {
      dispatch(setLoading(false));
      setDialogOpen(true);
    }
  };

  const updateFormData = (data) => {
    const newData = {
      ...formData,
      ...data,
    };
    setFormData(newData);
  };

  const handleNext = (data) => {
    updateFormData(data);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = (data) => {
    if (data) {
      updateFormData(data);
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleGoToPortal = () => {
    history.push(`/${formData.clientId}`);
  };

  const handleGoToPortalDeclined = () => {
    history.push('/');
  };

  const addTestClientData = () => {
    dispatch(addNewTestClient());
  };

  // used for testing
  useEffect(() => {
    setActiveStep(0);
  }, []);

  function getStepContent({ index, data }) {
    switch (index) {
      case 0:
        return <Overview formData={data} onSubmit={handleNext} />;
      case 1:
        return (
          <Images formData={data} onBack={handleBack} onSubmit={handleNext} />
        );
      case 2:
        return (
          <Location formData={data} onBack={handleBack} onSubmit={handleNext} />
        );
      case 3:
        return (
          <Admins
            formData={data}
            onBack={handleBack}
            onSubmit={handleNext}
            onUpdate={updateFormData}
          />
        );
      case 4:
        return (
          <Confirm
            formData={data}
            onSubmit={handleSubmit}
            onBack={handleBack}
          />
        );
      default:
        return 'Unknown step';
    }
  }

  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <TitleBar title="Add New Client Portal" />
          <Box mt={2}>
            <DebugOnly>
              <Button
                onClick={addTestClientData}
                variant="contained"
                color="secondary"
              >
                Add Test Client
              </Button>
            </DebugOnly>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                  <StepContent>
                    {getStepContent({
                      index,
                      data: formData,
                    })}
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </Box>
        </Grid>
      </Grid>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Success!</DialogTitle>
        <DialogContent>
          <Typography>
            You successfully created the client portal for{' '}
            <b>{formData.name}</b>.
          </Typography>

          <Typography>Would you like to view the portal?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleGoToPortalDeclined} color="primary">
            No
          </Button>
          <Button onClick={handleGoToPortal} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Addclient;
