import { Box, Paper, ThemeProvider, Typography } from '@mui/material';
import { MRT_ColumnDef, MaterialReactTable } from 'material-react-table';

import { ZodIssue } from 'zod';
import { useMemo } from 'react';
import useTableTheme from '../hooks/useTableTheme';

//have to use an offset to get the correct row,
// as there is a header row in the excel sheet and also excel is not 0 based like zod is
const ROW_OFFSET = 2;

const ROWS_PER_PAGE = 10;

type ZodIssuesListProps = {
  issues?: ZodIssue[];
};

type Issue = {
  message: string;
  row: string | number;
  column: string | number;
};

const COLUMNS: MRT_ColumnDef<Issue>[] = [
  { accessorKey: 'row', header: 'Row Number' },
  { accessorKey: 'column', header: 'Column' },
  { accessorKey: 'message', header: 'Message' },
];

const ZodIssuesList = ({ issues }: ZodIssuesListProps) => {
  const rows = useMemo(
    () =>
      issues?.map((issue) => {
        const [row, column] = issue.path;
        return {
          message: issue.message,
          row: typeof row === 'number' ? row + ROW_OFFSET : row,
          column,
        };
      }) ?? [],
    [issues],
  );

  const tableTheme = useTableTheme();

  if (issues?.length) {
    return (
      <Paper>
        <Box p={2} my={2}>
          <Typography color="error" paragraph>
            The following issues were found with your data, please fix them and
            upload again
          </Typography>

          <ThemeProvider theme={tableTheme}>
            <MaterialReactTable
              columns={COLUMNS}
              data={rows}
              enableDensityToggle={false}
              enableFullScreenToggle={false}
              enableGlobalFilter={false}
              enableTopToolbar={false}
              enableBottomToolbar={rows.length > ROWS_PER_PAGE}
              enableColumnActions={rows.length > ROWS_PER_PAGE}
              initialState={{
                density: 'compact',
                pagination: { pageSize: ROWS_PER_PAGE, pageIndex: 0 },
              }}
            />
          </ThemeProvider>
        </Box>
      </Paper>
    );
  }

  return null;
};

export default ZodIssuesList;
