import { Box, Grid } from '@mui/material';
import { set, useForm } from 'react-hook-form';
import {
  setFormData,
  useImportStepData,
} from '../../../../../state/survey-form';

import BackButton from '../../../../../components/Form/BackButton';
import ButtonsContainer from '../../../../../components/Form/ButtonsContainer';
import CheckDataStep from './components/CheckDataStep';
import ColumnMappingStep from './components/ColumnMappingStep';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import ImportTemplateStep from './components/ImportTemplateStep';
import InstructionsHeader from './components/InstructionsHeader';
import { MappedHeaders } from '../../../../../components/ImportColumnMatcher';
import NextButton from '../../../../../components/Form/NextButton';
import NumImports from './components/NumImports';
import PrevImportsWarning from './components/PrevImportsWarning';
import { RoomProps } from '../../../../../types/room';
import { StepperStepProps } from '../../../../../types/form';
import UploadFileStep from './components/UploadFileStep';
import { ZodIssue } from 'zod';
import ZodIssuesList from '../../../../../components/ZodIssuesList';
import { removeDuplicatesFromArray } from '../../../../../shared/utilities';
import { useAppDispatch } from '../../../../../config/store';
import useLeaveHandler from '../../hooks/useLeaveHandler';
import { useState } from 'react';

const Import = ({ onLeave, onSubmit, onBack }: StepperStepProps) => {
  const {
    importData,
    roomSchedule,
    name: surveyName,
    assetsNumImported,
  } = useImportStepData();

  const { handleSubmit, getValues } = useForm();

  const dispatch = useAppDispatch();

  useLeaveHandler(onLeave, getValues);

  const [issues, setIssues] = useState<ZodIssue[]>();

  const onFileAdded = async ({ rows, headers, name }) => {
    setIssues(undefined);

    dispatch(
      setFormData({
        importData: {
          initialRows: rows,
          initialHeaders: headers,
          name,
        },
      }),
    );
  };

  const onClickedRemove = () => {
    if (roomSchedule) {
      const roomScheduleRoomsWithoutImports = roomSchedule?.data.filter(
        (r) => !r.imported,
      );
      const floors = removeDuplicatesFromArray(
        roomScheduleRoomsWithoutImports.map((r) => r.floor),
      );
      dispatch(
        setFormData({
          roomSchedule: {
            ...roomSchedule,
            data: roomScheduleRoomsWithoutImports,
            floors,
          },
        }),
      );
    }
    dispatch(
      setFormData({
        importData: undefined,
      }),
    );
  };

  function onMappingResult({
    issues,
    mappedRows,
    matches,
    rooms,
  }: {
    matches?: MappedHeaders;
    mappedRows?: any;
    issues?: ZodIssue[];
    rooms?: Partial<RoomProps>[];
  }) {
    setIssues(issues);

    dispatch(
      setFormData({
        importData: {
          ...importData,
          rows: mappedRows,
          mappedHeaders: matches,
          rooms,
        },
      }),
    );
  }

  return (
    <Box p={2}>
      <ErrorBoundary>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {assetsNumImported ? null : <InstructionsHeader />}
              <ImportTemplateStep stepNumber={1} />
              <UploadFileStep
                stepNumber={2}
                onFileAdded={onFileAdded}
                onClickedRemove={importData?.initialRows && onClickedRemove}
              />
              <ColumnMappingStep
                stepNumber={3}
                onMappingResult={onMappingResult}
              />
              <ZodIssuesList issues={issues} />
              {!issues?.length && (
                <>
                  <CheckDataStep stepNumber={4} />
                  <NumImports numberOfImports={importData?.rows?.length} />
                </>
              )}
            </Grid>
          </Grid>
          <PrevImportsWarning />
          <ButtonsContainer>
            <BackButton onBack={onBack} />
            <NextButton />
          </ButtonsContainer>
        </form>
      </ErrorBoundary>
    </Box>
  );
};

export default Import;
