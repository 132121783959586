import { Box, Stack } from '@mui/material';
import { handleLocalImage, isLocalImage, sortTypes } from '../shared/utilities';
import { useMemo, useState } from 'react';

import ComponentGuard from './ComponentGuard';
import DynamicIcon from './DynamicIcon';
import Image from './Image';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableActionButton from './TableActionButton';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import clsx from 'clsx';
import { colours } from '../config/theme';
import { makeStyles } from '@mui/styles';

export const TABLE_IMAGE_SIZE = 50;
export const ICON_SIZE = 30;

export const getImageOrPlaceholder = (imageURL, iconSize) => {
  if (imageURL && !isLocalImage(imageURL)) {
    const parsedUrl = handleLocalImage(imageURL);

    return (
      <Image
        src={parsedUrl}
        width={TABLE_IMAGE_SIZE}
        height={TABLE_IMAGE_SIZE}
        alt="table image"
        style={{ objectFit: 'cover' }}
      />
    );
  }
  const icon = imageURL && isLocalImage(imageURL) ? 'image-sync' : 'no-photo';

  return (
    <Box
      width={TABLE_IMAGE_SIZE}
      height={TABLE_IMAGE_SIZE}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <DynamicIcon
        name={icon}
        colour="darkGrey"
        width={iconSize || ICON_SIZE}
        height={iconSize || ICON_SIZE}
      />
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  tableHeaderCell: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: theme.palette.secondary.main,
  },
  tableHeaderCellSortable: {
    cursor: 'pointer',
    userSelect: 'none',
  },
  tableHeaderCellSortableIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: theme.spacing(0.5),
  },
  tableHeaderCellArrowUp: {
    transform: 'rotate(-90deg)',
    display: 'inline-block',
  },
  tableCell: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: theme.palette.secondary.light,
  },
  imageHolder: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    marginRight: theme.spacing(2),
    borderRadius: '25%',
    width: 50,
    height: 50,
    backgroundColor: colours.lightGrey,
    '&& img': {
      width: 50,
      height: 50,
      borderRadius: '25%',
      objectFit: 'cover',
    },
  },
  nameColumn: {
    minWidth: 200,
  },
  actionsColumn: {
    minWidth: 100,
  },
}));

function getOppositeDirection(direction) {
  return direction === sortTypes.DESC ? sortTypes.ASC : sortTypes.DESC;
}

const DataTable = ({
  columns,
  rows: presorted,
  fixed,
  fallback = '',
  sortable = false,
  defaultSortColumn,
  defaultSortOrder,
}) => {
  const classes = useStyles();
  const isSortable = sortable && defaultSortColumn && defaultSortOrder;

  const [sortColumn, setSortColumn] = useState(() => {
    if (!isSortable) {
      return null;
    }
    const defaultColumn = columns.find((c) => c.sort);
    return {
      label: defaultColumn.label,
      direction: defaultColumn.sort.direction,
    };
  });

  const rows = useMemo(() => {
    if (!isSortable) {
      return presorted;
    }
    return [...presorted].sort((a, b) => {
      const aVal = a[sortColumn.id];
      const bVal = b[sortColumn.id];

      if (aVal > bVal) {
        return sortColumn.direction === sortTypes.ASC ? 1 : -1;
      }
      if (aVal < bVal) {
        return sortColumn.direction === sortTypes.ASC ? -1 : 1;
      }
      return 0;
    });
  }, [presorted, sortColumn, isSortable]);

  const clickedSortColumn = (column) => {
    if (!isSortable) {
      return;
    }
    let direction;
    if (sortColumn.label === column.label) {
      direction = getOppositeDirection(sortColumn.direction);
    } else {
      direction = sortTypes.DESC;
    }
    setSortColumn({
      ...column,
      direction,
    });
  };

  return (
    <TableContainer>
      <Table
        stickyHeader
        aria-label="sticky table"
        style={{ tableLayout: fixed ? 'fixed' : 'auto' }}
      >
        <TableHead>
          <TableRow>
            {columns.map((column) => {
              const headKey = column.id;
              return (
                <ComponentGuard key={headKey} auth={column.auth}>
                  <TableCell
                    onClick={() => {
                      clickedSortColumn(column);
                    }}
                    align={column.align}
                    style={column.style}
                    className={clsx(
                      classes.tableHeaderCell,
                      { [classes.nameColumn]: column.id === 'name' },
                      { [classes.actionsColumn]: column.id === 'actions' },
                      { [classes.tableHeaderCellSortable]: isSortable },
                    )}
                  >
                    <Stack flexDirection="row">
                      <span>{column.label}</span>
                      {isSortable && column.id !== 'actions' ? (
                        <span
                          className={classes.tableHeaderCellSortableIcon}
                          style={{
                            opacity: sortColumn.label === column.label ? 1 : 0,
                          }}
                        >
                          {sortColumn.direction === sortTypes.DESC ? (
                            <DynamicIcon
                              name="arrow-down-bold-outline"
                              colour="primary"
                              size={14}
                            />
                          ) : (
                            // <Typography variant="caption">&#708;</Typography>
                            <DynamicIcon
                              name="arrow-up-bold-outline"
                              colour="primary"
                              size={14}
                            />

                            // <Typography variant="caption">&#709;</Typography>
                          )}
                        </span>
                      ) : null}
                    </Stack>
                  </TableCell>
                </ComponentGuard>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            const rowKey = row.id || row.name;
            if (!rowKey) {
              throw new Error(
                'Rows must contain a unique "id" or "name" property',
              );
            }
            return (
              <TableRow tabIndex={-1} key={rowKey}>
                {columns.map((column, index) => {
                  const columnKey = column.id + row.id;

                  const value =
                    typeof row[column.id] === 'undefined'
                      ? fallback
                      : row[column.id];
                  let cellContent = column.format
                    ? column.format(value, fallback)
                    : value;

                  if (column.id === 'actions') {
                    cellContent = row.actions.map((action) => {
                      const actionKey = rowKey + action.text;
                      return (
                        <ComponentGuard auth={action.auth} key={actionKey}>
                          <TableActionButton action={action} />
                        </ComponentGuard>
                      );
                    });
                  }
                  if (column.image) {
                    cellContent = (
                      <Box display="flex" alignItems="center">
                        <Box className={classes.imageHolder}>
                          {getImageOrPlaceholder(row.image)}
                        </Box>

                        {cellContent}
                      </Box>
                    );
                  }

                  if (column.component) {
                    cellContent = row.component(value);
                  }

                  return (
                    <ComponentGuard auth={column.auth} key={columnKey}>
                      <TableCell
                        align={column.align}
                        className={classes.tableCell}
                      >
                        {cellContent}
                      </TableCell>
                    </ComponentGuard>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

DataTable.defaultProps = {
  fixed: false,
  fallback: '',
};

DataTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  fixed: PropTypes.bool,
  fallback: PropTypes.string,
};

export default DataTable;
