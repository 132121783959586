import { AppState, useAppDispatch } from '../../../../../../config/store';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { SurveyMeta, SurveyProps } from '../../../../../../types/survey';

import CloseIcon from '@mui/icons-material/Close';
import FileUploaderCard from '../../../../../../components/FileUploaderCard';
import TabPanel from '../../../../../../components/TabPanel';
import { exportFormFieldsFromSurvey } from '../../../../../../state/survey-form';
import { parseJsonFile } from '../../../../../../shared/utilities';
import { useFieldsCopier } from '../hooks/useFieldsCopier';
import { useFirestore } from 'react-redux-firebase';
import { useFormContext } from 'react-hook-form';
import { useModal } from '../../../../../../components/Modal';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import useSurveysMeta from '../../../../../../hooks/useSurveysMeta';

const PANEL_WIDTH = 320;
const PANEL_HEIGHT = 600;

function PreviousTab() {
  const { onClickSave } = useFieldsCopier();

  const client = useSelector((state: AppState) => state.firestore.data.client);
  const { surveys } = useSurveysMeta();

  const firestore = useFirestore();

  const surveysParsed = surveys.filter((s) => s.name);

  const selectedSurvey = async (survey: SurveyMeta) => {
    const surveyDoc = await firestore
      .collection('clients')
      .doc(client.id)
      .collection('surveys')
      .doc(survey.id)
      .get();

    if (surveyDoc) {
      onClickSave(surveyDoc.data() as SurveyProps);
    }
  };

  return (
    <List sx={{ height: PANEL_HEIGHT }}>
      {surveysParsed.map((survey) => (
        <ListItem key={survey.id} disablePadding>
          <ListItemButton divider onClick={() => selectedSurvey(survey)}>
            <ListItemText
              primary={survey.name}
              secondary={`${survey.siteName} `}
            />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
}

function ExportTab() {
  const dispatch = useAppDispatch();
  const { getValues } = useFormContext();

  return (
    <Box
      p={2}
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
      }}
    >
      <Typography color="secondary" mb={2}>
        If you would like to keep a library of templates, you can export your
        current form fields setup and use it across portals and share with your
        colleagues.
      </Typography>

      <Button
        variant="contained"
        color="secondary"
        onClick={() => {
          const { formFields, formOptions } = getValues();
          dispatch(exportFormFieldsFromSurvey({ formFields, formOptions }));
        }}
      >
        Export
      </Button>
    </Box>
  );
}

function ImportTab() {
  const { onClickSave } = useFieldsCopier();
  const [fileName, setFileName] = useState();

  const { showModal } = useModal();

  function showErrorModal() {
    showModal({
      title: 'Error reading file',
      messages: ['Please ensure you are importing a valid file.'],
      buttons: {
        confirm: [{ text: 'Ok' }],
      },
    });
  }

  const setFileData = async (file) => {
    const { name } = file;

    try {
      const importData = (await parseJsonFile(file)) as SurveyProps;

      if (
        !importData.formFields ||
        !importData.formOptions ||
        !importData.assetListId
      ) {
        return showErrorModal();
      }
      setFileName(name);

      onClickSave(importData as SurveyProps);
    } catch (e) {
      return showErrorModal();
    }
  };

  return (
    <Box
      p={2}
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
      }}
    >
      <Box>
        <Typography color="secondary" paragraph>
          Import your previously exported field setups here.
        </Typography>
        <Typography color="secondary" paragraph>
          You will be given the choice whether to replace your existing fields
          or merge into them. If you merge, any imported fields with the same
          label as an existing field will replace that field.
        </Typography>
        <Typography color="secondary" paragraph>
          Note: your imported niche fields settings may not be preserved, this
          depends on the compatibility and consistency between your selected
          Asset Taxonomy and the one associated with your imported form fields.
        </Typography>
      </Box>

      <Box width="100%">
        <FileUploaderCard
          onChange={(file) => setFileData(file)}
          // onRemove={onClickedRemove}
          fileName={fileName}
        />
      </Box>
    </Box>
  );
}

export default function FormFieldsCopyFromPreviousDialog({ fields }) {
  const { isOpen, close } = useFieldsCopier();

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Dialog open={isOpen} onClose={close}>
      <DialogTitle id="copy-form-fields">
        <Typography>Import/Export Form Fields</Typography>
        <IconButton
          onClick={close}
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers sx={{ padding: 0 }}>
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="tabs for import or export of form fields"
            >
              <Tab
                label="Import from previous"
                sx={{ textTransform: 'capitalize' }}
              />
              <Tab
                label="Export to file"
                sx={{ textTransform: 'capitalize' }}
              />
              <Tab
                label="Import from file"
                sx={{ textTransform: 'capitalize' }}
              />
            </Tabs>
          </Box>

          <TabPanel value={value} index={0}>
            <PreviousTab />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ExportTab />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ImportTab />
          </TabPanel>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
