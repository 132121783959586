import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { useState } from 'react';

const CONFIRM_TEXT = 'delete';

interface DeleteClientButtonProps {
  onDelete?: () => void;
}

export default function DeleteClientButton({
  onDelete,
}: DeleteClientButtonProps) {
  const [open, setOpen] = useState(false);
  const [canDelete, setCanDelete] = useState(false);

  function onConfirmDelete() {
    setOpen(false);
    onDelete?.();
  }

  function onCancelDelete() {
    setOpen(false);
  }

  return (
    <>
      <Dialog open={open} onClose={onCancelDelete}>
        <DialogTitle id="alert-dialog-title">
          Client Portal and Data Delete
        </DialogTitle>
        <DialogContent>
          <Stack direction="column" alignItems="flex-start">
            <Typography color="error" fontWeight={700} marginBottom={2}>
              WARNING: This operation is destructive and cannot be undone!
            </Typography>
            <Typography marginBottom={2}>
              Please enter the word <b>{CONFIRM_TEXT}</b> below to confirm
            </Typography>
            <TextField
              onChange={(event) => {
                setCanDelete(event.target.value === CONFIRM_TEXT);
              }}
            ></TextField>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancelDelete} color={'error'}>
            CANCEL
          </Button>
          <Button
            onClick={onConfirmDelete}
            color={'primary'}
            disabled={!canDelete}
          >
            CONFIRM
          </Button>
        </DialogActions>
      </Dialog>
      <Button onClick={() => setOpen(true)} color="error">
        Delete Portal and Data
      </Button>
    </>
  );
}
