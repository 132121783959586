import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';

import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

function getMatchingColumnIfExists(array, matcher) {
  return array?.find((a) => a === matcher) || '';
}

type ImportMatcherRowProps = {
  destination: string;
  sourceColumns: string[];
  value?: string;
  onUpdate: (destination: string, selected: string) => void;
};

const ImportMatcherRow = ({
  destination,
  sourceColumns,
  value = '',
  onUpdate,
}: ImportMatcherRowProps) => {
  const match = useMemo(
    () => getMatchingColumnIfExists(sourceColumns, destination),
    [sourceColumns, destination],
  );
  const [selectedColumn, setSelectedColumn] = useState(value || match);

  useEffect(() => {
    onUpdate?.(destination, selectedColumn);
  }, [selectedColumn]);

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedColumn(event.target.value as string);
  };

  return (
    <Box
      sx={{ width: '100%' }}
      display="flex"
      alignItems="center"
      borderBottom={'1px solid lightgrey'}
      py={1}
    >
      <Grid container alignItems="center" sx={{ width: '100%' }}>
        <Grid item xs={6} textAlign="right">
          <FormControl variant="standard">
            <Select
              disableUnderline
              id="asset-import-column-matcher"
              value={selectedColumn}
              onChange={handleChange}
            >
              <MenuItem value={''}>Please select...</MenuItem>
              <MenuItem value={'N/A'}>Empty value</MenuItem>
              {sourceColumns.map((sc) => (
                <MenuItem key={sc} value={sc}>
                  {sc}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={6} textAlign="left">
          <Stack direction="row" alignItems="center">
            <KeyboardDoubleArrowRightIcon color="secondary" />
            <Typography color="secondary">{destination}</Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export type MappedHeaders = { [key: string]: string };

type ImportColumnWatcherProps = {
  destinationColumns: string[];
  sourceColumns: string[];
  mappedHeaders?: MappedHeaders;
  onConfirmMapping: (matches: MappedHeaders) => void;
};

function ImportColumnMatcher({
  destinationColumns,
  sourceColumns,
  mappedHeaders,
  onConfirmMapping,
}: ImportColumnWatcherProps) {
  const [matches, setMatches] = useState<MappedHeaders>(mappedHeaders || {});

  const onUpdate = useCallback((destination: string, selected: string) => {
    setMatches((m) => {
      if (typeof m === 'object') {
        return { ...m, [destination]: selected };
      } else {
        return { [destination]: selected };
      }
    });
  }, []);

  const allColumnsMapped = useMemo(() => {
    const numUnmapped =
      matches &&
      Object.values(matches).filter((m) => {
        return !m;
      });
    return numUnmapped?.length === 0;
  }, [matches]);

  return (
    <Paper sx={{ width: '100% ', marginTop: 2 }} elevation={2}>
      <Box pt={2}>
        <Stack direction="row">
          <Box flex={0.5} justifyContent={'flex-end'} display={'flex'}>
            <Typography
              align="right"
              pr={2}
              borderRadius={'1px solid red'}
              color="secondary"
              fontWeight="bold"
              width={'100%'}
            >
              Your source column
            </Typography>
          </Box>
          <Box flex={0.5}>
            <Typography align="left" pl={1} color="secondary" fontWeight="bold">
              Destination column
            </Typography>
          </Box>
        </Stack>
        <Divider />
      </Box>
      {destinationColumns.map((d) => {
        return (
          <ImportMatcherRow
            key={d}
            destination={d}
            sourceColumns={sourceColumns}
            value={mappedHeaders?.[d]}
            onUpdate={onUpdate}
          />
        );
      })}
      <Box
        sx={{ width: '100%' }}
        py={2}
        display="flex"
        flex={1}
        justifyContent="center"
      >
        <Button
          color="secondary"
          variant="contained"
          disabled={!allColumnsMapped}
          onClick={() => onConfirmMapping(matches)}
        >
          Validate
        </Button>
      </Box>
    </Paper>
  );
}

export default ImportColumnMatcher;
