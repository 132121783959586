import { Box, Button, Paper, Stack } from '@mui/material';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../../../../../shared/helpers';
import {
  MRT_ShowHideColumnsButton,
  MRT_ToggleFiltersButton,
} from 'material-react-table';
import {
  RIGHT_ALIGN_BODY,
  RIGHT_ALIGN_HEADER,
} from '../../../../../config/constants';
import { documentIdMatch, getMainImage } from '../../../../../shared/utilities';
import {
  getAssetDeleteMessages,
  onSelectedAssetDelete,
} from '../../../../../state/asset';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, useFormState } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import AddOrEditRoomDialog from '../../../../../components/AddOrEditRoomDialog';
import AssetIdSearcher from '../../../../../components/AssetIdSearcher';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import Grid from '@mui/material/Grid';
import Image360Viewer from './components/Image360Viewer';
import ImageLightBox from '../../../../../components/ImageLightBox';
import ImageTableCell from '../../../../../components/ImageTableCell';
import LastUpdatedTableCell from '../../../../../components/LastUpdatedTableCell';
import StatusStringTableCell from '../../../../../components/StatusStringTableCell';
import { Statuses } from '../../../../../types/status';
import Table from '../../../../../components/Table';
import TableActionButton from '../../../../../components/TableActionButton';
import { onSaveRoom } from '../../../../../state/room';
import useLocalStorage from '../../../../../hooks/useLocalStorage';
import { useModal } from '../../../../../components/Modal';
import useSurvey from '../../../../../hooks/useSurvey';

const getColumnFromData = (data) => {
  const columnObj = {
    accessorKey: `values.${data.id || data.label}`,
    header: data.label,
  };

  if (data.type === 'Number') {
    return {
      ...columnObj,
      ...RIGHT_ALIGN_HEADER,
      ...RIGHT_ALIGN_BODY,
    };
  }
  return columnObj;
};

const WANTED_COLUMNS = [
  'Code',
  'Quantity',
  'Remaining Life',
  'Condition',
  'Partials',
  'Flagged',
  'Status',
];

const DEFAULT_HIDDEN_COLUMNS = {
  code0: false,
  code1: false,
  code2: false,
  label0: false,
  label1: false,
  label2: false,
};

const defaultValues = {
  name: '',
  floor: '',
  areaWall: 0,
  areaFloor: 0,
  notes: '',
  images: [],
};

const Assets = () => {
  const { roomId } = useParams();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [columns, setColumns] = useState([]);
  const [showImageGallery, setShowImageGallery] = useState(-1);
  const [showImage360Viewer, setShowImage360Viewer] = useState(false);

  const { showModal } = useModal();

  const survey = useSurvey();
  const room = useSelector(
    (state) => state.firestore.data.room || EMPTY_OBJECT,
  );
  const assets = useSelector(
    (state) => state.firestore.ordered.assets || EMPTY_ARRAY,
  );
  const [assetsTableColumnVisibility, setAssetsTableColumnVisibility] =
    useLocalStorage('assetsTableColumnVisibility');

  const { reset } = useForm({
    defaultValues,
  });

  useEffect(() => {
    if (room) {
      reset(room);
    }
  }, [room]);

  const columnVisibility = useMemo(() => {
    return {
      ...DEFAULT_HIDDEN_COLUMNS,
      ...assetsTableColumnVisibility,
    };
  }, [assetsTableColumnVisibility]);

  useEffect(() => {
    if (!survey.formFields) {
      return;
    }
    const assetInfo = [
      {
        accessorKey: 'label0',
        header: 'Label 0',
      },
      {
        accessorKey: 'label1',
        header: 'Label 1',
      },
      {
        accessorKey: 'label2',
        header: 'Label 2',
      },
      {
        accessorKey: 'label',
        header: 'Asset',
        Cell: ImageTableCell,
      },
      {
        accessorKey: 'code0',
        header: 'Code 0',
      },
      {
        accessorKey: 'code1',
        header: 'Code 1',
      },
      {
        accessorKey: 'code2',
        header: 'Code 2',
      },
      {
        accessorKey: 'code',
        header: 'Code',
      },
      {
        accessorKey: 'status',
        header: 'Status',
        Cell: StatusStringTableCell,
      },
      {
        accessorKey: 'lastUpdated.time',
        header: 'Last Updated',
        Cell: LastUpdatedTableCell,
      },
    ];

    const parsed = survey.formFields
      ?.map((field) => getColumnFromData(field))
      .filter((c) => WANTED_COLUMNS.includes(c.header));
    setColumns([
      ...assetInfo,
      {
        accessorKey: 'flagged',
        header: 'Flagged',
      },
      ...parsed,
      {
        accessorKey: 'partials',
        header: 'Partials',
        ...RIGHT_ALIGN_HEADER,
        ...RIGHT_ALIGN_BODY,
      },
    ]);
  }, [survey]);

  const showConfirmDialogForAsset = async (assetRow) => {
    const assetObj = assets.find((a) => a.id === assetRow.id);

    const deleteConfirmed = await showModal({
      title: 'Please Confirm',
      buttons: {
        confirm: [{ text: 'Yes', value: true }],
        cancel: [{ text: 'No' }],
      },
      messages: getAssetDeleteMessages(assetObj),
    });
    if (deleteConfirmed) {
      dispatch(onSelectedAssetDelete({ asset: assetObj }));
    }
  };

  const rows = assets.map((asset) => {
    const {
      id,
      label,
      code,
      status,
      values,
      attributes,
      flag,
      lastUpdated,
      partialsNumCreated,
      partialsNumDeleted,
    } = asset;

    const imageURL = getMainImage(asset);

    return {
      id,
      label0: attributes.label0,
      label1: attributes.label1,
      label2: attributes.label2,
      label,
      code0: attributes.code0,
      code1: attributes.code1,
      code2: attributes.code2,
      code,
      flagged: flag ? 'YES' : 'NO',
      lastUpdated,
      values,
      renewalCost: values.costPerUnit || attributes.costPerUnit,
      status,
      image: imageURL,
      partials: partialsNumCreated - partialsNumDeleted,
    };
  });

  const addNewAsset = () => {
    history.push(`${location.pathname}/0`);
  };

  const toggleImageGallery = () => {
    setShowImageGallery(0);
  };

  const onClickedViewAsset = ({ id }) => {
    history.push(`${location.pathname}/${id}`);
  };

  const actionButtons = useCallback(
    ({ row, closeMenu }) => {
      const buttons = [];

      const rowData = row.original;

      buttons.push(
        <TableActionButton
          key="View"
          text="View"
          colour="green3"
          onClick={() => closeMenu(onClickedViewAsset(rowData))}
        />,
      );

      buttons.push(
        <TableActionButton
          key="Delete"
          text="Delete"
          colour="red"
          onClick={() => closeMenu(showConfirmDialogForAsset(rowData))}
        />,
      );

      return (
        <Stack direction="column" p={1}>
          {buttons}
        </Stack>
      );
    },
    [assets],
  );

  const [editMode, setEditMode] = useState(false);

  const floors = useMemo(() => {
    return survey?.roomSchedule?.floors || EMPTY_ARRAY;
  }, [survey]);

  const onUpdateRoom = (data) => {
    dispatch(onSaveRoom({ room: data }));
  };

  return !documentIdMatch(roomId, room) ? null : (
    <Paper sx={{ flex: 1 }} key={columns.length} id="main-content">
      <ErrorBoundary>
        {editMode ? (
          <AddOrEditRoomDialog
            onSubmit={onUpdateRoom}
            floors={floors}
            room={room}
            onClose={() => setEditMode(false)}
          />
        ) : null}
        <ImageLightBox
          selectedImageIndex={showImageGallery}
          onClose={() => setShowImageGallery(-1)}
          images={room.images}
        />
        {showImage360Viewer ? (
          <Image360Viewer
            open={showImage360Viewer}
            onClose={() => setShowImage360Viewer(false)}
            imageUri={room.images360?.[0]?.uri}
            name={room.name}
          />
        ) : null}
        <Table
          rows={rows}
          columns={columns}
          onClickRow={onClickedViewAsset}
          tableProps={{
            renderRowActionMenuItems: actionButtons,
            enableRowActions: true,
            positionActionsColumn: 'first',
            onColumnVisibilityChange: setAssetsTableColumnVisibility,
            state: {
              columnVisibility,
            },

            renderTopToolbar: ({ table }) => (
              <>
                <Stack
                  spacing={1}
                  p={1}
                  mb={1}
                  direction="row"
                  justifyContent="flex-end"
                  sx={{ width: '100%' }}
                >
                  {survey.status !== Statuses.IN_PROGRESS ? (
                    <Button
                      variant="outlined"
                      onClick={() => {
                        setEditMode((prev) => !prev);
                      }}
                      color="primary"
                    >
                      Edit Room
                    </Button>
                  ) : null}
                  {room?.images360?.length ? (
                    <Button
                      variant="outlined"
                      onClick={() => setShowImage360Viewer(true)}
                      color="primary"
                    >
                      360 Room Image
                    </Button>
                  ) : null}{' '}
                  {room?.images?.length ? (
                    <Button
                      variant="outlined"
                      onClick={toggleImageGallery}
                      color="primary"
                    >
                      Room Images ({room?.images?.length})
                    </Button>
                  ) : null}
                  <AssetIdSearcher />
                  <Button
                    variant="outlined"
                    onClick={addNewAsset}
                    color="primary"
                  >
                    Add An Asset
                  </Button>
                  <Box>
                    <MRT_ToggleFiltersButton table={table} />
                    <MRT_ShowHideColumnsButton table={table} />
                  </Box>
                </Stack>
              </>
            ),
          }}
        />
      </ErrorBoundary>
    </Paper>
  );
};

export default Assets;
