import { RootStateOrAny, useSelector } from 'react-redux';

import { RoomProps } from '../types/room';
import { getAllCombinedRoomsForSurvey } from '../shared/utilities';
import isEqual from 'lodash.isequal';
import { useMemo } from 'react';
import useSurvey from './useSurvey';

export function useAllRoomsForFloor(
  floor?: string,
  sortFunction?: (a: any, b: any) => number | undefined,
) {
  const survey = useSurvey();

  const rooms = useSelector(
    (state: RootStateOrAny) => state.firestore.ordered.rooms ?? [],
    isEqual,
  );

  const roomsRequestComplete = useSelector((state: RootStateOrAny) => {
    return !state.firestore.status.requesting?.rooms;
  });

  const allRooms = useMemo(() => {
    return getAllCombinedRoomsForSurvey(survey, rooms, floor, sortFunction);
  }, [survey, rooms, floor, sortFunction]);

  return roomsRequestComplete && allRooms ? (allRooms as RoomProps[]) : [];
}
