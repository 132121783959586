import { createSlice } from '@reduxjs/toolkit';

const initialState = { ids: {}, pathSegments: [], pathParams: [] };

const paramsSlice = createSlice({
  name: 'params',
  initialState,
  reducers: {
    setParams(state, action) {
      // have to put this hack in to keep any ids that aren't stored in url ('floor' in this case)
      return {
        ...action.payload,
        ids: { ...action.payload.ids },
      };
    },
    setParamId(state, action) {
      state.ids[action.payload.id] = action.payload.value;
      return state;
    },
  },
});

export default paramsSlice.reducer;

export const { setParams, setParamId } = paramsSlice.actions;
