import { CreatePhotoScheduleReportData } from '../types';

interface InsidePage {
  data: CreatePhotoScheduleReportData;
}
export default function InsidePage({ data }: InsidePage) {
  return (
    <div className="fill-page-no-margin flex-column flex-justify-space-evenly flex-align-center">
      <div className="top-right-content"></div>

      <div className="bottom-left-content">
        <p className="powered-by-asseticom">Powered by Asseticom</p>
      </div>

      <div className="bottom-right-content">
        <div className="flex-justify-end">
          <img src={data.organisationLogo} className="footer-header-image" />
        </div>
      </div>
      <div>
        <p className="text-bold">Address</p>
        <p>{data.address1}</p>
        {data.address2 && <p>{data.address2}</p>}
        <p>{data.townCity}</p>
        {data.county && <p>{data.county}</p>}
        {data.postCode && <p>{data.postCode}</p>}
      </div>
      <div className="full-width flex-row flex-align-center flex-justify-space-around">
        <div>
          <p className="text-bold">Prepared By</p>
          <p>{data.preparedByName}</p>
          {data.preparedByEmail && <p>{data.preparedByEmail}</p>}
          {data.preparedByPhone && <p>{data.preparedByPhone}</p>}

          {data.preparedBySignatureImage && (
            <img
              className="signature-image"
              src={data.preparedBySignatureImage}
            ></img>
          )}
        </div>

        <div>
          <p className="text-bold">Approved By</p>
          <p>{data.approvedByName}</p>
          {data.approvedByEmail && <p>{data.approvedByEmail}</p>}
          {data.approvedByPhone && <p>{data.approvedByPhone}</p>}

          {data.approvedBySignatureImage && (
            <img
              className="signature-image"
              src={data.approvedBySignatureImage}
            ></img>
          )}
        </div>
      </div>
    </div>
  );
}
