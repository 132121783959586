import { CreatePhotoScheduleReportData } from '../types';
import { asseticomLogoData } from '../assets/asseticom-logo';
import { useMemo } from 'react';

interface FrontPage {
  data: CreatePhotoScheduleReportData;
}
export default function FrontPage({ data }: FrontPage) {
  const reportDatePretty = useMemo(
    () => new Date(data.reportDate).toLocaleDateString(),
    [data.reportDate],
  );
  return (
    <div className="front-cover fill-page-no-margin flex-justify-center flex-align-center">
      <div style={{ position: 'absolute', top: 20, right: 20 }}>
        <img src={asseticomLogoData} className="front-page-image" />
      </div>

      <div style={{ position: 'absolute', bottom: 20, left: 20 }}>
        <img src={data.clientLogo} className="front-page-image" />
      </div>
      <div style={{ position: 'absolute', bottom: 20, right: 20 }}>
        <img src={data.organisationLogo} className="front-page-image" />
      </div>

      <div style={{ textAlign: 'center' }}>
        <h1 className="title-text">{data.reportTitle}</h1>
        {data.reportSubtitle ? (
          <h1 className="title-text">{data.reportSubtitle}</h1>
        ) : null}
        <h1 className="title-text">{reportDatePretty}</h1>
      </div>
    </div>
  );
}
