import { Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';

import AssetDetails from './components/AssetDetails';
import { EMPTY_OBJECT } from '../../../../../shared/helpers';
import Partials from './components/Partials';
import TabPanel from '../../../../../components/TabPanel';
import { colours } from '../../../../../config/theme';
import { documentIdMatch } from '../../../../../shared/utilities';
import { makeStyles } from '@mui/styles';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useSurvey from '../../../../../hooks/useSurvey';

const useStyles = makeStyles((theme) => ({
  cardTitle: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${colours.grey5}`,
  },
  cardTitleText: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const defaultValues = {
  additionalComments: '',
  assetDescription: '',
  quantity: 1,
  remainingLife: 0,
  condition: '',
  healthAndSafetyIssue: '',
};

export function getEmptyAsset(survey, room) {
  return {
    id: '',
    values: {},
    attributes: {
      code0: '',
      code1: '',
      code2: '',
      code3: '',
      label0: '',
      label1: '',
      label2: '',
      label3: '',
      uom: '',
      costPerUnit: 0,
      expectedLife: 0,
      uplift: 0,
    },
    label: '',
    code: '',
    created: Date.now(),
    deleted: 0,
    flag: null,
    images: [],
    confidence: 0,
    status: 'inProgress',
    clientId: survey.clientId,
    siteId: survey.siteId,
    siteName: survey.siteName,
    buildingId: survey.buildingId,
    buildingName: survey.buildingName,
    surveyId: survey.id,
    surveyName: survey.name,
    roomId: room.id,
    roomName: room.name,
    surveyorId: survey.surveyorId,
    surveyorName: survey.surveyorName,
    partials: {},
    partialsNumCreated: 0,
    partialsNumDeleted: 0,
  };
}

const Asset = () => {
  const { assetId, clientId, roomId, surveyId } = useParams();

  const newAsset = assetId === '0';

  const a = useSelector((state) =>
    !newAsset ? state.firestore.data.asset : null,
  );

  const survey = useSurvey();
  const room = useSelector(
    (state) => state.firestore.data.room || EMPTY_OBJECT,
  );

  const asset = useMemo(() => {
    if (!a && survey && room) {
      return getEmptyAsset(survey, room);
    }
    return a;
  }, [a, survey, room]);

  const [numPartials, setNumPartials] = useState(0);

  useFirestoreConnect([
    {
      collection: 'users',
      doc: asset?.surveyorId || 0,
      storeAs: 'surveyor',
    },
  ]);
  useFirestoreConnect([
    {
      collection: 'clients',
      doc: clientId,
      subcollections: [
        { collection: 'surveys', doc: surveyId },
        { collection: 'rooms', doc: roomId },
        { collection: 'assets', doc: assetId },
      ],
      storeAs: 'asset',
    },
  ]);

  const [tab, setTab] = useState('asset');

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.up('lg'));
  const sm = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    if (asset) {
      const partialIds = Object.keys(asset.partials);
      const num = partialIds.filter(
        (id) => asset.partials[id].deleted === 0,
      ).length;
      setNumPartials(num);
    }
  }, [asset]);

  return !newAsset && !documentIdMatch(assetId, asset) ? null : (
    <>
      <Tabs
        id="main-content"
        variant={sm ? 'fullWidth' : null}
        centered={lg}
        value={tab}
        onChange={handleTabChange}
        aria-label="simple tabs example"
        textColor="primary"
        indicatorColor="primary"
      >
        <Tab label="Asset" value="asset" />
        {numPartials && (
          <Tab label={`Partial Renewals (${numPartials})`} value="partials" />
        )}
        {/* dont allow adding partials until asset is saved */}
        {!newAsset && !numPartials && (
          <Tab label="Add Partial Renewal" value="partials" />
        )}
      </Tabs>
      <TabPanel value={tab} index="asset">
        <AssetDetails asset={asset} numPartials={numPartials} />
      </TabPanel>
      <TabPanel value={tab} index="partials">
        <Partials asset={asset} />
      </TabPanel>
    </>
  );
};

export default Asset;
