import { Stack, Step, StepButton, StepContent, Stepper } from '@mui/material';
import { useContext, useState } from 'react';

import { AppState } from '../../../../../../../config/store';
import { CompleteDialog } from '../../../shared/form/CompleteDialog';
import { CreateAssetReportsContext } from '../../..';
import { CreateSpreadsheetReportData } from '../../../shared/types';
import FiltersStep from '../../../shared/form/FiltersStep';
import PreviewStep from './PreviewStep';
import ReportTypeStep from '../../../shared/form/ReportTypeStep';
import SettingsStep from './SettingsStep';
import { createSpreadsheetReport } from '../../../shared/form/data-access';
import { useSelector } from 'react-redux';

const FORM_STEPS = ['Report Type', 'Filters', 'Settings', 'Preview'];

const DEFAULT_VALUES = {
  useFriendlyImageLinks: true,
  numYears: 20,
};

function SpreadsheetReports() {
  const client = useSelector((state: AppState) => state.firestore.data.client);

  const [currentStep, setCurrentStep] = useState(0);

  const [showDialog, setShowDialog] = useState(false);

  const { changeReportType, reportType } = useContext(
    CreateAssetReportsContext,
  );

  const [values, setValues] = useState<Partial<CreateSpreadsheetReportData>>({
    ...DEFAULT_VALUES,
    clientId: client.id,
    clientName: client.name,
    reportType,
  });

  const handleClickStep = (index) => {
    setCurrentStep(index);
  };

  const handleBack = (data?: Partial<CreateSpreadsheetReportData>) => {
    console.log({ handleBack: data });
    setValues((prev) => ({ ...prev, ...data }));
    setCurrentStep((prev) => prev - 1);
  };

  const handleNext = (data: Partial<CreateSpreadsheetReportData>) => {
    console.log({ data });
    setValues((prev) => ({ ...prev, ...data }));
    setCurrentStep((prev) => prev + 1);
  };

  const handleFinish = () => {
    console.log('Generate Report Here', values);
    createSpreadsheetReport(values);
    setShowDialog(true);
  };

  return (
    <>
      <Stepper activeStep={currentStep} orientation="vertical" sx={{ p: 2 }}>
        {FORM_STEPS.map((label, index) => (
          <Step key={label}>
            <Stack direction="row" spacing={2} alignItems="center">
              <StepButton onClick={() => handleClickStep(index)}>
                {label}
              </StepButton>
            </Stack>
            <StepContent>
              {(index === 0 && (
                <ReportTypeStep
                  handleNext={handleNext}
                  onChange={changeReportType}
                  reportType={reportType}
                />
              )) ||
                (index === 1 && (
                  <FiltersStep
                    clientId={client.id}
                    defaultValues={values}
                    handleNext={handleNext}
                    handleBack={handleBack}
                    requiredFilters={{ siteId: true }}
                  />
                )) ||
                (index === 2 && (
                  <SettingsStep
                    reportType={reportType}
                    handleBack={handleBack}
                    handleNext={handleNext}
                    defaultValues={values}
                  />
                )) ||
                (index === 3 && values.assetData && (
                  <PreviewStep
                    handleBack={handleBack}
                    handleNext={handleFinish}
                    data={values}
                  />
                ))}
            </StepContent>
          </Step>
        ))}
      </Stepper>
      <CompleteDialog open={showDialog} onClose={() => setShowDialog(false)} />
    </>
  );
}

export default SpreadsheetReports;
