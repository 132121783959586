import { Avatar, Box, Typography, useTheme } from '@mui/material';

import PropTypes from 'prop-types';
import { colours } from '../config/theme';
import { isLoaded } from 'react-redux-firebase';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

const UserInfo = ({ type, hideName }) => {
  const theme = useTheme();
  const profile = useSelector((state) => state.firebase.profile);

  const initials = isLoaded(profile)
    ? profile.nameFirst.slice(0, 1) + profile.nameLast.slice(0, 1)
    : '';

  const history = useHistory();

  const handleClick = () => {
    history.push('/user');
  };

  const avatarSize = type === 'small' ? theme.spacing(4) : theme.spacing(8);

  return (
    <Box
      onClick={handleClick}
      sx={{
        cursor: 'pointer',
        padding: type === 'small' ? 0 : 1,
        flexDirection: type === 'small' ? 'row' : 'column',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Avatar
        src={profile.imageURL}
        sx={{
          bgcolor:
            type === 'small' ? colours.white : theme.palette.secondary.main,
          color:
            type === 'small' ? theme.palette.secondary.main : colours.white,
          mr: type === 'small' ? theme.spacing(1) : 0,

          width: avatarSize,
          height: avatarSize,
          fontWeight: theme.typography.fontWeightMedium,
          fontSize:
            type === 'small'
              ? theme.typography.caption.fontSize
              : theme.typography.h4.fontSize,
        }}
      >
        {initials}
      </Avatar>
      {!hideName && (
        <Box display="flex" alignItems="center" flexDirection="column">
          <Typography
            fontWeight="medium"
            variant={type === 'small' ? 'caption' : 'body1'}
          >{`${profile.nameFirst} ${profile.nameLast}`}</Typography>
        </Box>
      )}
    </Box>
  );
};

UserInfo.propTypes = {
  type: PropTypes.string,
  hideName: PropTypes.bool,
};

UserInfo.defaultProps = {
  type: null,
  hideName: false,
};

export default UserInfo;
