import * as pdfjs from 'pdfjs-dist/build/pdf';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
} from '@mui/material';

import { EMPTY_ARRAY } from '../../../../../../shared/helpers';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileUploaderCard from '../../../../../../components/FileUploaderCard';
import { Floorplan } from '../../../../../../types/floorplan';
import MapElement from './MapElement';
import { RoomScheduleData } from '../../../../../../types/survey';
import { addFileObject } from '../../../../../../state/files';
import { readURLAsData } from '../../../../../../shared/utilities';
import { recordError } from '../../../../../../shared/logTools';
import { setLoading } from '../../../../../../state/ui';
import { useAppDispatch } from '../../../../../../config/store';
import { useModal } from '../../../../../../components/Modal';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const FLOOR_PLAN_IMAGE_QUALITY = 0.7;
const MAX_FLOORPLAN_IMAGE_DIMENSION = 5000;

type FloorPlanProps = {
  floorPlan?: Floorplan;
  rooms: RoomScheduleData;
  onChange: (event: any, isExpanded: any) => void;
  onUpdate: (data: Partial<Floorplan> | undefined, floorName: string) => void;
  expanded: boolean;
  index: number;
  floorName: string;
};

export default function FloorPlan({
  floorPlan,
  floorName,
  rooms,
  expanded,
  index,
  onChange,
  onUpdate,
}: FloorPlanProps) {
  const dispatch = useAppDispatch();
  const { showModal } = useModal();

  const setFileData = async (file, id) => {
    try {
      const { lastModified, name } = file;

      const url = window.URL.createObjectURL(file);

      // used to keep the existing markers if the file name is the same when replacing
      const isSameFileName = name === floorPlan?.fileName;

      if (file.type === 'application/pdf') {
        dispatch(
          setLoading({
            loadingMessage: 'Converting PDF, please wait...',
          }),
        );
        const load = await pdfjs.getDocument(url);
        const pdf = await load.promise;
        const page = await pdf.getPage(1);
        const [left, top, width, height] = page.view;
        const scaleX = MAX_FLOORPLAN_IMAGE_DIMENSION / (width - left);
        const scaleY = MAX_FLOORPLAN_IMAGE_DIMENSION / (height - top);
        const scale = Math.min(scaleX, scaleY);
        const viewport = page.getViewport({ scale });

        const canvas = document.createElement('canvas');

        // Prepare canvas using PDF page dimensions
        const context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        // Render PDF page into canvas context
        const renderContext = {
          canvasContext: context,
          viewport,
        };

        const renderTask = await page.render(renderContext);
        await renderTask.promise;
        const dataUrl = canvas.toDataURL(
          'image/jpeg',
          FLOOR_PLAN_IMAGE_QUALITY,
        );
        dispatch(
          addFileObject({ fileUrl: dataUrl, fileName: name, id, lastModified }),
        );
        onUpdate(
          {
            data: dataUrl,
            fileName: name,
            lastModified,
            markers: isSameFileName ? floorPlan?.markers : [],
          },
          floorName,
        );
        dispatch(setLoading(false));
      } else {
        onUpdate(undefined, floorName);
        dispatch(
          addFileObject({ fileUrl: url, fileName: name, id, lastModified }),
        );
        const data = (await readURLAsData(file)) as string;
        onUpdate(
          {
            data,
            floor: floorName,
            fileName: name,
            lastModified,
            markers: isSameFileName ? floorPlan?.markers : [],
          },
          floorName,
        );
      }
    } catch (error) {
      recordError(error, { file });
      dispatch(setLoading(false));
      showModal({
        title: 'There has been an error',
        buttons: {
          confirm: [{ text: 'Ok' }],
        },
        messages: [
          'Sorry, there has been an error reading this file.',
          'Please ensure it is a valid file',
        ],
      });
    }
  };

  const fileUrl = floorPlan?.data || floorPlan?.url;

  const onImageLoaded = (imageDimensions) => {
    onUpdate({ ...floorPlan, ...imageDimensions }, floorName);
  };

  const markersChanged = (markers) => {
    onUpdate({ ...floorPlan, markers }, floorName);
  };

  const onClickedRemove = () => {
    onUpdate(undefined, floorName);
  };

  return (
    <>
      <Accordion
        expanded={expanded}
        onChange={onChange}
        key={floorName}
        TransitionProps={{ unmountOnExit: true }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${index}bh-content`}
          id={`panel${index}bh-header`}
        >
          <Typography>{floorName}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box flexDirection="column" flex={1}>
            <Box mb={2} flex={1}>
              <FileUploaderCard
                title="Floorplan"
                onChange={(file) => setFileData(file, floorName)}
                onRemove={onClickedRemove}
                fileName={floorPlan?.fileName}
              />
            </Box>
            {fileUrl && (
              <>
                <Box my={2}>
                  <Divider variant="middle">
                    <Typography color="primary.main" variant="body1">
                      Double-click the floor plan to add a marker
                    </Typography>
                  </Divider>
                </Box>
                <MapElement
                  onImageLoaded={onImageLoaded}
                  onMarkersChanged={markersChanged}
                  fileUrl={fileUrl}
                  roomsList={rooms}
                  markersList={floorPlan?.markers || EMPTY_ARRAY}
                />
              </>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
}
