import { Box, Typography, useTheme } from '@mui/material';
import { StandardTheme, colours } from '../config/theme';

type PillProps = {
  text: string;
  colour: keyof typeof StandardTheme['palette'];
};

const Pill = ({ text, colour }: PillProps) => {
  const theme = useTheme();
  const colourFromTheme = theme.palette[colour]?.main;
  return (
    <Box
      py={0.3}
      px={1}
      borderRadius={500}
      sx={{ backgroundColor: colourFromTheme }}
    >
      <Typography
        sx={{
          color: colours.white,
          fontFamily: 'Roboto',
          fontStyle: 'italic',
          fontSize: 10,
          fontWeight: 500,
        }}
      >
        {text.toUpperCase()}
      </Typography>
    </Box>
  );
};

export default Pill;
