import { AppState } from '../config/store';
import { createSelector } from '@reduxjs/toolkit';
import { documentIdMatch } from '../shared/utilities';
import { useSelector } from 'react-redux';

const selectParamsData = (state: AppState) => state.params;

const paramsSelector = createSelector([selectParamsData], (state) => {
  return state.ids;
});

const selectFirestoreData = (state: AppState) => state.firestore.data;

const firestoreSelector = createSelector([selectFirestoreData], (state) => {
  const { client, site, survey, room, asset, user } = state;
  return {
    client,
    site,
    survey,
    room,
    asset,
    user,
  };
});

type PrettyParamProps = {
  id: string;
};

const PrettyParam = ({ id }: PrettyParamProps) => {
  const paramIds = useSelector(paramsSelector);

  const { client, site, survey, room, asset, user } =
    useSelector(firestoreSelector);

  const getTextForParam = (param) => {
    switch (param) {
      case 'clientId':
        return documentIdMatch(paramIds[param], client) && client.name;
      case 'siteId':
        return documentIdMatch(paramIds[param], site) && site.name;
      case 'surveyId':
        return documentIdMatch(paramIds[param], survey) && survey.name;
      case 'roomId':
        return documentIdMatch(paramIds[param], room) && room.name;
      case 'assetId':
        return documentIdMatch(paramIds[param], asset) && asset.label;
      case 'userId':
        return (
          documentIdMatch(`${paramIds[param]}`, user) &&
          `${user.nameFirst} ${user.nameLast}`
        );
      default:
        // tidy it up by removing hyphen. capitalisation is taken care of with css class
        return param ? param.replace('-', ' ') : '';
    }
  };
  return <>{id ? getTextForParam(id) : null}</>;
};

export default PrettyParam;
