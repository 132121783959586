import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { FormField, NicheFields } from '../../../../../../types/form';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';

import { AssetAttributes } from '../../../../../../types/asset';
import { Draggable } from 'react-beautiful-dnd';
import { EMPTY_ARRAY } from '../../../../../../shared/helpers';
import ErrorIcon from '@mui/icons-material/Error';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FORM_FIELDS_NAME } from '../../../../../../config/constants';
import FormFieldControls from './FormFieldControls';
import FormFieldLabel from './FormFieldLabel';
import FormFieldNicheFieldSelector from './FormFieldNicheFieldsSelector';
import FormFieldTypeSelector from './FormFieldTypeSelector';
import { FormValues } from '..';
import { NicheFieldsSelectorProvider } from '../hooks/useNicheFieldsSelector';
import { colours } from '../../../../../../config/theme';
import { getFieldElementForType } from '../utils';

type FormFieldRowProps = {
  index: number;
  assetList: AssetAttributes[] | typeof EMPTY_ARRAY;
  expanded?: boolean;
  onRemoveField: (index: number) => void;
  id: string;
} & FormField;

function FormFieldRow({
  assetList,
  expanded,
  index,
  onRemoveField,
  id,
}: FormFieldRowProps) {
  const [expand, setExpand] = useState(expanded);

  const { setValue, control } = useFormContext<FormValues>();

  const formField: FormField = useWatch({
    name: `${FORM_FIELDS_NAME}.${index}`,
  });

  const { errors } = useFormState({
    control,
    name: `${FORM_FIELDS_NAME}.${index}`,
  });

  const error = errors[FORM_FIELDS_NAME]?.[index] ?? undefined;

  useEffect(() => {
    setExpand(expanded);
  }, [expanded]);

  function onSaveNicheFields(nicheFields: NicheFields, excludeMode: boolean) {
    setValue(
      `${FORM_FIELDS_NAME}.${index}.nicheFieldsExcludeMode`,
      excludeMode,
      { shouldValidate: true },
    );
    setValue(`${FORM_FIELDS_NAME}.${index}.nicheFields`, nicheFields, {
      shouldValidate: true,
    });
  }

  const FormElement = useMemo(() => {
    return getFieldElementForType(formField.type)({
      formField,
      index,
    });
  }, [formField, index]);

  const disabledSXStyle = useMemo(() => {
    if (formField?.base) {
      return {
        opacity: 0.4,
        pointerEvents: 'none',
      };
    }
    return {};
  }, [formField.base]);

  async function onClickAccordion() {
    setExpand((prev) => !prev);
  }

  return (
    <Draggable key={id} draggableId={id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Accordion
            key={formField.id}
            TransitionProps={{ unmountOnExit: true }}
            expanded={expand}
            onChange={onClickAccordion}
          >
            <AccordionSummary
              sx={{
                backgroundColor: colours.white,
                borderTop: `1px solid ${colours.lightGrey}`,
                borderLeft: `1px solid ${colours.lightGrey}`,
                borderRight: `1px solid ${colours.lightGrey}`,
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${formField.id}-content`}
              id={`${formField.id}-header`}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ flex: 1, mr: 1 }}
              >
                <Stack
                  direction="row"
                  alignItems="flex-end"
                  spacing={1}
                  flex={1}
                >
                  <Typography color="secondary">
                    {formField.label || 'No Label Set'}
                  </Typography>
                  {error && <ErrorIcon color="error" />}
                </Stack>

                <Chip
                  label={formField.type || 'No Type Set'}
                  sx={{ pointerEvents: 'none' }}
                />
              </Stack>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                backgroundColor: colours.light2,
                border: `1px solid ${colours.lightGrey}`,
                padding: 2,
              }}
            >
              <Box
                sx={{
                  borderColor: colours.lightGrey,
                  backgroundColor: colours.white,
                  borderRadius: 3,
                }}
              >
                <Paper
                  elevation={3}
                  sx={{
                    padding: 2,
                    borderRadius: 3,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={4} sx={disabledSXStyle}>
                      <FormFieldLabel label={formField.label} index={index} />
                    </Grid>
                    <Grid item xs={6} sm={6} lg={4} sx={disabledSXStyle}>
                      <FormFieldTypeSelector
                        index={index}
                        type={formField?.type}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6} lg={4} sx={disabledSXStyle}>
                      <NicheFieldsSelectorProvider
                        assetList={assetList}
                        onSave={onSaveNicheFields}
                      >
                        <FormFieldNicheFieldSelector
                          nicheFields={formField?.nicheFields}
                          excludeMode={formField?.nicheFieldsExcludeMode}
                          index={index}
                        />
                      </NicheFieldsSelectorProvider>
                    </Grid>
                  </Grid>
                  {FormElement}
                  <FormFieldControls
                    base={formField.base}
                    disabled={formField.disabled}
                    hidden={formField.hidden}
                    required={formField.required}
                    index={index}
                    onDelete={() => onRemoveField(index)}
                  />
                </Paper>
              </Box>
            </AccordionDetails>
          </Accordion>
        </div>
      )}
    </Draggable>
  );
}

export default FormFieldRow;
