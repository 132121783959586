import { Box, Typography } from '@mui/material';

export default function InstructionsHeader() {
  return (
    <Box>
      <Box border={1} borderColor="primary.main" p={2}>
        <Typography color="secondary" variant="body1" align="center">
          If you have existing data that you would like to use in this survey,
          you can import it here.
        </Typography>
        <Typography color="secondary" variant="body1" align="center">
          Otherwise, you can skip this section.
        </Typography>
      </Box>
    </Box>
  );
}
