import { Box, Button, Dialog, Grid, TextField } from '@mui/material';
import { Controller, useForm, useWatch } from 'react-hook-form';

import CardTitle from '../routes/client-portal/surveys/edit/asset/components/CardTitle';
import ErrorText from './Form/ErrorText';
import NextButton from './Form/NextButton';
import { colours } from '../config/theme';
import { useEffect } from 'react';

type EditFloorDialogProps = {
  onSubmit: (floorName: string, prevFloorName: string) => void;
  floor?: string;
  onClose: () => void;
};

const EditFloorDialog = ({
  onSubmit,
  floor,
  onClose,
}: EditFloorDialogProps) => {
  const {
    reset,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues: { floor } });

  const nameWatcher = useWatch({ control, name: 'floor' });

  const handleDialogClose = () => {
    onClose?.();
  };

  useEffect(() => {
    if (floor) {
      reset({ floor });
    }
  }, [floor]);

  return (
    <form
      onSubmit={handleSubmit((data) => {
        const floorName = data.floor?.trim();
        if (floorName && floor) {
          floorName !== floor && onSubmit(floorName, floor);
          handleDialogClose();
        }
      })}
    >
      <Dialog
        fullScreen={false}
        open={!!floor}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form>
          <CardTitle text={`Edit Floor`} />
          <Box
            p={2}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            borderBottom={`1px solid ${colours.grey5}`}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      autoComplete="off"
                      fullWidth
                      label="Floor Name"
                      variant="outlined"
                      error={!!errors.floor}
                    />
                  )}
                  control={control}
                  name="floor"
                  rules={{
                    required: true,
                  }}
                />
                {errors.floor && <ErrorText />}
              </Grid>

              <Grid item xs={12}>
                <Box display="flex" flex={1} justifyContent="space-between">
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleDialogClose}
                  >
                    Cancel
                  </Button>
                  <NextButton
                    text="Save Floor"
                    disabled={!nameWatcher?.length}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Dialog>
    </form>
  );
};

export default EditFloorDialog;
