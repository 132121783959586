import files from './files';
import params from './params';
import surveyForm from './survey-form';
import ui from './ui';

export default {
  ui,
  files,
  params,
  surveyForm,
};
