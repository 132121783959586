import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const filesSlice = createSlice({
  name: 'files',
  initialState,
  reducers: {
    addFileObject(state, action) {
      const { fileUrl, id, fileName, lastModified } = action.payload;
      state[id] = { fileUrl, fileName, lastModified };
    },
    deleteFileObject(state, action) {
      const { id } = action.payload;
      delete state[id];
    },
  },
});

export default filesSlice.reducer;

export const { addFileObject, deleteFileObject } = filesSlice.actions;
