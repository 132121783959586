import {
  FormHelperText,
  Grid,
  Slider,
  Stack,
  TextField,
  useTheme,
} from '@mui/material';
import { forwardRef, useMemo } from 'react';

import PropTypes from 'prop-types';
import { handlePlural } from '../../shared/utilities';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((muiTheme) => ({
  textField: {
    justifyContent: 'center',
    '& input': { textAlign: 'center', padding: muiTheme.spacing(1) },
  },
}));

const SliderWithInput = forwardRef(
  (
    { onChange, disablePluralSuffix, value, defaultValue, max, min, suffix },
    ref,
  ) => {
    const theme = useTheme();
    const classes = useStyles();
    const getValueFromEvent = (event) => {
      return Number(event.target.value);
    };

    const onChangeSliderValue = (_, val) => {
      onChange(val);
    };

    const onChangeTextValue = (event) => {
      const val = getValueFromEvent(event);
      const parsed = Math.min(max, Math.max(min, val));
      onChange(parsed);
    };

    const marks = useMemo(() => {
      return [
        { value: min, label: String(min) },
        { value: max, label: String(max) },
      ];
    }, [min, max]);

    return (
      <Grid container spacing={3}>
        <Grid item xs={12} justifyContent="center">
          <Stack direction="column" alignItems="center">
            <TextField
              color="primary"
              sx={{
                border: 'none',
                borderRadius: 1,
                maxWidth: 100,

                '& .MuiInputBase-input': {
                  fontStyle: 'italic',
                  textAlign: 'center',

                  borderRadius: 1,
                  border: '1px solid lightgray',
                  height: 40,
                  padding: 0,
                  color: theme.palette.primary.main,
                  '&:hover': {
                    border: '1px solid red',
                  },
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  border: '1px solid lightgray',
                  // visibility: 'hidden',
                },
              }}
              className={classes.textField}
              onChange={onChangeTextValue}
              onClick={(event) => event.target.select()}
              value={typeof value === 'number' ? value : defaultValue}
              type="number"
              variant="outlined"
            />
            {suffix && (
              <FormHelperText id="standard-weight-helper-text">
                {disablePluralSuffix
                  ? suffix
                  : handlePlural(value || defaultValue, suffix)}
              </FormHelperText>
            )}
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <Slider
            marks={marks}
            onChange={onChangeSliderValue}
            value={typeof value === 'number' ? value : defaultValue}
            min={min}
            max={max}
          />
        </Grid>
      </Grid>
    );
  },
);

SliderWithInput.displayName = 'SliderWithInput';

SliderWithInput.defaultProps = {
  suffix: '',
  disablePluralSuffix: false,
  defaultValue: null,
};

SliderWithInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  suffix: PropTypes.string,
  disablePluralSuffix: PropTypes.bool,
};
export default SliderWithInput;
