import { Box } from '@mui/material';
import PropTypes from 'prop-types';

type ButtonsContainerProps = {
  children: React.ReactNode;
};

const ButtonsContainer = ({ children }: ButtonsContainerProps) => {
  return (
    <Box
      display="flex"
      flex={1}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      my={2}
    >
      {children}
    </Box>
  );
};

export default ButtonsContainer;
