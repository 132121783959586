import { PATHS, UNSPECIFIED_LABEL } from '../config/constants';
import {
  checkForUndefinedInObject,
  getLastUpdated,
  recordError,
} from '../shared/logTools';
import {
  fileNameAndExtensionFromPath,
  getBlobFile,
  getSafeTextFromText,
  getStorageForPath,
  isSameFile,
  statuses,
} from '../shared/utilities';
import { setLoading, setSnackMessage } from './ui';

import { EMPTY_ARRAY } from '../shared/helpers';
import { Statuses } from '../types/status';
import { functions } from '../config/store';
import { resetFormData } from './survey-form';

const TEST_ROOM_SCHEDULE_URL =
  'https://firebasestorage.googleapis.com/v0/b/asseticom-uk.appspot.com/o/testing%2FTrial-room-schedule.xlsx?alt=media&token=1a9a1126-b436-42bb-92b5-5c1c2a521e03';
const TEST_FLOORPLAN_URL =
  'https://firebasestorage.googleapis.com/v0/b/asseticom-uk.appspot.com/o/testing%2Ffloorplan.jpg?alt=media&token=ca0c5a75-5cfc-471d-854a-fadf5da7ee6c';

async function uploadFloorplan(ref, fileUrl, floor, fileName) {
  try {
    const file = await getBlobFile(fileUrl);
    await ref.put(file);
    const url = await ref.getDownloadURL();
    return { url, floor, fileName };
  } catch (error) {
    throw new Error(
      `There has been an error uploading your floor plan file: ${fileName}. Please go back to the floor plan step and add it again (without clicking remove), then try to save your survey.`,
      { cause: { type: 'FloorPlanUpload', originalError: error } },
    );
  }
}

function getRoomForAssetImport(rooms, name, floor) {
  return rooms.find(
    (r) =>
      String(r.name).trim() === String(name).trim() &&
      String(r.floor).trim() === String(floor).trim(),
  );
}

function getImportsSortedByRoom(rows, rooms) {
  const importsByRoom = {};

  rows.forEach((row) => {
    try {
      const room = getRoomForAssetImport(
        rooms,
        row['Room Name'] || UNSPECIFIED_LABEL,
        row['Floor'] || UNSPECIFIED_LABEL,
      );
      const id = `${room.floor}${room.name}`;
      importsByRoom[id] = importsByRoom[id] || [];
      importsByRoom[id].push(row);
    } catch (err) {
      recordError(err, {
        row,
        importsByRoom,
      });
    }
  });

  return importsByRoom;
}

export const onSurveySubmitted =
  ({ surveyId }) =>
  async (dispatch, getState, { getFirebase }) => {
    // initialise these as empty
    let surveyUpdateObj = { roomSchedule: null, floorPlans: [] };
    const { files, surveyForm } = getState();

    try {
      const firestore = getFirebase().firestore();
      const { client, survey: currentSurveyData } = getState().firestore.data;
      const { clientId, name: clientName } = client;

      const {
        name,
        siteId,
        siteName,
        gifa,
        external,
        surveyorId,
        surveyorName,
        surveyorEmail,
        surveyType,
        estimatedStartDate,
        estimatedDuration,
        buildingId,
        buildingName,
        assetListId,
        roomSchedule,
        floorPlans,
        formFields,
        formOptions,
        importData,
        uploadsNotFound,
        uploadsOnOtherDevice,
        uploadsQueueSize,
      } = surveyForm;

      const formFieldsParsed = formFields.map((field) => {
        return {
          ...field,
          // only assign id based on label if its a user created field
          // this allows the user to rename the base fields but not affect the id
          // have to fall back to using the label if the id doesn't already exist (legacy reasons)
          id: field.base
            ? field.id || getSafeTextFromText(field.label)
            : getSafeTextFromText(field.label),
          // have to cater for copying old formfields where hidden/base didn't exist
          hidden: typeof field.hidden !== 'undefined' ? field.hidden : false,
          base: typeof field.base !== 'undefined' ? field.base : false,
        };
      });

      surveyUpdateObj = {
        status: surveyorId ? statuses.ISSUED : statuses.UNISSUED,
        name,
        deleted: 0,
        synced: 0,
        clientId,
        clientName,
        siteId,
        siteName,
        gifa,
        external,
        surveyorId,
        surveyorName,
        surveyorEmail,
        surveyType,
        estimatedStartDate,
        estimatedDuration,
        buildingId,
        buildingName,
        assetListId,
        roomsNumNotStarted: roomSchedule?.data?.length || 0,
        roomsNumInProgress: 0,
        roomsNumCompleted: 0,
        roomsNumDeleted: 0,
        assetsNumCreated: 0,
        assetsNumDeleted: 0,
        assetsNumImported: 0,
        partialsNumCreated: 0,
        partialsNumDeleted: 0,
        assetsNumFlagged: 0,
        formFields: formFieldsParsed,
        formOptions,
        roomSchedule: null,
        floorPlans: [],
        uploadsNotFound: uploadsNotFound || 0,
        uploadsOnOtherDevice: uploadsOnOtherDevice || 0,
        uploadsQueueSize: uploadsQueueSize || 0,
      };

      const roomScheduleStorage = getStorageForPath(
        PATHS.STORAGE.ROOM_SCHEDULE,
      );
      const floorPlanStorage = getStorageForPath(
        PATHS.STORAGE.IMAGE_FLOOR_PLAN,
      );

      const batch = firestore.batch();

      const clientDocRef = await firestore.collection('clients').doc(clientId);

      let surveyDocRef;

      if (surveyId) {
        surveyDocRef = await clientDocRef.collection('surveys').doc(surveyId);
        surveyUpdateObj = {
          ...surveyUpdateObj,
          assetsNumCreated: currentSurveyData.assetsNumCreated,
          assetsNumImported: currentSurveyData.assetsNumImported || 0,
        };
      } else {
        // assign the created date
        surveyDocRef = await clientDocRef.collection('surveys').doc();
        surveyUpdateObj = {
          ...surveyUpdateObj,
          created: Date.now(),
          id: surveyDocRef.id,
          duplicatedSurveyId: surveyId || null,
        };
      }

      const { id } = surveyDocRef;

      // only want to set up room schedule and floorplan if there actually is a room schedule
      if (roomSchedule) {
        if (importData?.rows || !roomSchedule.fileName) {
          surveyUpdateObj.roomSchedule = {
            data: roomSchedule.data,
            floors: roomSchedule.floors || [],
          };
        } else {
          surveyUpdateObj.roomSchedule = {
            ...roomSchedule,
          };
        }

        surveyUpdateObj.floorPlans = [];

        const fpUploads = [];
        floorPlans?.forEach((floorPlan, index) => {
          const {
            fileName,
            lastModified,
            markers,
            width,
            height,
            url,
            data,
            floor,
          } = floorPlan;

          const existingPlan = currentSurveyData?.floorPlans?.find(
            (fp) => fp.floor === floor,
          );

          const sameFile = existingPlan && isSameFile(existingPlan, floorPlan);

          surveyUpdateObj.floorPlans[index] =
            url || data
              ? {
                  fileName,
                  lastModified,
                  markers,
                  width,
                  height,
                  url: sameFile ? existingPlan.url : url,
                  floor,
                }
              : { floor };

          if (data && (!surveyId || !sameFile)) {
            const { name: fpName } = fileNameAndExtensionFromPath(fileName);
            const floorPlanPath = `${client.clientId}/${id}/${fpName}.jpg`;
            const floorPlanRef = floorPlanStorage.ref(floorPlanPath);
            const { fileUrl } = files[floor];
            fpUploads.push(
              uploadFloorplan(floorPlanRef, fileUrl, floor, fileName),
            );
          }
        });

        const completedUploads = await Promise.all(fpUploads);

        completedUploads.forEach(({ url, floor }) => {
          if (url) {
            const floorPlanIndex = surveyUpdateObj?.floorPlans?.findIndex(
              (fp) => fp.floor === floor,
            );

            if (floorPlanIndex >= 0) {
              surveyUpdateObj.floorPlans[floorPlanIndex] = {
                ...surveyUpdateObj.floorPlans[floorPlanIndex],
                url,
              };
            }
          }
        });
      }

      checkForUndefinedInObject(surveyUpdateObj, 'surveyUpdateObj');

      batch.set(
        surveyDocRef,
        { ...surveyUpdateObj, ...getLastUpdated() },
        { merge: true },
      );
      await batch.commit();

      if (!importData?.rows) {
        return { id, data: surveyUpdateObj };
      }

      const importsSortedByRoom = getImportsSortedByRoom(
        importData.rows,
        roomSchedule.data,
      );

      functions.httpsCallable('importAssetData')({
        importsSortedByRoom,
        clientId,
        surveyId: id,
        name,
        siteId,
        siteName,
        surveyorId,
        surveyorName,
        surveyorEmail,
        buildingId,
        buildingName,
        assetListId,
        formFields: formFieldsParsed,
        roomSchedule,
        assetsNumImported: currentSurveyData?.assetsNumImported || 0,
      });

      return { id, data: surveyUpdateObj };
    } catch (error) {
      const surveyDataWithoutBlob = {
        ...surveyForm,
        floorPlans: surveyForm.floorPlans?.map((fp) => ({
          ...fp,
          data: fp.data?.substr(0, 4) === 'data' ? 'Is data URL' : fp.data,
        })),
      };
      const filesDataWithoutBlob = Object.keys(files)?.map((fileId) => ({
        id: fileId,
        ...files[fileId],
        fileUrl:
          files[fileId].fileUrl.substr(0, 4) === 'data'
            ? 'Is data URL'
            : files[fileId].fileUrl,
      }));
      recordError(error, {
        surveyUpdateObj,
        surveyData: surveyDataWithoutBlob,
        files: filesDataWithoutBlob,
      });
      dispatch(setLoading(false));

      return { error };
    }
  };

export const onSelectedSurveyDelete =
  ({ survey }) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const { client } = getState().firestore.data;
    const deletedDate = Date.now();

    dispatch(setSnackMessage({ message: 'Survey deleted' }));

    const batch = firestore.batch();

    const clientDocRef = firestore.collection('clients').doc(client.clientId);
    const surveyDocRef = clientDocRef.collection('surveys').doc(survey.id);

    batch.update(surveyDocRef, {
      deleted: deletedDate,
      ...getLastUpdated(),
    });

    return batch.commit();
  };

export const onSelectedSurveyReissue =
  ({ survey, resetRooms }) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const { client } = getState().firestore.data;

    dispatch(
      setSnackMessage({
        message: `${survey.name} reissued to ${survey.surveyorName}`,
      }),
    );

    const batch = firestore.batch();

    const clientDocRef = firestore.collection('clients').doc(client.clientId);
    const surveyDocRef = clientDocRef.collection('surveys').doc(survey.id);

    batch.update(surveyDocRef, {
      ...survey,
      status: Statuses.ISSUED,
      roomsNumCompleted: resetRooms ? 0 : survey.roomsNumCompleted,
      roomsNumNotStarted: resetRooms ? survey.roomsNumCompleted : 0,
      ...getLastUpdated(),
    });

    if (resetRooms) {
      const roomSnapshot = await surveyDocRef
        .collection('rooms')
        .where('deleted', '==', 0)
        .get();

      roomSnapshot.docs.forEach((roomDoc) => {
        batch.update(roomDoc.ref, {
          status: Statuses.NOT_STARTED,
          ...getLastUpdated(),
        });
      });
    }

    return batch.commit();
  };
