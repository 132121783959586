/* eslint-disable react/jsx-props-no-spreading */
import { Box, Chip, Divider, Typography } from '@mui/material';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import {
  FORM_FIELDS_NAME,
  FORM_OPTIONS_NAME,
} from '../../../../../config/constants';
import {
  FieldErrors,
  FormProvider,
  useFieldArray,
  useForm,
  useFormState,
} from 'react-hook-form';
import { FormField, StepperStepProps } from '../../../../../types/form';
import {
  FormOptions as FormOptionsProps,
  SurveyProps,
} from '../../../../../types/survey';
import {
  checkForDuplicates,
  performNicheFieldInversionIfNeeded,
} from './utils';
import {
  importExistingFormFieldsFromSurvey,
  setFormData,
  useFieldStepData,
} from '../../../../../state/survey-form';
import { useEffect, useRef } from 'react';

import BackButton from '../../../../../components/Form/BackButton';
import ButtonsContainer from '../../../../../components/Form/ButtonsContainer';
import { DEFAULT_VALUES_FORM } from './constants';
import { EMPTY_ARRAY } from '../../../../../shared/helpers';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import { FieldsCopierProvider } from './hooks/useFieldsCopier';
import FormFieldAddButton from './components/FormFieldAddButton';
import FormFieldRow from './components/FormFieldRow';
import FormFieldsCopyFromPreviousButton from './components/FormFieldsCopyFromPreviousButton';
import FormOptions from './components/FormOptions';
import FormSetupPreview from './components/FormSetupPreview';
import NextButton from '../../../../../components/Form/NextButton';
import { formFieldsSetupResolver } from './validation';
import { handlePlural } from '../../../../../shared/utilities';
import isEqual from 'lodash.isequal';
import { setLoading } from '../../../../../state/ui';
import { useAppDispatch } from '../../../../../config/store';
import { useAssetList } from '../../../../../hooks/useAssetList';
import useLeaveHandler from '../../hooks/useLeaveHandler';
import { useModal } from '../../../../../components/Modal';

export type FormValues = {
  [FORM_OPTIONS_NAME]: FormOptionsProps;
  [FORM_FIELDS_NAME]: FormField[];
};

const Fields = ({ onSubmit, onBack, onLeave }: StepperStepProps) => {
  const fieldsData = useFieldStepData();

  const prevFieldsData = useRef<typeof fieldsData>(fieldsData);

  const dispatch = useAppDispatch();

  const assetList = useAssetList(fieldsData.assetListId);

  const { showModal } = useModal();

  const methods = useForm<FormValues>({
    resolver: formFieldsSetupResolver,
    defaultValues: {
      [FORM_FIELDS_NAME]: fieldsData.formFields,
      [FORM_OPTIONS_NAME]: fieldsData.formOptions,
    },
  });

  const { handleSubmit, control, reset, getValues } = methods;

  useEffect(() => {
    if (isEqual(fieldsData, prevFieldsData.current) === false) {
      prevFieldsData.current = fieldsData;
      reset(fieldsData);
    }
  }, [prevFieldsData.current, fieldsData]);

  useLeaveHandler(onLeave, getValues);

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: FORM_FIELDS_NAME,
  });

  const addNewField = () => {
    append({
      ...(DEFAULT_VALUES_FORM as FormField),
    });
    return;
  };

  const onRemoveField = (index) => {
    remove(index);
  };

  const onDragEnd = (result) => {
    if (result.destination) {
      move(result.source.index, result.destination.index);
    }
  };

  async function onSelectedFieldsToCopy(survey: SurveyProps) {
    const merge = await showModal({
      title: 'Please Confirm',
      messages: [
        'Would you like to merge the imported form fields or replace them?',
      ],
      buttons: {
        confirm: [{ text: 'Replace', value: false }],
        cancel: [{ text: 'Merge', value: true }],
      },
    });

    dispatch(
      importExistingFormFieldsFromSurvey({
        survey,
        merge,
        currentFormFields: getValues(FORM_FIELDS_NAME) || EMPTY_ARRAY,
      }),
    );
  }

  const beforeSubmit = (data: FormValues) => {
    const formFields = data[FORM_FIELDS_NAME];
    const duplicates = checkForDuplicates(formFields);

    if (duplicates.length) {
      return showModal({
        title: 'Duplicate Fields',
        buttons: {
          confirm: [{ text: 'Ok' }],
        },
        messages: [
          'Your form field labels must be unique.',
          'We found the following duplicates:',
          ...duplicates.map((d) => d.label),
        ],
      });
    } else {
      const alreadyHasExcludeMode = formFields
        .filter((f) => !f.base)
        .some((field) => typeof field.nicheFieldsExcludeMode !== 'undefined');

      if (!alreadyHasExcludeMode) {
        dispatch(
          setLoading({
            loadingMessage: 'Optimising survey, please wait...',
          }),
        );

        setTimeout(() => {
          const updatedFormFields = formFields.map((field) => {
            if (field.nicheFields && !field.base) {
              const { fields, excludeMode } =
                performNicheFieldInversionIfNeeded(
                  field.nicheFields,
                  assetList,
                );
              return {
                ...field,
                nicheFields: fields,
                nicheFieldsExcludeMode: excludeMode,
              };
            }
            return field;
          });

          // have to ensure the form is reset with the updated form fields
          // as the onLeaveHandler will overwrite the form fields with the old ones otherwise
          reset({ [FORM_FIELDS_NAME]: updatedFormFields });
          dispatch(
            setFormData({
              formFields: updatedFormFields,
            }),
          );
          dispatch(setLoading(false));
        });
      }
      onSubmit();
    }
  };

  return (
    <ErrorBoundary>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(beforeSubmit)}>
          <Box mt={2}>
            <Divider variant="middle">
              <Chip variant="outlined" label="Form Setup" color="primary" />
            </Divider>
          </Box>

          <FieldsCopierProvider onSelect={onSelectedFieldsToCopy}>
            <FormFieldsCopyFromPreviousButton />
          </FieldsCopierProvider>

          <Box mt={2}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {assetList &&
                      fields.map((field, index) => (
                        <FormFieldRow
                          {...field}
                          key={field.id}
                          index={index}
                          onRemoveField={onRemoveField}
                          assetList={assetList}
                          expanded={index === fields.length - 1 && !field.label}
                        />
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            marginTop={2}
          >
            <FormFieldAddButton onAddNewField={addNewField} />
          </Box>

          <Box my={2}>
            <FormOptions />
          </Box>
          <Box
            border={1}
            borderColor="error.main"
            p={2}
            mt={2}
            borderRadius={2}
          >
            <Typography color="secondary" variant="body1" align="center">
              PLEASE NOTE: A &apos;Required&apos; field means the surveyor must
              input a value before being able to save the asset or mark it as
              complete during the survey.
            </Typography>
          </Box>

          <FormSetupPreview formFields={fields} />

          <ButtonsContainer>
            <BackButton onBack={onBack} />
            <ErrorReporter />
            <NextButton />
          </ButtonsContainer>
        </form>
      </FormProvider>
    </ErrorBoundary>
  );
};

function ErrorReporter() {
  const { errors } = useFormState({
    name: FORM_FIELDS_NAME,
    exact: true,
  });

  // horrible hack due to hook-form-ts-limitation - see https://github.com/react-hook-form/react-hook-form/issues/7711
  const numErrors = (
    errors[FORM_FIELDS_NAME] as unknown as FieldErrors<
      FormValues[typeof FORM_FIELDS_NAME]
    >
  )?.filter((e) => e).length;

  if (numErrors) {
    return (
      <Typography color="error">
        {`${handlePlural(numErrors, 'form fields', true)} ${handlePlural(
          numErrors - 1,
          'contains',
          false,
        )} errors`}
      </Typography>
    );
  }
  return null;
}

export default Fields;
