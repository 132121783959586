import { Box } from '@mui/material';

type AccessibleSwitcherProps = {
  children: React.ReactNode;
  accessibleChild: React.ReactNode;
};

export default function AccessibleSwitcher({
  children,
  accessibleChild,
}: AccessibleSwitcherProps) {
  return (
    <>
      <Box aria-hidden="true">{children}</Box>
      <Box
        sx={{
          display: 'inline-block',
          clip: 'rect(0 0 0 0)',
          clipPath: 'inset(50%)',
          height: 1,
          overflow: 'hidden',
          position: 'absolute',
          whiteSpace: 'nowrap',
          width: 1,
        }}
      >
        {accessibleChild}
      </Box>
    </>
  );
}
