import { Breadcrumbs } from '@mui/material';
import { NavLink } from 'react-router-dom';
import PrettyParam from '../../../../components/PrettyParam';
import React from 'react';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  container: {},
  breadcrumb: {
    color: theme.palette.secondary.main,
    fontSize: 13,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
    textTransform: 'capitalize',
  },
  selected: {
    fontSize: 18,
    pointerEvents: 'none',
    fontWeight: theme.typography.fontWeightBold,
    userSelect: 'none',
    display: 'none',
  },
}));

const BreadcrumbProgress = () => {
  const classes = useStyles();
  const pathParams = useSelector((state) => state.params.pathParams);
  const pathSegments = useSelector((state) => state.params.pathSegments);

  const isAtRoot = pathParams.length === 1;

  return (
    <Breadcrumbs
      separator="›"
      aria-label="breadcrumb"
      classes={{ ol: classes.container }}
    >
      {pathParams?.map((param, index) => {
        return (
          <NavLink
            key={param}
            color="inherit"
            to={`/${pathSegments.slice(0, index + 1).join('/')}`}
            className={classes.breadcrumb}
            activeClassName={isAtRoot ? null : classes.selected}
            exact
          >
            <PrettyParam id={param} />
          </NavLink>
        );
      })}
    </Breadcrumbs>
  );
};

BreadcrumbProgress.propTypes = {};

export default BreadcrumbProgress;
