import { Box, Grid, Link, Paper, Typography } from '@mui/material';

import { useLocation } from 'react-router-dom';

const QRCodeNotFound = () => {
  const location = useLocation();
  console.log(location);
  const params = new URLSearchParams(location.search);
  const link = params.get('link');
  console.log('hi', params.get('test'));
  return (
    <Paper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box p={2}>
            <Typography
              variant="h3"
              color="primary"
              align="center"
              gutterBottom
            >
              Whoops!
            </Typography>
            <Typography variant="body1" color="secondary" align="left">
              We couldn&apos;t find a match for the QR code link{' '}
              <Typography fontWeight={700}>{link}</Typography>
            </Typography>
            <Typography
              variant="body1"
              color="secondary"
              align="left"
              paragraph
            >
              If you need help, please{' '}
              <Link
                href="mailto:support@asseticom.co.uk"
                sx={{ fontWeight: 700 }}
              >
                contact us
              </Link>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default QRCodeNotFound;
