import 'react-color-palette/css';

import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  Stack,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { ReportType, ReportTypes } from '../../../shared/types';

import ErrorText from '../../../../../../../components/Form/ErrorText';

type FormData = {
  highlightChanges: boolean;
  includeDeletedAssets: boolean;
  useFriendlyImageLinks: boolean;
  consolidateToSingleSheet: boolean;
  numYears: number;
};

interface SettingsStep {
  defaultValues: Partial<FormData>;
  handleBack: (data: FormData) => void;
  handleNext: (data: Partial<FormData>) => void;
  reportType: ReportTypes;
}

const CHECKBOXES_ASSET_DATA = [
  {
    name: 'highlightChanges' as const,
    label: 'Highlight changes for imported assets',
  },
  { name: 'includeDeletedAssets' as const, label: 'Include deleted assets' },
  {
    name: 'consolidateToSingleSheet' as const,
    label: 'Consolidate surveys into a single worksheet',
  },
  { name: 'useFriendlyImageLinks' as const, label: 'Use friendly image links' },
];
const CHECKBOXES_LIFE_CYCLE = [
  {
    name: 'consolidateToSingleSheet' as const,
    label: 'Consolidate surveys into a single worksheet',
  },
  { name: 'useFriendlyImageLinks' as const, label: 'Use friendly image links' },
];

const LIFE_CYCLE_YEAR_OPTIONS = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50];
const MAINTENANCE_PLANNER_YEAR_OPTIONS = [1, 2, 3, 4, 5];

const SHOW_YEAR_REPORT_TYPES = [
  ReportType.LIFE_CYCLE,
  ReportType.OPERATIONAL_CARBON,
  ReportType.OPERATIONAL_ENERGY,
  ReportType.EMBODIED_CARBON,
  ReportType.MAINTENANCE_PLANNER,
];

export default function SettingsStep({
  defaultValues,
  handleBack,
  handleNext,
  reportType,
}) {
  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues,
  });

  return (
    <form onSubmit={handleSubmit(handleNext)}>
      <Stack>
        {(reportType === ReportType.ASSET_DATA
          ? CHECKBOXES_ASSET_DATA
          : CHECKBOXES_LIFE_CYCLE
        ).map(({ name, label }) => (
          <Controller
            key={name}
            name={name}
            control={control}
            render={({ field }) => (
              <FormControlLabel
                sx={{ mr: 0 }}
                label={label}
                control={
                  <Checkbox
                    {...field}
                    color="primary"
                    checked={!!field.value}
                  />
                }
              />
            )}
          />
        ))}
        {SHOW_YEAR_REPORT_TYPES.includes(reportType) ? (
          <FormControl variant="outlined" sx={{ width: 240, mt: 2 }}>
            <InputLabel htmlFor="building">
              Number of Years to Display
            </InputLabel>
            <Controller
              control={control}
              name="numYears"
              render={({ field }) => (
                <Select
                  variant="outlined"
                  label="Number of Life Cycle Years"
                  native
                  {...field}
                >
                  {(reportType === ReportType.MAINTENANCE_PLANNER
                    ? MAINTENANCE_PLANNER_YEAR_OPTIONS
                    : LIFE_CYCLE_YEAR_OPTIONS
                  ).map((amount) => (
                    <option key={amount} value={amount}>
                      {amount}
                    </option>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        ) : null}
      </Stack>

      <Stack
        direction={'row'}
        spacing={2}
        justifyContent="space-between"
        mt={2}
      >
        <Button variant="outlined" onClick={() => handleBack(getValues())}>
          Back
        </Button>
        <Button variant="contained" type="submit">
          Next
        </Button>
      </Stack>
    </form>
  );
}
