import { Box, Button, Stack, Typography } from '@mui/material';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import { colours } from '../../../../config/theme';
import { useState } from 'react';

interface DropTarget {
  children: React.ReactNode;
  onAddFiles?: (files: FileList | null) => void;
}

export default function DropWrapper({ children, onAddFiles }: DropTarget) {
  const [highlighted, setHighlighted] = useState(false);

  const dragOver = (e) => {
    e.preventDefault();
    setHighlighted(true);
  };

  const dragEnter = (e) => {
    e.preventDefault();
    setHighlighted(true);
  };

  const dragLeave = (e) => {
    e.preventDefault();
    setHighlighted(false);
  };

  const fileDrop = (e) => {
    e.preventDefault();
    const { files } = e.dataTransfer;
    onAddFiles?.(files);
    setHighlighted(false);
  };

  return (
    <Box
      sx={{
        backgroundColor: highlighted ? colours.light2 : colours.white,
      }}
      onDragOver={dragOver}
      onDragEnter={dragEnter}
      onDragLeave={dragLeave}
      onDrop={fileDrop}
    >
      {children}
    </Box>
  );
}
