import { Button } from '@mui/material';

type FormFieldAddButtonProps = {
  onAddNewField: () => void;
};

export default function FormFieldAddButton({
  onAddNewField,
}: FormFieldAddButtonProps) {
  return (
    <Button color="secondary" variant="contained" onClick={onAddNewField}>
      Add New Field
    </Button>
  );
}
