export const ASSET_IMAGE_HEIGHT = 150;
const SHORT_URL_PREFIX = 'https://asseticom.co.uk/s/';

export function getSafeTextFromText(label: string) {
  return label.replace(/\s/g, '_').replace(/[^\w\d_]/g, '');
}

export function removeParamsFromUrl(url: string) {
  return url.split('?')[0] ?? url;
}

export function addThumbParamToUrl(url: string) {
  //if its not a short url, because the url has not been shortened for whatever reason - return it as is
  if (url.startsWith(SHORT_URL_PREFIX) === false) {
    return url;
  }
  //ensure there arent already params present
  const withoutParams = removeParamsFromUrl(url);
  return `${withoutParams}?height=${ASSET_IMAGE_HEIGHT}`;
}
