import { Theme, createTheme } from '@mui/material/styles';

export const colours = {
  black: '#000000',
  blackNeutral: '#151522',
  light: '#FAFEFE',
  light2: '#F4F6F8',
  white: '#FFFFFF',
  grey1: '#333333',
  grey2: '#666666',
  grey3: '#828282',
  grey4: '#BDBDBD',
  grey5: '#e0e0e0',
  grey6: '#F2F2F2',
  green1: '#007a78',
  green3: '#6FCF97',
  brand1: '#06BEB6',
  medium: '#536B78',
  alphaLowest: '#CCCCCC20',
  alphaLow: '#CCCCCC50',
  alphaMid: '#CCCCCC99',
  alphaHighMid: '#CCCCCCCC',
  dark: '#000F08',
  yellow: '#fc9d03',
  orange: '#ebae34',
  red: '#E52424',
  sicklyGreen: '#99CC09',
  blue: '#6979F8',
  veryDarkGrey: '#263238',
  mediumGrey: '#546E7A',
  darkGrey: '#828282',
  lightGrey: '#e0e0e0',
};

export const newColours = {
  light1: '#FCFCFD',
  light2: '#F8F9FC',
  light3: '#EAECF5',
  blue1: '#D5D9EB',
  blue2: '#717BBC',
  blue3: '#4E5BA6',
  blue4: '#363F72',
};

export const StandardTheme = {
  palette: {
    // teal brand colour
    primary: {
      main: colours.brand1,
      contrastText: colours.white,
    },
    // grey-blue 'medium' colour
    secondary: {
      main: colours.medium,
      contrastText: colours.white,
    },
    background: {
      default: colours.light2,
    },
    success: { main: '#06BEB6', contrastText: '#FFFFFF' },
    helper: { main: '#0F6BBF', contrastText: '#FFFFFF' },
    green: { main: colours.green1, contrastText: '#FFFFFF' },
    orange: { main: '#ebae34', contrastText: '#FFFFFF' },
    red: { main: colours.red, contrastText: '#FFFFFF' },
    blue: { main: '#6979F8', contrastText: '#FFFFFF' },

    darkGrey: { main: colours.darkGrey, contrastText: '#FFFFFF' },
    white: { main: colours.white, contrastText: '#FFFFFF' },
  },
  typography: {
    h1: {
      fontSize: 24,
      color: colours.veryDarkGrey,
    },
    h6: {
      fontFamily: 'Roboto',
      fontSize: 10,
      lineHeight: '22px',
      color: colours.veryDarkGrey,
    },
    subtitle1: {
      fontFamily: 'Roboto',
      fontSize: 12,
      lineHeight: '14px',
      color: colours.mediumGrey,
    },
    attributeType: {
      fontFamily: 'Open Sans',
      fontSize: 12,
    },
    attributeValue: {
      fontSize: 16,
      fontStyle: 'italic',
      fontWeight: 'bold',
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  components: {
    MuiListItemText: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 500,
          color: colours.medium,
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          fontSize: 14,
          lineHeight: '31px',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '30px',
          '&& svg': {
            width: '20px',
            height: '20px',
            fill: colours.medium,
          },
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          borderTop: `1px solid ${colours.grey5}`,
          justifyContent: 'flex-end',
          paddingTop: '4px',
          paddingBottom: '4px',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: { backgroundColor: colours.white },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        stickyHeader: {
          backgroundColor: 'inherit',
          left: 'auto',
        },
        root: {
          padding: '10px',
          color: colours.medium,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: colours.mediumGrey,
        },
      },
    },
    MuiTabPanel: {
      styleOverrides: {
        root: { paddingLeft: 0, paddingRight: 0 },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h2',
          h2: 'h2',
          h3: 'h2',
          h4: 'h2',
          h5: 'h2',
          h6: 'h2',
          subtitle1: 'h2',
          subtitle2: 'h2',
          body1: 'span',
          body2: 'span',
        },
      },
      styleOverrides: {
        subtitle2: {
          color: colours.mediumGrey,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize' as const,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: colours.light2,
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          color: colours.light2,
          backgroundColor: colours.medium,
        },
        expandIcon: {
          color: colours.light2,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: colours.medium,
          fontSize: 18,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          color: colours.white,
          fontSize: 14,
        },
      },
    },
  },
};

const NewBrandingTheme = {
  ...StandardTheme,
  components: {
    ...StandardTheme.components,
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: newColours.blue3,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: newColours.blue4,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 500,
          color: newColours.blue4,
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          fontSize: 14,
          lineHeight: '31px',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '30px',
          '&& svg': {
            width: '20px',
            height: '20px',
            fill: newColours.blue4,
          },
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          borderTop: `1px solid ${colours.grey5}`,
          justifyContent: 'flex-end',
          paddingTop: '4px',
          paddingBottom: '4px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: newColours.blue4,
        },
      },
    },
    MuiTabPanel: {
      styleOverrides: {
        root: { paddingLeft: 0, paddingRight: 0 },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h2',
          h2: 'h2',
          h3: 'h2',
          h4: 'h2',
          h5: 'h2',
          h6: 'h2',
          subtitle1: 'h2',
          subtitle2: 'h2',
          body1: 'span',
          body2: 'span',
        },
      },
      styleOverrides: {
        subtitle2: {
          color: colours.mediumGrey,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'capitalize' as const,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: colours.light2,
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          color: colours.light2,
          backgroundColor: newColours.blue4,
        },
        expandIcon: {
          color: colours.light2,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: newColours.blue4,
          fontSize: 18,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          color: colours.white,
          fontSize: 14,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: newColours.light3,
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          backgroundColor: newColours.light2,
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: newColours.light1,
        },
      },
    },
  },
  palette: {
    ...StandardTheme.palette,
    primary: {
      main: newColours.blue3,
      contrastText: newColours.light2,
    },
    secondary: {
      main: newColours.blue4,
      contrastText: newColours.light2,
    },
    background: {
      default: newColours.light2,
    },
    success: { main: newColours.blue3, contrastText: newColours.light2 },
    helper: { main: colours.medium, contrastText: newColours.light2 },
    sickly: { main: colours.sicklyGreen, contrastText: newColours.light2 },
    orange: { main: colours.orange, contrastText: newColours.light2 },
    red: { main: colours.red, contrastText: newColours.light2 },
    blue: { main: newColours.blue2, contrastText: newColours.light2 },
  },
};

export const standardTheme = createTheme(StandardTheme);

export const newBrandingTheme = createTheme(NewBrandingTheme);

declare module '@mui/material/styles' {
  interface Palette {
    sickly: Palette['primary'];
    orange: Palette['primary'];
    red: Palette['primary'];
    blue: Palette['primary'];
    darkGrey: Palette['primary'];
  }

  interface PaletteOptions {
    sickly?: PaletteOptions['primary'];
    orange?: PaletteOptions['primary'];
    red?: PaletteOptions['primary'];
    blue?: PaletteOptions['primary'];
    darkGrey?: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    sickly: true;
    orange: true;
    red: true;
    blue: true;
    darkGrey: true;
  }
}

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line
  interface DefaultTheme extends Theme {}
}

export default standardTheme;
