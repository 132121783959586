import {
  DEFAULT_IMPORT_COLUMNS_SCHEMA,
  getFormFieldSchema,
  getFormOptionsSchema,
  schemaAsArray,
} from '../../../../../../shared/validation';

import { FormField } from '../../../../../../types/form';
import { FormOptions } from '../../../../../../types/survey';
import { useMemo } from 'react';

export default function useImportSchema({
  formFields,
  formOptions,
}: {
  formFields?: FormField[];
  formOptions?: FormOptions;
}) {
  const schema = useMemo(() => {
    if (!formFields || !formOptions) {
      throw new Error(
        'You must pass both formFields and formOptions to use this hook',
      );
    }
    const ffSchema = getFormFieldSchema(formFields);

    const formOptionsSchema = getFormOptionsSchema(formOptions);
    return DEFAULT_IMPORT_COLUMNS_SCHEMA.merge(formOptionsSchema).merge(
      ffSchema,
    );
  }, [formFields]);

  console.log({ schema });

  const columns = useMemo(() => {
    if (schema) {
      return Object.keys(schema.shape);
    }
    return [];
  }, [schema]);

  return { schema: schemaAsArray({ schema }), columns };
}
