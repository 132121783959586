import { dateFromTimeStamp, timeFromTimeStamp } from '../shared/utilities';

export function getLastUpdatedStringFromTimestamp(timeStamp) {
  return `${dateFromTimeStamp(timeStamp)} at ${timeFromTimeStamp(timeStamp)}`;
}

export default ({ cell }) => {
  const lastUpdated = cell.getValue();

  return lastUpdated === 0
    ? 'N/A'
    : getLastUpdatedStringFromTimestamp(lastUpdated);
};
