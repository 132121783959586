import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useMemo, useState } from 'react';

import { AssetAttributes } from '../../../../../../types/asset';
import CloseIcon from '@mui/icons-material/Close';
import { FixedSizeList } from 'react-window';
import { useNicheFieldsSelector } from '../hooks/useNicheFieldsSelector';

export default function NicheFieldsSelectorDialog() {
  const { isOpen, close, onClickReset, onClickSelectAll, onClickSave } =
    useNicheFieldsSelector();

  if (!isOpen) {
    return null;
  }

  return (
    <Dialog
      open={isOpen}
      onClose={close}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">
        <Typography>Select Niche Fields</Typography>
        <IconButton
          onClick={close}
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Content />
      <DialogActions>
        <Stack direction="row" justifyContent="space-between" sx={{ flex: 1 }}>
          <Box>
            <Button color="secondary" onClick={onClickSelectAll}>
              Select All
            </Button>
            <Button color="secondary" onClick={onClickReset}>
              Reset
            </Button>
          </Box>
          <Button onClick={onClickSave}>Save</Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

const Content = () => {
  const { assetList } = useNicheFieldsSelector();
  const [searchTerm, setSearchTerm] = useState('');

  const clearSearchTerm = () => {
    setSearchTerm('');
  };

  const parsedAssetList = useMemo(() => {
    const lowered = searchTerm.toLowerCase();

    return (assetList as any[]).filter(
      (a?: AssetAttributes) =>
        a?.label3.toLowerCase().indexOf(lowered) !== -1 ||
        a?.code3.toLowerCase().indexOf(lowered) !== -1,
    );
  }, [searchTerm, assetList]);

  return (
    <DialogContent dividers sx={{ padding: 0 }}>
      <Box
        sx={{
          width: '100%',
          height: 50,
          paddingX: 1.5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottomWidth: 1,
          borderBottomColor: (theme) => theme.palette.grey[300],
          borderBottomStyle: 'solid',
        }}
      >
        <TextField
          fullWidth
          variant="standard"
          margin="dense"
          size="small"
          onInput={(event: React.FormEvent<HTMLInputElement>) => {
            const target = event.target as HTMLInputElement;
            setSearchTerm(target.value);
          }}
          placeholder="Search..."
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  disableFocusRipple
                  aria-label="clear search term"
                  size="small"
                  onClick={clearSearchTerm}
                >
                  <CloseIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box
        sx={{
          width: '100%',
          height: 400,
        }}
      >
        <List list={parsedAssetList}></List>
      </Box>
    </DialogContent>
  );
};

type ListProps = {
  list: AssetAttributes[];
};

const List = ({ list }: ListProps) => {
  if (!list) {
    return null;
  }
  return (
    <FixedSizeList
      height={400}
      width={400}
      itemSize={80}
      itemCount={list.length}
      overscanCount={5}
      itemData={{
        assetList: list,
      }}
    >
      {Row}
    </FixedSizeList>
  );
};

type RowProps = {
  index: number;
  style: any;
  data: any;
};

function Row({ index, style, data }: RowProps) {
  const { onToggleNicheField, nicheFields } = useNicheFieldsSelector();
  const assetListData = data.assetList;
  const code = assetListData[index].code3;
  const label = assetListData[index].label3;

  const selected = !!nicheFields.find((i) => i === code);

  return (
    <ListItem style={style} key={index} component="div" disablePadding>
      <ListItemButton
        disableGutters
        divider
        onClick={() => onToggleNicheField({ code, selected })}
      >
        <Checkbox checked={selected} sx={{ margin: 0 }} />
        <ListItemText primary={code} secondary={label} />
      </ListItemButton>
    </ListItem>
  );
}
