import { Box, Button, useTheme } from '@mui/material';
import { forwardRef, useEffect, useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { colours } from '../../config/theme';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

const Radio = forwardRef(
  ({ onChange, options = [], value, defaultValue }, ref) => {
    const classes = useStyles();
    const theme = useTheme();
    const [selectedValue, setSelectedValue] = useState(value);

    useEffect(() => {
      setSelectedValue(value || defaultValue);
    }, [value, defaultValue]);

    const buttonWidth = useMemo(() => {
      return `calc(100% / ${
        options?.filter((o) => o.value).length
      } - ${theme.spacing(1)}`;
    }, [options, theme]);

    return (
      <Box
        className={classes.root}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        {options?.map((option, index) => {
          const { value: val, colour } = option;
          const isSelected = selectedValue === val;
          return val ? (
            <Button
              key={val.trim()}
              onClick={() => onChange(val === value ? null : val)}
              color="primary"
              variant={isSelected ? 'contained' : 'outlined'}
              fullWidth
              disableElevation
              sx={{
                backgroundColor: isSelected ? colour : 'transparent',
                borderColor: colour,
                color: isSelected ? colours.white : colour,
                minWidth: 0,
                transition: 'none',
                width: buttonWidth,
                marginLeft: index === 0 ? 0 : 5,
              }}
            >
              {val}
            </Button>
          ) : null;
        })}
      </Box>
    );
  },
);

Radio.displayName = 'Radio';

Radio.defaultProps = {
  defaultValue: null,
};

Radio.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      colour: PropTypes.string,
    }),
  ).isRequired,
};
export default Radio;
