import { Box, CardActionArea, Typography } from '@mui/material';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import DynamicIcon from '../../components/DynamicIcon';
import ErrorBoundary from '../../components/ErrorBoundary';
import Image from '../../components/Image';
import { Link } from 'react-router-dom';
import Pill from '../../components/Pill';
import PropTypes from 'prop-types';
import React from 'react';
import { colours } from '../../config/theme';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  imageHolder: {
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&& img': {
      width: '50px',
      height: '50px',
      objectFit: 'contain',
      borderRadius: 5,
    },
  },
});

const ClientCard = ({ client }) => {
  const classes = useStyles();
  const createdDateString = new Date(client.created);

  return (
    <ErrorBoundary>
      <Card>
        <CardActionArea component={Link} to={`/${client.clientId}`}>
          <CardContent>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={1}
            >
              <Box display="flex" alignItems="center">
                <Box mr={2} className={classes.imageHolder}>
                  {client.logoImage ? (
                    <Image src={client.logoImage} width={50} alt="logo" />
                  ) : (
                    <DynamicIcon
                      name="view-portals"
                      colour="primary"
                      width={40}
                      height={40}
                      inCircle
                    />
                  )}
                </Box>
                <Typography color="secondary">{client.name}</Typography>
              </Box>
              <Pill colour="primary" text="ACTIVE" />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <Typography color="secondary" component="p">
                  Sites
                </Typography>
                <Typography color="secondary">
                  {client.sitesNumCreated - client.sitesNumDeleted}
                </Typography>
              </Box>
              <Box>
                <Typography align="right" color="secondary" component="p">
                  Created
                </Typography>
                <Typography color="secondary" align="right">
                  {createdDateString.toLocaleDateString()}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </CardActionArea>
        {/* <CardActions>
          <IconButton>
            <DynamicIcon name="arrow" colour="primary" width={15} />
          </IconButton>
        </CardActions> */}
      </Card>
    </ErrorBoundary>
  );
};

ClientCard.propTypes = {
  client: PropTypes.shape({
    created: PropTypes.number,
    name: PropTypes.string,
    sitesNumCreated: PropTypes.number,
    sitesNumDeleted: PropTypes.number,
    clientId: PropTypes.string,
    logoImage: PropTypes.string,
  }).isRequired,
};

export default ClientCard;
