import { AppState } from '../config/store';
import { EMPTY_OBJECT } from '../shared/helpers';
import { UrlParams } from '../types/urlParams';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

function useSurvey() {
  const { clientId, surveyId } = useParams<UrlParams>();
  const survey = useSelector(
    (state: AppState) => state.firestore.data.survey || EMPTY_OBJECT,
  );
  useFirestoreConnect([
    {
      collection: 'clients',
      doc: clientId,
      subcollections: [{ collection: 'surveys', doc: surveyId }],
      storeAs: 'survey',
    },
  ]);
  return survey;
}

export default useSurvey;
