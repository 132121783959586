/* eslint-disable react/jsx-props-no-spreading */
import { Button, Stack } from '@mui/material';

import { useFieldsCopier } from '../hooks/useFieldsCopier';

export default function FormFieldsCopyFromPreviousButton() {
  const { open } = useFieldsCopier();

  return (
    <Stack direction="row" flex={1} justifyContent="flex-end">
      <Button color="secondary" variant="outlined" onClick={open}>
        Import/Export Fields
      </Button>
    </Stack>
  );
}
