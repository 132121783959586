import {
  AddEditRoomProps,
  RoomProps,
  RoomScheduleRoomProps,
} from '../../../../../types/room';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import {
  exportRoomSchedule,
  setFormData,
  useRoomScheduleStepData,
} from '../../../../../state/survey-form';
import {
  getRandomID,
  parseXLSX,
  readURLAsBinary,
  removeDuplicatesFromArray,
} from '../../../../../shared/utilities';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';

import AddorEditRoomForm from './components/AddorEditRoomForm';
import BackButton from '../../../../../components/Form/BackButton';
import ButtonsContainer from '../../../../../components/Form/ButtonsContainer';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import ErrorText from '../../../../../components/Form/ErrorText';
import FileUploaderCard from '../../../../../components/FileUploaderCard';
import NextButton from '../../../../../components/Form/NextButton';
import StepGuide from '../../components/StepGuide';
import { StepperStepProps } from '../../../../../types/form';
import Table from '../../../../../components/Table';
import TableActionButton from '../../../../../components/TableActionButton';
import { addFileObject } from '../../../../../state/files';
import { generateRoomScheduleSheet } from './util';
import { getRoomList } from './utils';
import { setSnackMessage } from '../../../../../state/ui';
import { useAppDispatch } from '../../../../../config/store';
import useLeaveHandler from '../../hooks/useLeaveHandler';
import { useModal } from '../../../../../components/Modal';

const columns = [
  { accessorKey: 'floor', header: 'Floor' },
  { accessorKey: 'name', header: 'Room' },
  {
    accessorKey: 'areaFloor',
    header: 'Area (Floor & Ceiling)',
  },
  {
    accessorKey: 'areaWall',
    header: 'Area (Wall)',
  },
];

const RoomSchedule = ({ onSubmit, onBack, onLeave }: StepperStepProps) => {
  const { roomSchedule, importData, floorPlans, surveyName } =
    useRoomScheduleStepData();

  const dispatch = useAppDispatch();

  const { handleSubmit, register, watch, reset, setValue, getValues, control } =
    useForm();

  const { errors } = useFormState({
    control,
  });

  useLeaveHandler(onLeave, getValues);

  const { showModal } = useModal();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [roomToEdit, setRoomToEdit] = useState<RoomProps>();

  const roomScheduleWatcher = watch('roomSchedule');

  const setFileData = async (file, id) => {
    const { lastModified, name } = file;

    const fileUrl = window.URL.createObjectURL(file);
    dispatch(addFileObject({ fileUrl, fileName: name, id, lastModified }));

    const data = await readURLAsBinary(file);

    const roomListParsed = await parseXLSX<RoomScheduleRoomProps>(data, [
      'floor',
      'name',
      'areaFloor',
      'areaWall',
    ]);

    if (roomListParsed.headers.length !== 4) {
      return showModal({
        title: 'Invalid File',
        messages: [
          'The file you uploaded is not a valid room schedule.',
          '',
          'Please download the room schedule template and try again, ensuring your columns match the template ordering exactly.',
          '',
          'Should you require assistance, please contact support@asseticom.co.uk',
        ],
        buttons: {
          confirm: [{ text: 'Ok', value: true }],
        },
      });
    }

    const { rooms } = getRoomList(roomListParsed.rows, roomSchedule?.data);

    let allRooms = [...rooms];

    const replaceRooms = roomScheduleWatcher?.data
      ? await showModal({
          title: 'Please Confirm',
          messages: [
            'This survey already contains a room schedule, would you like to replace it or merge into it?',
            'If you replace it then you may lose any floor plan settings associated with the previous room schedule.',
          ],
          buttons: {
            confirm: [{ text: 'Replace', value: true }],
            cancel: [{ text: 'Merge', value: false }],
          },
        })
      : true;

    if (!replaceRooms) {
      allRooms = [...roomScheduleWatcher.data, ...allRooms];
    }

    // reverse the array so that the first instance of a room is the one that is kept
    const deduped = allRooms
      .reverse()
      .filter(
        (room, index) =>
          index ===
          allRooms.findIndex(
            (other) => room.name === other.name && room.floor === other.floor,
          ),
      )
      //reverse the array back to the original order, with the new/updated rooms at the end
      .reverse();

    const allFloors = removeDuplicatesFromArray(deduped.map((r) => r.floor));

    dispatch(
      setFormData({
        roomSchedule: {
          data: deduped,
          fileName: name,
          lastModified,
          floors: allFloors,
        },
      }),
    );
  };

  useEffect(() => {
    let roomScheduleUpdate;
    if (importData?.rooms) {
      const consolidatedRooms = roomSchedule?.data.length
        ? [...roomSchedule.data]
        : [];

      importData?.rooms.forEach((room) => {
        const match = consolidatedRooms.find(
          (cr) =>
            cr.floor === room.floor &&
            cr.name === room.name &&
            cr.deleted === 0,
        );
        if (!match) {
          consolidatedRooms.push(room);
        }
      });

      const floors = removeDuplicatesFromArray(
        consolidatedRooms.map((r) => r.floor),
      );
      roomScheduleUpdate = {
        data: consolidatedRooms,
        floors,
      };
    } else {
      roomScheduleUpdate = roomSchedule;
    }
    reset({ roomSchedule: roomScheduleUpdate });
  }, [importData, roomSchedule]);

  const rows = useMemo(
    () =>
      roomScheduleWatcher?.data
        ? roomScheduleWatcher.data.map((room) => {
            const { id, imported, name, floor, areaFloor, areaWall } = room;
            return {
              id,
              imported,
              name,
              floor,
              areaFloor: parseFloat(areaFloor).toFixed(1),
              areaWall: parseFloat(areaWall).toFixed(1),
              survey: surveyName,
            };
          })
        : null,
    [roomScheduleWatcher],
  );

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const clearRoomScheduleAndFloorplans = () => {
    if (importData?.rooms) {
      const floors = removeDuplicatesFromArray(
        importData.rooms.map((r) => r.floor),
      );
      // setValue('roomSchedule', { data: importData.rooms, floors });
      dispatch(
        setFormData({ roomSchedule: { data: importData.rooms, floors } }),
      );
    } else {
      dispatch(setFormData({ roomSchedule: undefined }));
    }
    dispatch(setFormData({ floorPlans: undefined }));

    handleDialogClose();
  };

  const onClickedRemove = () => {
    if (floorPlans?.length) {
      return setDialogOpen(true);
    }
    return clearRoomScheduleAndFloorplans();
  };

  const onRoomSaved = (roomData: AddEditRoomProps) => {
    const trimmedName = roomData.name.trim();
    const trimmedFloor = roomData.floor.trim();

    const existingRoom = roomScheduleWatcher.data.find(
      (r: RoomProps) =>
        r.name === trimmedName && r.floor === trimmedFloor && r.deleted === 0,
    );

    const editingRoom = !!roomData.id;

    if (
      //stops allowing the room to be added if the name or floor is the same as another room
      (!editingRoom && existingRoom) ||
      //stops allowing the room to be updated if the name or floor is the same as another room
      (editingRoom && existingRoom && existingRoom.id !== roomData.id)
    ) {
      return showModal({
        title: 'Room Exists',
        messages: [
          'That room already exists in the room schedule, please enter a different name or floor.',
        ],
        buttons: {
          confirm: [{ text: 'Ok', value: true }],
        },
      });
    }

    // remove the room being edited from the list
    const roomListWithoutRoomBeingEdited = roomScheduleWatcher.data.filter(
      (r) => r.id !== roomData.id,
    );

    const roomDataWithAdditionalProps = {
      name: trimmedName,
      floor: trimmedFloor,
      areaFloor: Number(roomData.areaFloor),
      areaWall: Number(roomData.areaWall),
      id: getRandomID(),
      deleted: 0,
    };
    const floorLastIndex = roomListWithoutRoomBeingEdited.findLastIndex(
      (r) => r.floor === roomDataWithAdditionalProps.floor,
    );
    if (floorLastIndex < 0) {
      dispatch(
        setFormData({
          roomSchedule: {
            data: [
              ...roomListWithoutRoomBeingEdited,
              roomDataWithAdditionalProps,
            ],
            floors: [
              ...roomScheduleWatcher.floors,
              roomDataWithAdditionalProps.floor,
            ],
          },
        }),
      );
    } else {
      const newData = [...roomListWithoutRoomBeingEdited];
      newData.splice(floorLastIndex + 1, 0, roomDataWithAdditionalProps);
      dispatch(
        setFormData({
          roomSchedule: {
            data: newData,
            floors: roomScheduleWatcher.floors,
          },
        }),
      );
    }
    dispatch(
      setSnackMessage({
        message: `${roomData.name} ${editingRoom ? 'updated' : 'added'}`,
      }),
    );
  };

  const actionButtons = useCallback(
    ({ row, closeMenu }) => {
      const buttons: JSX.Element[] = [];

      const rowData = row.original;

      buttons.push(
        <TableActionButton
          key="Delete"
          text="Delete"
          colour="red"
          onClick={() => {
            closeMenu();
            onClickedRoomDelete(rowData);
          }}
        />,
        <TableActionButton
          key="Edit"
          text="Edit"
          colour="blue"
          onClick={() => {
            closeMenu();
            onClickedRoomEdit(rowData);
          }}
        />,
      );

      return (
        <Stack direction="column" p={1}>
          {buttons}
        </Stack>
      );
    },
    [rows],
  );

  const showRoomChangeDisallowedModal = useCallback((operation: string) => {
    showModal({
      title: `Room cannot be ${operation}`,
      messages: [
        `This room was created as part of an asset data import and cannot be ${operation}.`,
      ],
      buttons: { confirm: [{ text: 'Ok', value: true }] },
    });
  }, []);

  const onClickedRoomDelete = useCallback(
    (room: RoomProps) => {
      setRoomToEdit(undefined);
      if (room.imported) {
        return showRoomChangeDisallowedModal('deleted');
      }
      const withRoomRemoved: RoomProps[] = roomScheduleWatcher.data.filter(
        (r: RoomProps) => r.id !== room.id,
      );
      const floors = removeDuplicatesFromArray(
        withRoomRemoved.map((r) => r.floor),
      );
      const floorPlanForRoom = floorPlans?.find(
        (fp) => fp.floor === room.floor,
      );
      const floorPlanForRoomWithMarkerRemoved =
        floorPlanForRoom?.markers.filter((m) => m.roomId !== room.id);
      const updatedFloorPlans = floorPlans?.map((fp) => {
        if (fp.floor === room.floor) {
          return {
            ...fp,
            markers: floorPlanForRoomWithMarkerRemoved,
          };
        }
        return fp;
      });
      dispatch(
        setFormData({
          roomSchedule: {
            data: withRoomRemoved,
            floors,
          },
          floorPlans: updatedFloorPlans,
        }),
      );
    },
    [roomScheduleWatcher],
  );

  const onClickedRoomEdit = useCallback((room: RoomProps) => {
    if (room.imported) {
      return showRoomChangeDisallowedModal('edited');
    }
    setRoomToEdit(room);
  }, []);

  return (
    <Box p={2}>
      <ErrorBoundary>
        {/* <DownloadTemplate /> */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={12}>
              <Stack direction="column" alignItems="center">
                <StepGuide
                  stepNumber={1}
                  text="Download the room schedule template."
                />
                <Button
                  onClick={() => {
                    generateRoomScheduleSheet(
                      columns.map((c) => c.header),
                      surveyName,
                    );
                  }}
                  variant="contained"
                  color="secondary"
                >
                  Download Room Schedule Template
                </Button>
              </Stack>

              <Box my={4}>
                <StepGuide
                  stepNumber={2}
                  text="Upload your completed template file."
                />
                <FileUploaderCard
                  title="Room Schedule"
                  onChange={(file) => setFileData(file, 'roomSchedule')}
                  onRemove={roomScheduleWatcher && onClickedRemove}
                  fileName={roomScheduleWatcher?.fileName}
                />
                {errors.roomSchedule && (
                  <ErrorText text="You must upload a room schedule" />
                )}
              </Box>

              {rows?.length ? (
                <Box>
                  <StepGuide stepNumber={3} text="Check your data" />
                  <Table
                    rows={rows}
                    columns={columns}
                    disableSearchParams
                    rowsPerPage={10}
                    tableProps={{
                      enableTopToolbar: false,
                      renderRowActionMenuItems: actionButtons,
                      enableRowActions: true,
                      positionActionsColumn: 'first',
                    }}
                  />
                </Box>
              ) : null}
            </Grid>
          </Grid>

          {rows?.length ? (
            <>
              <AddorEditRoomForm
                room={roomToEdit}
                floors={roomScheduleWatcher?.floors}
                onRoomSaved={onRoomSaved}
                onCancel={() => setRoomToEdit(undefined)}
              />
              <Stack direction="row" justifyContent="flex-end" my={2}>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    dispatch(exportRoomSchedule());
                  }}
                >
                  Export Room Schedule
                </Button>
              </Stack>
            </>
          ) : null}
          <ButtonsContainer>
            <BackButton onBack={onBack} />
            <NextButton />
          </ButtonsContainer>
        </form>
        <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Please Confirm</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to remove this room schedule? This will also
              remove any floorplans and their associated markers.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={handleDialogClose}>
              Cancel
            </Button>
            <Button onClick={clearRoomScheduleAndFloorplans} color="primary">
              Remove
            </Button>
          </DialogActions>
        </Dialog>
      </ErrorBoundary>
    </Box>
  );
};

export default RoomSchedule;
