import 'firebase/firestore';

import { AssetList, AssetLists } from '../types/asset-list';
import { useEffect, useState } from 'react';

import { EMPTY_ARRAY } from '../shared/helpers';
import { UrlParams } from '../types/urlParams';
import firebase from 'firebase/app';
import { useParams } from 'react-router-dom';

export function useAssetListsOnce(clientId?: string) {
  const [assetLists, setAssetLists] = useState<
    AssetList[] | typeof EMPTY_ARRAY
  >();

  useEffect(() => {
    async function getAssetLists() {
      try {
        const firestore = firebase.firestore();

        const clientDocRef = firestore.collection('clients').doc(clientId);

        const assetListDocs = await clientDocRef
          .collection('assetLists')
          .where('deleted', '==', 0)
          .orderBy('name', 'asc')
          .get();

        if (assetListDocs?.size === 0) {
          setAssetLists(EMPTY_ARRAY);
        } else {
          setAssetLists(
            assetListDocs.docs.map((doc) => doc.data() as AssetList),
          );
        }
      } catch (error) {
        console.error(error);
      }
    }
    if (clientId) {
      getAssetLists();
    }
  }, [clientId]);
  return assetLists;
}
