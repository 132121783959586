import { Box, Grid, Typography } from '@mui/material';

import BackButton from '../../../components/Form/BackButton';
import ButtonsContainer from '../../../components/Form/ButtonsContainer';
import { EMPTY_ARRAY } from '../../../shared/helpers';
import NextButton from '../../../components/Form/NextButton';
import PropTypes from 'prop-types';
import SectionTitle from '../../../components/Form/SectionTitle';
import { clientPropTypes } from '../../../shared/propTypes';
import { colours } from '../../../config/theme';
import { makeStyles } from '@mui/styles';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  detailType: {
    ...theme.typography.attributeType,
  },
  sectionTitle: {
    borderBottom: `1px solid ${colours.grey6}`,
    marginBottom: theme.spacing(2),
  },
  image: {
    objectFit: 'cover',
    maxHeight: '200px',
  },
}));

const Detail = ({ title, value }) => {
  const classes = useStyles();
  return value ? (
    <Grid item xs={12} sm={6}>
      <Box mr={1} mb={2}>
        <Typography className={classes.detailType}>{title}</Typography>
        <Typography variant="caption" color="primary">
          {value}
        </Typography>
      </Box>
    </Grid>
  ) : null;
};
Detail.defaultProps = {
  value: '',
};

Detail.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
};

const Confirm = ({ formData, onSubmit, onBack }) => {
  const classes = useStyles();
  const { handleSubmit } = useForm();

  const staff = useSelector(
    (state) => state.firestore.ordered.staff || EMPTY_ARRAY,
  );

  const staffMember = staff.find((member) => {
    return member.uid === formData.owner;
  });

  return (
    <Box p={2}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
          <Grid item container xs={12}>
            <SectionTitle title="Overview" marginTop={0} />
            <Detail title="Portal Name" value={formData.name} />
            <Detail title="Web URL" value={formData.id} />
            <Detail title="Key Contact Name" value={formData.keyContactName} />
            <Detail
              title="Key Contact Email"
              value={formData.keyContactEmail}
            />
            <Detail title="Category" value={formData.category} />
            <Detail
              title="Asseticom Owner"
              value={`${staffMember.nameFirst} ${staffMember.nameLast}`}
            />
            <Detail title="Company Name" value={formData.companyName} />
            <Detail title="Company Number" value={formData.companyNumber} />
            <Detail title="Bank Name" value={formData.bankName} />
            <Detail
              title="Bank Account Number"
              value={formData.bankAccountNumber}
            />
            <Detail title="Bank Sort Code" value={formData.bankSortCode} />
            {(formData.logoImage || formData.backgroundImage) && (
              <SectionTitle title="Images" />
            )}
            {formData.logoImage && (
              <Grid item xs={12} sm={5}>
                <Box
                  my={1}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Box mb={2}>
                    <Typography variant="subtitle1">Company Logo</Typography>
                  </Box>
                  <img
                    width="100%"
                    src={formData.logoImage}
                    alt="logo"
                    className={classes.image}
                  />
                </Box>
              </Grid>
            )}
            <Grid item xs={false} sm={2} />
            {formData.backgroundImage && (
              <Grid item xs={12} sm={5}>
                <Box
                  my={1}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <Box mb={2}>
                    <Typography variant="subtitle1">
                      Background Image
                    </Typography>
                  </Box>
                  <img
                    width="100%"
                    src={formData.backgroundImage}
                    alt="logo"
                    className={classes.image}
                  />
                </Box>
              </Grid>
            )}
            <SectionTitle title="Location" />
            <Detail title="Address Line 1" value={formData.address1} />
            <Detail title="Address Line 2" value={formData.address2} />
            <Detail title="Town/City" value={formData.townCity} />
            <Detail title="County" value={formData.county} />
            <Detail title="Post Code" value={formData.postCode} />
            <SectionTitle title="Admins" />
            {formData.portalAdmins.map((admin) => {
              return (
                <Detail
                  key={admin.email}
                  title={admin.email}
                  value={`${admin.nameFirst} ${admin.nameLast}`}
                />
              );
            })}
          </Grid>
        </Grid>
        <ButtonsContainer>
          <BackButton onBack={() => onBack({})} />
          <NextButton text="confirm" />
        </ButtonsContainer>
      </form>
    </Box>
  );
};

Confirm.defaultProps = {
  formData: {},
};

Confirm.propTypes = {
  formData: PropTypes.shape(clientPropTypes),
  onBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default Confirm;
