import { Box, Typography } from '@mui/material';

import DynamicIcon from '../../../../../../components/DynamicIcon';
import PropTypes from 'prop-types';

const InfoAttributeWithIcon = ({ icon, text }) => {
  return (
    <Box display="flex" alignItems="center" mb={1}>
      <DynamicIcon name={icon} size={30} colour="primary" inCircle />
      <Box ml={2}>
        <Typography variant="caption">{text}</Typography>
      </Box>
    </Box>
  );
};

InfoAttributeWithIcon.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

export default InfoAttributeWithIcon;
