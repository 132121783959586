import { Backdrop, CircularProgress } from '@mui/material';

import PropTypes from 'prop-types';
import { colours } from '../config/theme';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 99999999999990,
    color: colours.white,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

const LoadingOverlay = ({ children, open }) => {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open={open}>
      <CircularProgress color="inherit" />
      {children}
    </Backdrop>
  );
};

LoadingOverlay.defaultProps = {
  children: null,
};

LoadingOverlay.propTypes = {
  children: PropTypes.node,
  open: PropTypes.bool.isRequired,
};

export default LoadingOverlay;
