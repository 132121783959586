import { useEffect, useState } from 'react';

import { EMPTY_OBJECT } from '../shared/helpers';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import { getSnapshotByObject } from 'redux-firestore';
import { makeStyles } from '@mui/styles';
import useQueryParamsUpdater from '../hooks/useQueryParamsUpdater';

const useStyles = makeStyles((theme) => ({
  paginationRoot: {
    borderBottom: 'none',
    display: 'inline-flex',
  },
  paginationToolbar: {
    minHeight: 0,
  },
  paginationActions: {
    '&& button': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
}));

function getTrimmedArray(array, pageNum, resultsPerPage) {
  const startPos = pageNum * resultsPerPage;
  return array.slice(startPos, startPos + resultsPerPage);
}

export const DataPaginator = ({
  label,
  total,
  perPage,
  perPageOptions,
  onChangePage,
  dataArray,
  initialPageNum = 0,
  children = null,
}) => {
  const classes = useStyles();
  const [pageNum, setPageNum] = useState(initialPageNum);
  const [resultsPerPage, setResultsPerPage] = useState(perPage);
  const { updateQueryParams } = useQueryParamsUpdater();

  const handleChangePage = (event, newPageNum) => {
    updateQueryParams({ page: newPageNum });
  };

  const handleChangeRowsPerPage = (event) => {
    const newValue = parseInt(event.target.value, 10);
    setResultsPerPage(newValue);
    updateQueryParams({ page: 0, count: newValue });
  };

  useEffect(() => {
    onChangePage(getTrimmedArray(dataArray, initialPageNum, resultsPerPage));
  }, [dataArray]);

  useEffect(() => {
    onChangePage(getTrimmedArray(dataArray, pageNum, resultsPerPage));
  }, [pageNum, resultsPerPage]);

  useEffect(() => {
    setPageNum(initialPageNum);
  }, [initialPageNum]);

  return (
    <Stack direction="row">
      {children}
      <TablePagination
        component="div"
        classes={{
          root: classes.paginationRoot,
          toolbar: classes.paginationToolbar,
          actions: classes.paginationActions,
        }}
        labelRowsPerPage={label}
        rowsPerPageOptions={perPageOptions}
        colSpan={1}
        count={total}
        rowsPerPage={resultsPerPage}
        page={pageNum}
        SelectProps={{
          inputProps: { 'aria-label': label },
          native: true,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Stack>
  );
};

DataPaginator.defaultProps = {
  total: 0,
  onChangePage: () => null,
  onChangePerPage: () => null,
  children: null,
};

DataPaginator.propTypes = {
  total: PropTypes.number,
  label: PropTypes.string.isRequired,
  perPage: PropTypes.number.isRequired,
  perPageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  onChangePage: PropTypes.func,
  onChangePerPage: PropTypes.func,
  dataArray: PropTypes.arrayOf(PropTypes.object).isRequired,
  children: PropTypes.node,
};

const Paginator = ({
  label,
  total,
  perPage,
  perPageOptions,
  onChangePage,
  onChangePerPage,
  dataArray,
  dataProp,
  dataArrayId,
  children = null,
}) => {
  const classes = useStyles();

  const [pageNum, setPageNum] = useState(0);
  const [resultsPerPage, setResultsPerPage] = useState(perPage);
  const [paginationMap, setPaginationMap] = useState({});
  const [currentDataId, setCurrentDataId] = useState(null);

  const handleChangePage = (event, newPageNum) => {
    if (dataArray.length) {
      const position = dataProp
        ? dataArray[dataArray.length - 1][dataProp]
        : getSnapshotByObject(dataArray[dataArray.length - 1]);
      setPaginationMap((prev) => {
        return {
          ...prev,
          ...{
            [pageNum]: {
              position,
            },
          },
        };
      });
      setPageNum(newPageNum);
      let nextCursor = 0;
      if (newPageNum > pageNum) {
        nextCursor = position;
      } else if (newPageNum !== 0) {
        nextCursor = paginationMap[newPageNum - 1].position;
      }
      onChangePage(nextCursor, newPageNum);
    }
  };

  const reset = () => {
    setPageNum(0);
    onChangePage(0, 0);
  };

  useEffect(() => {
    if (dataArrayId !== currentDataId) {
      setCurrentDataId(dataArrayId);
      reset();
    }
  }, [dataArrayId, reset, setCurrentDataId]);

  const handleChangeRowsPerPage = (event) => {
    const newValue = parseInt(event.target.value, 10);
    setResultsPerPage(newValue);
    onChangePerPage(newValue);
    reset();
  };

  return (
    <Stack direction="row">
      {children}
      <TablePagination
        component="div"
        classes={{
          root: classes.paginationRoot,
          toolbar: classes.paginationToolbar,
          actions: classes.paginationActions,
        }}
        labelRowsPerPage={label}
        rowsPerPageOptions={perPageOptions}
        colSpan={1}
        count={total}
        rowsPerPage={resultsPerPage}
        page={pageNum}
        SelectProps={{
          inputProps: { 'aria-label': label },
          native: true,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Stack>
  );
};

Paginator.defaultProps = {
  total: 0,
  dataProp: null,
  dataArrayId: null,
  children: null,
};

Paginator.propTypes = {
  total: PropTypes.number,
  dataProp: PropTypes.string,
  dataArrayId: PropTypes.string,
  label: PropTypes.string.isRequired,
  perPage: PropTypes.number.isRequired,
  perPageOptions: PropTypes.arrayOf(PropTypes.number).isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePerPage: PropTypes.func.isRequired,
  dataArray: PropTypes.arrayOf(PropTypes.object).isRequired,
  children: PropTypes.node,
};

export default Paginator;
