import { useFormContext, useFormState } from 'react-hook-form';
import { FORM_FIELDS_NAME } from '../../../../../../config/constants';
import { FormField } from '../../../../../../types/form';
import { FormValues } from '..';

type useFormError =
  | { message: string; type: string; ref: { name: string } }
  | undefined;

export default function useFormError(
  index: number,
  path: keyof FormField,
  exact = false,
): useFormError {
  const { control } = useFormContext<FormValues>();

  const { errors } = useFormState({
    control,
    name: `${FORM_FIELDS_NAME}.${index}.${path}`,
    exact,
  });

  const error =
    (errors[FORM_FIELDS_NAME]?.[index]?.[path] as useFormError) ?? undefined;
  return error;
}
