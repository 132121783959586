import { AppState, useAppDispatch } from '../../../../config/store';
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  NOT_APPLICABLE,
  RIGHT_ALIGN_BODY,
  RIGHT_ALIGN_HEADER,
} from '../../../../config/constants';
import {
  autoSizeWorksheetWidth,
  dateFromTimeStamp,
  getCleanedRoomScheduleData,
  getSafeExcelSheetName,
  getStyledExportHeaders,
  getStyledRows,
  handlePlural,
  statusToString,
  statuses,
  timeFromTimeStamp,
} from '../../../../shared/utilities';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import useSurveysMeta, {
  parseSurveysMeta,
} from '../../../../hooks/useSurveysMeta';

import { ALL_ROLES } from '../../../../config/roles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import LastUpdatedTableCell from '../../../../components/LastUpdatedTableCell';
import { MissingUploads } from '../../../../types/missing-uploads';
import { SiteProps } from '../../../../types/site';
import StatusStringTableCell from '../../../../components/StatusStringTableCell';
import { Statuses } from '../../../../types/status';
import Table from '../../../../components/Table';
import TableActionButton from '../../../../components/TableActionButton';
import { UrlParams } from '../../../../types/urlParams';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import WarningIcon from '@mui/icons-material/Warning';
import XLSX from 'xlsx-js-style';
import { getLastUpdated } from '../../../../shared/logTools';
import { onSelectedSurveyDelete } from '../../../../state/survey';
import { useModal } from '../../../../components/Modal';
import { useSelector } from 'react-redux';

const SurveyNameTableCell = ({ cell, onClickToolTip }) => {
  const name = cell.getValue();
  const uploadsNotFound = cell.row.original.uploadsNotFound;
  const uploadsOnOtherDevice = cell.row.original.uploadsOnOtherDevice;
  const uploadsLost = cell.row.original.uploadsLost;
  const totalMissing = uploadsOnOtherDevice + uploadsNotFound + uploadsLost;

  return (
    <>
      <Stack alignItems="center" direction="row">
        {name}
        {uploadsOnOtherDevice || uploadsLost || uploadsNotFound ? (
          <Tooltip
            title={
              <Typography>
                This survey has {totalMissing} missing{' '}
                {handlePlural(totalMissing, 'uploads', false)}
                <br />
                Click to see more details
              </Typography>
            }
            sx={{ width: 200 }}
          >
            <IconButton
              aria-label="delete"
              sx={{ ml: 0.5 }}
              onClick={(e) => {
                e.stopPropagation();
                onClickToolTip?.(cell.row.original);
              }}
            >
              <WarningAmberIcon color="error" />
            </IconButton>
          </Tooltip>
        ) : null}
      </Stack>
    </>
  );
};

type SurveyHistory = {
  time: number;
  status: string;
};

function parseSurveyHistories(histories) {
  return (
    histories
      .map((survey) => {
        const key = Object.keys(survey)[0];
        return { [key]: survey[key] };
      })
      //remove any empties
      .filter((s) => s)
  );
}

const OnOtherDevice = ({ uploadsOnOtherDevice }) => {
  const list = useMemo(() => {
    return uploadsOnOtherDevice.map((upload) => {
      return (
        <Box
          sx={{
            border: 1,
            p: 1,
            borderColor: 'primary.main',
            borderRadius: 1,
            my: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
          key={upload.fileId}
        >
          <Typography variant="caption">{`Description: ${upload.description}`}</Typography>
          {upload.lastUpdated ? (
            <>
              <Typography variant="caption">
                {`Created: ${dateFromTimeStamp(
                  upload.lastUpdated.time,
                )} at ${timeFromTimeStamp(upload.lastUpdated.time)}`}
              </Typography>

              <Typography variant="caption">
                {`Location: ${upload.lastUpdated.name}'s ${upload.lastUpdated.manufacturer} ${upload.lastUpdated.deviceName}`}
              </Typography>
            </>
          ) : null}
        </Box>
      );
    });
  }, [uploadsOnOtherDevice]);

  console.log({ list });
  return list.length ? (
    <Stack my={1} border={1} p={2} borderRadius={2} borderColor="primary.main">
      <Typography color="primary.main" fontWeight={600}>
        Uploads on other devices
      </Typography>
      <Typography color="secondary.main" variant="body2" mb={1}>
        In order to retrieve these uploads, you should reissue the survey to the
        relevant surveyor and ask them to complete the survey from the device
        specified.
      </Typography>
      {list}
    </Stack>
  ) : null;
};

const NotFoundOnCurrentDevice = ({ uploadsNotFound }) => {
  const notFoundList = useMemo(() => {
    return uploadsNotFound
      .filter((u) => typeof u.lastUpdated === 'undefined')
      .map((upload) => {
        return (
          <Box
            sx={{
              border: 1,
              p: 1,
              borderColor: 'primary.main',
              borderRadius: 1,
              my: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
            key={upload.fileId}
          >
            <Typography variant="caption">{`Description: ${upload.description}`}</Typography>
            {upload.lastUpdated ? (
              <>
                <Typography variant="caption">
                  {`Created: ${dateFromTimeStamp(
                    upload.lastUpdated.time,
                  )} at ${timeFromTimeStamp(upload.lastUpdated.time)}`}
                </Typography>

                <Typography variant="caption">
                  {`Location: ${upload.lastUpdated.name}'s ${upload.lastUpdated.manufacturer} ${upload.lastUpdated.deviceName}`}
                </Typography>
              </>
            ) : null}
          </Box>
        );
      });
  }, [uploadsNotFound]);

  const otherDevicesList = useMemo(() => {
    return uploadsNotFound
      .filter((u) => typeof u.lastUpdated !== 'undefined')
      .map((upload) => {
        return (
          <Box
            sx={{
              border: 1,
              p: 1,
              borderColor: 'primary.main',
              borderRadius: 1,
              my: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
            key={upload.fileId}
          >
            <Typography variant="caption">{`Description: ${upload.description}`}</Typography>
            {upload.lastUpdated ? (
              <>
                <Typography variant="caption">
                  {`Created: ${dateFromTimeStamp(
                    upload.lastUpdated.time,
                  )} at ${timeFromTimeStamp(upload.lastUpdated.time)}`}
                </Typography>

                <Typography variant="caption">
                  {`Location: ${upload.lastUpdated.name}'s ${upload.lastUpdated.manufacturer} ${upload.lastUpdated.deviceName}`}
                </Typography>
              </>
            ) : null}
          </Box>
        );
      });
  }, [uploadsNotFound]);

  return otherDevicesList.length || notFoundList.length ? (
    <Stack my={1} border={1} p={2} borderRadius={2} borderColor="primary.main">
      {otherDevicesList.length ? (
        <Box>
          <Typography color="primary.main">
            Uploads that appear be lost
          </Typography>
          {otherDevicesList}
        </Box>
      ) : null}
      {notFoundList.length ? (
        <Stack>
          <Typography color="primary.main" fontWeight={600}>
            Uploads that may be on other devices
          </Typography>
          <Typography color="secondary.main" variant="body2" mb={1}>
            The location of these files could not be determined. They may be
            present on another device that was used to carry out this survey.
          </Typography>
          {notFoundList}
        </Stack>
      ) : null}
    </Stack>
  ) : null;
};

const LostFiles = ({ lostUploads }) => {
  const list = useMemo(() => {
    return lostUploads.map((upload) => {
      return (
        <Box
          sx={{
            border: 1,
            p: 1,
            borderColor: 'primary.main',
            borderRadius: 1,
            my: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
          key={upload.fileId}
        >
          <Typography variant="caption">
            {`Created: ${dateFromTimeStamp(
              upload.lastUpdated.time,
            )} at ${timeFromTimeStamp(upload.lastUpdated.time)}`}
          </Typography>
          <Typography variant="caption">{`Description: ${upload.description}`}</Typography>
          <Typography variant="caption">
            {`Location: ${upload.lastUpdated.name}'s ${upload.lastUpdated.manufacturer} ${upload.lastUpdated.deviceName}`}
          </Typography>
        </Box>
      );
    });
  }, [lostUploads]);

  return lostUploads.length ? (
    <Stack my={1} border={1} p={2} borderRadius={2} borderColor="primary.main">
      <Typography color="primary.main" fontWeight={600}>
        Uploads that are not recoverable
      </Typography>
      <Typography color="secondary.main" variant="body2" mb={1}>
        When trying to upload, these files were not found on the device that
        created them. It may be that the app was uninstalled before the upload
        was attempted.
      </Typography>
      {list}
    </Stack>
  ) : null;
};

const MissingUploadsContent = ({
  clientId,
  surveyId,
}: {
  clientId: string | undefined;
  surveyId: string | undefined;
}) => {
  console.log('OPEN', { clientId, surveyId });
  const missingUploads = useSelector(
    (state: AppState) => state.firestore.data.missingUploads,
  );

  useFirestoreConnect(
    clientId && surveyId
      ? [
          {
            collection: 'clients',
            doc: clientId,
            subcollections: [{ collection: 'missing-uploads', doc: surveyId }],
            storeAs: 'missingUploads',
          },
        ]
      : [],
  );

  console.log({ missingUploads });

  return missingUploads ? (
    <>
      <OnOtherDevice
        uploadsOnOtherDevice={missingUploads.uploadsOnOtherDevice}
      />
      <NotFoundOnCurrentDevice
        uploadsNotFound={missingUploads.uploadsNotFound}
      />
      <LostFiles lostUploads={missingUploads.uploadsLost} />
    </>
  ) : null;

  // return (
  //   <OnOtherDevice uploadsOnOtherDevice={missingUploads.uploadsOnOtherDevice} />
  // );
};

const View = () => {
  const location = useLocation();
  const history = useHistory();
  const { clientId } = useParams<UrlParams>();
  const { showModal } = useModal();
  const firestore = useFirestore();
  const dispatch = useAppDispatch();
  const [missingUploadsData, setMissingUploadsData] = useState<
    MissingUploads | undefined
  >(undefined);

  const userClaims = useSelector(
    (state: AppState) => state.firebase.profile.token?.claims,
  );

  const { surveys } = useSurveysMeta();

  const client = useSelector((state: AppState) => state.firestore.data.client);

  const showConfirmDialogForSurvey = async (survey) => {
    const messages = [
      `Are you sure you want to delete ${survey.name}?`,
      '*WARNING* You will lose access to all data associated with this survey',
    ];

    const deleteConfirmed = await showModal({
      title: 'Please Confirm',
      buttons: {
        confirm: [{ text: 'Yes', value: true }],
        cancel: [{ text: 'No' }],
      },
      messages,
    });
    if (deleteConfirmed) {
      dispatch(onSelectedSurveyDelete({ survey }));
    }
  };

  const getDuplicateOfSurvey = (survey) => {
    const surveyData = { ...survey };

    surveyData.assetsNumCreated = 0;
    surveyData.assetsNumDeleted = 0;
    surveyData.assetsNumFlagged = 0;
    surveyData.assetsNumImported = 0;
    surveyData.partialsNumCreated = 0;
    surveyData.partialsNumDeleted = 0;
    surveyData.roomsNumCompleted = 0;
    surveyData.roomsNumDeleted = 0;
    surveyData.roomsNumInProgress = 0;
    surveyData.roomsNumNotStarted = surveyData.roomSchedule?.data?.length || 0;
    surveyData.roomsNumDeleted = 0;
    surveyData.created = Date.now();
    surveyData.synced = 0;
    surveyData.roomSchedule = getCleanedRoomScheduleData(
      surveyData.roomSchedule,
    );
    surveyData.uploadsLost = 0;
    surveyData.uploadsNotFound = 0;
    surveyData.uploadsOnOtherDevice = 0;
    surveyData.uploadsQueueSize = 0;
    surveyData.status = Statuses.ISSUED;

    delete surveyData.id;
    delete surveyData.assets;
    delete surveyData.partials;
    delete surveyData.flagged;
    delete surveyData.lastUpdated;
    delete surveyData.surveyType;
    delete surveyData.surveyorId;
    delete surveyData.surveyorEmail;
    delete surveyData.surveyorName;
    delete surveyData.surveyorName;

    return surveyData;
  };

  const onClickDuplicateSurvey = async (id) => {
    const surveyDoc = await firestore
      .collection('clients')
      .doc(clientId)
      .collection('surveys')
      .doc(id)
      .get();

    const surveyData = getDuplicateOfSurvey(surveyDoc.data());

    surveyData.duplicatedSurveyId = id;
    surveyData.name = ``;

    const newSurveyDoc = firestore
      .collection('clients')
      .doc(surveyData.clientId)
      .collection('surveys')
      .doc();

    surveyData.id = newSurveyDoc.id;

    await newSurveyDoc.set({ ...surveyData, ...getLastUpdated() });

    const surveyUri = `/${surveyData.clientId}/surveys/${newSurveyDoc.id}`;

    history.push(surveyUri);
  };

  const onClickDownloadSurvey = async ({ id }) => {
    const surveyDoc = await firestore
      .collection('clients')
      .doc(clientId)
      .collection('surveys')
      .doc(id)
      .get();

    const surveyData = getDuplicateOfSurvey(surveyDoc.data());
    surveyData.name = `Export of ${surveyData.name}`;
    const dataStr =
      'data:text/json;charset=utf-8,' +
      encodeURIComponent(JSON.stringify(surveyData, null, 2));
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute('href', dataStr);
    downloadAnchorNode.setAttribute('download', surveyData.name + '.json');
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  };

  const rows = surveys?.map((survey) => {
    const {
      id,
      name,
      surveyorName,
      siteName,
      buildingName,
      status,
      assets,
      partials,
      flagged,
      lastUpdated,
      surveyType,
      uploadsNotFound,
      uploadsLost,
      uploadsOnOtherDevice,
      uploadsQueueSize,
    } = survey;

    return {
      id,
      name,
      surveyorName,
      siteName,
      buildingName,
      status,
      assets,
      partials,
      flagged,
      lastUpdated,
      type: surveyType,
      uploadsNotFound,
      uploadsLost,
      uploadsOnOtherDevice,
      uploadsQueueSize: uploadsQueueSize || 0,
    };
  });

  const showMissingUploadsDialog = ({ rowData }) => {
    setMissingUploadsData(rowData);
  };

  const closeMissingUploadsDialog = () => {
    setMissingUploadsData(undefined);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
        Cell: ({ cell }) => (
          <SurveyNameTableCell
            cell={cell}
            onClickToolTip={(rowData) => {
              console.log({ rowData });
              showMissingUploadsDialog({ rowData });
            }}
          />
        ),
      },
      { accessorKey: 'type', header: 'Type' },

      {
        accessorKey: 'status',
        header: 'Status',
        Cell: StatusStringTableCell,
      },
      { accessorKey: 'surveyorName', header: 'Surveyor' },
      {
        accessorKey: 'lastUpdated.time',
        header: 'Last Updated',
        Cell: LastUpdatedTableCell,
      },
      { accessorKey: 'siteName', header: 'Site' },
      { accessorKey: 'buildingName', header: 'Building' },
      {
        accessorKey: 'assets',
        header: 'Assets',
        ...RIGHT_ALIGN_HEADER,
        ...RIGHT_ALIGN_BODY,
      },
      {
        accessorKey: 'partials',
        header: 'Partials',
        ...RIGHT_ALIGN_HEADER,
        ...RIGHT_ALIGN_BODY,
      },
      {
        accessorKey: 'flagged',
        header: 'Flagged',
        ...RIGHT_ALIGN_HEADER,
        ...RIGHT_ALIGN_BODY,
      },
      {
        accessorKey: 'uploadsQueueSize',
        header: 'Queued Uploads',
        ...RIGHT_ALIGN_HEADER,
        ...RIGHT_ALIGN_BODY,
      },
    ],
    [],
  );

  const onClickedEditSurvey = (surveyId) => {
    history.push(`${location.pathname}/${surveyId}`);
  };

  const onClickedViewSurvey = ({ id }) => {
    history.push(`${location.pathname}/${id}`);
  };

  const onClickReissueSurvey = async ({ id }) => {
    history.push(`${location.pathname}/reissue/${id}`);
  };

  const actionButtons = useCallback(
    ({ row, closeMenu }) => {
      const buttons: JSX.Element[] = [];

      const rowData = row.original;

      const handleClick = () => {
        closeMenu();
      };

      if (
        rowData.status === statuses.IN_PROGRESS ||
        rowData.status === statuses.COMPLETED
      ) {
        buttons.push(
          <TableActionButton
            key="View"
            text="View"
            colour="primary"
            onClick={() => closeMenu(onClickedViewSurvey(rowData))}
          />,
        );
      }

      if (
        userClaims?.roles?.asseticom >= ALL_ROLES.LEAD_SURVEYOR ||
        (clientId && userClaims?.roles?.[clientId] >= ALL_ROLES.LEAD_SURVEYOR)
      ) {
        buttons.push(
          <TableActionButton
            key="Duplicate"
            text="Duplicate"
            colour="secondary"
            onClick={() => closeMenu(onClickDuplicateSurvey(rowData.id))}
          />,
        );
      }

      if (
        rowData.status === statuses.ISSUED ||
        rowData.status === statuses.NOT_STARTED
      ) {
        buttons.push(
          <TableActionButton
            key="Edit"
            text="Edit"
            colour="blue"
            onClick={() => closeMenu(onClickedEditSurvey(rowData.id))}
          />,
        );
      }

      if (
        rowData.status !== statuses.ISSUED &&
        rowData.status !== statuses.NOT_STARTED &&
        (userClaims?.roles?.asseticom >= ALL_ROLES.LEAD_SURVEYOR ||
          (clientId &&
            userClaims?.roles?.[clientId] >= ALL_ROLES.LEAD_SURVEYOR))
      ) {
        buttons.push(
          <TableActionButton
            key="Reissue"
            text="Reissue"
            colour="secondary"
            onClick={() => closeMenu(onClickReissueSurvey(rowData))}
          />,
        );
      }
      if (userClaims?.roles?.asseticom >= ALL_ROLES.LEAD_SURVEYOR) {
        buttons.push(
          <TableActionButton
            key="Download"
            text="Download"
            colour="blue"
            onClick={() => closeMenu(onClickDownloadSurvey(rowData))}
          />,
        );
      }
      if (
        rowData.status !== statuses.IN_PROGRESS ||
        userClaims?.roles?.asseticom >= ALL_ROLES.LEAD_SURVEYOR ||
        (clientId && userClaims?.roles?.[clientId] >= ALL_ROLES.LEAD_SURVEYOR)
      ) {
        buttons.push(
          <TableActionButton
            key="Delete"
            text="Delete"
            colour="red"
            onClick={() => closeMenu(showConfirmDialogForSurvey(rowData))}
          />,
        );
      }
      return (
        <Stack direction="column" p={1}>
          {buttons}
        </Stack>
      );
    },
    [userClaims, clientId],
  );

  const onClickExportSurveyList = async () => {
    const allSites = await firestore
      .collection('clients')
      .doc(clientId)
      .collection('sites')
      .get();

    const allAssetLists = await firestore
      .collection('clients')
      .doc(clientId)
      .collection('assetLists')
      .get();

    const allAssetListsById = {};

    allAssetLists.docs.forEach((assetListDoc) => {
      allAssetListsById[assetListDoc.id] = assetListDoc.data();
    });

    const sitesById = {};

    allSites.docs.forEach((siteDoc) => {
      const siteData = siteDoc.data();
      const siteId = siteDoc.id;
      sitesById[siteId] = siteData;
    });

    const allSurveysMetaSnapshot = await firestore
      .collection('clients')
      .doc(clientId)
      .collection('surveys-meta')
      .get();

    const allSurveysMeta = parseSurveysMeta(
      allSurveysMetaSnapshot.docs.map((doc) => doc.data()),
    );

    const metaHasAssetListId = allSurveysMeta.list.filter(
      (survey) => survey.assetListId,
    ).length;

    const allSurveysStatusHistorySnapshot = await firestore
      .collection('clients')
      .doc(clientId)
      .collection('surveys-status-history')
      .get();

    const allSurveysHistory = parseSurveyHistories(
      allSurveysStatusHistorySnapshot.docs.map((doc) => doc.data()),
    );

    if (!allSurveysMeta.list.length) {
      return;
    }

    const surveysData = allSurveysMeta.list.sort(
      (a, b) => a.lastUpdated.time - b.lastUpdated.time,
    );

    const workbook = XLSX.utils.book_new();

    const SHOW_COLUMNS = [
      'Name',
      'Type',
      'Status',
      metaHasAssetListId ? 'Taxonomy' : '',
      'Surveyor',
      'Last Updated',
      'Site Name',
      'Building Name',
      'GIFA (m2)',
      'External Area (m2)',
      'City/Town',
      'County',
      'Post Code',
      'Region',
      'Assets',
      'Partials',
      'Flagged',
      'Estimated Start Date',
      'Actual Start Date',
      'Estimated Days to Complete',
      'Actual Days to Complete',
    ].filter(Boolean);

    const ALL_COLUMNS = getStyledExportHeaders(SHOW_COLUMNS);

    const surveyRows = surveysData.map((survey) => {
      const siteForSurvey: SiteProps = sitesById[survey.siteId];
      const statusesForSurvey = allSurveysHistory?.[survey.id];

      let actualStartDate = [Statuses.NOT_STARTED, Statuses.ISSUED].includes(
        survey.status,
      )
        ? null
        : survey.created;

      const hasStartDate = statusesForSurvey?.find(
        (statusForSurvey) => statusForSurvey.status === Statuses.IN_PROGRESS,
      );
      if (hasStartDate) {
        actualStartDate = hasStartDate.time;
      }

      let actualEndDate = survey.created;
      const hasEndDate = statusesForSurvey?.find(
        (statusForSurvey) => statusForSurvey.status === Statuses.COMPLETED,
      );

      if (hasEndDate) {
        actualEndDate = hasEndDate.time;
      }

      let actualDuration = survey.status === Statuses.COMPLETED ? 1 : null;

      const ONE_DAY_IN_MILLISECONDS = 86400000;

      if (hasStartDate && hasEndDate) {
        actualDuration = Math.round(
          (hasEndDate.time - hasStartDate.time) / ONE_DAY_IN_MILLISECONDS,
        );
      }

      return [
        {
          v: survey.name,
          t: 's',
        },
        {
          v: survey.surveyType || '',
          t: 's',
        },
        {
          v: statusToString(survey.status),
          t: 's',
        },
        metaHasAssetListId
          ? {
              v: allAssetListsById[survey.assetListId]?.name || '',
              t: 's',
            }
          : undefined,
        {
          v: survey.surveyorName || '',
          t: 's',
        },
        {
          v: `${dateFromTimeStamp(survey.lastUpdated.time)} ${timeFromTimeStamp(
            survey.lastUpdated.time,
          )}`,
          t: 's',
        },
        {
          v: survey.siteName,
          t: 's',
        },
        {
          v: survey.buildingName,
          t: 's',
        },
        {
          v: survey.gifa,
          t: 'n',
        },
        {
          v: survey.external || 0,
          t: 'n',
        },
        {
          v: siteForSurvey.townCity,
          t: 's',
        },
        {
          v: siteForSurvey.county,
          t: 's',
        },
        {
          v: siteForSurvey.postCode,
          t: 's',
        },
        {
          v: siteForSurvey.region || 'N/A',
          t: 's',
        },
        {
          v: survey.assets ?? 0 ?? 0,
          t: 'n',
        },
        {
          v: survey.partials ?? 0 ?? 0,
          t: 'n',
        },
        {
          v: survey.flagged ?? 0,
          t: 'n',
        },
        {
          v: `${dateFromTimeStamp(
            survey.estimatedStartDate || survey.created,
          )}`,
          t: 'd',
        },
        {
          v: actualStartDate
            ? `${dateFromTimeStamp(actualStartDate)}`
            : NOT_APPLICABLE,
          t: actualStartDate ? 'd' : 's',
          s: { alignment: { horizontal: 'right' } },
        },
        {
          v: survey.estimatedDuration || 1,
          t: 'n',
        },
        {
          v: actualDuration || NOT_APPLICABLE,
          t: actualDuration ? 'n' : 's',
          s: { alignment: { horizontal: 'right' } },
        },
      ].filter(Boolean);
    });

    const sRowStyled = getStyledRows(surveyRows);

    const worksheet = XLSX.utils.aoa_to_sheet([ALL_COLUMNS, ...sRowStyled]);
    XLSX.utils.sheet_add_aoa(worksheet, [ALL_COLUMNS], {
      origin: 'A1',
    });

    const fixedWorksheet = await autoSizeWorksheetWidth(worksheet);
    const workbookName = getSafeExcelSheetName(
      `${client.name.substring(0, 23)} Surveys`,
    );

    XLSX.utils.book_append_sheet(workbook, fixedWorksheet, workbookName);
    XLSX.writeFile(workbook, `${workbookName}.xlsx`);
  };

  if (!actionButtons.length || !surveys.length) {
    return null;
  }

  return (
    <Paper sx={{ width: '100%' }} id="main-content" elevation={0}>
      <ErrorBoundary>
        <Dialog
          fullScreen={false}
          open={!!missingUploadsData}
          onClose={closeMissingUploadsDialog}
          aria-labelledby="missing-uploads-dialog-title"
          aria-describedby="missing-uploads-dialog-description"
          sx={{ backgroundColor: 'transparent' }}
        >
          {missingUploadsData ? (
            <>
              <DialogTitle id="missing-uploads-dialog-title" align="center">
                Missing Uploads
              </DialogTitle>
              <DialogContent>
                <MissingUploadsContent
                  clientId={clientId}
                  surveyId={missingUploadsData?.id}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={closeMissingUploadsDialog} color={'primary'}>
                  OK
                </Button>
              </DialogActions>
            </>
          ) : null}
        </Dialog>
        <Table
          rows={rows}
          columns={columns}
          onClickRow={onClickedViewSurvey}
          sortBy={{ id: 'lastUpdated.time', desc: true }}
          tableProps={{
            //@ts-ignore
            renderRowActionMenuItems: actionButtons,
            enableRowActions: true,
            positionActionsColumn: 'first',
            renderTopToolbarCustomActions: () => {
              return (
                <Stack
                  px={1}
                  mb={1}
                  direction="row"
                  justifyContent="flex-end"
                  sx={{ width: '100%' }}
                >
                  <Button
                    variant="outlined"
                    onClick={onClickExportSurveyList}
                    color="secondary"
                  >
                    Export Survey List
                  </Button>
                </Stack>
              );
            },
          }}
        />
      </ErrorBoundary>
    </Paper>
  );
};

export default View;
