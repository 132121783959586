import { Controller, useFormContext } from 'react-hook-form';
import {
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useEffect, useRef } from 'react';

import ErrorText from '../../../../../../components/Form/ErrorText';
import { FORM_FIELDS_NAME } from '../../../../../../config/constants';
import { FormFieldOption } from '../../../../../../types/form';
import { FormValues } from '..';
import useFormError from '../hooks/useFormError';

type FormFieldDefaultSelectProps = {
  value?: FormFieldOption['value'];
  index: number;
  options?: FormFieldOption[];
};
export default function FormFieldDefaultSelect({
  value,
  index,
  options,
}: FormFieldDefaultSelectProps) {
  const { control, setValue } = useFormContext<FormValues>();
  const error = useFormError(index, 'default');
  const optionsValuesPrev = useRef<string[]>();

  useEffect(() => {
    const newOptionValues = options?.map((o) => o.value);
    const prevIndex = optionsValuesPrev.current?.findIndex((p) => p === value);
    const sameSize = options?.length === optionsValuesPrev.current?.length;
    const valueNotInCurrentOptions =
      value && newOptionValues?.includes(value) === false;

    if (
      value &&
      sameSize &&
      typeof prevIndex === 'number' &&
      valueNotInCurrentOptions
    ) {
      setValue(
        `${FORM_FIELDS_NAME}.${index}.default`,
        newOptionValues?.[prevIndex],
      );
    } else if (!sameSize && valueNotInCurrentOptions) {
      setValue(`${FORM_FIELDS_NAME}.${index}.default`, undefined);
    }

    optionsValuesPrev.current = newOptionValues;
  }, [options, value]);

  if (!options?.length) {
    return null;
  }

  return (
    <>
      <Controller
        control={control}
        name={`${FORM_FIELDS_NAME}.${index}.default`}
        rules={{
          required: false,
        }}
        render={({ field }) => (
          <FormControl variant="outlined" fullWidth error={!!error?.message}>
            <InputLabel htmlFor="assetList">Default Option</InputLabel>

            <Select
              onChange={(event: SelectChangeEvent) => {
                return field.onChange(event?.target?.value);
              }}
              native
              variant="outlined"
              label="Default Option"
              fullWidth
              value={String(value)}
            >
              <option key={0} value="" aria-label="empty" />
              {options?.map((option, index) => {
                if (!option.value) {
                  return null;
                }
                return (
                  <option key={index} value={option.value}>
                    {option.value}
                  </option>
                );
              })}
            </Select>
          </FormControl>
        )}
      />
      {error?.message && <ErrorText text={error?.message} />}
    </>
  );
}
