import { Button, Typography, useTheme } from '@mui/material';

import { StandardTheme } from '../config/theme';

type TableActionButtonProps = {
  text: string;
  colour: keyof typeof StandardTheme.palette;
  onClick?: () => void;
};

const TableActionButton = ({
  text,
  colour,
  onClick,
}: TableActionButtonProps) => {
  const theme = useTheme();
  return (
    <Button onClick={onClick} size="small" key={text}>
      <Typography
        my={0.5}
        variant="caption"
        sx={{ textTransform: 'capitalize', color: theme.palette[colour]?.main }}
      >
        {text?.toLowerCase()}
      </Typography>
    </Button>
  );
};

export default TableActionButton;
