import FormFieldDefaultSelect from './FormFieldDefaultSelect';
import FormFieldOptions from './FormFieldOptions';
import { FormFieldRowElementProps } from '../../../../../../types/form';
import { Grid } from '@mui/material';

export default function FormFieldRowSelect({
  formField,
  index,
}: FormFieldRowElementProps) {
  return (
    <Grid container spacing={2} mt={1}>
      <Grid item xs={12}>
        <FormFieldOptions index={index} />
      </Grid>

      <Grid item xs={12} md={6}>
        <FormFieldDefaultSelect
          value={formField.default ? String(formField.default) : ''}
          index={index}
          options={formField.options}
        />
      </Grid>
    </Grid>
  );
}
