import { Controller, useWatch } from 'react-hook-form';

import ErrorText from './Form/ErrorText';
import { TextField } from '@mui/material';
import { passwordRegex } from '../shared/utilities';

const ChangePassword = ({ control, errors }) => {
  const currentNewPassword = useWatch({ control, name: 'newPassword' });
  return (
    <>
      <Controller
        control={control}
        rules={{
          required: false,
          pattern: {
            value: passwordRegex,
          },
        }}
        name="newPassword"
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            type="password"
            margin="normal"
            id="newPassword"
            label="New Password"
            variant="outlined"
            autoComplete="new-password"
            error={!!errors.newPassword}
          />
        )}
      />
      {errors.newPassword && (
        <ErrorText
          text={`Your password must:\nBe at least 12 characters in length.\nContain at least one uppercase letter.\nContain at least one lowercase letter.\nContain at least one number.`}
        />
      )}
      <Controller
        control={control}
        rules={{
          validate: (value) => {
            return value === currentNewPassword;
          },
        }}
        name="confirmPassword"
        render={({ field }) => (
          <TextField
            {...field}
            fullWidth
            type="password"
            margin="normal"
            id="confirmPassword"
            label="Confirm Password"
            variant="outlined"
            autoComplete="off"
            error={!!errors.confirmPassword}
          />
        )}
      />
      {errors.confirmPassword && (
        <ErrorText text="The passwords do not match" />
      )}
    </>
  );
};

ChangePassword.defaultProps = {};

ChangePassword.propTypes = {};

export default ChangePassword;
