import * as Sentry from '@sentry/react';

let userObj = { displayName: '', uid: '' };

const setUserObj = (_userObj) => {
  userObj = { ..._userObj };
};

const getLastUpdated = () => {
  return {
    lastUpdated: {
      uid: userObj.uid,
      name: userObj.displayName,
      time: Date.now(),
      platform: 'web',
      deviceId: window.navigator.userAgent,
    },
  };
};

function recordBreadcrumb(message, data) {
  Sentry.addBreadcrumb({
    message,
    level: 'info',
    data,
  });
}

function recordError(err, data) {
  console.error(err, data);
  if (data) {
    recordBreadcrumb(err?.message ?? err, data);
  }
  Sentry.captureException(err);
}

const checkForUndefinedInObject = (obj, objName) => {
  return (
    obj &&
    Object.keys(obj).forEach((key) => {
      if (typeof obj[key] === 'undefined') {
        console.log(
          `Undefined found: ${key} in ${objName} : ${JSON.stringify(
            obj,
            null,
            2,
          )}`,
        );
        recordBreadcrumb(
          `Undefined found: ${key} in ${objName} in obj with keys ${JSON.stringify(
            Object.keys(obj, null, 2),
          )}`,
        );
      } else if (typeof obj[key] === 'object') {
        checkForUndefinedInObject(obj[key], objName);
      }
    })
  );
};

export {
  setUserObj,
  getLastUpdated,
  recordBreadcrumb,
  recordError,
  checkForUndefinedInObject,
};
