import {
  getRandomHyphenatedStringFromArray,
  getRandomNumberBetween,
} from '../shared/utilities';

import { getLastUpdated } from '../shared/logTools';
import { setSnackMessage } from '../state/ui';

const getTestSiteData = () => {
  const randomString = getRandomHyphenatedStringFromArray([
    'client',
    'site',
    getRandomNumberBetween(0, 500),
  ]);
  const randomBuildingString = getRandomHyphenatedStringFromArray([
    'building',
    getRandomNumberBetween(0, 500),
  ]);
  return {
    address1: `${getRandomNumberBetween(1, 300)} Site Street`,
    address2: 'Site Town',
    deleted: 0,
    lat: 50.858513,
    lng: -0.172191,
    external: getRandomNumberBetween(1000, 3000),
    gifa: getRandomNumberBetween(1000, 3000),
    name: randomString,
    nrmURLFabric: null,
    nrmURLMande:
      'https://firebasestorage.googleapis.com/v0/b/asseticom-uk.appspot.com/o/test%2Fnrm%2Ftest%2Fme_1609243755438.xlsx?alt=media&token=59f1ab57-53af-46f5-8a6c-f763d7e2ad8a',
    townCity: 'Eastbourne',
    county: 'East Sussex',
    postCode: 'PO1 ST2',
    status: 'notStarted',
    usage: '',
  };
};

export const onNewSiteSubmitted =
  ({ site }) =>
  async (dispatch, getState, { getFirebase }) => {
    try {
      const firestore = getFirebase().firestore();
      const { client } = getState().firestore.data;
      const siteData = { ...site };
      // assign the created date
      siteData.created = Date.now();
      siteData.deleted = 0;
      siteData.clientId = client.clientId;
      siteData.clientName = client.name;
      siteData.buildingsNumCreated = 0;
      siteData.buildingsNumDeleted = 0;
      siteData.surveysNumCreated = 0;
      siteData.surveysNumDeleted = 0;
      siteData.surveysNumCompleted = 0;
      siteData.surveysNumInProgress = 0;
      siteData.assetsNumCreated = 0;
      siteData.assetsNumDeleted = 0;
      siteData.partialsNumCreated = 0;
      siteData.partialsNumDeleted = 0;
      siteData.assetsNumFlagged = 0;

      // create admin users
      const siteDocRef = await firestore
        .collection('clients')
        .doc(client.clientId)
        .collection('sites')
        .doc();
      const { id } = siteDocRef;
      siteDocRef.set({ ...siteData, id, ...getLastUpdated() });

      return { id };
    } catch (error) {
      return { error };
    }
  };

export const onSiteEditSubmitted =
  ({ site, siteId }) =>
  async (dispatch, getState, { getFirebase }) => {
    try {
      const firestore = getFirebase().firestore();
      const { client } = getState().firestore.data;
      const siteData = { ...site };
      // create admin users
      const siteDocRef = await firestore
        .collection('clients')
        .doc(client.clientId)
        .collection('sites')
        .doc(siteId);
      siteDocRef.update({ ...siteData, ...getLastUpdated() });
      dispatch(setSnackMessage({ message: 'Site details updated' }));
      return { id: siteId };
    } catch (error) {
      return { error };
    }
  };

export const onBuildingSubmitted =
  ({ building, siteId, useSiteAddress }) =>
  async (dispatch, getState, { getFirebase }) => {
    const firestore = getFirebase().firestore();
    const { client, site } = getState().firestore.data;

    // create admin users
    const siteDocRef = await firestore
      .collection('clients')
      .doc(client.clientId)
      .collection('sites')
      .doc(siteId);

    const buildingObj = {
      ...building,
      siteId,
      siteName: site.name,
      clientName: client.name,
      clientId: client.clientId,
      deleted: 0,
    };

    // must be editing a building
    if (building.id) {
      dispatch(setSnackMessage({ message: 'Building details updated' }));
      return siteDocRef
        .collection('buildings')
        .doc(building.id)
        .update({ ...buildingObj, ...getLastUpdated() });
    }
    dispatch(setSnackMessage({ message: 'New building added' }));
    const buildingDocRef = siteDocRef.collection('buildings').doc();
    return buildingDocRef.set({
      ...buildingObj,
      id: buildingDocRef.id,
      created: Date.now(),
      ...getLastUpdated(),
    });
  };

export const onSelectedBuildingDelete =
  ({ building, siteId }) =>
  async (dispatch, getState, { getFirebase }) => {
    const firestore = getFirebase().firestore();
    const { client } = getState().firestore.data;

    const deletedDate = Date.now();

    const surveysAttachedToBuilding = await firestore
      .collection('clients')
      .doc(client.id)
      .collection('surveys')
      .where('buildingId', '==', building.id)
      .where('deleted', '==', 0)
      .get();

    if (surveysAttachedToBuilding.size > 0) {
      return dispatch(
        setSnackMessage({
          message: `Building cannot be deleted as ${surveysAttachedToBuilding.size} surveys are assigned to it`,
          type: 'error',
        }),
      );
    }

    dispatch(setSnackMessage({ message: `${building.name} deleted` }));
    return firestore
      .collection('clients')
      .doc(client.clientId)
      .collection('sites')
      .doc(siteId)
      .collection('buildings')
      .doc(building.id)
      .update({ deleted: deletedDate, ...getLastUpdated() });
  };

export const onSelectedSiteDelete =
  ({ site }) =>
  async (dispatch, getState, { getFirebase, getFirestore }) => {
    const { client } = getState().firestore.data;

    const firestore = getFirestore();
    const deletedDate = Date.now();

    const surveysAttachedToSites = await firestore
      .collection('clients')
      .doc(client.id)
      .collection('surveys')
      .where('siteId', '==', site.id)
      .where('deleted', '==', 0)
      .get();

    if (surveysAttachedToSites.size > 0) {
      return dispatch(
        setSnackMessage({
          message: `Site cannot be deleted as ${site.surveys} surveys are assigned to it`,
          type: 'error',
        }),
      );
    }

    dispatch(setSnackMessage({ message: `${site.name} deleted` }));

    const siteDocRef = firestore
      .collection('clients')
      .doc(client.clientId)
      .collection('sites')
      .doc(site.id);

    // get and update the deleted date of all the associated buildings docs
    const buildings = await siteDocRef
      .collection('buildings')
      .where('deleted', '==', 0)
      .get();
    buildings.forEach((doc) => {
      doc.ref.update({
        deleted: deletedDate,
        ...getLastUpdated(),
      });
    });

    return siteDocRef.update({
      deleted: deletedDate,
      ...getLastUpdated(),
    });
  };

export const addNewTestSite =
  () =>
  async (dispatch, getState, { getFirebase }) => {
    const formData = getTestSiteData();
    return dispatch(onNewSiteSubmitted({ site: formData }));
  };
