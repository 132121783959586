import 'react-image-crop/dist/ReactCrop.css';

import * as pdfjs from 'pdfjs-dist/build/pdf';

import { Box, Button, Grid, Paper, Stack, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

import FileUploaderCard from '../../../../components/FileUploaderCard';
import { PDFDocument } from 'pdf-lib';
import ReactCrop from 'react-image-crop';
import { fileNameAndExtensionFromPath } from '../../../../shared/utilities';
import { useModal } from '../../../../components/Modal';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const Cropper = () => {
  const fileUploaderRef = useRef();
  const [fileData, setFileData] = useState();
  const { showModal } = useModal();

  const onClickedRemove = () => {
    setFileData(undefined);
    setBase64img(undefined);
    fileUploaderRef.current = undefined;
  };

  const [PDFUrl, setPDFurl] = useState();

  const [base64img, setBase64img] = useState();

  async function fileToPDF(file) {
    if (file.type !== 'application/pdf') {
      return showModal({
        title: 'Unsupported File',
        messages: ['You can only upload PDF files.'],
        buttons: {
          confirm: [{ text: 'Ok', value: true }],
        },
      });
    }
    const url = window.URL.createObjectURL(file);
    setPDFurl(url);
    const load = await pdfjs.getDocument(url);
    const pdf = await load.promise;
    const page = await pdf.getPage(1);
    const viewport = page.getViewport({ scale: 1 });

    // // Prepare canvas using PDF page dimensions
    const canvas = document.getElementById('canvas');
    const context = canvas.getContext('2d');
    canvas.height = viewport.height;
    canvas.width = viewport.width;

    // Render PDF page into canvas context
    const renderContext = {
      canvasContext: context,
      viewport,
    };

    // onUpdate(null, index);
    const renderTask = await page.render(renderContext);
    await renderTask.promise;
    const dataUrl = canvas.toDataURL('image/jpeg');
    setBase64img(dataUrl);
  }

  const [crop, setCrop] = useState();
  const [completeCrop, setCompleteCrop] = useState();

  async function downloadPDF() {
    const existingPdfBytes = await fetch(PDFUrl).then((res) =>
      res.arrayBuffer(),
    );
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    const { width: pdfWidth, height: pdfHeight } = firstPage.getSize();
    const scaledWidth = Math.floor(pdfWidth * (completeCrop.width / 100));
    const scaledHeight = Math.floor(pdfHeight * (completeCrop.height / 100));

    const cropBox = {
      x: Math.floor(pdfWidth * (completeCrop.x / 100)),
      y:
        pdfHeight -
        (Math.floor(pdfHeight * (completeCrop.y / 100)) + scaledHeight),
      width: scaledWidth,
      height: scaledHeight,
    };
    firstPage.setCropBox(cropBox.x, cropBox.y, cropBox.width, cropBox.height);

    const pdfBytes = await pdfDoc.save();
    var blob = new Blob([pdfBytes], { type: 'application/pdf' });
    var pdflink = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    const { name } = fileNameAndExtensionFromPath(fileData.name);
    link.download = `${name}-[cropped].pdf`;
    link.href = pdflink;
    link.click();
  }

  const downloadImage = async () => {
    const load = await pdfjs.getDocument(PDFUrl);
    const pdf = await load.promise;
    const page = await pdf.getPage(1);
    const [left, top, width, height] = page.view;
    const targetCanvas = document.createElement('canvas');
    const context = targetCanvas.getContext('2d');
    var viewport = page.getViewport({
      scale: 2,
    });

    targetCanvas.width = viewport.width;
    targetCanvas.height = viewport.height;
    targetCanvas.style.width = viewport.width + 'px';
    targetCanvas.style.height = viewport.height + 'px';

    // Render PDF page into canvas context
    const renderContext = {
      canvasContext: context,
      viewport,
    };

    const renderTask = await page.render(renderContext);
    await renderTask.promise;
    const dataUrl = targetCanvas.toDataURL('image/png', { quality: 1 });

    // const canvas = document.getElementById('canvas');
    // const dataUrl = canvas.toDataURL('image/jpeg');
    const link = document.createElement('a');
    link.download = 'image.png';
    link.href = dataUrl;
    link.click();
  };

  useEffect(() => {
    if (fileData) {
      fileToPDF(fileData);
    }
  }, [fileData]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper id="main-content">
          <Box p={2}>
            <Typography variant="body1" fontSize={18} paragraph>
              Cropper
            </Typography>
            <Typography color="secondary" my={2}>
              Using this tool you can crop any .pdf file to help reduce its
              size. It can be useful for floor plans that have a lot of
              unnecessary elements in them, enabling you to crop out just the
              useful part.
            </Typography>
            <Typography variant="body1" mb={2} fontSize={14} paragraph mt={2}>
              Instructions
            </Typography>
            <Typography color="secondary">
              1. Upload a .pdf file using the upload panel.
              <br />
              2. Draw a box on top of the image to select your area of interest.
              <br />
              3. Download the cropped file by clicking the{' '}
              <Typography component="span" color="primary">
                DOWNLOAD PDF
              </Typography>{' '}
              button.
              {/* 3. Choose whether to download as a new PDF or image file. */}
            </Typography>
          </Box>
          <FileUploaderCard
            height={'100px'}
            ref={fileUploaderRef}
            onChange={setFileData}
            onRemove={onClickedRemove}
            fileName={fileData?.name}
          />
        </Paper>
      </Grid>
      {base64img && (
        <Grid item xs={12}>
          <Paper>
            <Box>
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(crop, percentCrop) => setCompleteCrop(percentCrop)}
              >
                <img src={base64img} />
              </ReactCrop>
              <Box p={2}>
                <Stack direction="row" justifyContent="flex-end" spacing={2}>
                  {/* <Button
                    variant="contained"
                    color="secondary"
                    onClick={downloadImage}
                  >
                    Download image
                  </Button> */}
                  <Button variant="contained" onClick={downloadPDF}>
                    Download PDF
                  </Button>
                </Stack>
              </Box>
            </Box>
          </Paper>
        </Grid>
      )}
      <canvas id="canvas" hidden />
    </Grid>
  );
};

export default Cropper;
