import { getStatusColour, statusToString } from '../shared/utilities';

const StatusStringTableCell = ({ cell }) => {
  const status = cell.getValue();
  return (
    <div style={{ color: getStatusColour(status) }}>
      {statusToString(status)}
    </div>
  );
};

export default StatusStringTableCell;
