export const ALL_ROLES = {
  PUBLIC: '0',
  VIEWER: '1000',
  SURVEYOR: '2000',
  LEAD_SURVEYOR: '3000',
  ADMIN: '4000',
  ASSETICOM_ADMIN: '5000',
  ASSETICOM_OWNER: '6000',
} as const;

export const PORTAL_ROLES = {
  VIEWER: '1000',
  SURVEYOR: '2000',
  LEAD_SURVEYOR: '3000',
  ADMIN: '4000',
};

export const ASSETICOM_ROLES = {
  VIEWER: '1000',
  PORTAL_SURVEYOR: '2000',
  LEAD_SURVEYOR: '3000',
  ADMIN: '4000',
  ASSETICOM_ADMIN: '5000',
};

const getRolesArray = (roles) =>
  Object.keys(roles).map((role) => ({
    type: role,
    typePretty: role
      .toLowerCase()
      .replace(/_/g, ' ')
      .replace(/(^\w{1})|(\s{1}\w{1})/g, (match) => match.toUpperCase()),
    level: roles[role],
  }));

export const ROLES_ARRAY_ALL = getRolesArray(ALL_ROLES);
export const ROLES_ARRAY_PORTAL = getRolesArray(PORTAL_ROLES);
export const ROLES_ARRAY_ASSETICOM = getRolesArray(ASSETICOM_ROLES);

export const getUserRolePretty = (roleNumber) => {
  return ROLES_ARRAY_ALL.find((role) => role?.level === roleNumber)?.typePretty;
};
