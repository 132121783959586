import { Box, IconButton, Link } from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { colours, newColours } from '../config/theme';
import { makeStyles, useTheme } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';

import AppBar from '@mui/material/AppBar';
import DynamicIcon from './DynamicIcon';
import PropTypes from 'prop-types';
import { ROUTES_MAP } from '../config/routes';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import UserInfo from './UserInfo';
import { isViewingClientPortal } from '../shared/utilities';
import { setNavOpen } from '../state/ui';
import useMediaQuery from '@mui/material/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  hamburger: {
    marginLeft: -theme.spacing(1),
    marginRight: -theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(2),
      padding: theme.spacing(2),
    },
  },
  logo: {
    stroke: '#fff',
    height: '30px',
    marginRight: theme.spacing(1),
  },
  logoNew: {
    stroke: newColours.light2,
    height: '30px',
  },
  brandingName: {
    fontFamily: 'Open Sans',
    fontSize: 24,
    fontWeight: theme.typography.fontWeightLight,
    color: colours.white,
    userSelect: 'none',
  },
  companyName: {
    fontFamily: 'Roboto',
    fontSize: 24,
    fontWeight: theme.typography.fontWeightLight,
    userSelect: 'none',
  },
  separator: {
    userSelect: 'none',
  },
}));

const Branding = ({ clientId }) => {
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();
  const profile = useSelector((state) => state.firebase.profile);
  const toLink =
    clientId && !profile?.token?.claims?.roles?.asseticom
      ? `/${clientId}`
      : ROUTES_MAP.ROOT.path;
  const comp = location.pathname === toLink ? null : RouterLink;

  return (
    <Box
      component={comp}
      to={toLink}
      display="flex"
      alignItems="center"
      justifyContent="center"
      style={{ textDecoration: 'none' }}
    >
      <DynamicIcon
        name="new-logo"
        width="160px"
        className={classes.logoNew}
        colour="white"
      />
    </Box>
  );
};

Branding.defaultProps = {
  clientId: null,
};

Branding.propTypes = {
  clientId: PropTypes.string,
};

const TopToolbar = () => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const bigScreen = useMediaQuery(theme.breakpoints.up('md'));
  const client = useSelector((state) => state.firestore.data.client);
  const clientMode = isViewingClientPortal(client?.clientId);

  const openNav = () => {
    dispatch(setNavOpen({ open: true }));
  };

  return (
    <AppBar position="fixed" color="secondary" className={classes.appBar}>
      <Link
        variant="solid"
        tabIndex={0}
        sx={{
          clipPath: 'polygon(0 0, 0 0, 0 0, 0 0)',
          boxSizing: 'border-box',
          position: 'absolute',
          margin: 0,
          padding: 2,
          top: 16,
          left: 16,
          transform: 'translateY(-100%)',
          transition: 'transform 0.3s ease-in-out',
          '&:focus': {
            transform: 'translateY(0)',
            clipPath: 'none',
            height: 20,
            display: 'flex',
            alignItems: 'center',
            lineHeight: 1,
            background: 'white',
            borderRadius: '5px',
            fontSize: 16,
            textDecoration: 'none',
            color: 'red',
            textAlign: 'center',
            zIndex: 10000,
          },
        }}
        href="#main-content"
        color="secondary"
      >
        Skip to main content
      </Link>

      <Toolbar>
        <Box
          flex={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box display="flex" alignItems="center">
            {!bigScreen && (
              <IconButton
                className={classes.hamburger}
                onClick={openNav}
                size="large"
              >
                <DynamicIcon name="hamburger" colour="white" size="20px" />
              </IconButton>
            )}
            {bigScreen && (
              <Box display="flex" alignItems="center">
                <Branding clientId={client?.clientId} />
                {clientMode && (
                  <Box display="flex" alignItems="center">
                    <Box>
                      <Typography
                        sx={{
                          fontFamily: 'Roboto',
                          fontSize: 24,
                          fontWeight: theme.typography.fontWeightLight,
                          userSelect: 'none',
                          color: colours.white,
                          mx: 2,
                        }}
                      >
                        |
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        fontFamily: 'Roboto',
                        fontSize: 24,
                        fontWeight: theme.typography.fontWeightLight,
                        userSelect: 'none',
                      }}
                    >
                      {client.name}
                    </Typography>
                  </Box>
                )}
              </Box>
            )}
          </Box>
          {!bigScreen && <Branding clientId={client?.clientId} />}
          <UserInfo type="small" hideName={!bigScreen} />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopToolbar;
