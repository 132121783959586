import { Box, Chip, Divider, Typography } from '@mui/material';

interface StepGuideProps {
  stepNumber: number;
  text: string;
}

export default function StepGuide({ stepNumber, text }: StepGuideProps) {
  return (
    <Box my={2} display="flex" flexDirection="column" alignItems="center">
      <Box mb={2}>
        <Divider variant="middle">
          <Chip
            variant="outlined"
            label={`Step ${stepNumber}`}
            color="primary"
          />
        </Divider>
      </Box>
      <Typography color="secondary" variant="body1">
        {text}
      </Typography>
    </Box>
  );
}
