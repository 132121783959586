import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

import * as Sentry from '@sentry/react';

import { CLOUD_FUNCTION_LOCATION, ENVIRONMENT } from './constants';
import { Provider, useDispatch } from 'react-redux';
import {
  ReactReduxFirebaseProvider,
  firebaseReducer,
  getFirebase,
} from 'react-redux-firebase';
import {
  configureStore,
  createSerializableStateInvariantMiddleware,
} from '@reduxjs/toolkit';
import {
  createFirestoreInstance,
  firestoreReducer,
  getFirestore,
} from 'redux-firestore';
import { isViewingLocalServer, isViewingProdServer } from '../shared/utilities';

import PropTypes from 'prop-types';
import { combineReducers } from 'redux';
import firebase from 'firebase/app';
import reducers from '../state';

const CACHE_10_MEGABYTES_IN_BYTES = 10000000;

let firebaseConfig;

// default staging config
firebaseConfig = {
  apiKey: 'AIzaSyBm9hhiiseEGw6MVl8OpJXG3p9NwxzpECM',
  authDomain: 'staging.asseticom.co.uk',
  databaseURL: 'https://asseticom-uk-staging.firebaseio.com',
  projectId: 'asseticom-uk-staging',
  storageBucket: 'asseticom-uk-staging.appspot.com',
  messagingSenderId: '665554994145',
  appId: '1:665554994145:web:6105024c44a9f19e5748a0',
  measurementId: 'G-YKKGW6KXKM',
};

console.log('ENVIRONMENT', ENVIRONMENT);

// use live cofig based on URL
if (isViewingProdServer() || ENVIRONMENT === 'production') {
  firebaseConfig = {
    apiKey: 'AIzaSyCt70afhLarN2mlELt0qN0Dnk4YI8dk6YE',
    authDomain: 'portal.asseticom.co.uk',
    databaseURL: 'https://asseticom-uk.firebaseio.com',
    projectId: 'asseticom-uk',
    storageBucket: 'asseticom-uk.appspot.com',
    messagingSenderId: '116975784223',
    appId: '1:116975784223:web:df7519962d78ff744ca02a',
    measurementId: 'G-3V2R6MZZXJ',
  };
}

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  stateTransformer: (state) => {
    // Transform the state to remove large data sets
    const transformedState = {
      ...state,
      files: null,
      surveyForm: null,
      firebase: { profle: { ...state.firebase.profile, token: null } },
      firestore: Object.keys(state.firestore.data),
    };

    return transformedState;
  },
  actionTransformer: (action) => {
    return null;
  },
});

// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users', // where profiles are stored in database
  useFirestoreForProfile: true, // use Firestore for profile instead of RTDB
  enableClaims: true,
};

// Initialize firebase instance
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

// Initialize other services on firebase instance
export const firestore = firebase.firestore();
export const functions = firebase.app().functions(CLOUD_FUNCTION_LOCATION);

const settings = {
  experimentalAutoDetectLongPolling: true,
  cacheSizeBytes: CACHE_10_MEGABYTES_IN_BYTES,
  ignoreUndefinedProperties: true,
};
// console.log('discovered settings from local storage', settings);
firestore.settings(settings);

const createInitialAccount = async (email, role) => {
  const account = await functions.httpsCallable('createUserAccount')({
    email,
    clientId: 'asseticom',
    role,
  });
  if (account.data.error) {
    console.log(`Add initial user result: ${account.data.error}`);
  } else {
    console.log(`Add initial user result: ${JSON.stringify(account.data)}`);
  }
};

if (isViewingLocalServer() && ENVIRONMENT === 'dev') {
  console.log('using emulators...');
  firebase.auth().useEmulator('http://localhost:9099/');
  functions.useEmulator('localhost', 5001);
  firebase.firestore().useEmulator('localhost', 8080);
} else {
  // firebase.firestore().enablePersistence();
}

// Add firebase to reducers
const rootReducer = combineReducers({
  ...reducers,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

const extraArgument = {
  getFirebase,
  getFirestore,
};

const serializableMiddleware = createSerializableStateInvariantMiddleware({
  ignoredPaths: ['firebase.profile', 'firestore.errors'],
  ignoredActionPaths: ['profile.token', 'auth'],
});

export const store = configureStore({
  devTools:
    ENVIRONMENT === 'dev'
      ? {
          serialize: {
            //@ts-ignore
            replacer: (key, value) => {
              if (key === 'taxonomy') {
                return {
                  size: value.data.length,
                  data: [],
                  id: value.id,
                };
              }
              return value;
            },
          },
        }
      : false,
  reducer: rootReducer,
  //@ts-ignore
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      thunk: {
        extraArgument,
      },
      immutableCheck: true,
    }).concat(serializableMiddleware),
  enhancers: [sentryReduxEnhancer],
});

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

const Store = ({ children }) => {
  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        {children}
      </ReactReduxFirebaseProvider>
    </Provider>
  );
};

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch; //

Store.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Store;
