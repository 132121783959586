import 'leaflet/dist/leaflet.css';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { addNewTestSite, onNewSiteSubmitted } from '../../../../state/site';
import {
  parseXLSX,
  readURLAsBinary,
  stringToHash,
} from '../../../../shared/utilities';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { BigBatch } from '@qualdesk/firestore-big-batch';
import DebugOnly from '../../../../components/DebugOnly';
import FileUploaderCard from '../../../../components/FileUploaderCard';
import { HashLink } from 'react-router-hash-link';
import SiteForm from '../components/SiteForm';
import firebase from 'firebase/app';
import { getLastUpdated } from '../../../../shared/logTools';
import { useState } from 'react';

const Add = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [newSiteId, setNewSiteId] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const client = useSelector((state) => state.firestore.data.client);

  const showDialog = (id) => {
    setNewSiteId(id);
    setDialogOpen(true);
  };

  const onAgreeGoToSite = () => {
    history.push(location.pathname.replace('add', newSiteId));
  };

  const onDeclineGoToSite = () => {
    history.push(`/${client.clientId}`);
  };

  const onSiteSubmit = async (site) => {
    const result = await dispatch(onNewSiteSubmitted({ site }));
    showDialog(result.id);
  };

  const addTestSiteData = async () => {
    const result = await dispatch(addNewTestSite());
    showDialog(result.id);
  };

  const setFileData = async (file, id) => {
    const postcodesNotFound = [];

    const { lastModified, name } = file;

    const fileUrl = window.URL.createObjectURL(file);

    const data = await readURLAsBinary(file);

    const siteAndBuildingListParsed = await parseXLSX(data, [
      'name',
      'address1',
      'address2',
      'townCity',
      'county',
      'region',
      'postCode',
      'buildingName',
      'buildingAddress1',
      'buildingAddress2',
      'buildingTownCity',
      'buildingCounty',
      'buildingRegion',
      'buildingPostCode',
    ]);

    const firestore = firebase.firestore();
    const batch = new BigBatch({ firestore });
    const clientId = client.clientId;

    const clientDocRef = await firestore
      .collection('clients')
      .doc(clientId)
      .get();

    const sitesRef = clientDocRef.ref.collection('sites');

    let existingSiteDoc;
    let existingSiteData;

    for (const row of siteAndBuildingListParsed.rows) {
      if (row.name) {
        const siteId = stringToHash(JSON.stringify(row));

        existingSiteDoc = sitesRef.doc(siteId);

        const response = await fetch(
          `https://api.postcodes.io/postcodes/${row.postCode}`,
        );

        let responseJson = await response.json();

        if (responseJson.error) {
          responseJson.result = { latitude: 0, longitude: 0 };
          console.warn(
            'post code not found',
            row.postCode,
            'for site',
            row.name,
          );
          postcodesNotFound.push({ name: row.name, postCode: row.postCode });
        }

        existingSiteData = {
          id: siteId,
          created: Date.now(),
          deleted: 0,
          clientId,
          clientName: clientDocRef.data().name,
          buildingsNumCreated: 0,
          buildingsNumDeleted: 0,
          surveysNumCreated: 0,
          surveysNumDeleted: 0,
          surveysNumCompleted: 0,
          surveysNumInProgress: 0,
          assetsNumCreated: 0,
          assetsNumDeleted: 0,
          partialsNumCreated: 0,
          partialsNumDeleted: 0,
          assetsNumFlagged: 0,
          name: row.name,
          address1: row.address1,
          address2: row.address2,
          townCity: row.townCity,
          county: row.county,
          region: row.region,
          postCode: row.postCode,
          lat: responseJson.result.latitude,
          lng: responseJson.result.longitude,
          ...getLastUpdated(),
        };

        batch.set(existingSiteDoc, existingSiteData, { merge: true });
      } else {
        const buildingId = stringToHash(JSON.stringify(row));

        const buildingDoc = existingSiteDoc
          .collection('buildings')
          .doc(buildingId);

        let buildingObj = {
          id: buildingId,
          created: Date.now(),
          deleted: 0,
          clientId,
          clientName: clientDocRef.data().name,
          name: row.buildingName,
          siteName: existingSiteData.name,
          siteId: existingSiteData.id,
          ...getLastUpdated(),
        };

        if (row.buildingPostCode) {
          const response = await fetch(
            `https://api.postcodes.io/postcodes/${row.buildingPostCode}`,
          );
          const responseJson = await response.json();

          buildingObj = {
            ...buildingObj,
            address1: row.buildingAddress1,
            address2: row.buildingAddress2,
            townCity: row.buildingTownCity,
            county: row.buildingCounty,
            region: row.buildingRegion,
            postCode: row.buildingPostCode,
            lat: responseJson.result.latitude,
            lng: responseJson.result.longitude,
          };
        }
        batch.set(buildingDoc, buildingObj, { merge: true });
      }
    }

    batch.commit();
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <DebugOnly>
          <Box mb={2}>
            <FileUploaderCard
              title="Bulk Upload"
              onChange={(file) => setFileData(file, 'siteAndBuildingList')}
            />
          </Box>
          <Button
            variant="outlined"
            color="secondary"
            onClick={addTestSiteData}
          >
            Add Test Site Data
          </Button>
        </DebugOnly>
        <SiteForm onSiteSubmit={onSiteSubmit} />
      </Grid>
      <Dialog
        open={dialogOpen}
        onClose={onDeclineGoToSite}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">New Site Created</DialogTitle>
        <DialogContent>
          <Typography>
            Before you can add any surveys for this site you need to add at
            least one building to it.
          </Typography>
          <br />
          <Typography>Would you like to do this now?</Typography>
        </DialogContent>
        {client && (
          <DialogActions>
            <Button onClick={onDeclineGoToSite} color="primary">
              No
            </Button>
            <Button
              onClick={onAgreeGoToSite}
              color="primary"
              autoFocus
              component={HashLink}
              to={`/${client.clientId}/sites/${newSiteId}#buildings`}
            >
              Yes
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </Grid>
  );
};

export default Add;
