import { Box, Typography } from '@mui/material';

type ContextAttributeProps = {
  type: string;
  noPadding?: boolean;
  value: number | string;
};

const ContextAttribute = ({
  type,
  value,
  noPadding,
}: ContextAttributeProps) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      flexDirection="column"
      py={noPadding ? 0 : 1}
      px={noPadding ? 0 : 2}
    >
      <Typography
        color="secondary"
        align="center"
        sx={{ fontSize: 14, fontWeight: 500 }}
      >
        {type}
      </Typography>
      <Typography
        color="secondary"
        align="center"
        sx={{ fontSize: 18, fontWeight: 200 }}
      >
        {value}
      </Typography>
    </Box>
  );
};

export default ContextAttribute;
