import 'react-color-palette/css';

import {
  Box,
  Button,
  Chip,
  Divider,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useForm, useWatch } from 'react-hook-form';

import ErrorText from '../../../../../../components/Form/ErrorText';
import FileUploaderCard from '../../../../../../components/FileUploaderCard';
import { FormStep } from '../types';
import { readURLAsData } from '../../../../../../shared/utilities';
import useHookFormErrorHandler from '../../../../../../hooks/useFormErrorHandler';

export type FormData = {
  address1: string;
  address2: string;
  townCity: string;
  county: string;
  postCode: string;
  preparedByName: string;
  preparedByEmail: string;
  preparedByPhone: string;
  preparedBySignatureImage: string;
  approvedByName: string;
  approvedByEmail: string;
  approvedByPhone: string;
  approvedBySignatureImage: string;
};

interface InsidePageStep {
  defaultValues: Partial<FormData>;
  handleBack: (data: Partial<FormData>) => void;
  handleNext: (data: FormData) => void;
}

export default function InsidePageStep({
  defaultValues,
  handleBack,
  handleNext,
}) {
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    register,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({ defaultValues });

  useHookFormErrorHandler(errors, isSubmitting);

  const preparedBySignatureImage = useWatch({
    control,
    name: 'preparedBySignatureImage',
  });
  const approvedBySignatureImage = useWatch({
    control,
    name: 'approvedBySignatureImage',
  });

  const setPreparedBySignatureImage = async (file) => {
    const fileData = await readURLAsData(file);
    if (fileData) {
      register('preparedBySignatureImage', { required: false });
      setValue('preparedBySignatureImage', fileData);
    }
  };

  const setApprovedBySignatureImage = async (file) => {
    const fileData = await readURLAsData(file);
    if (fileData) {
      register('approvedBySignatureImage', { required: false });
      setValue('approvedBySignatureImage', fileData);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleNext)}>
      <Box py={2}>
        <Stack spacing={2} alignItems="center">
          <Typography variant="h5">Inside page settings</Typography>
          <Divider flexItem />
          <Chip variant="outlined" label="Address" color="primary" />

          <Stack
            sx={{ width: '100%', maxWidth: 'md', flexShrink: 1 }}
            spacing={2}
          >
            <Controller
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  autoComplete="off"
                  label="Address Line 1"
                  variant="outlined"
                  error={!!errors.address1}
                />
              )}
              control={control}
              name="address1"
              rules={{
                required: true,
              }}
            />
            {errors.address1 && <ErrorText />}
            <Controller
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  autoComplete="off"
                  label="Address Line 2"
                  variant="outlined"
                  error={!!errors.address2}
                />
              )}
              control={control}
              name="address2"
              rules={{
                required: false,
              }}
            />
            {errors.address2 && <ErrorText />}
            <Controller
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  autoComplete="off"
                  label="Town/City"
                  variant="outlined"
                  error={!!errors.townCity}
                />
              )}
              control={control}
              name="townCity"
              rules={{
                required: true,
              }}
            />
            {errors.townCity && <ErrorText />}
            <Controller
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  autoComplete="off"
                  label="County"
                  variant="outlined"
                  error={!!errors.county}
                />
              )}
              control={control}
              name="county"
              rules={{
                required: false,
              }}
            />
            {errors.county && <ErrorText />}

            <Controller
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  autoComplete="off"
                  label="Post Code"
                  variant="outlined"
                  error={!!errors.postCode}
                />
              )}
              control={control}
              name="postCode"
              rules={{
                required: false,
              }}
            />
            {errors.postCode && <ErrorText />}
          </Stack>

          <Divider flexItem />

          <Box
            sx={{
              p: 2,
              width: '100%',
              maxWidth: 'md',
              border: '1px solid lightgrey',
              borderRadius: 1,
            }}
          >
            <Stack direction="row" justifyContent="center">
              <Chip variant="outlined" label="Prepared By" color="primary" />
            </Stack>
            {/* <Typography variant="subtitle2">Prepared By:</Typography> */}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              flexWrap={'wrap'}
            >
              <Stack sx={{ width: '100%', maxWidth: 'md', mt: 2 }} spacing={1}>
                <Controller
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      autoComplete="off"
                      label="Name"
                      variant="outlined"
                      error={!!errors.preparedByName}
                    />
                  )}
                  control={control}
                  name="preparedByName"
                  rules={{
                    required: true,
                  }}
                />
                {errors.preparedByName && <ErrorText />}
                <Controller
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      autoComplete="off"
                      label="Email"
                      variant="outlined"
                      error={!!errors.preparedByEmail}
                    />
                  )}
                  control={control}
                  name="preparedByEmail"
                  rules={{
                    required: false,
                  }}
                />

                <Controller
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      autoComplete="off"
                      label="Phone"
                      variant="outlined"
                      error={!!errors.preparedByPhone}
                    />
                  )}
                  control={control}
                  name="preparedByPhone"
                  rules={{
                    required: false,
                  }}
                />
              </Stack>
              <Box m={1} width={300} mt={2}>
                <FileUploaderCard
                  title="Signature"
                  onChange={setPreparedBySignatureImage}
                  fileUrl={preparedBySignatureImage}
                />
              </Box>
            </Stack>
          </Box>

          <Divider flexItem />
          <Box
            sx={{
              p: 2,
              width: '100%',
              maxWidth: 'md',
              border: '1px solid lightgrey',
              borderRadius: 1,
            }}
          >
            <Stack direction="row" justifyContent="center">
              <Chip variant="outlined" label="Approved By" color="primary" />
            </Stack>
            {/* <Typography variant="subtitle2">Approved By:</Typography> */}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              flexWrap={'wrap'}
            >
              <Stack sx={{ width: '100%', maxWidth: 'md', mt: 2 }} spacing={1}>
                <Controller
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      autoComplete="off"
                      label="Name"
                      variant="outlined"
                      error={!!errors.approvedByName}
                    />
                  )}
                  control={control}
                  name="approvedByName"
                  rules={{
                    required: true,
                  }}
                />
                {errors.approvedByName && <ErrorText />}
                <Controller
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      autoComplete="off"
                      label="Email"
                      variant="outlined"
                      error={!!errors.approvedByEmail}
                    />
                  )}
                  control={control}
                  name="approvedByEmail"
                  rules={{
                    required: false,
                  }}
                />
                <Controller
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      autoComplete="off"
                      label="Phone"
                      variant="outlined"
                      error={!!errors.approvedByPhone}
                    />
                  )}
                  control={control}
                  name="approvedByPhone"
                  rules={{
                    required: false,
                  }}
                />
              </Stack>
              <Box m={1} width={300} mt={2}>
                <FileUploaderCard
                  title="Signature"
                  onChange={setApprovedBySignatureImage}
                  fileUrl={approvedBySignatureImage}
                />
              </Box>
            </Stack>
          </Box>
        </Stack>
        <Stack
          direction={'row'}
          spacing={2}
          justifyContent="space-between"
          mt={2}
        >
          <Button variant="outlined" onClick={() => handleBack(getValues())}>
            Back
          </Button>
          <Button variant="contained" type="submit">
            Next
          </Button>
        </Stack>
      </Box>
    </form>
  );
}
