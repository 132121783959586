import { Grid } from '@mui/material';
import { FormFieldRowElementProps } from '../../../../../../types/form';
import { NUMBER_REGEX, NUMBER_VALIDATION_MESSAGE } from '../constants';
import FormFieldDefaultTextInput from './FormFieldDefaultTextInput';
import FormFieldMinMax from './FormFieldMinMax';

export default function FormFieldRowNumber({
  formField,
  index,
}: FormFieldRowElementProps) {
  return (
    <Grid container spacing={1} mt={1}>
      <Grid item xs={4}>
        <FormFieldDefaultTextInput
          value={formField.default}
          identifier="default"
          index={index}
          pattern={{ value: NUMBER_REGEX, message: NUMBER_VALIDATION_MESSAGE }}
        />
      </Grid>
      <Grid item xs={4}>
        <FormFieldMinMax
          identifier="min"
          value={formField.min}
          index={index}
          label="Minimum Value"
        />
      </Grid>
      <Grid item xs={4}>
        <FormFieldMinMax
          identifier="max"
          value={formField.max}
          index={index}
          label="Maximum Value"
        />
      </Grid>
    </Grid>
  );
}
