import { Address, SiteProps } from '../../../../../../types/site';
import { assign, emit, fromPromise, setup } from 'xstate';
import {
  getAssetData,
  getBuildingsForSite,
  getSitesForClient,
  getSurveysForBuilding,
} from './data-access';

import { AssetDataRow } from '../types';
import { BuildingProps } from '../../../../../../types/building';
import { SurveyMeta } from '../../../../../../types/survey';
import { createActorContext } from '@xstate/react';

interface CacheItem<R> {
  data: R;
  timestamp: number;
}

const dataCache = new Map<string, CacheItem<any>>();
const CACHE_EXPIRY_MS = 5 * 60 * 1000; // 5 minutes in milliseconds

function withCache<T extends object, R>(fn: (input: T) => Promise<R>) {
  return async (input: T): Promise<R> => {
    const cacheKey = JSON.stringify(input);
    const cachedItem = dataCache.get(cacheKey);

    const now = Date.now();
    if (cachedItem && now - cachedItem.timestamp < CACHE_EXPIRY_MS) {
      return cachedItem.data;
    }

    const data = await fn(input);
    dataCache.set(cacheKey, { data, timestamp: now });
    return data;
  };
}

const getSitesActor = fromPromise(withCache(getSitesForClient));
const getBuildingsActor = fromPromise(withCache(getBuildingsForSite));
const getSurveysActor = fromPromise(withCache(getSurveysForBuilding));
const getAssetDataActor = fromPromise(withCache(getAssetData));

function getAddressFromSiteOrBuilding(
  siteOrBuilding: SiteProps | BuildingProps,
) {
  return {
    address1: siteOrBuilding.address1.trim() ?? '',
    address2: siteOrBuilding.address2?.trim() ?? '',
    townCity: siteOrBuilding.townCity.trim() ?? '',
    county: siteOrBuilding.county.trim() ?? '',
    countryCode: siteOrBuilding.countryCode.trim() ?? '',
    region: siteOrBuilding.region?.trim() ?? '',
    postCode: siteOrBuilding.postCode?.trim() ?? '',
  };
}

export type FiltersMachineData = {
  clientId: string;
  siteId: string;
  siteName: string;
  buildingId: string;
  buildingName: string;
  surveyId: string;
  surveyName: string;
  exportInProgressSurveysOnly: boolean;
  sites: SiteProps[];
  buildings: BuildingProps[];
  surveys: SurveyMeta[];
  assetData: AssetDataRow[];
  surveyIds: string[];
  assetListIds: string[];
  address?: Address;
};

export const filtersMachine = setup({
  types: {} as {
    emitted: { type: 'ASSET_DATA_RECEIVED' };
    context: FiltersMachineData;
    input: {
      clientId: string;
      siteId?: string | undefined;
      siteName?: string | undefined;
      buildingId?: string | undefined;
      buildingName?: string | undefined;
      surveyId?: string | undefined;
      surveyName?: string | undefined;
      sites?: SiteProps[] | undefined;
      buildings?: BuildingProps[] | undefined;
      surveys?: SurveyMeta[] | undefined;
      exportInProgressSurveysOnly?: boolean | undefined;
    };
    events:
      | {
          type: 'SITE_ID_SELECTED';
          siteId: string;
        }
      | {
          type: 'BUILDING_ID_SELECTED';
          buildingId: string;
        }
      | {
          type: 'SURVEY_ID_SELECTED';
          surveyId: string;
        }
      | {
          type: 'GET_ASSET_DATA';
        }
      | {
          type: 'TOGGLE_IN_PROGRESS_SURVEYS_ONLY';
          toggle: boolean;
        };
  },
  actors: {
    getSitesActor,
    getBuildingsActor,
    getSurveysActor,
    getAssetDataActor,
  },
  guards: {
    hasSelectedBuilding: ({ context }) => {
      return !!context.buildingId;
    },
  },
}).createMachine({
  /** @xstate-layout N4IgpgJg5mDOIC5QAcAWB7ALugygY1UgFcAbMAWQEMCBLAOzAGIBxAeQBVWBtABgF1EKdLBqYa6OoJAAPRACYAnADoAjDxUBWAGxyNAGhABPRCpUBmAL4WDaLLgLEyVWgxYduKgUhDJho8ZLesggqcqoA7ApaABzh+kaIGspaACwpGmahGlY2GNj4hBCkFNSo9ExsnFxyXkIiYhJSwaEGxiFyVtYgdOgQcFK2+Q5FTqXlA34NgaDBALRmWq2Is3FK2V2D9oXFzmUMSgAKAE5gAG40YADuE-UBTYjhPKpmctFZSwhyZtGdFkA */
  id: 'filtersMachine',
  initial: 'Selecting',

  context: ({ input }) => {
    return {
      clientId: input.clientId ?? '',
      siteId: input.siteId ?? '',
      siteName: input.siteName ?? '',
      buildingId: input.buildingId ?? '',
      buildingName: input.buildingName ?? '',
      surveyId: input.surveyId ?? '',
      surveyName: input.surveyName ?? '',
      sites: input.sites ?? [],
      buildings: input.buildings ?? [],
      surveys: input.surveys ?? [],
      exportInProgressSurveysOnly: input.exportInProgressSurveysOnly ?? false,
      assetData: [],
      surveyIds: [],
      assetListIds: [],
    };
  },
  on: {
    SITE_ID_SELECTED: {
      target: '.SelectedSite',
      actions: assign({
        siteId: ({ event }) => event.siteId,
        siteName: ({ event, context }) => {
          const selectedSite = context.sites.find((s) => s.id === event.siteId);
          return selectedSite?.name.trim() ?? '';
        },
        buildingId: '',
        buildings: [],
        surveyId: '',
        surveys: [],
        address: ({ event, context }) => {
          const selectedSite = context.sites.find((s) => s.id === event.siteId);
          return selectedSite && getAddressFromSiteOrBuilding(selectedSite);
        },
      }),
    },
    BUILDING_ID_SELECTED: {
      target: '.SelectedBuilding',
      actions: assign({
        buildingId: ({ event }) => event.buildingId,
        buildingName: ({ event, context }) => {
          const selectedBuilding = context.buildings.find(
            (s) => s.id === event.buildingId,
          );

          return selectedBuilding?.name.trim() ?? '';
        },
        surveyId: '',
        surveys: [],
        address: ({ event, context }) => {
          const selectedBuilding = context.buildings.find(
            (s) => s.id === event.buildingId,
          );
          if (selectedBuilding?.address1) {
            return getAddressFromSiteOrBuilding(selectedBuilding);
          }
          const selectedSite = context.sites.find(
            (s) => s.id === context.siteId,
          );
          return selectedSite && getAddressFromSiteOrBuilding(selectedSite);
        },
      }),
    },
    SURVEY_ID_SELECTED: {
      target: '.Selecting',
      actions: assign({
        surveyIds: ({ event }) => [event.surveyId],
        surveyId: ({ event }) => event.surveyId,
        surveyName: ({ event, context }) => {
          const selectedSurvey = context.surveys.find(
            (s) => s.id === event.surveyId,
          );

          return selectedSurvey?.name.trim() ?? '';
        },
        surveys: ({ event, context }) => {
          const selectedSurvey = context.surveys.find(
            (s) => s.id === event.surveyId,
          );

          return selectedSurvey ? [selectedSurvey] : [];
        },
      }),
    },
    GET_ASSET_DATA: {
      target: '.FetchingAssetData',
    },
    TOGGLE_IN_PROGRESS_SURVEYS_ONLY: [
      {
        actions: assign({
          exportInProgressSurveysOnly: ({ event }) => event.toggle,
        }),
        guard: 'hasSelectedBuilding',
        target: '.SelectedBuilding',
      },
      {
        actions: assign({
          exportInProgressSurveysOnly: ({ event }) => event.toggle,
        }),
      },
    ],
  },
  states: {
    Selecting: {
      invoke: {
        src: 'getSitesActor',
        input: ({ context }) => ({
          clientId: context.clientId,
        }),
        onDone: {
          actions: assign({
            sites: ({ event }) =>
              event.output.length === 0 ? [] : event.output,
          }),
        },
        onError: {
          target: 'Error',
        },
      },
    },
    SelectedSite: {
      entry: assign({
        buildings: [],
        surveys: [],
      }),
      invoke: {
        src: 'getBuildingsActor',
        input: ({ context }) => ({
          clientId: context.clientId,
          siteId: context.siteId,
        }),
        onDone: {
          target: 'Selecting',
          actions: assign({
            buildings: ({ event }) =>
              event.output.length === 0 ? [] : event.output,
          }),
        },
        onError: {
          target: 'Error',
        },
      },
    },
    SelectedBuilding: {
      entry: assign({
        surveys: [],
      }),
      invoke: {
        src: 'getSurveysActor',
        input: ({ context }) => ({
          clientId: context.clientId,
          buildingId: context.buildingId,
          exportInProgressOnly: context.exportInProgressSurveysOnly,
        }),
        onDone: {
          target: 'Selecting',
          actions: assign({
            surveys: ({ event }) =>
              event.output.length === 0 ? [] : event.output,
          }),
        },
        onError: {
          target: 'Error',
        },
      },
    },
    FetchingAssetData: {
      initial: 'Fetching',
      states: {
        Fetching: {
          invoke: {
            src: 'getAssetDataActor',
            input: ({ context }) => ({
              clientId: context.clientId,
              siteId: context.siteId,
              buildingId: context.buildingId,
              surveyId: context.surveyId,
              exportInProgressSurveysOnly: context.exportInProgressSurveysOnly,
            }),
            onDone: {
              target: '#filtersMachine.Selecting',
              actions: [
                assign({
                  assetData: ({ event }) =>
                    event.output.rows.length === 0 ? [] : event.output.rows,
                  surveyIds: ({ event }) => event.output.surveyIds,
                  assetListIds: ({ event }) => event.output.assetListIds,
                }),
                emit({ type: 'ASSET_DATA_RECEIVED' }),
              ],
            },
            onError: { target: 'EmptyResult' },
          },
        },
        EmptyResult: {
          description: 'Put a minimum timer of 500 MS to make UI less glitchy',
          after: {
            500: '#filtersMachine.NoAssetDataFound',
          },
        },
      },
    },
    NoAssetDataFound: {
      entry: assign({
        assetData: [],
      }),
    },
    Error: {},
  },
});

export const FiltersMachineContext = createActorContext(filtersMachine);
