import { Box, Typography } from '@mui/material';

import { colours } from '../../../../../../config/theme';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  cardTitle: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${colours.grey5}`,
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
  },
  cardTitleText: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

type CardTitleProps = {
  text: string;
  action?: React.ReactNode;
};

const CardTitle = ({ text, action }: CardTitleProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.cardTitle}>
      <Typography
        variant="subtitle2"
        className={classes.cardTitleText}
        component="span"
      >
        {text}
      </Typography>
      {action || null}
    </Box>
  );
};

export default CardTitle;
