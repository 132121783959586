import { isEmpty, isLoaded } from 'react-redux-firebase';
import { useEffect, useMemo, useState } from 'react';

import { isViewingClientPortal } from '../shared/utilities';
import { useSelector } from 'react-redux';

const authIsValid = (auth, roles, clientId) => {
  const roleIsAllowed = roles[clientId] && auth <= roles[clientId];
  return !!(roles?.asseticom || roleIsAllowed);
};

export default function useAuthChecker(auth, label) {
  const [isChecking, setIsChecking] = useState(true);
  const [isAuthed, setIsAuthed] = useState(false);
  const roles = useSelector(
    (state) => state.firebase.profile.token.claims.roles,
  );
  const ids = useSelector((state) => state.params.ids);

  useEffect(() => {
    if (auth && !isChecking) {
      const valid = authIsValid(auth, roles, ids.clientId);
      return setIsAuthed(valid);
    }
    return setIsAuthed(true);
  }, [auth, roles, ids, isChecking]);

  useEffect(() => {
    const idsNotPopulated = isViewingClientPortal() && isEmpty(ids);
    const rolesNotLoaded = !isLoaded(roles);
    setIsChecking(idsNotPopulated || rolesNotLoaded);
  }, [roles, ids]);

  const noRoles = useMemo(() => {
    return isLoaded(roles) && isEmpty(roles);
  });

  const checkAuth = (toCheck) => {
    return !toCheck || authIsValid(toCheck, roles, ids.clientId);
  };

  return { isAuthed, isChecking, checkAuth, noRoles };
}
