import 'leaflet/dist/leaflet.css';

import {
  Autocomplete,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { addressPropTypes, buildingPropTypes } from '../../shared/propTypes';
import { useEffect, useMemo } from 'react';

import COUNTRY_LIST from './country-codes.json';
import { EMPTY_OBJECT } from '../../shared/helpers';
import LocationMap from '../LocationMap';
import PropTypes from 'prop-types';
import { capitaliseString } from '../../shared/utilities';
import { visuallyHidden } from '@mui/utils';

const defaultValues = {
  name: '',
  address1: '',
  address2: '',
  townCity: '',
  county: '',
  region: '',
  countryCode: 'GB',
  postCode: '',
};

const LAT_FORM_LABEL = 'lat';
const LNG_FORM_LABEL = 'lng';

const AddressForm = ({ formData, showAddress, onSubmit, type, buttons }) => {
  const {
    handleSubmit,
    reset,
    control,
    watch,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const countryCodeWatcher = watch('countryCode');
  const postCodeWatcher = watch('postCode');

  const countryDefault = useMemo(() => {
    if (countryCodeWatcher) {
      return COUNTRY_LIST.find(
        (country) => country.countryCode === countryCodeWatcher,
      );
    }
    return COUNTRY_LIST.find((country) => country.countryCode === 'GB');
  }, [countryCodeWatcher]);

  useEffect(() => {
    reset({ ...defaultValues, ...formData });
  }, [formData, reset]);

  const onLocationResult = (locationData) => {
    if (locationData.latitude) {
      register(LAT_FORM_LABEL, { required: false });
      setValue(LAT_FORM_LABEL, locationData.latitude);
    }
    if (locationData.longitude) {
      register(LNG_FORM_LABEL, { required: false });
      setValue(LNG_FORM_LABEL, locationData.longitude);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      {type && (
        <Controller
          control={control}
          name="name"
          rules={{
            required: true,
          }}
          render={({ field }) => (
            <TextField
              value={field.value}
              autoComplete="off"
              inputRef={field.ref}
              inputProps={{
                autocomplete: 'off',
              }}
              onChange={field.onChange}
              onBlur={(e) => {
                field.onChange(capitaliseString(e.target.value));
              }}
              error={!!errors.name}
              helperText={!!errors.name && 'Site Name is required'}
              fullWidth
              margin="normal"
              variant="outlined"
              label={`${type} Name`}
            />
          )}
        />
      )}

      {showAddress && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name="address1"
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <TextField
                    value={field.value}
                    inputRef={field.ref}
                    autoComplete="off"
                    inputProps={{
                      autocomplete: 'no',
                    }}
                    margin="normal"
                    onChange={field.onChange}
                    onBlur={(e) => {
                      field.onChange(capitaliseString(e.target.value));
                    }}
                    error={!!errors.address1}
                    helperText={
                      !!errors.address1 && 'Street Address 1 is required'
                    }
                    label="Street Address 1"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                rules={{
                  required: false,
                }}
                name="address2"
                render={({ field }) => (
                  <TextField
                    value={field.value}
                    inputRef={field.ref}
                    margin="normal"
                    autoComplete="off"
                    inputProps={{
                      autocomplete: 'no',
                    }}
                    onChange={field.onChange}
                    onBlur={(e) => {
                      field.onChange(capitaliseString(e.target.value));
                    }}
                    label="Street Address 2"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                name="townCity"
                render={({ field }) => (
                  <TextField
                    value={field.value}
                    inputRef={field.ref}
                    fullWidth
                    margin="normal"
                    autoComplete="off"
                    inputProps={{
                      autocomplete: 'no',
                    }}
                    onChange={field.onChange}
                    onBlur={(e) => {
                      field.onChange(capitaliseString(e.target.value));
                    }}
                    error={!!errors.townCity}
                    helperText={!!errors.townCity && 'Town/City is required'}
                    label="Town/City"
                    variant="outlined"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                name="county"
                render={({ field }) => (
                  <TextField
                    value={field.value}
                    inputRef={field.ref}
                    fullWidth
                    margin="normal"
                    autoComplete="off"
                    inputProps={{
                      autocomplete: 'no',
                    }}
                    onChange={field.onChange}
                    onBlur={(e) => {
                      field.onChange(capitaliseString(e.target.value));
                    }}
                    error={!!errors.county}
                    helperText={!!errors.county && 'County is required'}
                    label="County"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                name="region"
                render={({ field }) => (
                  <TextField
                    value={field.value}
                    inputRef={field.ref}
                    fullWidth
                    margin="normal"
                    autoComplete="off"
                    inputProps={{
                      autocomplete: 'no',
                    }}
                    onChange={field.onChange}
                    onBlur={(e) => {
                      field.onChange(capitaliseString(e.target.value));
                    }}
                    error={!!errors.region}
                    helperText={!!errors.region && 'Region is required'}
                    label="Region"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                variant="outlined"
                fullWidth
                error={!!errors.countryCode}
                sx={{ marginTop: 2 }}
              >
                <Controller
                  control={control}
                  name="countryCode"
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => {
                    return (
                      <Autocomplete
                        onChange={(e, data) => {
                          field.onChange(data?.countryCode);
                        }}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.countryCode}>
                              {option.name}
                            </li>
                          );
                        }}
                        value={countryDefault}
                        options={COUNTRY_LIST}
                        getOptionLabel={(entry) => {
                          return entry.name;
                        }}
                        isOptionEqualToValue={(option, value) => {
                          return option.countryCode === value.countryCode;
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Country"
                            variant="outlined"
                            error={!!errors.countryCode}
                            helperText={
                              !!errors.countryCode && 'Country is required'
                            }
                            inputRef={field.ref}
                          />
                        )}
                      />
                    );
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography sx={visuallyHidden} id="post-code-description">
                Adding a valid post code will show its location on a map below
                this input
              </Typography>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                name="postCode"
                render={({ field }) => (
                  <TextField
                    value={field.value}
                    inputRef={field.ref}
                    fullWidth
                    autoComplete="off"
                    inputProps={{
                      autocomplete: 'no',
                      'aria-describedby': 'post-code-description',
                    }}
                    onChange={field.onChange}
                    onBlur={(e) => {
                      field.onChange(capitaliseString(e.target.value));
                    }}
                    error={!!errors.postCode}
                    helperText={!!errors.postCode && 'Post code is required'}
                    label="Post Code"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          </Grid>
          <LocationMap
            postCode={postCodeWatcher}
            countryCode={countryCodeWatcher}
            onLocationResult={onLocationResult}
          />
        </>
      )}
      {buttons}
    </form>
  );
};

AddressForm.defaultProps = {
  showAddress: true,
  formData: EMPTY_OBJECT,
  type: '',
};

AddressForm.propTypes = {
  type: PropTypes.string,
  showAddress: PropTypes.bool,
  formData: PropTypes.oneOfType([
    PropTypes.shape(addressPropTypes),
    PropTypes.shape(buildingPropTypes),
  ]),
  onSubmit: PropTypes.func.isRequired,
  buttons: PropTypes.node.isRequired,
};

export default AddressForm;
