import { Controller, useFormContext } from 'react-hook-form';
import {
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
} from '@mui/material';

import ErrorText from '../../../../../../components/Form/ErrorText';
import { FORM_FIELDS_NAME } from '../../../../../../config/constants';
import { FormFieldType } from '../../../../../../types/form';
import { fieldTypes } from '../constants';
import useFormError from '../hooks/useFormError';

type FormFieldTypeSelectorProps = {
  index: number;
  type: FormFieldType;
};

export default function FormFieldTypeSelector({
  index,
  type,
}: FormFieldTypeSelectorProps) {
  const { control } = useFormContext();
  const error = useFormError(index, 'type');

  return (
    <>
      <Controller
        rules={{
          required: {
            value: true,
            message: 'You must select a field type',
          },
        }}
        name={`${FORM_FIELDS_NAME}.${index}.type`}
        control={control}
        render={({ field }) => {
          return (
            <FormControl variant="outlined" fullWidth /*error={!!error.type}*/>
              <InputLabel htmlFor="assetList">Field Type</InputLabel>

              <Select
                onChange={(event: SelectChangeEvent) =>
                  field.onChange(event?.target?.value)
                }
                native
                variant="outlined"
                label="Field Type"
                fullWidth
                defaultValue={String(type)}
              >
                <option key={0} value="" aria-label="empty" />
                {Object.values(fieldTypes)?.map((t) => (
                  <option key={t} value={t}>
                    {t}
                  </option>
                ))}
              </Select>
            </FormControl>
          );
        }}
      />
      {error && <ErrorText text={error?.message} />}
    </>
  );
}
