import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import React, { useRef, useState } from 'react';

import { EMPTY_ARRAY } from '../shared/helpers';

const INITIAL_MODAL_STATE = {
  open: false,
  messages: [],
  buttons: [],
  title: '',
  onConfirm: () => {
    return true;
  },
};
const BUTTON_TYPES = {
  CANCEL: 'CANCEL',
  CONFIRM: 'CONFIRM',
};

type ModalButton = {
  value?: string | boolean;
  text: string;
};

type ModalDialogProps = {
  messages: string[];
  buttons: ModalButton[];
  title?: string;
  open: boolean;
  onConfirm: (result: string | boolean) => void;
};

type UseModalProps = {
  showModal: (ModalDialogProps) => Promise<any>;
};

const ModalContext = React.createContext<UseModalProps>({} as UseModalProps);

const getButtonsFromConfig = (buttonConfig) => {
  return [
    ...(buttonConfig.cancel || EMPTY_ARRAY).map((button) => {
      return { ...button, type: BUTTON_TYPES.CANCEL };
    }),
    ...(buttonConfig.confirm || EMPTY_ARRAY).map((button) => {
      return { ...button, type: BUTTON_TYPES.CONFIRM };
    }),
  ];
};

function ModalDialog({
  open,
  onConfirm,
  title = 'Select an option',
  buttons = [],
  messages,
}: ModalDialogProps) {
  return (
    <Dialog open={open} onClose={() => onConfirm(false)}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent sx={{ minWidth: 300 }}>
        {messages &&
          messages.map((message) => {
            if (message === '') {
              return <br />;
            }
            return (
              <Typography
                key={message}
                sx={{ display: 'block' }}
                variant="body1"
              >
                {message}
              </Typography>
            );
          })}
      </DialogContent>
      <DialogActions>
        {buttons
          ? getButtonsFromConfig(buttons).map((button) => {
              return (
                <Button
                  key={button.text}
                  onClick={() => onConfirm(button.value)}
                  color={
                    button.type === BUTTON_TYPES.CONFIRM
                      ? 'primary'
                      : 'secondary'
                  }
                >
                  {button.text ||
                    (button.type === BUTTON_TYPES.CONFIRM ? 'Yes' : 'No')}
                </Button>
              );
            })
          : null}
      </DialogActions>
    </Dialog>
  );
}

type ModalProviderProps = {
  children: React.ReactNode;
};

type DeferredPromise<DeferType> = {
  resolve: (value: DeferType) => void;
};

function ModalProvider({ children }: ModalProviderProps) {
  const [dialog, setDialog] = useState(INITIAL_MODAL_STATE);
  const promiseRef = useRef<DeferredPromise<any>>();

  const onConfirm = (value: any) => {
    if (promiseRef.current?.resolve) {
      promiseRef.current?.resolve(value);
    }
    setDialog((prevState) => {
      return { ...prevState, open: false };
    });
  };

  const showModal = (props) => {
    setDialog({ ...props, onConfirm, open: true });

    return new Promise((resolve) => {
      promiseRef.current = { resolve };
    });
  };

  const value = { showModal };
  return (
    <ModalContext.Provider value={value}>
      {children}
      <ModalDialog {...dialog} />
    </ModalContext.Provider>
  );
}

function useModal() {
  const context = React.useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
}
export { ModalProvider, useModal };
