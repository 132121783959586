import {
  getSafeExcelSheetName,
  getSafeTextFromText,
  getStyledExportHeaders,
} from '../../../../../shared/utilities';

import XLSX from 'xlsx-js-style';

export async function generateImportSheet(
  columns: string[],
  surveyName: string,
) {
  const styledColumns = getStyledExportHeaders(columns);

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet([]);
  XLSX.utils.sheet_add_aoa(worksheet, [styledColumns], {
    origin: 'A1',
  });
  const parsedName = getSafeExcelSheetName(
    `${getSafeTextFromText(surveyName).slice(0, 24)}_import`,
  );
  XLSX.utils.book_append_sheet(workbook, worksheet, `${parsedName}`);
  const maxWidth = columns.reduce((w, r) => Math.max(w, r.length), 10);
  worksheet['!cols'] = columns.map((h) => ({ wch: maxWidth }));
  XLSX.writeFile(workbook, `${parsedName}.xlsx`);
}
