import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { FormValues } from '..';
import ErrorText from '../../../../../../components/Form/ErrorText';
import { FORM_FIELDS_NAME } from '../../../../../../config/constants';
import { FormField } from '../../../../../../types/form';
import { NUMBER_REGEX, NUMBER_VALIDATION_MESSAGE } from '../constants';
import useFormError from '../hooks/useFormError';

type FormFieldMinMaxProps = {
  index: number;
  value?: number;
  identifier: keyof FormField;
  label: string;
  pattern?: { value: RegExp; message: string };
};

const trans = {
  input: (value) => (isNaN(value) || value === 0 ? '' : value.toString()),
  output: (e) => {
    const output = parseInt(e.target.value, 10);
    return isNaN(output) ? 0 : output;
  },
};

export default function FormFieldMinMax({
  index,
  value,
  identifier,
  label,
  pattern,
}: FormFieldMinMaxProps) {
  const { control } = useFormContext<FormValues>();
  const error = useFormError(index, identifier);

  return (
    <>
      <Controller
        control={control}
        name={`${FORM_FIELDS_NAME}.${index}.${identifier}`}
        rules={{
          required: false,
          pattern: pattern || {
            value: NUMBER_REGEX,
            message: NUMBER_VALIDATION_MESSAGE,
          },
        }}
        render={({ field }) => (
          <TextField
            autoComplete="off"
            onChange={(event) => field.onChange(event?.target.value)}
            error={!!error?.message}
            fullWidth
            variant="outlined"
            label={label}
            defaultValue={value}
          />
        )}
      />
      {error && <ErrorText text={error?.message} />}
    </>
  );
}
