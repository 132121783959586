import { SurveyProps } from '../../../../types/survey';
import { useEffect } from 'react';

export default function useLeaveHandler(
  onLeave: (data: Partial<SurveyProps>) => void,
  getValues,
) {
  if (!getValues) {
    throw new Error('Must be inside a FormContext to use useLeaveHandler hook');
  }
  if (typeof onLeave !== 'function') {
    throw new Error(
      'Must pass an onLeave function to use useLeaveHandler hook',
    );
  }
  useEffect(() => {
    return () => {
      const values = getValues();

      onLeave(values);
    };
  }, []);
}
