import { Box, Typography } from '@mui/material';

import { handlePlural } from '../../../../../../shared/utilities';

type NumImports = {
  numberOfImports: number;
};

export default function NumImports({ numberOfImports }: NumImports) {
  return (
    <Box mt={2}>
      <Typography
        color="secondary"
        variant="body1"
        align="center"
        fontWeight={700}
      >
        <Typography
          component="span"
          sx={{ fontWeight: 700 }}
          color={numberOfImports ? 'primary' : 'error'}
        >
          {numberOfImports || 0}
        </Typography>{' '}
        {handlePlural(numberOfImports, 'rows', false)} will be imported
      </Typography>
    </Box>
  );
}
