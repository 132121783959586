import Add from './add';
import Asset from './edit/asset';
import Assets from './edit/assets';
import { Box } from '@mui/material';
import Edit from './edit';
import { ROUTES_MAP } from '../../../config/routes';
import Reissue from './edit/reissue';
import RouteGuard from '../../../components/RouteGuard';
import { Switch } from 'react-router-dom';
import View from './view';

const Surveys = () => {
  return (
    <Box>
      <Switch>
        <RouteGuard {...ROUTES_MAP.SURVEYS_ADD}>
          <Add />
        </RouteGuard>
        <RouteGuard {...ROUTES_MAP.SURVEYS_REISSUE}>
          <Reissue />
        </RouteGuard>
        <RouteGuard {...ROUTES_MAP.SURVEYS_SURVEY}>
          <Edit />
        </RouteGuard>
        <RouteGuard {...ROUTES_MAP.SURVEYS_ROOM}>
          <Assets />
        </RouteGuard>
        <RouteGuard {...ROUTES_MAP.SURVEYS_ASSET}>
          <Asset />
        </RouteGuard>
        <RouteGuard {...ROUTES_MAP.SURVEYS}>
          <View />
        </RouteGuard>
      </Switch>
    </Box>
  );
};

export default Surveys;
