import { useMemo } from 'react';

export default function useQueryParams<T>() {
  const queryParams = useMemo(
    () => new URLSearchParams(window.location.search),
    [window.location.search],
  );
  return {
    page: Number(queryParams.get('page')),
    perpage: Number(queryParams.get('perpage')),
    fp: queryParams.get('fp') === 'true',
    floor: Number(queryParams.get('floor')),
    filters: String(queryParams.get('filters') || ''),
  };
}
