import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from '@mui/material';
import { emailRegex, parseSamlError } from '../../shared/utilities';
import { getFirebase, useFirestore } from 'react-redux-firebase';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import DynamicIcon from '../../components/DynamicIcon';
import ErrorText from '../../components/Form/ErrorText';
import LockIcon from '@mui/icons-material/Lock';
import RoleSelect from '../../components/RoleSelect';
import { makeStyles } from '@mui/styles';
import { setLoading } from '../../state/ui';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useModal } from '../../components/Modal';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.secondary.main,
    height: '100vh',
    width: '100vw',
  },
  card: {
    maxWidth: 480,
    overflow: 'visible',
  },
  form: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  iconContainer: {
    transform: `translateY(-50px)`,
    width: '60px',
    height: '60px',
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    stroke: theme.palette.primary.main,
    height: '40px',
    marginRight: '10px',
  },
  companyName: {
    fontFamily: 'Open Sans',
  },
}));

const NO_USER_MESSAGE =
  'That email address & password combination is not recognised';

const NO_ROLES_MESSAGE =
  'Your user account is not added to any Asseticom portals, please contact your portal administrator(s) for more information';

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [dialogOpen, setDialogOpen] = useState();
  const [forgotPasswordMode, setForgotPasswordMode] = useState(false);
  const classes = useStyles();
  const firebase = getFirebase();
  const firestore = useFirestore();
  const history = useHistory();
  const { showModal } = useModal();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.firebase.profile);
  const [userRoles, setUserRoles] = useState(null);
  const [usePasswordFlow, setUsePasswordFlow] = useState(false);

  useEffect(() => {
    if (profile.isLoaded && !profile.isEmpty) {
      const roles = Object.keys(profile?.token?.claims?.roles);
      console.log({ roles });
      if (roles.indexOf('asseticom') >= 0) {
        history.push('/');
      } else if (roles.length > 1) {
        setUserRoles(roles);
      } else if (roles.length === 1) {
        history.push(roles[0]);
      } else if (!roles.length) {
        firebase.logout();
        setDialogOpen(NO_ROLES_MESSAGE);
      }
    }
  }, [profile]);

  const onSubmit = async (formData) => {
    const { email, password } = formData;

    if (!usePasswordFlow) {
      return alert('SSO HERE', formData);
    }

    try {
      dispatch(
        setLoading({
          loadingMessage: 'Logging in...Please wait',
        }),
      );
      const result = await firebase.login({
        email,
        password,
      });
    } catch (error) {
      console.error(error);
      setDialogOpen(NO_USER_MESSAGE);
    } finally {
      dispatch(setLoading(false));
    }
  };
  const onForgotPasswordSubmit = async (formData) => {
    const { email } = formData;
    await showModal({
      title: 'Password reset',
      buttons: {
        confirm: [{ text: 'Ok' }],
      },
      messages: [
        `If ${email} exists on our system, you will receive an email with instructions on how to reset your password.`,
      ],
    });
    await firebase.auth().sendPasswordResetEmail(email);
    try {
      setForgotPasswordMode(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDialogClose = () => setDialogOpen(false);

  const { ref: emailRef, ...emailRest } = register('email', {
    required: true,
    pattern: {
      value: emailRegex,
      message: 'Enter a valid e-mail address',
    },
  });
  const { ref: passwordRef, ...passwordRest } = register('password', {
    required: true,
  });

  const checkEmailForSSO = async (event) => {
    const email = event.target.value;
    const suffix = email.split('@')[1];

    const ssoEnabledQuery = await firestore.collection('sso').doc(suffix).get();
    const ssoEnabled = ssoEnabledQuery.exists;

    setUsePasswordFlow(ssoEnabled === false);
    if (ssoEnabled) {
      const ssoData = ssoEnabledQuery.data();
      const provider = new firebase.auth.SAMLAuthProvider(ssoData.id);
      try {
        const ssoResult = await firebase.auth().signInWithRedirect(provider);
      } catch (error) {
        console.error(error);
      }
    }
  };

  // handle 3rd-party auth login/errors here
  useEffect(() => {
    firebase
      .auth()
      .getRedirectResult()
      .then((result) => {
        console.log({ ssoresult: result });
        // User is signed in.
        // Provider data available in result.additionalUserInfo.profile,
        // or from the user's ID token obtained from result.user.getIdToken()
        // as an object in the firebase.sign_in_attributes custom claim.
      })
      .catch((error) => {
        const parsed = parseSamlError(error);

        // Handle error.
        if (parsed.status === 'NOT_FOUND') {
          showModal({
            title: 'Account not found',
            buttons: {
              confirm: [{ text: 'Ok' }],
            },
            messages: [parsed.message],
          });
        }
      });
  }, []);

  return (
    <Box
      className={classes.container}
      component="main"
      display="flex"
      justifyContent="center"
      alignItems="center"
      p={2}
    >
      <Card raised>
        <CardContent>
          <Box display="flex" justifyContent="space-between"></Box>
          {!userRoles && (
            <Box>
              <Typography component="h1" variant="h1" align="center" paragraph>
                Log in to Asseticom Command Centre
              </Typography>
              <Divider />
              <form onSubmit={handleSubmit(onSubmit)}>
                <TextField
                  {...emailRest}
                  ref={emailRef}
                  fullWidth
                  autoFocus
                  margin="normal"
                  id="login-email"
                  label="Email address"
                  variant="outlined"
                  type="email"
                  autoComplete="email"
                  onBlur={checkEmailForSSO}
                />
                {errors.email && <ErrorText />}
                {usePasswordFlow && !forgotPasswordMode && (
                  <>
                    {' '}
                    <TextField
                      {...passwordRest}
                      ref={passwordRef}
                      fullWidth
                      margin="normal"
                      id="login-password"
                      label="Password"
                      variant="outlined"
                      type="password"
                      autoComplete="current-password"
                    />
                    {errors.password && <ErrorText />}
                  </>
                )}
                {
                  <Box mt={2} display="flex" justifyContent="space-between">
                    {!forgotPasswordMode && (
                      <>
                        {
                          <Button
                            sx={{
                              visibility: usePasswordFlow
                                ? 'visible'
                                : 'hidden',
                            }}
                            onClick={() => setForgotPasswordMode(true)}
                            color="secondary"
                          >
                            Forgot Password?
                          </Button>
                        }
                        <Button
                          variant="contained"
                          onClick={handleSubmit}
                          color="secondary"
                          type="submit"
                        >
                          {usePasswordFlow ? 'Log In' : 'Next'}
                        </Button>
                      </>
                    )}
                    {forgotPasswordMode && (
                      <>
                        <Button
                          onClick={() => setForgotPasswordMode(false)}
                          color="secondary"
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          onClick={handleSubmit(onForgotPasswordSubmit)}
                          color="secondary"
                          type="submit"
                        >
                          Submit
                        </Button>
                      </>
                    )}
                  </Box>
                }
              </form>
            </Box>
          )}
          {userRoles && (
            <Box>
              <Typography variant="h5" align="center" paragraph>
                Which portal would you like to enter?
              </Typography>
              <Divider />
              <Box mt={2}>
                <RoleSelect roles={userRoles} />
              </Box>
            </Box>
          )}
        </CardContent>
      </Card>
      <Dialog
        open={!!dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="Notifies of login errors"
      >
        <DialogTitle id="alert-dialog-title">Account not found</DialogTitle>
        <DialogContent>
          <Typography>{dialogOpen}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Login;
