import { Box, Button, Chip, Divider, Step, Typography } from '@mui/material';
import { parseXLSX, readURLAsBinary } from '../../../../../../shared/utilities';

import StepGuide from '../../../components/StepGuide';
import { generateImportSheet } from '../util';
import useImportSchema from '../hooks/useImportSchema';
import { useImportStepData } from '../../../../../../state/survey-form';

type ImportTemplateStepProps = {
  stepNumber: number;
};

export default function ImportTemplateStep({
  stepNumber,
}: ImportTemplateStepProps) {
  const { columns, name } = useImportStepData();

  function onClickDownload() {
    generateImportSheet(columns, name);
  }

  return (
    <Box my={2} display="flex" flexDirection="column" alignItems="center">
      <StepGuide
        stepNumber={stepNumber}
        text="Download the unique template file for this survey and then add your data
        to the correct columns."
      />
      <Box mt={2}>
        <Button onClick={onClickDownload} variant="contained" color="secondary">
          Download Import Template
        </Button>
      </Box>
    </Box>
  );
}
