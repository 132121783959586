import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  useTheme,
} from '@mui/material';

import DynamicIcon from './DynamicIcon';
import { Link } from 'react-router-dom';

const TextureButton = ({ colour, children, linkTo }) => {
  const theme = useTheme();

  return (
    <Card>
      <CardActionArea component={Link} to={linkTo}>
        <CardContent
          sx={{
            color: theme.palette[colour].main,
            overflow: 'hidden',
            padding: 2,
            width: '100%',
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            flex={1}
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '300%',
                height: '300%',
              }}
            >
              <DynamicIcon
                name="logo-square"
                size="100%"
                colour="primary"
                stroke="primary"
                opacity={0.1}
              />
            </Box>
            {children}
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default TextureButton;
