import { FormField, NicheFields } from '../../../../../types/form';

import { AssetAttributes } from '../../../../../types/asset';
import { EMPTY_ARRAY } from '../../../../../shared/helpers';
import FormFieldTypeBarcode from './components/FormFieldRowBarcode';
import FormFieldTypeDecimal from './components/FormFieldRowDecimal';
import FormFieldTypeNumber from './components/FormFieldRowNumber';
import FormFieldTypeRadio from './components/FormFieldRowRadio';
import FormFieldTypeSelect from './components/FormFieldRowSelect';
import FormFieldTypeSlider from './components/FormFieldRowSlider';
import FormFieldTypeText from './components/FormFieldRowText';
import { fieldTypes } from './constants';
import { getSafeTextFromText } from '../../../../../shared/utilities';

export function getFieldElementForType(type: string) {
  switch (type) {
    case fieldTypes.text:
      return FormFieldTypeText;
    case fieldTypes.barcode:
      return FormFieldTypeBarcode;
    case fieldTypes.decimal:
      return FormFieldTypeDecimal;
    case fieldTypes.number:
      return FormFieldTypeNumber;
    case fieldTypes.slider:
      return FormFieldTypeSlider;
    case fieldTypes.select:
      return FormFieldTypeSelect;
    case fieldTypes.radio:
      return FormFieldTypeRadio;
    default:
      return FormFieldTypeText;
  }
}

type ApplyNicheFieldsDefaultsProps = {
  formFields: FormField[];
  resetAll?: boolean;
};

export function applyFormFieldsDefaults({
  formFields,
  resetAll,
}: ApplyNicheFieldsDefaultsProps) {
  return [
    ...formFields.map((f) => {
      if (f.base || !f.nicheFields || !f.nicheFields?.length || resetAll) {
        return {
          ...f,
          nicheFields: [],
          nicheFieldsExcludeMode: true,
        };
      }
      return f;
    }),
  ];
}

// check that there are no form fields with the same name as another
export const checkForDuplicates = (data) => {
  return data.filter((field, index, arr) => {
    const lastIndex = arr.findLastIndex(
      (f) =>
        // used for field id creation which cannot clash
        getSafeTextFromText(f.label).toLowerCase() ===
        getSafeTextFromText(field.label).toLowerCase(),
    );
    return lastIndex !== index;
  });
};

export function invertNicheFields(
  nicheFields: string[],
  assetList: AssetAttributes[] | typeof EMPTY_ARRAY,
) {
  return assetList
    .map((t) => t.code3)
    .filter((t: string) => nicheFields.findIndex((code) => code === t) === -1);
}

export function performNicheFieldInversionIfNeeded(
  nicheFields: NicheFields,
  assetList: AssetAttributes[] | typeof EMPTY_ARRAY,
) {
  if (nicheFields.length >= assetList.length / 2) {
    return {
      fields: invertNicheFields(nicheFields, assetList),
      excludeMode: true,
    };
  } else {
    return { fields: nicheFields, excludeMode: false };
  }
}
