import { CreatePhotoScheduleReportData } from '../types';

interface Introduction {
  data: CreatePhotoScheduleReportData;
}

export function IntroductionPage({ data }: Introduction) {
  const { field1, field2, field3, field4, field5 } = data;

  const customFields = [field1, field2, field3, field4, field5].filter(
    (f) => f,
  );

  return (
    <div className="fill-page-no-margin flex-justify-center flex-align-center">
      <div className="page-content">
        <h1 className="page-heading toc-child-element">1.0 - Introduction</h1>
        <div>
          <p>
            1.1 - This {data.reportTitle} has been prepared for{' '}
            {data.clientName} and details the photographic schedule for the
            assets contained therein.
          </p>
          <p>
            1.2 - The photographic schedule contained herein is based on the
            following surveys at {data.siteName}:
          </p>
          <div className="introduction-table-container">
            <div className="introduction-table-header-row-container">
              <span className="introduction-table-header-cell">Name</span>
              <span className="introduction-table-header-cell">
                Building Name
              </span>
              <span className="introduction-table-header-cell">
                Asset Count
              </span>
            </div>
            {data.surveys.map((survey) => (
              <div key={survey.id} className="introduction-table-row-container">
                <span className="introduction-table-cell">{survey.name}</span>
                <span className="introduction-table-cell">
                  {survey.buildingName}
                </span>
                <span className="introduction-table-cell">{survey.assets}</span>
              </div>
            ))}
          </div>
          <p>
            1.3 - The photographic schedule is a bespoke report and contains
            standard and custom information against each asset.
          </p>

          <p>
            1.4 - The custom fields against each asset contained within this
            report are:
          </p>

          <div className="introduction-custom-fields-list">
            {customFields.length === 0 ? (
              <span className="introduction-custom-field">None selected</span>
            ) : null}
            {customFields.length
              ? customFields.map((field) => (
                  <span className="introduction-custom-field" key={field}>
                    &bull; {field}
                  </span>
                ))
              : null}
          </div>

          <p>
            1.5 - Please note that not all assets may contain data for every
            field. Where this is the case, an &apos;N/A&apos; will be noted.
          </p>

          <p>
            1.6 - Images within this photographic schedule are interactive.
            Clicking on them will take you to the Asseticom portal where you can
            div further information and image data on the asset if it is
            available.
          </p>
          <p>
            1.7 - Assets without an associated image will not appear in this
            report.
          </p>
        </div>
      </div>
    </div>
  );
}
