import { Box, Grid, Typography } from '@mui/material';

import PropTypes from 'prop-types';
import { colours } from '../../config/theme';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    borderBottom: `1px solid ${colours.grey6}`,
    marginBottom: theme.spacing(2),
  },
}));

const SectionTitle = ({ title, marginTop }) => {
  const classes = useStyles();
  return (
    <Grid item xs={12}>
      <Box mt={marginTop || 2}>
        <Typography gutterBottom className={classes.sectionTitle}>
          {title}
        </Typography>
      </Box>
    </Grid>
  );
};

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  marginTop: PropTypes.number,
};

SectionTitle.defaultProps = {
  marginTop: 2,
};

export default SectionTitle;
