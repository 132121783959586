import { Avatar, Box, Grid, Paper, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import CreatedTableCell from '../../../../components/CreatedTableCell';
import { EMPTY_ARRAY } from '../../../../shared/helpers';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import Table from '../../../../components/Table';
import TableActionButton from '../../../../components/TableActionButton';
import { getUserRolePretty } from '../../../../config/roles';
import { onUserDelete } from '../../../../state/user';
import { useCallback } from 'react';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useModal } from '../../../../components/Modal';

const columns = [
  { accessorKey: 'name', header: 'Name' },
  { accessorKey: 'role', header: 'Role' },
  {
    accessorKey: 'created',
    header: 'Created',
    Cell: CreatedTableCell,
  },
];

const View = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { clientId } = useParams();

  const { showModal } = useModal();

  const query = {
    collection: 'roles',
    where: [
      ['clientId', '==', clientId],
      ['deleted', '==', 0],
    ],
    storeAs: 'allUserRoles',
    orderBy: [['nameFirst', 'asc']],
  };

  useFirestoreConnect(query);

  const roles = useSelector(
    (state) => state.firestore.ordered.allUserRoles || EMPTY_ARRAY,
  );

  const handleUserDelete = async (user) => {
    const { uid, name } = user;
    const messages = [`Are you sure you want to delete ${name}?`];
    const deleteConfirmed = await showModal({
      title: 'Please Confirm',
      buttons: {
        confirm: [{ text: 'Yes', value: true }],
        cancel: [{ text: 'No' }],
      },
      messages,
    });
    if (deleteConfirmed) {
      dispatch(onUserDelete({ userId: uid, name }));
    }
  };

  const onClickedEditUser = ({ uid }) => {
    history.push(`${location.pathname}/${uid}`);
  };

  const rows = roles.map((user) => {
    const { id, uid, nameFirst, nameLast, created, imageURL, role } = user;
    return {
      uid,
      id,
      role: getUserRolePretty(role),
      name: `${nameFirst} ${nameLast}`,
      component: (value) => {
        return (
          <Box display="flex" alignItems="center">
            <Box mr={2}>
              <Avatar src={imageURL} width="100%" alt="logo">
                {`${nameFirst.slice(0, 1)}${nameLast.slice(0, 1)}`}
              </Avatar>
            </Box>
            {`${nameFirst} ${nameLast}`}
          </Box>
        );
      },
      created,
    };
  });

  const actionButtons = useCallback(({ row, closeMenu }) => {
    const buttons = [];

    const rowData = row.original;

    buttons.push(
      <TableActionButton
        key="Edit"
        text="Edit"
        colour="blue"
        onClick={() => closeMenu(onClickedEditUser({ uid: rowData.uid }))}
      />,
    );

    buttons.push(
      <TableActionButton
        key="Delete"
        text="Delete"
        colour="red"
        onClick={() => closeMenu(handleUserDelete(rowData))}
      />,
    );

    return (
      <Stack direction="column" p={1}>
        {buttons}
      </Stack>
    );
  }, []);

  return (
    <Grid container spacing={2}>
      <Paper sx={{ flex: 1 }} id="main-content">
        <ErrorBoundary>
          <Table
            rows={rows}
            columns={columns}
            onClickRow={onClickedEditUser}
            tableProps={{
              enableRowActions: true,
              positionActionsColumn: 'first',
              renderRowActionMenuItems: actionButtons,
            }}
          />
        </ErrorBoundary>
      </Paper>
    </Grid>
  );
};

export default View;
