import { Button, Stack, Typography } from '@mui/material';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import DropWrapper from './DropWrapper';

interface DropTarget {
  dropText?: string;
  onAddFiles?: (files: FileList | null) => void;
  width?: number | string;
  height?: number | string;
}

const DEFAULT_SIZE = 300;

export default function DropTarget({
  dropText,
  onAddFiles,
  width = DEFAULT_SIZE,
  height = DEFAULT_SIZE,
}: DropTarget) {
  return (
    <DropWrapper onAddFiles={onAddFiles}>
      <label style={{ cursor: 'pointer' }}>
        <Stack
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          spacing={1}
          p={2}
          sx={{
            width,
            height,
            outline: '1px dashed lightgrey',
          }}
        >
          <AddCircleIcon color="primary" sx={{ fontSize: 40 }} />
          <Typography variant="h5" component="span">
            {dropText || 'Add a file'}{' '}
          </Typography>
          <Typography variant="body2" component="span">
            Drop here or
            <Button component="label">
              Click to upload{' '}
              <input
                type="file"
                multiple
                hidden
                onChange={(e) => onAddFiles?.(e.target.files)}
              />
            </Button>
          </Typography>
        </Stack>
      </label>
    </DropWrapper>
  );
}
