import 'firebase/firestore';

import { decompressSync, strFromU8 } from 'fflate';
import { useEffect, useState } from 'react';

import { AssetAttributes } from '../types/asset';
import { EMPTY_ARRAY } from '../shared/helpers';
import { UrlParams } from '../types/urlParams';
import firebase from 'firebase/app';
import { useParams } from 'react-router-dom';

const CURRENT_LIST: { id: string; data: AssetAttributes[] } = {
  id: '',
  data: [],
};

export async function getAssetListById(
  clientId?: string,
  assetListId?: string,
) {
  if (!clientId || !assetListId) {
    throw 'You must supply both a clientId and an assetListId.';
  }
  if (CURRENT_LIST.id === assetListId) {
    return CURRENT_LIST.data;
  }
  // Initialize other services on firebase instance
  const firestore = firebase.firestore();

  const clientDocRef = firestore.collection('clients').doc(clientId);

  const assetListDocRef = await clientDocRef
    .collection('assetLists')
    .doc(assetListId)
    .get();

  const url = assetListDocRef.data()?.jsonUrl;

  const arrayBuffer = await fetch(url).then((r) => r.arrayBuffer());

  const compressedArrayBuffer = new Uint8Array(arrayBuffer);

  const data = JSON.parse(strFromU8(decompressSync(compressedArrayBuffer)));

  CURRENT_LIST.id = assetListId;
  CURRENT_LIST.data = data;

  return data;
}

export function useAssetList(assetListId) {
  const { clientId } = useParams<UrlParams>();
  const [assetList, setAssetList] = useState<
    AssetAttributes[] | typeof EMPTY_ARRAY
  >([]);

  useEffect(() => {
    async function fetchAndDecompressAssetList() {
      try {
        const data = await getAssetListById(clientId, assetListId);

        if (data?.length === 0) {
          setAssetList(EMPTY_ARRAY);
        } else {
          setAssetList(data);
        }
      } catch (error) {
        console.error(error);
      }
    }

    if (assetListId) {
      fetchAndDecompressAssetList();
    } else {
      setAssetList(EMPTY_ARRAY);
    }
  }, [clientId, assetListId]);
  return assetList;
}
