import { Box } from '@mui/material';
import Cropper from './cropper';
import { ROUTES_MAP } from '../../../config/routes';
import React from 'react';
import RouteGuard from '../../../components/RouteGuard';
import { Switch } from 'react-router-dom';

const Tools = () => {
  return (
    <Box>
      <Switch>
        <RouteGuard {...ROUTES_MAP.CROPPER}>
          <Cropper />
        </RouteGuard>
      </Switch>
    </Box>
  );
};

export default Tools;
