import { Controller, useFormContext } from 'react-hook-form';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';

import { AppState } from '../../../../../../config/store';
import ErrorText from '../../../../../../components/Form/ErrorText';
import { FORM_FIELDS_NAME } from '../../../../../../config/constants';
import { FormValues } from '..';
import { NicheFields } from '../../../../../../types/form';
import NicheFieldsCopierOpenButton from './NicheFieldsCopierOpenButton';
import { NicheFieldsCopierProvider } from '../hooks/useNicheFieldsCopier';
import { useAssetList } from '../../../../../../hooks/useAssetList';
import useFormError from '../hooks/useFormError';
import { useNicheFieldsSelector } from '../hooks/useNicheFieldsSelector';
import { useSelector } from 'react-redux';

type FormFieldNicheFieldSelectorProps = {
  index: number;
  nicheFields: NicheFields | undefined;
  excludeMode: boolean;
};

export default function FormFieldNicheFieldSelector({
  index,
  nicheFields,
  excludeMode,
}: FormFieldNicheFieldSelectorProps) {
  const { control, setValue } = useFormContext<FormValues>();
  const assetListId = useSelector(
    (state: AppState) => state.surveyForm.assetListId,
  );
  const assetList = useAssetList(assetListId);
  const { open } = useNicheFieldsSelector();

  const error = useFormError(index, 'nicheFields');

  function onCopyNicheFields(nicheFields: NicheFields) {
    setValue(`${FORM_FIELDS_NAME}.${index}.nicheFields`, nicheFields);
  }

  const numSelected = excludeMode
    ? assetList.length - (nicheFields?.length || 0)
    : nicheFields?.length;

  const allSelected = numSelected === assetList.length;

  const valueText = allSelected
    ? 'All selected'
    : `${numSelected} of ${assetList?.length} selected`;

  return (
    <>
      <NicheFieldsCopierProvider index={index} onSelect={onCopyNicheFields}>
        <Stack direction="row" spacing={1}>
          <FormControl variant="outlined" error={!!error?.message} fullWidth>
            <InputLabel htmlFor="nicheFields" shrink>
              Niche Fields
            </InputLabel>

            <Controller
              name={`${FORM_FIELDS_NAME}.${index}.nicheFields`}
              control={control}
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    notched
                    displayEmpty
                    multiple={typeof field.value !== 'undefined'}
                    label="Niche Fields"
                    open={false}
                    onOpen={() => {
                      open({ nicheFields, excludeMode });
                    }}
                    renderValue={() => valueText}
                  ></Select>
                );
              }}
            />
          </FormControl>
          <NicheFieldsCopierOpenButton />
        </Stack>
      </NicheFieldsCopierProvider>
      {error && <ErrorText text={error?.message} />}
    </>
  );
}
