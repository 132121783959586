import { Controller, useFormContext } from 'react-hook-form';

import ErrorText from '../../../../../../components/Form/ErrorText';
import { FORM_FIELDS_NAME } from '../../../../../../config/constants';
import { FormValues } from '..';
import { TextField } from '@mui/material';
import useFormError from '../hooks/useFormError';

type FormFieldLabelProps = {
  label: string;
  index: number;
};

export default function FormFieldLabel({ label, index }: FormFieldLabelProps) {
  const { control } = useFormContext<FormValues>();
  const error = useFormError(index, 'label');

  return (
    <>
      <Controller
        control={control}
        name={`${FORM_FIELDS_NAME}.${index}.label`}
        rules={{
          required: true,
        }}
        render={({ field }) => (
          <TextField
            inputProps={{ color: 'primary' }}
            autoComplete="off"
            onChange={(event) => field.onChange(event?.target.value.trim())}
            error={!!error?.message}
            fullWidth
            variant="outlined"
            label="Label"
            defaultValue={label}
          />
        )}
      />
      {error && <ErrorText text={error?.message} />}
    </>
  );
}
