import { Box } from '@mui/material';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import RouteGuard from '../../components/RouteGuard';
import { NAV_ROUTES_MAP } from '../../config/routes';
import AddNewclient from './add';
import View from './View';

const Clients = () => {
  return (
    <Box>
      <Switch>
        <RouteGuard {...NAV_ROUTES_MAP.CLIENTS_ADD}>
          <AddNewclient />
        </RouteGuard>
        <RouteGuard {...NAV_ROUTES_MAP.CLIENTS}>
          <View />
        </RouteGuard>
      </Switch>
    </Box>
  );
};

export default Clients;
