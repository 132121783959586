import { EMPTY_OBJECT } from '../shared/helpers';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

export default function useQueryParamsUpdater() {
  const { search, pathname } = window.location;

  const updateQueryParams = useCallback(
    (newParams) => {
      const { pathname, search } = window.location;
      const existingParams = Object.fromEntries(new URLSearchParams(search));
      window.history.replaceState(
        {},

        '',
        pathname +
          `?${new URLSearchParams({
            ...existingParams,
            ...newParams,
          }).toString()}`,
      );
    },
    [search, pathname],
  );

  const deleteQueryParam = useCallback(
    (param) => {
      const { pathname, search } = window.location;
      const existingParams = Object.fromEntries(new URLSearchParams(search));
      try {
        delete existingParams[param];
        window.history.replaceState(
          {},
          '',
          pathname +
            `?${new URLSearchParams({
              ...existingParams,
            }).toString()}`,
        );
      } catch (e) {
        console.error('No query param', param, 'to delete');
      }
    },
    [search, pathname],
  );

  return { updateQueryParams, deleteQueryParam };
}
