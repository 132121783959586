import { Box } from '@mui/material';
import { colours } from '../config/theme';
import { getImageOrPlaceholder } from './DataTable';

const ImageTableCell = ({ cell, row }) => {
  const { image } = row.original;
  return (
    <Box display="flex" alignItems="center">
      <Box
        sx={{
          backgroundColor: colours.lightGrey,
          flexShrink: 0,
          mr: 2,
          borderRadius: '25%',
          width: 50,
          height: 50,
          overflow: 'hidden',
        }}
      >
        {getImageOrPlaceholder(image)}
      </Box>
      {cell.getValue()}
    </Box>
  );
};

export default ImageTableCell;
