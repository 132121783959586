import 'leaflet/dist/leaflet.css';

import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import { buildingPropTypes, sitePropTypes } from '../../../../shared/propTypes';

import AccessibleSwitcher from '../../../../components/AccessibleSwitcher';
import BuildingForm from './BuildingForm';
import DynamicIcon from '../../../../components/DynamicIcon';
import PropTypes from 'prop-types';
import SiteForm from './SiteForm';
import { makeStyles } from '@mui/styles';
import { setSnackMessage } from '../../../../state/ui';
import { useDispatch } from 'react-redux';
import { useModal } from '../../../../components/Modal';
import useScrollToHash from '../../../../hooks/useScrollToHash';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  paper: {
    flex: 1,
  },
  buildings: {
    marginTop: theme.spacing(2),
  },
}));

const SiteAndBuildingsForm = ({
  site,
  buildings,
  onSiteSubmit,
  onBuildingSubmit,
  onBuildingDelete,
}) => {
  const dispatch = useDispatch();
  const { showModal } = useModal();
  const [addBuildingMode, setAddBuildingMode] = useState(false);
  const [buildingToEdit, setBuildingToEdit] = useState();

  useScrollToHash(500);
  const classes = useStyles();

  const onClickEditBuilding = (building) => {
    setBuildingToEdit(building);
    setAddBuildingMode(true);
  };

  const onClickAddBuilding = () => {
    setBuildingToEdit(null);
    setAddBuildingMode(true);
  };

  function checkForDuplicate(buildingData) {
    return buildings.find(
      (b) => b.name.toLowerCase() === buildingData.name.toLowerCase(),
    );
  }

  const showConfirmDialogForBuilding = async (building) => {
    const messages = [`Are you sure you want to delete ${building.name}?`];

    const deleteConfirmed = await showModal({
      title: 'Please Confirm',
      buttons: {
        confirm: [{ text: 'Yes', value: true }],
        cancel: [{ text: 'No' }],
      },
      messages,
    });
    if (deleteConfirmed) {
      if (buildingToEdit && buildingToEdit.id === building.id) {
        setBuildingToEdit(null);
        setAddBuildingMode(false);
      }
      onBuildingDelete(building);
    }
  };

  return (
    <Grid
      container
      item
      alignItems="flex-end"
      spacing={2}
      className={classes.topRow}
      justifyContent="center"
    >
      <Grid item xs={12}>
        <SiteForm site={site} onSiteSubmit={onSiteSubmit} />
        <Paper className={classes.buildings} id="buildings">
          <Box p={2}>
            <Typography variant="subtitle2">Site Buildings</Typography>
            <Box mt={2}>
              {buildings?.length ? (
                buildings.map((b) => {
                  return (
                    <Box
                      my={2}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      key={b.id}
                    >
                      <Box>
                        <Typography
                          variant="body1"
                          color="secondary"
                        >{`${b.name}`}</Typography>
                        <Typography
                          variant="caption"
                          color="secondary"
                          paragraph
                        >
                          {b.address1 || site.address1}
                        </Typography>
                      </Box>
                      <Box>
                        <IconButton
                          onClick={() => onClickEditBuilding(b)}
                          sx={{ width: '40px', height: '40px' }}
                        >
                          <AccessibleSwitcher
                            accessibleChild={
                              <Typography>Edit Building</Typography>
                            }
                          >
                            <DynamicIcon
                              name="edit"
                              colour="secondary"
                              size="20px"
                            />
                          </AccessibleSwitcher>
                        </IconButton>
                        <IconButton
                          onClick={() => showConfirmDialogForBuilding(b)}
                          sx={{ width: '40px', height: '40px' }}
                        >
                          <AccessibleSwitcher
                            accessibleChild={
                              <Typography>Delete Building</Typography>
                            }
                          >
                            <DynamicIcon name="bin" colour="red" size="20px" />
                          </AccessibleSwitcher>
                        </IconButton>
                      </Box>
                    </Box>
                  );
                })
              ) : (
                <Box my={2}>
                  <Typography variant="body1" color="error">
                    No buildings added to site
                  </Typography>
                </Box>
              )}
              {!addBuildingMode && (
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={onClickAddBuilding}
                  >
                    Add New Building
                  </Button>
                </Box>
              )}
            </Box>
            {addBuildingMode && (
              <BuildingForm
                onSubmit={(buildingData) => {
                  const duplicate = checkForDuplicate(buildingData);

                  if (duplicate) {
                    return dispatch(
                      setSnackMessage({
                        message: `There is already a building called ${buildingData.name}`,
                        type: 'error',
                      }),
                    );
                  }
                  setAddBuildingMode(false);
                  if (buildingToEdit) {
                    return onBuildingSubmit({
                      ...buildingData,
                      id: buildingToEdit.id,
                    });
                  }
                  return onBuildingSubmit(buildingData);
                }}
                building={buildingToEdit}
              />
            )}
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

SiteAndBuildingsForm.defaultProps = {
  site: null,
  buildings: null,
};

SiteAndBuildingsForm.propTypes = {
  site: PropTypes.shape(sitePropTypes),
  buildings: PropTypes.arrayOf(PropTypes.shape(buildingPropTypes)),
  onSiteSubmit: PropTypes.func.isRequired,
  onBuildingSubmit: PropTypes.func.isRequired,
  onBuildingDelete: PropTypes.func.isRequired,
};

export default SiteAndBuildingsForm;
