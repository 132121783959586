import {
  getRandomID,
  removeDuplicatesFromArray,
} from '../../../../../shared/utilities';

import { RoomScheduleData } from '../../../../../types/survey';
import { RoomScheduleRoomProps } from '../../../../../types/room';

export function getRoomList(
  newList: RoomScheduleRoomProps[],
  previousList?: RoomScheduleData,
) {
  const getIdFromPreviousRoomOrNew = (room) => {
    const match = previousList?.find((r, index) => {
      return r.name === room.name && r.floor === room.floor;
    });
    return match?.id || getRandomID();
  };

  const listWithCuid = newList.map((room) => ({
    name: String(room.name).trim(),
    floor: String(room.floor).trim(),
    areaFloor: Number(room.areaFloor) || 0,
    areaWall: Number(room.areaWall) || 0,
    id: getIdFromPreviousRoomOrNew(room),
    deleted: 0,
  }));

  const floors = removeDuplicatesFromArray(listWithCuid.map((r) => r.floor));

  return {
    rooms: listWithCuid,
    floors,
  };
}
