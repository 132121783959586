import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { FormValues } from '..';
import ErrorText from '../../../../../../components/Form/ErrorText';
import { FORM_FIELDS_NAME } from '../../../../../../config/constants';
import { FormField } from '../../../../../../types/form';
import useFormError from '../hooks/useFormError';

type FormFieldDefaultTextInputProps = {
  value?: string | number;
  index: number;
  identifier: keyof FormField;
  pattern?: { value: RegExp; message: string };
};

export default function FormFieldDefaultTextInput({
  value,
  index,
  identifier,
  pattern,
}: FormFieldDefaultTextInputProps) {
  const { control } = useFormContext<FormValues>();

  const error = useFormError(index, identifier);
  return (
    <>
      <Controller
        control={control}
        name={`${FORM_FIELDS_NAME}.${index}.${identifier}`}
        rules={{
          required: false,
          pattern,
        }}
        render={({ field }) => (
          <TextField
            autoComplete="off"
            onChange={(event) => field.onChange(event?.target.value)}
            error={!!error?.message}
            fullWidth
            variant="outlined"
            label="Default Value"
            defaultValue={value}
          />
        )}
      />
      {error?.message && <ErrorText text={error?.message} />}
    </>
  );
}
