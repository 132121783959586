import { Box, Typography } from '@mui/material';

import { useImportStepData } from '../../../../../../state/survey-form';

export default function PrevImportsWarning() {
  const { assetsNumImported } = useImportStepData();

  return (assetsNumImported || 0) > 0 ? (
    <Box mt={2}>
      <Box border={2} borderColor="error.main" p={2}>
        <Typography
          color="error.main"
          variant="body1"
          align="center"
          fontWeight={700}
        >
          This survey previously had {assetsNumImported} assets imported.
        </Typography>
        <Typography
          color="error.main"
          variant="body1"
          align="center"
          fontWeight={700}
        >
          To avoid duplicates, additional imports should not contain these
          assets.
        </Typography>
      </Box>
    </Box>
  ) : null;
}
