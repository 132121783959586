import 'leaflet/dist/leaflet.css';

import { Box, Button, Checkbox, FormControlLabel } from '@mui/material';
import { useEffect, useState } from 'react';

import AddressForm from '../../../../components/AddressForm/AddressForm';
import PropTypes from 'prop-types';
import { buildingPropTypes } from '../../../../shared/propTypes';

const BuildingForm = ({ building, onSubmit }) => {
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    setChecked(building && !building.address1);
  }, [building]);

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={handleChange}
            name="checkedB"
            color="primary"
          />
        }
        label="Use site address"
      />
      <AddressForm
        type="Building"
        showAddress={!checked}
        formData={building}
        onSubmit={(data) => {
          onSubmit(data, checked);
        }}
        buttons={
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            mt={2}
          >
            <Button variant="contained" color="primary" type="submit">
              Save Building Details
            </Button>
          </Box>
        }
      />
    </>
  );
};

BuildingForm.defaultProps = {
  building: null,
};

BuildingForm.propTypes = {
  building: PropTypes.shape(buildingPropTypes),
  onSubmit: PropTypes.func.isRequired,
};

export default BuildingForm;
