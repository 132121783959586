import 'firebase/firestore';
import 'firebase/functions';

import { BigBatch } from '@qualdesk/firestore-big-batch';
import { SiteProps } from '../types/site';
import firebase from 'firebase/app';

// Get Firestore instance
const db = firebase.firestore();

export const addCountryCodeToAllSites = async () => {
  try {
    // Get all clients from Firestore
    const sitesSnapshot = await db.collectionGroup('sites').get();

    console.log('number of sites', sitesSnapshot.size);

    const batch = new BigBatch({ firestore: db });

    sitesSnapshot.forEach((doc) => {
      const site = doc.data() as SiteProps;
      if (site) {
        batch.update(doc.ref, { countryCode: 'GB' });
      }
    });

    // Commit batched updates to Firestore
    // await batch.commit();
    console.log(`Updated ${sitesSnapshot.size} sites`);
  } catch (error) {
    console.error(error);
  }
};
