import { Box, Grid, TextField, Typography } from '@mui/material';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { EMPTY_ARRAY } from '../../../../../../shared/helpers';
import ErrorText from '../../../../../../components/Form/ErrorText';
import NumberInput from '../../../../../../components/Form/NumberInput';
import Pill from '../../../../../../components/Pill';
import Radio from '../../../../../../components/Form/Radio';
import React from 'react';
import SelectInput from '../../../../../../components/Form/SelectInput';
import SliderWithInput from '../../../../../../components/Form/SliderWithInput';
import { colours } from '../../../../../../config/theme';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {},
  component: {
    justifyContent: 'space-between',
    '& > div': {
      paddingTop: theme.spacing(2),
      borderTop: `1px solid ${colours.grey4}`,
    },
    '&:nth-child(1) > div': {
      borderTop: 'none',
    },
  },
  componentWithBorder: {
    borderTop: `1px solid ${colours.grey4}`,
  },
  topRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
}));

const getComponentExtras = ({ label, value, attributes }) => {
  switch (label) {
    case 'Remaining Life':
      return (
        <Pill
          colour="primary"
          text={`${attributes.expectedLife} YEAR EXPECTED`}
        />
      );
    case 'Quantity':
      return (
        <Box display="flex" flexDirection="column" alignItems="flex-end">
          <Typography variant="caption" color="secondary">
            Renewal Cost
          </Typography>
          <Typography variant="caption" color="primary" fontStyle="italic">
            £
            {(
              (value || 1) *
              attributes.costPerUnit *
              (attributes.uplift + 1)
            ).toFixed(2)}
          </Typography>
        </Box>
      );

    default:
      return null;
  }
};

const NUMERIC_TYPES = ['Number'];

const LinkedFormComponent = ({ index, ...rest }) => {
  const { control } = useFormContext();
  const formField = useWatch({
    control,
    name: `formFields.${index}`,
  });

  return !formField ||
    formField.disabled ||
    formField.hidden ||
    !formField.type ||
    !formField.label ? null : (
    <FormComponent index={index} {...rest} formField={formField} noValidate />
  );
};

const FormComponent = ({ formField, index, attributes, noValidate }) => {
  //fall back to label if id is not present (legacy reasons)
  const identifier = formField.id || formField.label;
  const classes = useStyles();
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext();
  const error = errors?.[identifier];
  let { min = 0, max = 9999999999, default: defaultValue } = formField;
  const value = useWatch({ control, name: identifier });
  const required = noValidate ? false : formField.required;
  let pattern = { value: /.*/, message: 'Please enter a valid value' };

  if (formField.label === 'Remaining Life') {
    min = 0;
    max = attributes.expectedLife;
    defaultValue = defaultValue || attributes.expectedLife;
  }

  let InnerComponent;
  switch (formField.type) {
    case 'Slider':
      InnerComponent = (
        <SliderWithInput
          min={Number(min)}
          max={Number(max)}
          defaultValue={Number(defaultValue)}
          unit=""
        />
      );
      break;

    case 'Barcode':
      InnerComponent = (
        <TextField
          placeholder={formField.label}
          type="text"
          variant="outlined"
          fullWidth
          minRows={4}
        />
      );
      break;
    case 'Number':
      InnerComponent = (
        <NumberInput defaultValue={Number(defaultValue)} min={min} max={max} />
      );
      break;
    case 'Decimal':
      pattern = {
        value: /^\d*\.?\d*$/g,
        message: 'Please enter a valid decimal',
      };
      InnerComponent = (
        <TextField
          variant="outlined"
          placeholder={formField.label}
          fullWidth
          value={defaultValue}
        />
      );
      break;
    case 'Radio':
      InnerComponent = (
        <Radio
          defaultValue={defaultValue}
          options={formField.options || EMPTY_ARRAY}
        />
      );
      break;
    case 'Select':
      InnerComponent = (
        <SelectInput
          defaultValue={defaultValue}
          options={formField.options || EMPTY_ARRAY}
        />
      );
      break;
    case 'Text':
    default:
      InnerComponent = (
        <TextField
          variant="outlined"
          placeholder={formField.label}
          multiline
          fullWidth
          minRows={2}
          value={defaultValue}
        />
      );
      break;
  }

  return (
    <Grid key={index} item xs={12} className={classes.component}>
      <Box>
        <Box className={classes.topRow}>
          <Typography>{formField.label}</Typography>
          {getComponentExtras({
            label: formField.label,
            value: value || 0,
            attributes,
          })}
        </Box>
        <Controller
          render={({ field, onChange }) =>
            React.cloneElement(InnerComponent, {
              ...field,
              onChange: (event) => {
                // need to check whether the event is actually an event or a number
                // it will be a number if coming from custom counter component
                // and an event if coming from the native textfield
                const parsedValue =
                  typeof event === 'number' || typeof event === 'string'
                    ? event
                    : event?.target?.value;

                if (NUMERIC_TYPES.includes(formField.type)) {
                  field.onChange(
                    parsedValue === '' ? undefined : Number(parsedValue),
                  );
                } else {
                  field.onChange(parsedValue);
                }
              },
            })
          }
          name={formField.id || formField.label}
          control={control}
          rules={{
            required,
            pattern,
          }}
        />
        {error && <ErrorText text={error.message} />}
      </Box>
    </Grid>
  );
};

const FormFields = ({ formFields, attributes, linked }) => {
  return formFields.map((field, index) => {
    return linked ? (
      <LinkedFormComponent
        formField={field}
        index={index}
        attributes={attributes}
        key={field.id}
      />
    ) : (
      <FormComponent
        formField={field}
        index={index}
        attributes={attributes}
        key={field.id}
      />
    );
  });
};

export default FormFields;
