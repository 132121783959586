import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  basePath: null,
  loading: false,
  loadingMessage: '',
  hideBusyBar: localStorage.getItem('hideBusyBar') || '',
  navOpen: false,
  snack: {},
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = !!action.payload?.loadingMessage;
      state.loadingMessage = action.payload?.loadingMessage || '';
    },
    setNavOpen(state, action) {
      state.navOpen = action.payload.open;
    },
    setSnackMessage(state, action) {
      state.snack = action.payload;
    },
    setHideBusyBar(state, action) {
      localStorage.setItem('hideBusyBar', action.payload);
      state.hideBusyBar = action.payload;
    },
  },
});

export default uiSlice.reducer;

export const { setLoading, setNavOpen, setSnackMessage, setHideBusyBar } =
  uiSlice.actions;
