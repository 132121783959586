import { AddEditRoomProps, RoomProps } from '../types/room';
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Dialog,
  FormControl,
  Grid,
  Stack,
  TextField,
} from '@mui/material';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useEffect, useRef, useState } from 'react';

import CardTitle from '../routes/client-portal/surveys/edit/asset/components/CardTitle';
import ErrorText from './Form/ErrorText';
import ImageGalleryGrid from './ImageGalleryGrid';
import { ImageProps } from '../types/image';
import NextButton from './Form/NextButton';
import { colours } from '../config/theme';

type AddOrEditRoomDialogProps = {
  onSubmit: (room: Partial<RoomProps>) => void;
  floors: string[];
  room: Partial<RoomProps> | undefined;
  onClose: () => void;
};

const DEFAULT_VALUES: AddEditRoomProps = {
  name: '',
  floor: '',
  areaWall: 0,
  areaFloor: 0,
  notes: '',
};

const AddOrEditRoomDialog = ({
  floors,
  onSubmit,
  room,
  onClose,
}: AddOrEditRoomDialogProps) => {
  const {
    reset,
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({ defaultValues: room || DEFAULT_VALUES });

  const floor = useWatch({ name: 'floor', control });

  const areaFloorWatcher = useWatch({ control, name: 'areaFloor' });

  const updatedImagesRef = useRef<ImageProps[] | undefined>();
  const updatedImages360Ref = useRef<ImageProps[] | undefined>();

  useEffect(() => {
    if (!areaFloorWatcher) {
      return;
    }
    const areaWall = Math.sqrt(Number(areaFloorWatcher)) * 2.4 * 4;
    setValue('areaWall', parseFloat(areaWall.toFixed(2)), {
      shouldDirty: true,
      shouldValidate: true,
    });
  }, [areaFloorWatcher]);

  const handleDialogClose = () => {
    onClose?.();
  };

  useEffect(() => {
    if (room) {
      reset(room);
    } else {
      reset(DEFAULT_VALUES);
    }
    updatedImagesRef.current = room?.images || [];
    updatedImages360Ref.current = room?.images360 || [];
  }, [room]);

  return (
    <form
      onSubmit={handleSubmit((data) => {
        onSubmit({
          ...data,
          images: updatedImagesRef.current,
          images360: updatedImages360Ref.current,
        });
        handleDialogClose();
      })}
    >
      <Dialog
        fullScreen={false}
        open={!!room}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form>
          <CardTitle text={room ? `Edit Room` : 'Add a Room'} />
          <Box
            p={2}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            borderBottom={`1px solid ${colours.grey5}`}
          >
            <Stack alignItems="center" mb={2}>
              <ImageGalleryGrid
                images={room?.images}
                onUpdate={(updatedImages) => {
                  updatedImagesRef.current = updatedImages;
                }}
              />
            </Stack>
            <Stack mb={2} alignItems="center">
              <ImageGalleryGrid
                prompt="Add a 360 image"
                imageLimit={1}
                images={room?.images360}
                onUpdate={(updatedImages) => {
                  updatedImages360Ref.current = updatedImages;
                }}
              />
            </Stack>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      autoComplete="off"
                      fullWidth
                      label="Room Name"
                      variant="outlined"
                      error={!!errors.name}
                    />
                  )}
                  control={control}
                  name="name"
                  rules={{
                    required: true,
                  }}
                />
                {errors.name && <ErrorText />}
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={!!errors.floor}
                >
                  <Controller
                    control={control}
                    name="floor"
                    rules={{
                      required: true,
                    }}
                    render={({ field: { onChange } }) => (
                      <Autocomplete
                        onChange={(event, data) => {
                          onChange(data);
                        }}
                        onInputChange={(event, value) => {
                          onChange(value);
                        }}
                        freeSolo
                        getOptionLabel={(option) => option}
                        options={floors}
                        value={floor}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Floor"
                            variant="outlined"
                            error={!!errors.floor}
                          />
                        )}
                      />
                    )}
                  />
                </FormControl>
                {errors.floor && <ErrorText />}
              </Grid>

              <Grid item xs={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      autoComplete="off"
                      fullWidth
                      label="Area (floor)"
                      variant="outlined"
                      error={!!errors.areaFloor}
                    />
                  )}
                  control={control}
                  name="areaFloor"
                  rules={{
                    required: true,
                  }}
                />
                {errors.areaFloor && <ErrorText />}
              </Grid>
              <Grid item xs={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      autoComplete="off"
                      fullWidth
                      label="Area (wall)"
                      variant="outlined"
                      error={!!errors.areaFloor}
                    />
                  )}
                  control={control}
                  name="areaWall"
                  rules={{
                    required: true,
                  }}
                />
                {errors.areaWall && <ErrorText />}
              </Grid>
              <Grid item xs={12}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      {...field}
                      autoComplete="off"
                      fullWidth
                      multiline
                      label="Notes"
                      variant="outlined"
                      error={!!errors.notes}
                    />
                  )}
                  control={control}
                  name="notes"
                  rules={{
                    required: false,
                  }}
                />
                {errors.notes && <ErrorText />}
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" flex={1} justifyContent="space-between">
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleDialogClose}
                  >
                    Cancel
                  </Button>
                  <NextButton text="Save Room" />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Dialog>
    </form>
  );
};

export default AddOrEditRoomDialog;
