import { MenuItem, Select } from '@mui/material';
import { forwardRef, useEffect, useState } from 'react';

import { makeStyles } from '@mui/styles';

const usePlaceholderStyles = makeStyles((theme) => ({
  placeholder: {
    color: '#aaa',
  },
}));

const Placeholder = ({ children }) => {
  const classes = usePlaceholderStyles();
  return <div className={classes.placeholder}>{children}</div>;
};

const SelectInput = forwardRef(
  ({ defaultValue, value, onChange, options = [], id }, ref) => {
    const [val, setVal] = useState(String(defaultValue) || '');
    useEffect(() => {
      if (typeof value === 'string') {
        setVal(value);
      } else if (typeof defaultValue === 'string') {
        setVal(defaultValue);
      }
    }, [defaultValue, value]);

    return (
      <Select
        id={id}
        fullWidth
        value={val}
        onChange={onChange}
        displayEmpty
        renderValue={
          val !== ''
            ? undefined
            : () => <Placeholder>Select an option...</Placeholder>
        }
        sx={{ textAlign: 'center' }}
      >
        <MenuItem value="" sx={{ color: 'lightGray' }}>
          <em>&nbsp;</em>
        </MenuItem>
        {options?.map((option) => {
          return (
            <MenuItem key={option.value.trim()} value={option.value.trim()}>
              {option.value}
            </MenuItem>
          );
        })}
      </Select>
    );
  },
);

SelectInput.defaultProps = {};

SelectInput.propTypes = {};

SelectInput.displayName = 'SelectInput';

export default SelectInput;
