import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const DebugOnly = ({ children }) => {
  if (window.location.hostname.indexOf('asseticom.co.uk') !== -1) {
    return null;
  }
  return <Box mb={2}>{children}</Box>;
};

DebugOnly.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default DebugOnly;
