import { Box, Chip, Divider, Grid, Stack, Typography } from '@mui/material';

import { AppState } from '../../../../../config/store';
import BackButton from '../../../../../components/Form/BackButton';
import ButtonsContainer from '../../../../../components/Form/ButtonsContainer';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import NextButton from '../../../../../components/Form/NextButton';
import PropTypes from 'prop-types';
import { StepperStepProps } from '../../../../../types/form';
import { useAssetList } from '../../../../../hooks/useAssetList';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

const Detail = ({ title, value }) => {
  return value ? (
    <Grid item xs={12} sm={6}>
      <Box mr={1} mb={2}>
        <Typography align="center" variant="subtitle1">
          {title}
        </Typography>
        <Typography
          variant="body1"
          color="primary"
          align="center"
          display="block"
        >
          {value}
        </Typography>
      </Box>
    </Grid>
  ) : null;
};

Detail.defaultProps = {
  value: '',
};

Detail.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const Confirm = ({
  onSubmit,
  onBack,
}: Pick<StepperStepProps, 'onBack' | 'onSubmit'>) => {
  const { handleSubmit } = useForm();

  const surveyData = useSelector((state: AppState) => state.surveyForm);

  const assetList = useAssetList[surveyData.assetListId];

  const floorPlansElement = surveyData.floorPlans?.length ? (
    <>
      <Box my={2}>
        <Divider variant="middle">
          <Chip variant="outlined" label="Floor Plans" color="primary" />
        </Divider>
      </Box>
      {surveyData.floorPlans
        .filter((fp) => fp.url || fp.data)
        .map((fp, index) => {
          return (
            <Box key={fp.floor}>
              <Stack alignItems="center">
                <Grid container maxWidth={640}>
                  <Grid item container xs={12}>
                    <Detail title="Floor" value={fp.floor} />
                    <Detail title="File name" value={fp.fileName} />
                    <Detail
                      title="Number of room markers"
                      value={fp.markers?.length}
                    />
                  </Grid>
                </Grid>
              </Stack>
              {surveyData.floorPlans?.length &&
              index < surveyData.floorPlans?.length - 2 ? (
                <Box mb={2} px={6}>
                  <Divider variant="middle" />
                </Box>
              ) : null}
            </Box>
          );
        })}
    </>
  ) : null;

  return (
    <Box p={2}>
      <ErrorBoundary>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box my={2}>
            <Divider variant="middle">
              <Chip variant="outlined" label="Overview" color="primary" />
            </Divider>
          </Box>
          <Stack alignItems="center">
            <Grid container maxWidth={640}>
              <Grid item container xs={12}>
                <Detail title="Survey Name" value={surveyData.name} />
                <Detail title="Site" value={surveyData.siteName} />
                <Detail title="Building" value={surveyData.buildingName} />
                <Detail title="GIFA" value={surveyData.gifa} />
                <Detail title="External" value={surveyData.external} />
                <Detail title="Survey Type" value={surveyData.surveyType} />
                <Detail title="Surveyor Name" value={surveyData.surveyorName} />
                <Detail
                  title="Surveyor Email"
                  value={surveyData.surveyorEmail}
                />
              </Grid>
            </Grid>
          </Stack>
          {surveyData.importData?.rows && (
            <>
              <Box my={2}>
                <Divider variant="middle">
                  <Chip
                    variant="outlined"
                    label="Data Import"
                    color="primary"
                  />
                </Divider>
              </Box>
              <Stack alignItems="center">
                <Grid container maxWidth={640}>
                  <Grid item container xs={12}>
                    <Detail
                      title="Number of assets"
                      value={surveyData.importData?.rows.length}
                    />
                  </Grid>
                </Grid>
              </Stack>
            </>
          )}

          {surveyData.roomSchedule ? (
            <>
              <Box my={2}>
                <Divider variant="middle">
                  <Chip
                    variant="outlined"
                    label="Room Schedule"
                    color="primary"
                  />
                </Divider>
              </Box>
              <Stack alignItems="center">
                <Grid container maxWidth={640}>
                  <Grid item container xs={12}>
                    <Detail
                      title="File name"
                      value={surveyData.roomSchedule?.fileName}
                    />
                    <Detail
                      title="Number of rooms"
                      value={surveyData.roomSchedule?.data?.length}
                    />
                    <Detail
                      title="Floors"
                      value={surveyData.roomSchedule?.floors?.length}
                    />
                  </Grid>
                </Grid>
              </Stack>
            </>
          ) : null}
          {floorPlansElement}

          <>
            <Box my={2}>
              <Divider variant="middle">
                <Chip
                  variant="outlined"
                  label="Asset Taxonomy"
                  color="primary"
                />
              </Divider>
            </Box>
            <Stack alignItems="center">
              <Grid container maxWidth={640}>
                <Grid item container xs={12}>
                  <Detail title="List name" value={assetList?.name} />
                  <Detail title="Number of assets" value={assetList?.numRows} />
                </Grid>
              </Grid>
            </Stack>
          </>

          <Box my={2}>
            <Divider variant="middle">
              <Chip variant="outlined" label="Form Fields" color="primary" />
            </Divider>
          </Box>

          <Stack alignItems="center">
            <Grid container maxWidth={640}>
              <Grid item container xs={12}>
                {surveyData.formFields.map((field) => (
                  <Detail
                    key={`${field.label}_${field.type}`}
                    title={`Type: ${field.type}`}
                    value={`Label: ${field.label}`}
                  />
                ))}
              </Grid>
            </Grid>
          </Stack>

          <ButtonsContainer>
            <BackButton onBack={onBack} />
            <NextButton text="confirm" />
          </ButtonsContainer>
        </form>
      </ErrorBoundary>
    </Box>
  );
};

export default Confirm;
