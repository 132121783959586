import { Box } from '@mui/material';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import View from './view';
import Add from './add';
import Edit from './edit';
import { ROUTES_MAP } from '../../../config/routes';
import RouteGuard from '../../../components/RouteGuard';

const Sites = () => {
  return (
    <Box>
      <Switch>
        <RouteGuard {...ROUTES_MAP.SITES_ADD}>
          <Add />
        </RouteGuard>
        <RouteGuard {...ROUTES_MAP.SITES_EDIT}>
          <Edit />
        </RouteGuard>
        <RouteGuard {...ROUTES_MAP.SITES}>
          <View />
        </RouteGuard>
      </Switch>
    </Box>
  );
};

export default Sites;
