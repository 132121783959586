import { FieldErrors } from 'react-hook-form';
import { useEffect } from 'react';

export default function useHookFormErrorHandler(
  errors: FieldErrors,
  isSubmitting: boolean,
) {
  useEffect(() => {
    if (Object.keys(errors).length === 0) {
      return;
    }
    const [name, error] = Object.entries(errors)[0];

    if (name && error) {
      const elem = document.querySelector<HTMLInputElement>(`[name=${name}]`);

      if (elem) {
        elem.scrollIntoView({ behavior: 'smooth', block: 'center' });
        try {
          elem.focus({ preventScroll: true });
        } catch (e) {
          console.error(e);
        }
      }
    }
  }, [errors, isSubmitting]);
}
