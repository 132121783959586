import 'leaflet/dist/leaflet.css';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';

import ErrorBoundary from '../../../../components/ErrorBoundary';
import ErrorText from '../../../../components/Form/ErrorText';
import PropTypes from 'prop-types';
import { ROLES_ARRAY_PORTAL } from '../../../../config/roles';
import { checkForUserByEmail } from '../../../../state/user';
import { emailRegex } from '../../../../shared/utilities';
import { useDispatch } from 'react-redux';
import { userDataPropTypes } from '../../../../shared/propTypes';

const defaultValues = {
  nameFirst: '',
  nameLast: '',
  email: '',
  role: null,
};

const UserForm = ({ formData, onSubmit, editMode }) => {
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: 'onBlur',
  });
  const dispatch = useDispatch();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [existingUser, setExistingUser] = useState();

  useEffect(() => {
    reset({ ...defaultValues, ...formData });
  }, [formData, reset]);

  const checkIfUserExists = async (email) => {
    if (editMode) {
      return true;
    }
    const user = await dispatch(checkForUserByEmail({ email }));
    if (user?.exists) {
      setDialogOpen(true);
      setExistingUser(user.data());
    } else {
      setDialogOpen(false);
      setExistingUser(null);
    }
    return true;
  };

  const handleAddExistingUser = () => {
    const { email, nameFirst, nameLast } = existingUser;
    reset({ ...defaultValues, email, nameFirst, nameLast });
    setDialogOpen(false);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setExistingUser(null);
    reset({ ...defaultValues, ...formData });
  };

  const beforeSubmit = (data) => {
    if (editMode) {
      onSubmit(data);
    } else {
      setDialogOpen(false);
      setExistingUser(null);
      reset({ ...defaultValues });
      onSubmit(data);
    }
  };

  return (
    <Paper elevation={2} id="main-content">
      <Box p={2}>
        <ErrorBoundary>
          <form onSubmit={handleSubmit(beforeSubmit)} autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                    pattern: {
                      value: emailRegex,
                      message: 'Enter a valid e-mail address',
                    },
                    validate: {
                      exists: checkIfUserExists,
                    },
                  }}
                  name="email"
                  render={({ field }) => (
                    <TextField
                      autoComplete="off"
                      inputProps={{
                        autocomplete: 'no',
                      }}
                      {...field}
                      margin="normal"
                      label="Email Address"
                      variant="outlined"
                      type="email"
                      fullWidth
                      error={!!errors.email}
                      disabled={editMode}
                    />
                  )}
                />
                {errors.email && <ErrorText text={errors.email.message} />}
              </Grid>

              <Grid item xs={12} sm={4}>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  name="nameFirst"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      margin="normal"
                      label="First Name"
                      variant="outlined"
                      error={!!errors.nameFirst}
                      disabled={!!(existingUser || editMode)}
                    />
                  )}
                />
                {errors.nameFirst && <ErrorText />}
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  name="nameLast"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      margin="normal"
                      label="Last Name"
                      variant="outlined"
                      disabled={!!(existingUser || editMode)}
                      error={!!errors.nameLast}
                    />
                  )}
                />
                {errors.nameLast && <ErrorText />}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Box mt={2}>
                <FormControl
                  variant="standard"
                  component="fieldset"
                  error={!!errors.role}
                  // disabled={formData?.role === ALL_ROLES.ADMIN}
                >
                  <Box mb={1}>
                    <FormLabel component="legend">User Role</FormLabel>
                  </Box>
                  <Controller
                    control={control}
                    rules={{ required: true }}
                    name="role"
                    render={({ field }) => (
                      <RadioGroup aria-label="User role" row {...field}>
                        {ROLES_ARRAY_PORTAL.map((role) => {
                          return (
                            <FormControlLabel
                              key={role.level}
                              value={role.level}
                              control={<Radio />}
                              label={role.typePretty}
                            />
                          );
                        })}
                      </RadioGroup>
                    )}
                  />
                </FormControl>
                {errors.role && <ErrorText />}
              </Box>
            </Grid>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              mt={2}
            >
              <Button variant="contained" color="primary" type="submit">
                {editMode ? 'Update' : 'Add'} User
              </Button>
            </Box>
          </form>
        </ErrorBoundary>
      </Box>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">User already exists</DialogTitle>
        <DialogContent>
          {existingUser && (
            <Typography>
              There is already an Asseticom user with the email address{' '}
              <b>{existingUser.email}</b>
            </Typography>
          )}
          <br />
          <Typography>Would you like to add them?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            No
          </Button>
          <Button onClick={handleAddExistingUser} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

UserForm.defaultProps = { formData: null, editMode: false };

UserForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  formData: PropTypes.shape(userDataPropTypes),
  editMode: PropTypes.bool,
};

export default UserForm;
