import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  Select,
  Typography,
} from '@mui/material';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import {
  assetListIdSelector,
  setFormData,
  useFormFieldMerger,
} from '../../../../../state/survey-form';
import { useEffect, useMemo } from 'react';

import BackButton from '../../../../../components/Form/BackButton';
import ButtonsContainer from '../../../../../components/Form/ButtonsContainer';
import { EMPTY_ARRAY } from '../../../../../shared/helpers';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import ErrorText from '../../../../../components/Form/ErrorText';
import NextButton from '../../../../../components/Form/NextButton';
import { SurveyProps } from '../../../../../types/survey';
import Table from '../../../../../components/Table';
import { UrlParams } from '../../../../../types/urlParams';
import { useAppDispatch } from '../../../../../config/store';
import { useAssetList } from '../../../../../hooks/useAssetList';
import { useAssetListsOnce } from '../../../../../hooks/useAssetListsOnce';
import useLeaveHandler from '../../hooks/useLeaveHandler';
import { useModal } from '../../../../../components/Modal';
import { useSelector } from 'react-redux';

const columns = [
  { accessorKey: 'code3', header: 'Code' },
  { accessorKey: 'label3', header: 'Label' },
  { accessorKey: 'uom', header: 'Unit Of Measure' },
  { accessorKey: 'costPerUnit', header: 'Cost' },
  { accessorKey: 'expectedLife', header: 'Expected Life' },
  { accessorKey: 'uplift', header: 'Uplift' },
];

const DEFAULT_ROWS_PER_PAGE = 10;

type AssetListProps = {
  onSubmit: (data: Partial<SurveyProps>) => void;
  onBack: () => void;
  onLeave: (data: Partial<SurveyProps>) => void;
};

const AssetList = ({ onSubmit, onBack, onLeave }: AssetListProps) => {
  const clientId = useParams<UrlParams>().clientId;
  const { showModal } = useModal();
  const assetLists = useAssetListsOnce(clientId);
  const assetListId = useSelector(assetListIdSelector);
  const assetList = useAssetList(assetListId);
  const { mergeFormFields } = useFormFieldMerger();

  const { handleSubmit, control, reset, getValues } = useForm({
    defaultValues: { assetListId },
  });
  const { errors } = useFormState({
    control,
  });
  useLeaveHandler(onLeave, getValues);

  const dispatch = useAppDispatch();

  const rows = useMemo(
    () =>
      assetList?.map((asset) => {
        const { label3, code3, uom, costPerUnit, expectedLife, uplift } = asset;
        return {
          label3,
          code3,
          uom,
          costPerUnit,
          expectedLife,
          uplift,
          id: code3,
        };
      }) || EMPTY_ARRAY,
    [assetList],
  );

  useEffect(() => {
    reset({ assetListId });
  }, [assetListId]);

  const confirmListChange = async (event) => {
    const listId = event.target.value;

    if (!listId) {
      return dispatch(setFormData({ assetListId: '' }));
    }

    if (!assetListId) {
      // if there was previously no asset list, just set the new one
      return mergeFormFields(listId);
    }

    if (listId !== assetListId) {
      const changeConfirmed = await showModal({
        title: 'Asset Taxonomy Change',
        buttons: {
          confirm: [{ text: 'Yes', value: true }],
          cancel: [{ text: 'No', value: false }],
        },
        messages: [
          `WARNING: Changing this asset taxonomy may reset some form niche fields settings. Assets in both the previous and selected taxonomies with the same Level 3 code will not be reset. Do you want to continue?`,
        ],
      });
      changeConfirmed && mergeFormFields(listId);
    }
  };

  return (
    <Box p={2}>
      <ErrorBoundary>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {assetLists?.length ? (
                <FormControl
                  variant="outlined"
                  fullWidth
                  error={!!errors.assetListId}
                >
                  <InputLabel htmlFor="assetList">Asset Taxonomy</InputLabel>
                  <Controller
                    rules={{
                      required: true,
                    }}
                    name="assetListId"
                    control={control}
                    render={({ field }) => (
                      <Select
                        native
                        variant="outlined"
                        label="Asset Taxonomy"
                        fullWidth
                        {...field}
                        onChange={confirmListChange}
                      >
                        <option key={0} value="" aria-label="empty" />
                        {assetLists?.map((list) => (
                          <option key={list.id} value={list.id}>
                            {`${list.name} - (${list.numRows} assets)`}
                          </option>
                        ))}
                      </Select>
                    )}
                  />
                </FormControl>
              ) : (
                <Typography color="secondary" align="center">
                  You have not added any asset taxonomies, please{' '}
                  <Link to={`/${clientId}/asset-taxonomies/add`}>
                    click here
                  </Link>{' '}
                  to upload
                </Typography>
              )}
              {errors.assetListId && <ErrorText />}
            </Grid>
          </Grid>

          {rows.length ? (
            <Box mt={2}>
              <Table
                rows={rows}
                columns={columns}
                rowsPerPage={DEFAULT_ROWS_PER_PAGE}
                disableSearchParams
              />
            </Box>
          ) : null}

          <ButtonsContainer>
            <BackButton onBack={onBack} />
            <NextButton disabled={!assetListId} />
          </ButtonsContainer>
        </form>
      </ErrorBoundary>
    </Box>
  );
};

export default AssetList;
