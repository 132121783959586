import 'react-color-palette/css';

import {
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useForm, useWatch } from 'react-hook-form';

import { DatePicker } from '@mui/x-date-pickers';
import ErrorText from '../../../../../../components/Form/ErrorText';
import FileUploaderCard from '../../../../../../components/FileUploaderCard';
import { readURLAsData } from '../../../../../../shared/utilities';
import useHookFormErrorHandler from '../../../../../../hooks/useFormErrorHandler';

export type FormData = {
  organisationLogo: string | undefined;
  clientLogo: string | undefined;
  coverImage: string | undefined;
  reportTitle: string;
  reportSubtitle: string;
  reportDate: string;
  themeColour: string;
};

interface FrontPageStep {
  defaultValues: Partial<FormData>;
  handleNext: (data: FormData) => void;
  handleBack: (data: Partial<FormData>) => void;
}

export default function FrontPageStep({
  defaultValues,
  handleBack,
  handleNext,
}: FrontPageStep) {
  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    register,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({ defaultValues });

  const organisationLogo = useWatch({ control, name: 'organisationLogo' });
  const clientLogo = useWatch({ control, name: 'clientLogo' });
  const coverImage = useWatch({ control, name: 'coverImage' });
  // const themeColour = useWatch({ control, name: 'themeColour' });

  useHookFormErrorHandler(errors, isSubmitting);

  const setOrganisationLogo = async (file) => {
    const fileData = await readURLAsData(file);
    if (fileData) {
      register('organisationLogo', { required: false });
      setValue('organisationLogo', fileData);
    }
  };

  const setClientLogo = async (file) => {
    const fileData = await readURLAsData(file);
    if (fileData) {
      register('clientLogo', { required: false });
      setValue('clientLogo', fileData);
    }
  };

  const setCoverImage = async (file) => {
    const fileData = await readURLAsData(file);
    if (fileData) {
      register('coverImage', { required: false });
      setValue('coverImage', fileData);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleNext)}>
      <Box py={2}>
        <Stack spacing={2} alignItems="center">
          <Typography variant="h5">Front cover settings</Typography>
          <Divider flexItem />
          <Chip variant="outlined" label="Images" color="primary" />
          <Stack
            direction={'row'}
            alignItems="center"
            justifyContent="center"
            flexWrap={'wrap'}
          >
            <Box m={1} width={300}>
              <FileUploaderCard
                title="Company Logo"
                onChange={setOrganisationLogo}
                fileUrl={organisationLogo}
              />
            </Box>
            <Box m={1} width={300}>
              <FileUploaderCard
                title="Client Logo"
                onChange={setClientLogo}
                fileUrl={clientLogo}
              />
            </Box>
            <Box m={1} width={300}>
              <FileUploaderCard
                title="Cover Image"
                onChange={setCoverImage}
                fileUrl={coverImage}
              />
            </Box>
          </Stack>
          <Divider flexItem />
          <Chip variant="outlined" label="Text Settings" color="primary" />
          <Stack
            spacing={2}
            direction="column"
            maxWidth="md"
            width="100%"
            alignItems="flex-start"
            justifyContent="center"
          >
            <Box sx={{ width: '100%' }}>
              <Controller
                control={control}
                name="reportTitle"
                defaultValue=""
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    {...field}
                    autoComplete="off"
                    label="Report Title"
                    variant="outlined"
                    error={!!errors.reportTitle}
                  />
                )}
              />
              {errors.reportTitle && <ErrorText />}
            </Box>
            <Box sx={{ width: '100%' }}>
              <Controller
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    autoComplete="off"
                    label="Report Subtitle"
                    variant="outlined"
                    error={!!errors.reportSubtitle}
                  />
                )}
                control={control}
                name="reportSubtitle"
                rules={{
                  required: false,
                }}
              />
            </Box>
            <Box sx={{ width: '100%' }}>
              <FormControl
                variant="outlined"
                fullWidth
                error={!!errors.reportDate}
              >
                <Controller
                  control={control}
                  name="reportDate"
                  rules={{
                    required: true,
                  }}
                  render={({ field }) => {
                    return (
                      <DatePicker
                        value={field.value || ''}
                        onChange={field.onChange}
                        label="Report Date"
                        slotProps={{
                          textField: {
                            ...field,
                            error: !!errors.reportDate,
                            helperText: errors.reportDate ? '' : 'MM/DD/YYYY',
                          },
                        }}
                      />
                    );
                  }}
                />
                {errors.reportDate && <ErrorText />}
              </FormControl>
            </Box>
          </Stack>

          {/* <Divider flexItem />
          <Chip variant="outlined" label="Theme Colour" color="primary" />
          <FormColourPicker
            defaultColour={themeColour || theme.palette.primary.main}
            onChange={(colour) => {
              setValue('themeColour', colour);
            }}
          /> */}
        </Stack>
        <Stack
          direction={'row'}
          spacing={2}
          justifyContent="space-between"
          mt={2}
        >
          <Button variant="outlined" onClick={() => handleBack(getValues())}>
            Back
          </Button>
          <Button variant="contained" type="submit">
            Next
          </Button>
        </Stack>
      </Box>
    </form>
  );
}
