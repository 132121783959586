import {
  Box,
  ButtonBase,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { forwardRef, useEffect, useState } from 'react';

const IncrementButton = ({ text, onClick }) => {
  return (
    <ButtonBase
      component="div"
      onClick={onClick}
      sx={{
        width: 40,
        height: 40,
        border: '1px solid lightgray',
        borderRadius: 1,
        boxShadow: '0px 2px 7px 3px rgba(0,0,0,0.05)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '&:hover': {
          border: '1px solid black',
        },
        '&:active': {
          opacity: 0.25,
        },
      }}
    >
      <Typography sx={{ color: 'gray' }}>{text}</Typography>
    </ButtonBase>
  );
};

const NumberInput = forwardRef(
  (
    { defaultValue, value, step = 1, min = 0, max = 99999999, onChange },
    ref,
  ) => {
    const [count, setCount] = useState(Number(defaultValue) || 1);
    const [prevVal, setPrevVal] = useState(value);
    const increment = step || 1;
    const theme = useTheme();
    const updateCount = (deduct) => {
      const nextValue = count + (deduct ? -increment : increment);
      if (isNaN(nextValue)) {
        return;
      }
      const clamped = Math.min(Math.max(nextValue, min), max);
      setCount(clamped);
      if (typeof onChange === 'function') {
        onChange(clamped);
      }
    };
    const onChangeText = ({ target: { value: text } }) => {
      if (!text) {
        return setCount(prevVal);
      }
      const pMin = Math.max(Number(text.replace(/[^0-9]/g, '')), min || 0);
      const pMax = max ? Math.min(pMin, max) : pMin;

      setCount(pMax);

      if (typeof onChange === 'function') {
        onChange(pMax);
      }
      return true;
    };
    useEffect(() => {
      if (typeof value === 'number') {
        setCount(value);
      } else if (typeof defaultValue === 'number') {
        setCount(defaultValue);
      }
    }, [defaultValue, value]);

    return (
      <Box display="flex" alignItems="center" justifyContent="space-evenly">
        <IncrementButton text="-" onClick={() => updateCount(true)} />

        <TextField
          onInput={onChangeText}
          type="number"
          value={count}
          color="primary"
          ref={ref}
          sx={{
            border: 'none',
            borderRadius: 1,
            maxWidth: 100,

            '& .MuiInputBase-input': {
              fontStyle: 'italic',
              textAlign: 'center',

              borderRadius: 1,
              border: '1px solid lightgray',
              height: 40,
              padding: 0,
              color: theme.palette.primary.main,
              '&:hover': {
                border: '1px solid red',
              },
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: '1px solid lightgray',
              // visibility: 'hidden',
            },
          }}
        />

        <IncrementButton text="+" onClick={() => updateCount(false)} />
      </Box>
    );
  },
);

NumberInput.displayName = 'SelectInput';

NumberInput.defaultProps = {};

NumberInput.propTypes = {};

export default NumberInput;
