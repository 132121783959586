import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

// const useStyles = makeStyles((theme) => ({
//   errorText: (props) => ({
//     color: props.colour,
//     marginTop: theme.spacing(2),
//     marginBottom: theme.spacing(0.25),
//   }),
// }));

type ErrorTextProps = {
  text?: string;
  id?: string;
};

const ErrorText = ({ text, id }: ErrorTextProps) => {
  // const classes = useStyles({ colour: colours.red });
  return (
    <Typography
      variant="caption"
      color="error"
      role="alert"
      whiteSpace="pre-wrap"
    >
      {text || 'This field is required'}
    </Typography>
  );
};

ErrorText.propTypes = {
  text: PropTypes.string,
};

ErrorText.defaultProps = {
  text: '',
};

export default ErrorText;
