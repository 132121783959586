import Add from './add';
import { Box } from '@mui/material';
import Edit from './edit';
import { ROUTES_MAP } from '../../../config/routes';
import React from 'react';
import RouteGuard from '../../../components/RouteGuard';
import { Switch } from 'react-router-dom';
import View from './view';

const Users = () => {
  return (
    <Box>
      <Switch>
        <RouteGuard {...ROUTES_MAP.USERS_ADD}>
          <Add />
        </RouteGuard>
        <RouteGuard {...ROUTES_MAP.USERS_USER}>
          <Edit />
        </RouteGuard>
        <RouteGuard {...ROUTES_MAP.USERS}>
          <View />
        </RouteGuard>
      </Switch>
    </Box>
  );
};

export default Users;
