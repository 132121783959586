import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  Select,
  TextField,
} from '@mui/material';
import {
  clientIdRegex,
  companyNumberRegex,
  emailRegex,
  refAdapter,
} from '../../../shared/utilities';
import { useFirestore, useFirestoreConnect } from 'react-redux-firebase';

import AwesomeDebouncePromise from 'awesome-debounce-promise';
import BackButton from '../../../components/Form/BackButton';
import ButtonsContainer from '../../../components/Form/ButtonsContainer';
import { EMPTY_ARRAY } from '../../../shared/helpers';
import ErrorText from '../../../components/Form/ErrorText';
import NextButton from '../../../components/Form/NextButton';
import PropTypes from 'prop-types';
import { clientPropTypes } from '../../../shared/propTypes';
import { colours } from '../../../config/theme';
import { makeStyles } from '@mui/styles';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  selectHolder: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      '& + &': {
        marginTop: 0,
      },
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(2),
    },
    '& .MuiFormControl-root': {
      marginBottom: theme.spacing(1),
    },
  },
  urlPrefix: {
    '&& .MuiInputAdornment-root': {
      marginRight: 0,
    },
    '&& .MuiInputAdornment-root .MuiTypography-root': {
      color: colours.brand1,
    },
  },
}));

const categories = [
  'Education',
  'Healthcare',
  'Defence',
  'Accommodation',
  'Retail',
  'Manufacturing',
  'Local Government',
  'Central Government',
  'Other',
];

const debugDefaults = {
  address1: 'test',
  address2: 'test',
  backgroundImage: 'test',
  bankAccountNumber: 'test',
  bankName: 'test',
  bankSortCode: 'test',
  category: 'Healthcare',
  companyName: 'test',
  companyNumber: 'test',
  county: 'test',
  keyContactEmail: 'test@test.com',
  keyContactName: 'test',
  logoImage: 'test',
  owner: 'Alex Stanbury',
  portalAdmins: PropTypes.arrayOf(
    PropTypes.objectOf({
      nameFirst: 'test',
      nameLast: 'test',
      email: 'test',
    }),
  ),
  name: 'test',
  postCode: 'test',
  townCity: 'test',
  id: Date.now(),
};

const Overview = ({ formData, onSubmit }) => {
  const classes = useStyles();

  const firestore = useFirestore();

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({});

  useEffect(() => {
    reset(formData, { errors: true });
  }, []);

  useFirestoreConnect([
    {
      collection: 'roles',
      where: ['clientId', '==', 'asseticom'],
      storeAs: 'staff',
    },
  ]);

  const staff = useSelector(
    (state) => state.firestore.ordered.staff || EMPTY_ARRAY,
  );

  const checkIfidExists = AwesomeDebouncePromise(async (id) => {
    if (id.length) {
      const clientDoc = await firestore.collection('clients').doc(id).get();
      return clientDoc.exists
        ? 'Web URL is already in use, please choose another'
        : true;
    }
    // will use default error message
    return false;
  }, 250);

  return (
    <Box p={2}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          {...refAdapter(
            register('name', {
              required: true,
            }),
            'inputRef',
          )}
          autoComplete="off"
          fullWidth
          margin="normal"
          id="name"
          label="Portal Name"
          variant="outlined"
        />
        {errors.name && <ErrorText />}
        <TextField
          {...refAdapter(
            register('clientId', {
              required: true,
              validate: {
                exists: checkIfidExists,
              },
              pattern: {
                value: clientIdRegex,
                message:
                  'Only lower case letters, numbers and hyphens are allowed',
              },
            }),
            'inputRef',
          )}
          className={classes.urlPrefix}
          fullWidth
          margin="normal"
          id="clientId"
          label="Portal ID"
          variant="outlined"
          autoComplete="off"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                https://portal.asseticom.co.uk/
              </InputAdornment>
            ),
          }}
        />
        {errors.clientId && <ErrorText text={errors.clientId.message} />}
        <TextField
          {...refAdapter(
            register('keyContactName', {
              required: true,
            }),
            'inputRef',
          )}
          autoComplete="off"
          fullWidth
          margin="normal"
          id="key-contact-name"
          label="Key Contact Name"
          variant="outlined"
        />
        {errors.keyContactName && <ErrorText />}
        <TextField
          {...refAdapter(
            register('keyContactEmail', {
              required: true,
              pattern: {
                value: emailRegex,
                message: 'Enter a valid e-mail address',
              },
            }),
            'inputRef',
          )}
          autoComplete="off"
          type="email"
          fullWidth
          margin="normal"
          id="key-contact-email"
          label="Key Contact Email"
          variant="outlined"
        />
        {errors.keyContactEmail && (
          <ErrorText text={errors.keyContactEmail.message} />
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} className={classes.selectHolder}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="category">Category</InputLabel>
              <Select
                variant="standard"
                {...refAdapter(
                  register('category', {
                    required: true,
                  }),
                  'inputRef',
                )}
                label="category"
                native
                inputProps={{
                  name: 'category',
                  id: 'category',
                }}
              >
                <option key={0} value="" aria-label="empty" />
                {categories.map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </Select>
            </FormControl>
            {errors.category && <ErrorText />}
          </Grid>
          <Grid item xs={12} sm={6} className={classes.selectHolder}>
            <FormControl variant="outlined" fullWidth>
              <InputLabel htmlFor="owner">Asseticom Owner</InputLabel>
              <Select
                variant="standard"
                {...refAdapter(
                  register('owner', {
                    required: true,
                  }),
                  'inputRef',
                )}
                native
                label="Asseticom Owner"
                inputProps={{
                  name: 'owner',
                  id: 'owner',
                }}
              >
                <option key={0} value="" aria-label="empty" />
                {staff.map((admin) => (
                  <option
                    key={admin.uid}
                    value={admin.uid}
                  >{`${admin.nameFirst} ${admin.nameLast}`}</option>
                ))}
              </Select>
            </FormControl>
            {errors.owner && <ErrorText />}
          </Grid>
        </Grid>
        <TextField
          {...refAdapter(
            register('companyName', {
              required: true,
            }),
            'inputRef',
          )}
          fullWidth
          margin="normal"
          id="company-name"
          label="Company Name"
          variant="outlined"
          autoComplete="off"
        />
        {errors.companyName && <ErrorText />}
        <TextField
          type="tel"
          {...refAdapter(
            register('companyNumber', {
              required: true,
              pattern: {
                value: companyNumberRegex,
                message: 'Enter a valid 8-digit company number',
              },
            }),
            'inputRef',
          )}
          autoComplete="off"
          fullWidth
          margin="normal"
          id="company-number"
          label="Company Number"
          variant="outlined"
        />
        {errors.companyNumber && (
          <ErrorText text={errors.companyNumber.message} />
        )}
        <TextField
          {...refAdapter(
            register('bankName', {
              required: false,
            }),
            'inputRef',
          )}
          autoComplete="off"
          fullWidth
          margin="normal"
          id="bank-name"
          label="Bank Name"
          variant="outlined"
        />
        {errors.bankName && <ErrorText />}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              {...refAdapter(
                register('bankAccountNumber', {
                  required: false,
                }),
                'inputRef',
              )}
              autoComplete="off"
              fullWidth
              margin="normal"
              id="bank-account-number"
              label="Bank Account Number"
              variant="outlined"
            />
            {errors.bankAccountNumber && <ErrorText />}
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              {...refAdapter(
                register('bankSortCode', {
                  required: false,
                }),
                'inputRef',
              )}
              autoComplete="off"
              fullWidth
              margin="normal"
              id="bank-sort-code"
              label="Bank Sort Code"
              variant="outlined"
            />
            {errors.bankSortCode && <ErrorText />}
          </Grid>
        </Grid>
        <ButtonsContainer>
          <BackButton disabled hidden />
          <NextButton />
        </ButtonsContainer>
      </form>
    </Box>
  );
};

Overview.defaultProps = {
  formData: '',
};

Overview.propTypes = {
  formData: PropTypes.shape(clientPropTypes),
  onSubmit: PropTypes.func.isRequired,
};

export default Overview;
