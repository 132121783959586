import { Box, Grid, Typography } from '@mui/material';

import { EMPTY_OBJECT } from '../../shared/helpers';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
  name: {
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: 13,
  },
  imageHolder: {
    width: '50px',
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  welcome: (props) => ({
    fontWeight: theme.typography.fontWeightLight,
    fontSize: 18,
    display: props.welcome ? 'block' : 'none',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
      fontSize: 26,
    },
  }),
}));

const TitleBar = ({ title, welcome }) => {
  const classes = useStyles({ welcome });
  const profile = useSelector((state) => state.firebase.profile);

  const client = useSelector((state) => state.client || EMPTY_OBJECT);

  return (
    <Grid item container spacing={2}>
      <Grid
        container
        item
        alignItems="center"
        spacing={2}
        justifyContent="space-between"
      >
        <Grid item xs={12} sm={welcome ? 6 : 12}>
          <Box display="flex" alignItems="center">
            {client.id && (
              <Box mr={2} className={classes.imageHolder}>
                <img src={client.logoImage} width="100%" alt="logo" />
              </Box>
            )}
            <Box>
              {client && (
                <Typography className={classes.name}>{client.name}</Typography>
              )}
              <Typography className={classes.title}>{title}</Typography>
            </Box>
          </Box>
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <Typography className={classes.welcome}>
            Welcome back, {profile.nameFirst}
          </Typography>
        </Grid> */}
      </Grid>
    </Grid>
  );
};

TitleBar.propTypes = {
  title: PropTypes.string.isRequired,
  welcome: PropTypes.bool,
};

TitleBar.defaultProps = {
  welcome: false,
};

export default TitleBar;
