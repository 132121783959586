import { Box, Typography } from '@mui/material';

import PropTypes from 'prop-types';
import { colours } from '../config/theme';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  dropContainer: ({ height }) => ({
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: height || '200px',
  }),
}));

const FileDropper = ({ prompt, onChange, children, height }) => {
  const classes = useStyles({ height });
  const isTouchScreen = matchMedia('(hover: none)').matches;
  const [highlighted, setHighlighted] = useState(false);

  const hasContent = children.filter((child) => !!child).length;

  const dragOver = (e) => {
    e.preventDefault();
    setHighlighted(true);
  };

  const dragEnter = (e) => {
    e.preventDefault();
    setHighlighted(true);
  };

  const dragLeave = (e) => {
    e.preventDefault();
    setHighlighted(false);
  };

  const fileDrop = (e) => {
    e.preventDefault();
    const { files } = e.dataTransfer;
    onChange(files[0]);
    setHighlighted(false);
  };
  return (
    <Box
      className={classes.dropContainer}
      flexDirection="row"
      alignItems="cemter"
      justifyContent="center"
      px={2}
      style={{
        height: height || '200px',
        backgroundColor: highlighted ? colours.brand1 : colours.light2,
      }}
      onDragOver={dragOver}
      onDragEnter={dragEnter}
      onDragLeave={dragLeave}
      onDrop={fileDrop}
    >
      {hasContent ? (
        children
      ) : (
        <Typography
          align="center"
          variant="subtitle2"
          style={{
            color: highlighted ? colours.light : colours.medium,
          }}
        >
          {isTouchScreen ? 'No file uploaded' : `${prompt}`}
        </Typography>
      )}
    </Box>
  );
};
FileDropper.defaultProps = {
  children: null,
  height: '0px',
};

FileDropper.propTypes = {
  onChange: PropTypes.func.isRequired,
  prompt: PropTypes.string.isRequired,
  height: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default FileDropper;
