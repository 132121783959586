import Grid from '@mui/material/Grid';
import React from 'react';
import TitleBar from '../../components/MainContent/TitleBar';

const Add = () => {
  return (
    <Grid container spacing={2}>
      <TitleBar title="Add New Asseticom Admin" />
    </Grid>
  );
};

export default Add;
