import 'leaflet/dist/leaflet.css';

import {
  Box,
  Button,
  Grid,
  InputLabel,
  Paper,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { emailRegex, readURLAsData } from '../../shared/utilities';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ChangePassword from '../../components/ChangePassword';
import ErrorText from '../../components/Form/ErrorText';
import FileUploaderCard from '../../components/FileUploaderCard';
import { onUserSettingsUpdate } from '../../state/user';
import { useFirestoreConnect } from 'react-redux-firebase';

const defaultValues = {
  nameFirst: '',
  nameLast: '',
  email: '',
  role: null,
  imageURL: '',
  newPassword: '',
  confirmPassword: '',
  exportEmails: true,
};

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5,
  );
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y),
  );

  // As Base64 string
  return canvas.toDataURL('image/jpeg');

  // // As a blob
  // return new Promise((resolve) => {
  //   canvas.toBlob((file) => {
  //     resolve(URL.createObjectURL(file));
  //   }, 'image/jpeg');
  // });
}

const UserSettings = () => {
  const { handleSubmit, reset, control, formState, watch } = useForm({
    defaultValues,
    mode: 'onBlur',
  });
  const { errors, dirtyFields } = formState;
  const dispatch = useDispatch();

  const [imagePreview, setImagePreview] = useState();
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  // const password = useRef({});
  // password.current = watch('newPassword', '');

  const uid = useSelector((state) => state.firebase.auth.uid);

  useFirestoreConnect({
    collection: 'users',
    doc: uid,
    storeAs: 'loggedInUser',
  });

  const loggedInUser = useSelector(
    (state) => state.firestore.data.loggedInUser,
  );

  useEffect(() => {
    reset({ ...defaultValues, ...loggedInUser });
  }, [loggedInUser, reset]);

  const getImagePreview = async (file) => {
    const fileData = await readURLAsData(file);
    if (fileData) {
      setImagePreview(fileData);
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedPixels) => {
    setCroppedAreaPixels(croppedPixels);
  }, []);

  const onSubmit = async (formData) => {
    let croppedImage;
    if (imagePreview) {
      croppedImage = await getCroppedImg(imagePreview, croppedAreaPixels, 0);
    }
    await dispatch(
      onUserSettingsUpdate({ formData, croppedImage, uid, dirtyFields }),
    );
    setImagePreview(null);
    reset({
      ...defaultValues,
      ...{
        nameFirst: formData.nameFirst,
        nameLast: formData.nameLast,
        email: formData.email,
        exportEmails: formData.exportEmails,
      },
    });
  };

  const userImage = imagePreview || loggedInUser?.imageURL;

  return loggedInUser ? (
    <Box p={2}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FileUploaderCard
              title="Your Image"
              onChange={getImagePreview}
              cropImage={!!imagePreview}
              onCrop={onCropComplete}
              fileUrl={userImage}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper elevation={2}>
              <Box p={2}>
                <Typography variant="subtitle2" gutterBottom>
                  Details
                </Typography>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                      pattern: {
                        value: emailRegex,
                      },
                    }}
                    name="email"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        margin="normal"
                        id="email"
                        label="Email Address"
                        variant="outlined"
                        type="email"
                        fullWidth
                      />
                    )}
                  />
                  {errors.email && (
                    <ErrorText text="Please enter a valid email address" />
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    name="nameFirst"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        margin="normal"
                        id="nameFirst"
                        label="First Name"
                        variant="outlined"
                        error={!!errors.nameFirst}
                      />
                    )}
                  />
                  {errors.nameFirst && <ErrorText />}
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    rules={{
                      required: true,
                    }}
                    name="nameLast"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        margin="normal"
                        id="nameLast"
                        label="Last Name"
                        variant="outlined"
                        error={!!errors.nameLast}
                      />
                    )}
                  />
                  {errors.nameLast && <ErrorText />}
                </Grid>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper>
              <Box p={2}>
                <Typography variant="subtitle2" gutterBottom>
                  Update Password
                </Typography>
                {/* <Controller
                  control={control}
                  rules={{
                    required: false,
                    pattern: {
                      value: passwordRegex,
                    },
                  }}
                  name="newPassword"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      type="password"
                      margin="normal"
                      id="newPassword"
                      label="New Password"
                      variant="outlined"
                      autoComplete="new-password"
                      error={!!errors.newPassword}
                    />
                  )}
                />
                {errors.newPassword && (
                  <ErrorText text="Password must contain at least one capital letter, at least one lower case letter, at least one number, at least one special character and be at least 8 characters in length" />
                )}
                <Controller
                  control={control}
                  rules={{
                    validate: (value) => {
                      return value === password.current;
                    },
                  }}
                  name="confirmPassword"
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      type="password"
                      margin="normal"
                      id="confirmPassword"
                      label="Confirm Password"
                      variant="outlined"
                      autoComplete="off"
                      error={!!errors.confirmPassword}
                    />
                  )}
                />
                {errors.confirmPassword && (
                  <ErrorText text="The passwords do not match" />
                )} */}
                <ChangePassword control={control} errors={errors} />
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper>
              <Box p={2}>
                <Typography variant="subtitle2" gutterBottom>
                  User Settings
                </Typography>
                <Grid item xs={12}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mt={2}
                  >
                    <InputLabel htmlFor="exportEmails">
                      Receive Export Email Notifications
                    </InputLabel>
                    <Controller
                      name="exportEmails"
                      id="exportEmails"
                      control={control}
                      error={!!errors.exportEmails}
                      rules={{
                        required: false,
                      }}
                      render={({ field: { onChange, value }, ...props }) => (
                        <Switch
                          {...props}
                          onChange={(_, val) => {
                            onChange(val);
                          }}
                          checked={value}
                          color="primary"
                          inputProps={{
                            'aria-label': 'secondary checkbox',
                          }}
                        />
                      )}
                    />
                  </Box>

                  {errors.reccurs && <ErrorText />}
                </Grid>
              </Box>
            </Paper>
          </Grid>
        </Grid>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          mt={2}
        >
          <Button variant="contained" color="primary" type="submit">
            Save Settings
          </Button>
        </Box>
      </form>
    </Box>
  ) : null;
};

UserSettings.defaultProps = {};

UserSettings.propTypes = {};

export default UserSettings;
