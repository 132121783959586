import { Box } from '@mui/material';
import { getFirebase } from 'react-redux-firebase';
import { useDispatch } from 'react-redux';

const searchParams = new URLSearchParams(window.location.search);

const SSO = () => {
  const dispatch = useDispatch();
  const firebase = getFirebase();

  return <Box>SSO</Box>;
};

export default SSO;
