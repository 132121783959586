import { z } from 'zod';

export const assetAttributesSchema = z.object({
  code0: z.string(),
  code1: z.string(),
  code2: z.string(),
  code3: z.string(),
  label0: z.string(),
  label2: z.string(),
  label1: z.string(),
  label3: z.string(),
  costPerUnit: z.number(),
  expectedLife: z.number(),
  uom: z.string(),
  uplift: z.number(),
});

export const optionSchema = z.object({
  value: z.string(),
});

export const formFieldSchema = z.object({
  id: z.string().optional(),
  default: z.union([z.number(), z.string(), z.boolean()]).optional(),
  disabled: z.boolean(),
  hidden: z.boolean(),
  label: z.string(),
  name: z.string(),
  min: z.number().optional(),
  max: z.number().optional(),
  options: z.array(optionSchema).optional(),
  unit: z.string().optional(),
  required: z.boolean(),
  nicheFields: z.array(z.string()).optional(),
  type: z.union([
    z.literal('Number'),
    z.literal('Text'),
    z.literal('Decimal'),
    z.literal('Select'),
    z.literal('Slider'),
    z.literal('Radio'),
    z.literal('Barcode'),
    z.literal('Switch'),
  ]),
});

export const surveyPropsSchema = z.object({
  formFields: z.array(formFieldSchema),
});

export const additionalDataSchema = z.object({
  surveyId: z.string().optional(),
  survey: surveyPropsSchema.optional(),
  assetList: z.array(assetAttributesSchema).optional(),
});

export const fullImportRowSchema = z.object({
  Created: z.string(),
  'Last Updated': z.string(),
  Relationship: z.string(),
  'Site Name': z.string(),
  'Building Name': z.string(),
  'Survey Name': z.string(),
  'Room Name': z.string(),
  'Floor Name': z.string(),
  'Surveyor Name': z.string(),
  'Code 0': z.string(),
  'Label 0': z.string(),
  'Code 1': z.string(),
  'Label 1': z.string(),
  'Code 2': z.string(),
  'Label 2': z.string(),
  'Code 3': z.string(),
  'Label 3': z.string(),
  Name: z.string(),
  Description: z.string(),
  Quantity: z.number(),
  UOM: z.string(),
  Cost: z.number(),
  'Uplift Multiplier': z.number(),
  'Renewal Multiplier': z.number(),
  'Renewal Cost': z.number(),
  'Remaining Life': z.number(),
  'Expected Life': z.number(),
  Reccurs: z.string(),
  ID: z.string(),
  'Parent ID': z.string(),
  'Image 1': z.string(),
  'Image 2': z.string(),
  'Image 3': z.string(),
  'Image 4': z.string(),
  'Image 5': z.string(),
});

export const assetImportRowSchema = z
  .object({
    ID: z.string(),
    UOM: z.string(),
    Cost: z.number().nonnegative(),
    'Uplift Multiplier': z
      .number({
        required_error: 'Uplift Multiplier is required',
        invalid_type_error:
          'Uplift Multiplier must be a number between 0 and 1',
      })
      .nonnegative()
      .lte(1),
  })
  .required({
    ID: true,
    UOM: true,
    Cost: true,
    'Uplift Multiplier': true,
  });

export type AssetImport = z.infer<typeof assetImportRowSchema>;

export const partialImportRowSchema = z
  .object({
    ID: z.string(),
    'Parent ID': z.string().default('N/A'),
    Relationship: z.string(),
    Description: z.string().default(''),
    'Renewal Multiplier': z
      .number({
        required_error: 'Renewal Multiplier is required',
        invalid_type_error:
          'Renewal Multiplier must be a number between 0 and 1',
      })
      .nonnegative()
      .lte(1),
    'Remaining Life': z.number().nonnegative(),
    'Expected Life': z.number().nonnegative().nullish(),
    Reccurs: z
      .enum(['YES', 'NO', 'N/A'])
      .nullable()
      .default(null)
      .transform((v) => (v === 'YES' ? true : false)),
  })
  .required({
    ID: true,
    'Parent ID': true,
    Relationship: true,
    'Renewal Multiplier': true,
    'Remaining Life': true,
  })
  .superRefine((val, ctx) => {
    // if reccurs is set but there is no expected life then its invalid
    if (val.Reccurs && !val['Expected Life']) {
      ctx.addIssue({
        path: ctx.path,
        code: z.ZodIssueCode.custom,
        message:
          'You must add a numerical value for Expected Life if the partial renewal is set to reccur.',
      });
    }
  });

export type PartialImport = z.infer<typeof partialImportRowSchema>;

export const importDataSchema = z.object({
  name: z.string(),
  errors: z.array(z.string()),
  mappedRows: z.record(fullImportRowSchema),
});

export const SurveyAssetImportSchema = z.object({
  'Room Name': z.string(),
  Floor: z.string(),
  'Code 0': z.string(),
  'Label 0': z.string(),
  'Code 1': z.string(),
  'Label 1': z.string(),
  'Code 2': z.string(),
  'Label 2': z.string(),
  'Code 3': z.string(),
  'Label 3': z.string(),
  Description: z.string(),
  Quantity: z.number(),
  'Unit of Measure': z.string(),
  'Cost Per Unit': z.number(),
  'Uplift Multiplier': z.number(),
  'Remaining Life': z.number(),
  'Expected Life': z.number(),
});
