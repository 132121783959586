import { Box, CardActionArea, Typography } from '@mui/material';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Link } from 'react-router-dom';

// const useStyles = makeStyles((theme) => ({
//   container: {
//     padding: theme.spacing(1),
//     position: 'absolute',
//     top: 0,
//     bottom: 0,
//     right: 0,
//     left: 0,
//   },
//   card: {
//     width: '100%',
//     height: '100%',
//   },
//   cardAction: {
//     display: 'flex',
//     alignItems: 'flex-end',
//     justifyContent: 'flex-end',
//     height: '100%',
//   },
// }));

const ViewAllCard = ({ to }) => {
  return (
    <Box
      display="flex"
      flex={1}
      position="absolute"
      sx={{ top: 0, right: 0, bottom: 0, left: 0, mt: 2, ml: 2 }}
    >
      <Card
        sx={{
          flex: 1,
          flexDirection: 'column',
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
          height: '100%',
        }}
      >
        <CardActionArea component={Link} to={to} sx={{ height: '100%' }}>
          <CardContent
            sx={{
              height: '100%',
              display: 'flex',
              flex: 1,
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
            }}
          >
            <Typography color="secondary" align="right">
              View All...
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  );
};

export default ViewAllCard;
