import { Box, Grid, IconButton, Stack, TextField } from '@mui/material';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

import { DEFAULT_VALUES_OPTION } from '../constants';
import DynamicIcon from '../../../../../../components/DynamicIcon';
import ErrorText from '../../../../../../components/Form/ErrorText';
import { FORM_FIELDS_NAME } from '../../../../../../config/constants';
import FormFieldAddOptionButton from './FormFieldAddOptionButton';
import { FormValues } from '..';
import useFormError from '../hooks/useFormError';

type FormFieldOptionsProps = {
  index: number;
};

export default function FormFieldOptions({ index }: FormFieldOptionsProps) {
  const { control, trigger } = useFormContext<FormValues>();

  const optionsErrors = useFormError(index, 'options');

  const {
    fields: optionFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: `${FORM_FIELDS_NAME}.${index}.options`,
  });

  function triggerValidation() {
    if (optionsErrors) {
      trigger(`${FORM_FIELDS_NAME}.${index}.options`);
    }
  }

  function onAppend(option: typeof DEFAULT_VALUES_OPTION) {
    append(option);
    triggerValidation();
  }

  function onRemove(index: number) {
    remove(index);
    triggerValidation();
  }

  return (
    <Stack alignItems="center">
      <Grid container spacing={2}>
        {optionFields?.map((optionField, optionIndex) => {
          return (
            <Grid item xs={6} key={optionField.id}>
              <Controller
                control={control}
                name={`${FORM_FIELDS_NAME}.${index}.options.${optionIndex}.value`}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <>
                    <TextField
                      defaultValue={optionField.value}
                      autoComplete="off"
                      fullWidth
                      variant="outlined"
                      label={`Option ${optionIndex + 1}`}
                      type="text"
                      onChange={(event) =>
                        field.onChange(event.target.value.trim())
                      }
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            onClick={() => onRemove(optionIndex)}
                            size="large"
                          >
                            <DynamicIcon
                              name="bin"
                              colour="secondary"
                              size={15}
                            />
                          </IconButton>
                        ),
                      }}
                    />
                    {optionsErrors?.[optionIndex] && (
                      <ErrorText
                        text={optionsErrors[optionIndex].value.message}
                      />
                    )}
                  </>
                )}
              />
            </Grid>
          );
        }) ?? null}
        <Grid
          item
          xs={optionFields.length ? 6 : 12}
          alignItems="center"
          justifyContent="center"
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
            flex={1}
          >
            <FormFieldAddOptionButton
              onAddOption={() => onAppend({ ...DEFAULT_VALUES_OPTION })}
            />
          </Box>
        </Grid>
      </Grid>
      {optionsErrors?.message && <ErrorText text={optionsErrors.message} />}
    </Stack>
  );
}
