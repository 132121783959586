import { Box, Button, Grid, Paper } from '@mui/material';
import React, { useState } from 'react';

import DataTable from '../../components/DataTable';
import DynamicIcon from '../../components/DynamicIcon';
import { EMPTY_ARRAY } from '../../shared/helpers';
import { Link } from 'react-router-dom';
import Paginator from '../../components/Paginator';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TitleBar from '../../components/MainContent/TitleBar';
import { colours } from '../../config/theme';
import { makeStyles } from '@mui/styles';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';

const columns = [
  {
    id: 'name',
    label: 'Name',
    minWidth: 170,
    image: true,
    imageStyle: {
      objectFit: 'contain',
    },
  },
  { id: 'location', label: 'Location', align: 'center' },
  {
    id: 'sites',
    label: 'Sites',
    align: 'center',
  },
  {
    id: 'surveys',
    label: 'Surveys',
    align: 'center',
  },
  {
    id: 'users',
    label: 'Users',
    align: 'center',
  },
  {
    id: 'created',
    label: 'Created',
    align: 'center',
    format: (value) => {
      const createdDateString = new Date(value);
      return createdDateString.toLocaleDateString();
    },
  },
  {
    id: 'actions',
    label: 'Actions',
    align: 'center',
  },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    // maxHeight: 440,
  },
  tableRow: {
    cursor: 'pointer',
  },
  imageHolder: {
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&& img': {
      width: '50px',
      height: '50px',
      objectFit: 'cover',
    },
  },
});

const View = () => {
  const [clientsPerPage, setClientsPerPage] = useState(5);
  const [cursor, setCursor] = useState(0);

  const clients = useSelector(
    (state) => state.firestore.ordered.clients || EMPTY_ARRAY,
  );

  useFirestoreConnect([
    {
      collection: 'clients',
      storeAs: 'clients',
      limit: clientsPerPage,
      startAfter: cursor,
      orderBy: ['created', 'desc'],
    },
  ]);

  const stats = useSelector(
    (state) => state.firestore.data.stats || EMPTY_ARRAY,
  );

  useFirestoreConnect([
    {
      collection: 'globals',
      doc: 'stats',
      storeAs: 'stats',
    },
  ]);

  const rows = clients.map((client) => {
    const {
      name,
      postCode,
      sitesNumCreated,
      sitesNumDeleted,
      surveysNumCreated,
      surveysNumDeleted,
      usersNumCreated,
      usersNumDeleted,
      created,
      logoImage,
      id,
    } = client;

    return {
      name,
      location: postCode,
      sites: sitesNumCreated - sitesNumDeleted,
      surveys: surveysNumCreated - surveysNumDeleted,
      users: usersNumCreated - usersNumDeleted,
      created,
      image: logoImage,
      id,
      actions: [
        {
          icon: 'eye',
          colour: colours.green3,
          text: 'View',
          to: `/${id}`,
        },
        {
          icon: 'edit',
          colour: colours.blue,
          text: 'Edit',
          onClick: () => alert('edit client?'),
        },
        {
          icon: 'bin',
          colour: colours.red,
          text: 'Delete',
          onClick: () => alert('delete client?'),
        },
      ],
    };
  });

  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <TitleBar title="Client Portals" />
      <Paper className={classes.root}>
        <DataTable columns={columns} rows={rows} />

        <Box m={2}>
          <Paginator
            label="Clients per page:"
            total={stats.clientsNumCreated}
            perPage={5}
            perPageOptions={[5, 10, 20]}
            onChangePage={setCursor}
            onChangePerPage={setClientsPerPage}
            dataArray={clients}
            dataProp="created"
          />
        </Box>
      </Paper>
    </Grid>
  );
};

export default View;
