import { Box, Button, Chip, Paper, Stack, Typography } from '@mui/material';
import { FormProvider, useForm, useFormState, useWatch } from 'react-hook-form';
import {
  addIdsToItemsIfNeeded,
  dateFromTimeStamp,
  getPrevUrlSection,
  getRandomID,
  readURLAsData,
  timeFromTimeStamp,
} from '../../../../../../shared/utilities';
import {
  getAssetDeleteMessages,
  onAssetSubmit,
  onAssetTypeChange,
  onSelectedAssetDelete,
} from '../../../../../../state/asset';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AssetAttribute from '../../../../../../components/AssetAttribute';
import AssetTypeChanger from '../../../../../../components/AssetTypeChanger';
import CardTitle from './CardTitle';
import DynamicIcon from '../../../../../../components/DynamicIcon';
import { EMPTY_OBJECT } from '../../../../../../shared/helpers';
import ErrorBoundary from '../../../../../../components/ErrorBoundary';
import FormFields from './Formfields';
import Grid from '@mui/material/Grid';
import ImageGalleryGrid from '../../../../../../components/ImageGalleryGrid';
import ImageGrid from './ImageGrid';
import InfoAttributeWithIcon from './InfoAttributeWithIcon';
import { colours } from '../../../../../../config/theme';
import { useHistory } from 'react-router-dom';
import { useModal } from '../../../../../../components/Modal';

const AssetDetails = ({ asset }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const lastEdit = asset?.edits ? asset.edits.slice(-1)[0] : null;
  const surveyor = useSelector(
    (state) => state.firestore.data.surveyor || EMPTY_OBJECT,
  );
  const survey = useSelector(
    (state) => state.firestore.data.survey || EMPTY_OBJECT,
  );

  const { showModal } = useModal();

  const defaultValues = survey?.formFields?.reduce(
    (acc, field) => {
      return { ...acc, [field.id || field.label]: field.default || '' };
    },
    {
      images: addIdsToItemsIfNeeded(asset.images, asset),
      attributes: asset.attributes,
    },
  );

  const methods = useForm();

  const { handleSubmit, control, reset, setValue } = methods;

  const { isDirty, errors } = useFormState({ control });
  const attributesWatcher = useWatch({ name: 'attributes', control });

  const formFieldsForAsset = useMemo(
    () =>
      attributesWatcher &&
      survey?.formFields?.filter((f) => {
        // only filter based on disabled if formOptions exists, as disabled props
        // for form fields meant something different before formOptions were brought in
        return (
          !f.disabled &&
          !f.hidden &&
          (!f.nicheFields ||
          // only show the field if the asset level 3 code is specified in the nicheFields array as set in the CCC
          f.nicheFieldsExcludeMode
            ? !f.nicheFields?.includes(attributesWatcher.code3)
            : f.nicheFields?.includes(attributesWatcher.code3) ||
              // if it's an imported asset and it has a value for this field then it should be
              // shown regardless of nicheFields settings. It may be the case that the imported asset
              // code did not match with any asset list codes so it couldn't be included in the niche fields
              // array so without this line the field wouldn't show. This should be resolved in a future update
              // where the asset list will contain all level 3 codes, imported assets and pick list assets, either
              // through merging them or another method.
              (asset.importOrder && asset.values?.[f.id || f.label]))
        );
      }),
    [survey, asset, attributesWatcher],
  );

  const onSubmit = async (formData) => {
    const { images, attributes, ...values } = formData;

    dispatch(
      onAssetSubmit({
        asset,
        images,
        values,
        attributes,
        updated: isDirty,
      }),
    );
    if (!asset.id) {
      // TODO - check if previous entry is asseticom domain, if so then
      history.goBack();
    }
  };

  const onChangedUplift = useCallback((value) => {
    // convert back into decimal
    setValue('attributes.uplift', Number(value) / 100, { shouldDirty: true });
  }, []);

  const onChangedCost = useCallback((value) => {
    setValue('attributes.costPerUnit', Number(value), { shouldDirty: true });
  }, []);

  const onClickDeleteAsset = async () => {
    const deleteConfirmed = await showModal({
      title: 'Please Confirm',
      buttons: {
        confirm: [{ text: 'Yes', value: true }],
        cancel: [{ text: 'No' }],
      },
      messages: getAssetDeleteMessages(asset),
    });
    if (deleteConfirmed) {
      dispatch(onSelectedAssetDelete({ asset }));
      history.push(getPrevUrlSection(1));
    }
  };

  const onSelectedNewAssetType = async (assetType) => {
    if (!asset.id) {
      setValue('Remaining_Life', assetType.expectedLife);
      return setValue('attributes', assetType);
    }
    return dispatch(
      onAssetTypeChange({
        asset,
        attributes: assetType,
      }),
    );
  };

  useEffect(() => {
    if (asset) {
      const assetValues = {
        ...defaultValues,
        ...asset.values,
        attributes: asset.attributes,
      };
      reset(assetValues);
    }
  }, [asset, reset]);

  const hasErrors = Object.keys(errors).length > 0;

  // dont render until required data is available
  return survey.id && attributesWatcher ? (
    <Grid container spacing={2}>
      <ErrorBoundary>
        <Grid item xs={12} xl={6}>
          <Grid container item spacing={2}>
            <Grid item xs={lastEdit ? 6 : 12}>
              {asset.id ? (
                <Paper>
                  <CardTitle text="Capture Details" />
                  <Box padding={2}>
                    <InfoAttributeWithIcon
                      icon="calendar"
                      text={dateFromTimeStamp(asset.created)}
                    />
                    <InfoAttributeWithIcon
                      icon="clock"
                      text={timeFromTimeStamp(asset.created)}
                    />
                    {surveyor.nameFirst && surveyor.nameLast ? (
                      <InfoAttributeWithIcon
                        icon="avatar"
                        text={`${surveyor.nameFirst} ${surveyor.nameLast}`}
                      />
                    ) : null}
                  </Box>
                </Paper>
              ) : null}
            </Grid>
            {lastEdit && (
              <Grid item xs={6}>
                <Box>
                  <Paper>
                    <CardTitle text="Last Edited" />
                    <Box padding={2}>
                      <>
                        <InfoAttributeWithIcon
                          icon="calendar"
                          text={dateFromTimeStamp(lastEdit.date)}
                        />
                        <InfoAttributeWithIcon
                          icon="clock"
                          text={timeFromTimeStamp(lastEdit.date)}
                        />
                        <InfoAttributeWithIcon
                          icon="avatar"
                          text={`${lastEdit.name}`}
                        />
                      </>
                    </Box>
                  </Paper>
                </Box>
              </Grid>
            )}
            <Grid item xs={12}>
              <Paper>
                <CardTitle text="Images" />
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 1 }}>
                  <ImageGalleryGrid
                    images={asset.images}
                    onUpdate={(updatedImages) => {
                      setValue('images', updatedImages, { shouldDirty: true });
                    }}
                  />
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} xl={6}>
          <Paper>
            <form onSubmit={handleSubmit(onSubmit)}>
              <CardTitle
                text="Asset Details"
                action={
                  <AssetTypeChanger
                    onSelected={onSelectedNewAssetType}
                    forceChoice={!asset.id}
                  />
                }
              />
              {attributesWatcher?.label3 ? (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                  padding={2}
                >
                  <Stack direction="row" flexWrap="wrap">
                    <Chip
                      size="small"
                      label={`${attributesWatcher.code0} - ${attributesWatcher.label0}`}
                      sx={{
                        pointerEvents: 'none',
                        marginBottom: 1,
                        marginRight: 1,
                      }}
                    />
                    <Chip
                      size="small"
                      label={`${attributesWatcher.code1} - ${attributesWatcher.label1}`}
                      sx={{
                        pointerEvents: 'none',
                        marginBottom: 1,
                        marginRight: 1,
                      }}
                    />
                    <Chip
                      size="small"
                      label={`${attributesWatcher.code2} - ${attributesWatcher.label2}`}
                      sx={{
                        pointerEvents: 'none',
                        marginBottom: 1,
                        marginRight: 1,
                      }}
                    />
                  </Stack>

                  <Typography fontSize={28} color="secondary">
                    {attributesWatcher.code3} - {attributesWatcher.label3}
                  </Typography>
                </Box>
              ) : null}

              {asset.flag !== null ? (
                <Box
                  display="flex"
                  alignItems="flex-end"
                  justifyContent="center"
                  paddingTop={2}
                >
                  <DynamicIcon name="flag" colour="orange" size={40} />
                  <Box
                    marginLeft={1}
                    height={40}
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{ color: colours.orange }}
                  >
                    <Typography variant="body1">Asset flagged</Typography>
                    {asset.flag !== '' ? (
                      <Typography variant="body1">: {asset.flag}</Typography>
                    ) : null}
                  </Box>
                </Box>
              ) : null}
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-around"
                style={{
                  padding: '10px 20px',
                  borderBottom: '1px solid lightgrey',
                }}
              >
                <AssetAttribute label="Unit" value={attributesWatcher.uom} />
                <AssetAttribute
                  onChanged={onChangedCost}
                  prefix="£"
                  label="Cost Per Unit"
                  value={attributesWatcher.costPerUnit}
                  validationRegex={/^\d+(\.?\d+)?$/}
                />
                <AssetAttribute
                  onChanged={onChangedUplift}
                  label="Uplift"
                  suffix="%"
                  value={`${(attributesWatcher.uplift * 100).toFixed(0)}`}
                  validationRegex={/^\d+$/}
                />
              </Box>
              <Box px={4}>
                <Grid container spacing={4}>
                  <FormProvider {...methods}>
                    <FormFields
                      formFields={formFieldsForAsset}
                      attributes={attributesWatcher}
                    />
                  </FormProvider>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    py={4}
                    alignItems="flex-start"
                  >
                    {asset.id ? (
                      <Button
                        color="secondary"
                        variant="outlined"
                        onClick={onClickDeleteAsset}
                      >
                        Delete Asset
                      </Button>
                    ) : null}
                    <Stack direction="column">
                      <Button
                        variant="contained"
                        color={'primary'}
                        type="submit"
                        disabled={hasErrors}
                      >
                        Save Asset Details
                      </Button>
                      {hasErrors && (
                        <Typography variant="caption" color="error" mt={1}>
                          Please fix any errors before saving
                        </Typography>
                      )}
                    </Stack>
                  </Box>
                </Grid>
              </Box>
            </form>
          </Paper>
        </Grid>
      </ErrorBoundary>
    </Grid>
  ) : null;
};

export default AssetDetails;
