import { Box, Chip, Divider, ThemeProvider, Typography } from '@mui/material';

import { MaterialReactTable } from 'material-react-table';
import StepGuide from '../../../components/StepGuide';
import { useImportStepData } from '../../../../../../state/survey-form';
import { useMemo } from 'react';
import useTableTheme from '../../../../../../hooks/useTableTheme';

type CheckDataStepProps = {
  stepNumber: number;
};

export default function CheckDataStep({ stepNumber }: CheckDataStepProps) {
  const { columns, importData } = useImportStepData();

  const tableTheme = useTableTheme();

  const tableColumns = useMemo(() => {
    return columns.map((c) => ({ accessorKey: c, header: c }));
  }, [columns]);

  return importData?.rows ? (
    <Box mt={8}>
      <StepGuide
        stepNumber={stepNumber}
        text="Check your data to ensure it is correct."
      />

      <ThemeProvider theme={tableTheme}>
        <MaterialReactTable
          columns={tableColumns}
          data={importData.rows}
          enableDensityToggle={false}
          enableFullScreenToggle={false}
          enableGlobalFilter={false}
          initialState={{
            density: 'compact',
            pagination: { pageSize: 10, pageIndex: 0 },
          }}
        />
      </ThemeProvider>
    </Box>
  ) : null;
}
