export const fieldTypes = {
  text: 'Text',
  number: 'Number',
  decimal: 'Decimal',
  select: 'Select',
  slider: 'Slider',
  radio: 'Radio',
  barcode: 'Barcode',
};

export const REQUIRE_DEFAULT = [
  fieldTypes.slider,
  fieldTypes.number,
  fieldTypes.decimal,
];
export const REQUIRE_DEFAULT_SELECT = [fieldTypes.select, fieldTypes.radio];
export const REQUIRE_MIN = [fieldTypes.slider];
export const REQUIRE_MAX = [fieldTypes.slider];
export const REQUIRE_CHOICES = [fieldTypes.radio, fieldTypes.select];

export const DEFAULT_VALUES_FORM = {
  type: '',
  label: '',
  id: '',
  required: false,
  disabled: false,
  hidden: false,
  base: false,
  nicheFields: [],
  nicheFieldsExcludeMode: true,
};

export const DEFAULT_VALUES_FORM_OPTIONS = {
  unit: true,
  cost: true,
  uplift: true,
};

export const DEFAULT_VALUES_OPTION = {
  value: '',
  description: '',
};

export const DEFAULT_VALUES_FORM_EXTRAS = {
  min: 0,
  max: 50,
  default: '',
};

export const DECIMAL_REGEX = /^\d*\.?\d*$/g;
export const DECIMAL_VALIDATION_MESSAGE = 'Must be a decimal';
export const NUMBER_REGEX = /^[0-9]+$/g;
export const NUMBER_VALIDATION_MESSAGE = 'Must be a number';
