import { CreatePhotoScheduleReportData } from '../types';

interface InsidePage {
  data: CreatePhotoScheduleReportData;
}
export default function TOC({ data }: InsidePage) {
  return (
    <div className="front-cover fill-page center-content">
      {/* this div and image is removed from the flow and put into the header see https://pagedjs.org/documentation/7-generated-content-in-margin-boxes/#running-elements%3A-headers%2Ffooter-with-specific-(complex)-content */}
      <div className="top-left-content">
        <div className="site-and-building flex-column flex-justify-start flex-align-start">
          <span>
            {data.siteName} - {data.buildingName}
          </span>
        </div>
      </div>

      <div className="top-right-content">
        <div className="flex-justify-end flex-align-center">
          <img src={data.clientLogo} className="footer-header-image" />
        </div>
      </div>

      <h1 className="page-heading">Table of Contents</h1>
      <nav id="toc-content"></nav>
    </div>
  );
}
