import { ALL_ROLES } from './roles';

const DASHBOARD = {
  path: '/:clientId',
  auth: ALL_ROLES.VIEWER,
  title: 'Dashboard',
};
const ROOT = {
  path: '/',
  exact: true,
  auth: ALL_ROLES.ASSETICOM_ADMIN,
  redirect: DASHBOARD.path,
  title: 'Asseticom Dashboard',
};
const PASSWORD_RESET = {
  path: '/password-reset',
  exact: true,
  auth: ALL_ROLES.PUBLIC,
  title: 'Password Reset',
};
const SSO = {
  path: '/sso',
  exact: true,
  auth: ALL_ROLES.PUBLIC,
  title: 'SSO',
};
const ASSETICOM = {
  path: '/asseticom',
  exact: true,
  auth: ALL_ROLES.ASSETICOM_ADMIN,
  title: 'Asseticom Portal',
};
const INTERNAL_TOOLS = {
  path: '/tools',
  exact: true,
  auth: ALL_ROLES.ASSETICOM_ADMIN,
  title: 'Internal Tools',
};
const CLIENTS = {
  path: '/clients',
  auth: ALL_ROLES.ASSETICOM_ADMIN,
  title: 'Clients',
};
const CLIENTS_ADD = {
  path: '/clients/add',
  auth: ALL_ROLES.ASSETICOM_ADMIN,
  title: 'Add a Client',
};
const ADMINS = {
  path: '/admins',
  auth: ALL_ROLES.ASSETICOM_ADMIN,
  title: 'Admins',
};
const ADMINS_ADD = {
  path: '/admins/add',
  auth: ALL_ROLES.ASSETICOM_ADMIN,
  title: 'Add an Admin',
};
const REPORTS = {
  path: '/reports',
  auth: ALL_ROLES.ASSETICOM_ADMIN,
  title: 'View Reports',
};
const USER = { path: '/user', title: 'My User Profile' };
const SURVEYS = {
  path: '/:clientId/surveys',
  auth: ALL_ROLES.SURVEYOR,
  title: 'Surveys',
};
const SURVEYS_ADD = {
  path: '/:clientId/surveys/add',
  exact: true,
  auth: ALL_ROLES.LEAD_SURVEYOR,
  title: 'Add a Survey',
};
const SURVEYS_REISSUE = {
  path: '/:clientId/surveys/reissue/:surveyId',
  exact: true,
  auth: ALL_ROLES.LEAD_SURVEYOR,
  title: 'Reissue a Survey',
};
const SURVEYS_SURVEY = {
  path: '/:clientId/surveys/:surveyId',
  exact: true,
  auth: ALL_ROLES.SURVEYOR,
  title: 'Survey',
};
const SURVEYS_ROOM = {
  path: '/:clientId/surveys/:surveyId/:roomId',
  exact: true,
  auth: ALL_ROLES.SURVEYOR,
  title: 'Room',
};
const SURVEYS_ASSET = {
  path: '/:clientId/surveys/:surveyId/:roomId/:assetId',
  exact: true,
  auth: ALL_ROLES.SURVEYOR,
  title: 'Asset',
};
const SITES = {
  path: '/:clientId/sites',
  auth: ALL_ROLES.LEAD_SURVEYOR,
  title: 'Sites',
};
const SITES_ADD = {
  path: '/:clientId/sites/add',
  exact: true,
  auth: ALL_ROLES.LEAD_SURVEYOR,
  title: 'Add a Site',
};
const SITES_EDIT = {
  path: '/:clientId/sites/:siteId',
  exact: true,
  auth: ALL_ROLES.LEAD_SURVEYOR,
  title: 'Site',
};
const ASSET_LISTS = {
  path: '/:clientId/asset-taxonomies',
  auth: ALL_ROLES.SURVEYOR,
  title: 'Taxonomies',
};
const ASSET_LISTS_ADD = {
  path: '/:clientId/asset-taxonomies/add',
  auth: ALL_ROLES.LEAD_SURVEYOR,
  title: 'Add a Taxonomy',
};
const USERS = {
  path: '/:clientId/users',
  auth: ALL_ROLES.LEAD_SURVEYOR,
  title: 'Users',
};
const USERS_ADD = {
  path: '/:clientId/users/add',
  auth: ALL_ROLES.ADMIN,
  title: 'Add a User',
};
const USERS_USER = {
  path: '/:clientId/users/:userId',
  auth: ALL_ROLES.ADMIN,
  title: 'User',
};
const REPORTING_CREATE = {
  path: '/:clientId/reporting/create',
  auth: ALL_ROLES.VIEWER,
  title: 'Reporting',
};

const REPORTING_VIEW = {
  path: '/:clientId/reporting',
  auth: ALL_ROLES.VIEWER,
  title: 'Reporting History',
};

const ASSET_DATA_IMPORT = {
  path: '/:clientId/asset-data-import',
  auth: ALL_ROLES.SURVEYOR,
  title: 'Asset Data Import',
};
const SETTINGS = {
  path: '/:clientId/settings',
  auth: ALL_ROLES.ADMIN,
  title: 'Settings',
};
const NOT_FOUND = { path: '/not-found', title: 'Page Not Found' };
const LOGIN = { path: '/login', exact: true, title: 'Log in to Asseticom' };
const NOT_AUTHED = { path: '/not-authed', title: 'Not Authed' };
const QR_CODE_NOT_FOUND = {
  path: '/qr-not-found',
  title: 'QR Code Not Found',
};
const TOOLS = { path: '/:clientId/tools', title: 'Tools' };
const CROPPER = { path: '/:clientId/tools/cropper', title: 'Cropper Tool' };

export const ROUTES_MAP = {
  ROOT,
  ASSETICOM,
  INTERNAL_TOOLS,
  LOGIN,
  PASSWORD_RESET,
  SSO,
  QR_CODE_NOT_FOUND,
  NOT_AUTHED,
  CLIENTS,
  CLIENTS_ADD,
  ADMINS,
  ADMINS_ADD,
  REPORTS,
  USER,
  DASHBOARD,
  SURVEYS,
  SURVEYS_ADD,
  SURVEYS_REISSUE,
  SURVEYS_SURVEY,
  SURVEYS_ROOM,
  SURVEYS_ASSET,
  SITES,
  SITES_ADD,
  SITES_EDIT,
  ASSET_LISTS,
  ASSET_LISTS_ADD,
  USERS,
  USERS_ADD,
  USERS_USER,
  REPORTING_CREATE,
  REPORTING_VIEW,
  ASSET_DATA_IMPORT,
  SETTINGS,
  NOT_FOUND,
  TOOLS,
  CROPPER,
} as const;

type RouteProps = {
  path: string;
  auth?: string;
  exact?: boolean;
  redirect?: string;
};

type NavRoutesMapProps = Record<keyof typeof ROUTES_MAP, RouteProps>;

// remove the :clientId as this is added dynamically in the nav menu component
export const NAV_ROUTES_MAP: NavRoutesMapProps = Object.keys(ROUTES_MAP).reduce(
  (accumulator, route) => {
    accumulator[route] = {
      ...ROUTES_MAP[route],
      path: ROUTES_MAP[route].path.replace('/:clientId', ''),
    };
    return accumulator;
  },
  { ...ROUTES_MAP },
);

type RouteConfigAuth = typeof ALL_ROLES[keyof typeof ALL_ROLES];

type RouteConfigItem = {
  text: string;
  path: string;
  icon: string;
  auth?: RouteConfigAuth;
  accessibleText?: string;
};

type AsseticomRouteConfigProps = {
  subHeading?: string;
  items: RouteConfigItem[];
  auth?: RouteConfigAuth;
}[];

export const asseticomRouteConfig: AsseticomRouteConfigProps = [
  {
    subHeading: 'Portals',
    items: [
      {
        text: 'Dashboard',
        path: NAV_ROUTES_MAP.ROOT.path,
        icon: 'dashboard',
        accessibleText: 'View Dashboard',
      },
      {
        text: 'View',
        path: NAV_ROUTES_MAP.CLIENTS.path,
        icon: 'view-portals',
        accessibleText: 'View Portals',
      },
      {
        text: 'Add',
        path: NAV_ROUTES_MAP.CLIENTS_ADD.path,
        icon: 'add-new',
        accessibleText: 'Add Portal',
      },
    ],
    auth: ALL_ROLES.ASSETICOM_ADMIN,
  },
  {
    subHeading: 'People',
    items: [
      {
        text: 'View',
        path: NAV_ROUTES_MAP.ADMINS.path,
        icon: 'view-users',
        accessibleText: 'View Users',
      },
      {
        text: 'Add',
        path: NAV_ROUTES_MAP.ADMINS_ADD.path,
        icon: 'add-new',
        accessibleText: 'Add User',
      },
    ],
    auth: ALL_ROLES.ASSETICOM_ADMIN,
  },
  {
    subHeading: 'Reports',
    items: [
      {
        text: 'View',
        path: NAV_ROUTES_MAP.REPORTS.path,
        icon: 'dashboard',
        accessibleText: 'View Users',
      },
    ],
    auth: ALL_ROLES.ASSETICOM_ADMIN,
  },
  {
    subHeading: 'Tools',
    items: [
      {
        text: 'View',
        path: NAV_ROUTES_MAP.INTERNAL_TOOLS.path,
        icon: 'cut',
        accessibleText: 'View Tools',
      },
    ],
    auth: ALL_ROLES.ASSETICOM_ADMIN,
  },
];

type ClientRouteConfigProps = {
  subHeading?: string;
  items: RouteConfigItem[];
  auth?: RouteConfigAuth;
}[];

export const clientRouteConfig: ClientRouteConfigProps = [
  {
    subHeading: 'Your Portal',
    items: [
      {
        text: 'Dashboard',
        path: NAV_ROUTES_MAP.DASHBOARD.path,
        icon: 'dashboard',
        accessibleText: 'View Dashboard',
      },
    ],
  },
  {
    subHeading: 'Sites & Buildings',
    auth: ALL_ROLES.LEAD_SURVEYOR,
    items: [
      {
        text: 'Add',
        path: NAV_ROUTES_MAP.SITES_ADD.path,
        icon: 'add-new',
        accessibleText: 'Add Sites & Buildings',
      },
      {
        text: 'View',
        path: NAV_ROUTES_MAP.SITES.path,
        icon: 'building',
        accessibleText: 'View Sites & Buildings',
      },
    ],
  },
  {
    subHeading: 'Surveys',
    auth: ALL_ROLES.SURVEYOR,
    items: [
      {
        text: 'Add',
        path: NAV_ROUTES_MAP.SURVEYS_ADD.path,
        icon: 'add-new',
        auth: ALL_ROLES.LEAD_SURVEYOR,
        accessibleText: 'Add Survey',
      },
      {
        text: 'View',
        path: NAV_ROUTES_MAP.SURVEYS.path,
        icon: 'edit',
        auth: ALL_ROLES.SURVEYOR,
        accessibleText: 'View Surveys',
      },
    ],
  },
  {
    subHeading: 'Asset Taxonomies',
    auth: ALL_ROLES.LEAD_SURVEYOR,
    items: [
      {
        text: 'Add',
        path: NAV_ROUTES_MAP.ASSET_LISTS_ADD.path,
        icon: 'add-new',
        accessibleText: 'Add Asset Taxonomy',
      },
      {
        text: 'View',
        path: NAV_ROUTES_MAP.ASSET_LISTS.path,
        icon: 'document',
        accessibleText: 'View Asset Taxonomies',
      },
    ],
  },
  {
    subHeading: 'Reporting',
    items: [
      {
        text: 'Create',
        path: NAV_ROUTES_MAP.REPORTING_CREATE.path,
        icon: 'add-new',
        auth: ALL_ROLES.VIEWER,
        accessibleText: 'Create a Report',
      },
      {
        text: 'View',
        path: NAV_ROUTES_MAP.REPORTING_VIEW.path,
        icon: 'document',
        auth: ALL_ROLES.VIEWER,
        accessibleText: 'View reports history',
      },
      {
        text: 'Import',
        path: NAV_ROUTES_MAP.ASSET_DATA_IMPORT.path,
        icon: 'import',
        auth: ALL_ROLES.SURVEYOR,
        accessibleText: 'Import Asset Data',
      },
    ],
  },
  {
    subHeading: 'Users',
    auth: ALL_ROLES.LEAD_SURVEYOR,
    items: [
      {
        text: 'Add',
        path: NAV_ROUTES_MAP.USERS_ADD.path,
        icon: 'add-new',
        auth: ALL_ROLES.ADMIN,
        accessibleText: 'Add User',
      },
      {
        text: 'View',
        path: NAV_ROUTES_MAP.USERS.path,
        icon: 'view-users',
        auth: ALL_ROLES.SURVEYOR,
        accessibleText: 'View Users',
      },
      // {
      //   text: 'Settings',
      //   path: NAV_ROUTES_MAP.SETTINGS.path,
      //   icon: 'settings',
      //   auth: ALL_ROLES.ADMIN,
      //   accessibleText: 'User Settings',
      // },
    ],
  },
  {
    subHeading: 'Help',
    auth: ALL_ROLES.VIEWER,
    items: [
      {
        text: 'FAQ',
        path: 'https://asseticom.co.uk/faq',
        icon: 'help',
        auth: ALL_ROLES.VIEWER,
        accessibleText: 'Frequently Asked Questions',
      },
    ],
  },
  {
    subHeading: 'Tools',
    auth: ALL_ROLES.SURVEYOR,
    items: [
      {
        text: 'Cropper',
        path: NAV_ROUTES_MAP.CROPPER.path,
        icon: 'cut',
        auth: ALL_ROLES.SURVEYOR,
        accessibleText: 'Cropper Tool',
      },
    ],
  },
];
