import { Box, Checkbox, FormControlLabel, Grid } from '@mui/material';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import {
  FORM_FIELDS_NAME,
  FORM_OPTIONS_NAME,
} from '../../../../../../config/constants';
import { useEffect, useMemo } from 'react';

export default function FormOptions() {
  const { control } = useFormContext();

  const costWatcher = useWatch({ name: `${FORM_OPTIONS_NAME}.cost`, control });

  return (
    <Grid spacing={2} container>
      <Grid item container xs={12}>
        <Box
          mt={1}
          display="flex"
          flex={1}
          flexDirection="row"
          flex-wrap="wrap"
          alignItems="flex-end"
          justifyContent="space-evenly"
        >
          <Box>
            <Controller
              name={`${FORM_OPTIONS_NAME}.unit`}
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  sx={{ mr: 0 }}
                  label="Enable Unit"
                  control={
                    <Checkbox
                      {...field}
                      color="primary"
                      checked={!!field.value}
                    />
                  }
                />
              )}
            />
          </Box>
          <Box>
            <Controller
              name={`${FORM_OPTIONS_NAME}.cost`}
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  sx={{ mr: 0 }}
                  label="Enable Cost"
                  control={
                    <Checkbox
                      {...field}
                      color="primary"
                      checked={!!field.value}
                    />
                  }
                />
              )}
            />
          </Box>
          <Box>
            <Controller
              name={`${FORM_OPTIONS_NAME}.uplift`}
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  sx={{ mr: 0 }}
                  label="Enable Uplift"
                  control={
                    <Checkbox
                      disabled={!costWatcher}
                      {...field}
                      color="primary"
                      checked={!!field.value}
                    />
                  }
                />
              )}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
