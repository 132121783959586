import { Box, Grid } from '@mui/material';
import { useEffect, useState } from 'react';

import BackButton from '../../../components/Form/BackButton';
import ButtonsContainer from '../../../components/Form/ButtonsContainer';
import FileUploaderCard from '../../../components/FileUploaderCard';
import NextButton from '../../../components/Form/NextButton';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { readURLAsData } from '../../../shared/utilities';
import { useForm } from 'react-hook-form';

const useStyles = makeStyles(() => ({
  imagePreview: {
    objectFit: 'cover',
    height: '200px',
  },
}));

const Images = ({ formData, onSubmit, onBack }) => {
  const classes = useStyles();
  const { handleSubmit, register, reset, setValue, getValues } = useForm();
  const [logoPreview, setLogoPreview] = useState();
  const [backgroundPreview, setBackgroundPreview] = useState();

  useEffect(() => {
    reset(formData, { errors: true });
  }, [formData, reset]);

  const getLogoPreview = async (file) => {
    const fileData = await readURLAsData(file);
    if (fileData) {
      register('logoImage', { required: false });
      setValue('logoImage', fileData);
      setLogoPreview(fileData);
    }
  };

  const getBackgroundPreview = async (file) => {
    const fileData = await readURLAsData(file);
    if (fileData) {
      register('backgroundImage', { required: false });
      setValue('backgroundImage', fileData);
      setBackgroundPreview(fileData);
    }
  };

  const logoImage = logoPreview || formData.logoImage;
  const bgImage = backgroundPreview || formData.backgroundImage;

  return (
    <Box p={2}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <FileUploaderCard
              title="Company Logo"
              onChange={getLogoPreview}
              fileUrl={logoImage}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FileUploaderCard
              title="Background Image"
              onChange={getBackgroundPreview}
              fileUrl={bgImage}
            />
          </Grid>
        </Grid>
        <ButtonsContainer>
          <BackButton onBack={() => onBack(getValues())} />
          <NextButton />
        </ButtonsContainer>
      </form>
    </Box>
  );
};

Images.propTypes = {
  formData: PropTypes.shape({
    backgroundImage: PropTypes.string,
    logoImage: PropTypes.string,
  }).isRequired,
  onBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default Images;
