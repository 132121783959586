import Theme, { newColours } from '../config/theme';
import { createTheme, useTheme } from '@mui/material';

import { useMemo } from 'react';

const useTableTheme = () => {
  const globalTheme = useTheme<typeof Theme>();
  return useMemo(
    () =>
      createTheme({
        ...globalTheme,
        palette: {
          ...globalTheme.palette,
          background: {
            default: newColours.light1,
          },
        },
        components: {
          ...globalTheme.components,
          MuiTableCell: {
            styleOverrides: {
              root: {
                color: newColours.blue4,
              },
            },
          },
          MuiTablePagination: {
            styleOverrides: {
              root: {
                color: newColours.blue4,
              },
            },
          },
        },
      }),
    [globalTheme],
  );
};

export default useTableTheme;
