import Add from './add';
import { Box } from '@mui/material';
import { ROUTES_MAP } from '../../../config/routes';
import RouteGuard from '../../../components/RouteGuard';
import { Switch } from 'react-router-dom';
import View from './view';

const AssetLists = () => {
  return (
    <Box>
      <Switch>
        <RouteGuard {...ROUTES_MAP.ASSET_LISTS_ADD}>
          <Add />
        </RouteGuard>
        <RouteGuard {...ROUTES_MAP.ASSET_LISTS}>
          <View />
        </RouteGuard>
      </Switch>
    </Box>
  );
};

export default AssetLists;
