import { AppState, useAppDispatch } from '../../../../../config/store';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Link,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';

import { ALL_ROLES } from '../../../../../config/roles';
import { EMPTY_ARRAY } from '../../../../../shared/helpers';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import { Role } from '../../../../../types/user';
import { Statuses } from '../../../../../types/status';
import { UrlParams } from '../../../../../types/urlParams';
import { onSelectedSurveyReissue } from '../../../../../state/survey';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import useSurvey from '../../../../../hooks/useSurvey';

const Reissue = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [surveyor, setSurveyor] = useState<Role | null>();
  const [resetRooms, setResetRooms] = useState<boolean>(false);
  const survey = useSurvey();
  const { clientId } = useParams<UrlParams>();

  // @ts-ignore
  useFirestoreConnect([
    {
      collection: 'roles',
      where: [
        ['clientId', '==', clientId],
        ['role', 'in', [ALL_ROLES.SURVEYOR, ALL_ROLES.LEAD_SURVEYOR]],
        ['deleted', '==', 0],
      ],
      storeAs: 'surveyors',
    },
  ]);

  const surveyors: Role[] = useSelector(
    (state: AppState) => state.firestore.ordered.surveyors || EMPTY_ARRAY,
  );
  //   .filter((surveyor) => {
  //   return survey.status !== Statuses.COMPLETED
  //     ? surveyor.uid !== survey.surveyorId
  //     : true;
  // });
  return (
    <Paper sx={{ width: '100%', p: 2 }} id="main-content">
      <ErrorBoundary>
        <Typography
          variant="body1"
          fontSize={18}
          mb={2}
          color="secondary"
          paragraph
        >
          Reissue
        </Typography>

        <Divider sx={{ mb: 4 }} />

        {survey.status === Statuses.IN_PROGRESS ? (
          <>
            <Box
              sx={{
                border: 1,
                borderColor: 'error.main',
                borderRadius: 2,
                p: 2,
              }}
            >
              <Typography color="error" paragraph my={2} fontWeight={700}>
                This survey is currently in progress
              </Typography>
              {survey.uploadsQueueSize ? (
                <Typography color="error" paragraph my={2}>
                  There are currently <b>{survey.uploadsQueueSize} or more</b>{' '}
                  images that have not yet been uploaded.
                </Typography>
              ) : null}
              <Typography color="error" paragraph my={2}>
                Before continuing, please ensure that{' '}
                <b>{survey.surveyorName}</b> has connected to the internet from
                their surveying device and checked that their survey data is
                fully uploaded.
                <br />
                <br />
                They can do this by navigating to the survey within the
                Asseticom app and checking whether it shows as {''}
                <b>&apos;Synced To Cloud&apos;</b>.
                <br />
                <br />
                <b>
                  Proceeding to reissue without checking may result in data
                  loss.
                </b>
                <br />
                <br />
                Should you be unsure of the status of your survey data please{' '}
                <Link color="secondary" href="mailto:support@asseticom.co.uk">
                  contact us
                </Link>{' '}
              </Typography>
            </Box>
            <Divider sx={{ my: 4 }} />
          </>
        ) : null}

        <Box my={2}>
          <Typography color="secondary" paragraph>
            Select a surveyor below in order to issue the{' '}
            <Typography component="span" color="secondary" fontWeight={700}>
              {survey.name}
            </Typography>{' '}
            survey to them.
          </Typography>
        </Box>

        <Autocomplete
          onChange={(e, data) => {
            setSurveyor(data);
          }}
          id="select-surveyor"
          options={surveyors}
          getOptionLabel={(surveyor) =>
            `${surveyor.nameFirst} ${surveyor.nameLast}`
          }
          isOptionEqualToValue={(option, value) => option.uid === value.uid}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Surveyor"
              variant="outlined"
              autoComplete="off"
            />
          )}
        />

        <Box my={2}>
          <FormGroup>
            <FormControlLabel
              componentsProps={{ typography: { color: 'secondary' } }}
              control={
                <Checkbox
                  checked={resetRooms}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setResetRooms(event.target.checked);
                  }}
                />
              }
              label="Reset room statuses to 'Not Started'?"
            />
            <Typography variant="caption" color="secondary">
              Switching on this setting will mean the surveyor must mark all
              rooms as complete before being able to finish the survey.
            </Typography>
          </FormGroup>
        </Box>

        {survey.status !== Statuses.COMPLETED &&
        survey?.surveyorId !== surveyor?.uid ? (
          <Box my={2}>
            <Typography color="secondary" my={2}>
              Once confirmed, <b>{survey.surveyorName}</b> will no longer have
              access to this survey.
            </Typography>
          </Box>
        ) : null}

        <Button
          onClick={() => {
            if (!surveyor) {
              return;
            }
            dispatch(
              onSelectedSurveyReissue({
                resetRooms,
                survey: {
                  ...survey,
                  surveyorEmail: surveyor.email,
                  surveyorName: `${surveyor.nameFirst} ${surveyor.nameLast}`,
                  surveyorId: surveyor.uid,
                },
              }),
            );
            history.push(`/${clientId}/surveys`);
          }}
          color="primary"
          variant="contained"
          disabled={!surveyor}
        >
          Confirm
        </Button>
      </ErrorBoundary>
    </Paper>
  );
};

export default Reissue;
