import {
  NOT_GOT_IMAGE_URL,
  isBlobImage,
  isLocalImage,
  isViewingLocalServer,
} from '../shared/utilities';

import { IKImage } from 'imagekitio-react';
import PropTypes from 'prop-types';

const getImageUrl = (fullUrl) => {
  if (typeof fullUrl !== 'string') {
    return '';
  }
  const url = new URL(fullUrl);
  return url.pathname + url.search;
};

const Image = ({ src, alt, width, height, ...props }) => {
  if (
    isViewingLocalServer() ||
    isLocalImage(src) ||
    isBlobImage(src) ||
    src === NOT_GOT_IMAGE_URL
  ) {
    return (
      <img
        {...props}
        alt={alt || ''}
        src={src}
        width={width}
        height={height}
        objectfit="contain"
        {...props}
      />
    );
  }
  const fullUrl = getImageUrl(src);

  return (
    <IKImage
      urlEndpoint="https://ik.imagekit.io/asseticom/"
      path={fullUrl}
      alt={alt || ''}
      transformation={[
        {
          width,
          height,
        },
      ]}
      {...props}
    />
  );
};

Image.defaultProps = {
  height: undefined,
  width: undefined,
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  alt: PropTypes.string.isRequired,
};

export default Image;
