import PropTypes from 'prop-types';

export const clientPropTypes = {
  address1: PropTypes.string,
  address2: PropTypes.string,
  backgroundImage: PropTypes.string,
  bankAccountNumber: PropTypes.string,
  bankName: PropTypes.string,
  bankSortCode: PropTypes.string,
  category: PropTypes.string,
  companyName: PropTypes.string,
  companyNumber: PropTypes.string,
  county: PropTypes.string,
  keyContactEmail: PropTypes.string,
  keyContactName: PropTypes.string,
  logoImage: PropTypes.string,
  owner: PropTypes.string,
  portalAdmins: PropTypes.arrayOf(
    PropTypes.shape({
      nameFirst: PropTypes.string,
      nameLast: PropTypes.string,
      email: PropTypes.string,
    }),
  ),
  name: PropTypes.string,
  postCode: PropTypes.string,
  townCity: PropTypes.string,
  id: PropTypes.string,
};

export const addressPropTypes = {
  address1: PropTypes.string.isRequired,
  address2: PropTypes.string,
  name: PropTypes.string.isRequired,
  townCity: PropTypes.string.isRequired,
  county: PropTypes.string.isRequired,
  postCode: PropTypes.string.isRequired,
};

export const buildingPropTypes = {
  address1: PropTypes.string,
  address2: PropTypes.string,
  name: PropTypes.string.isRequired,
  townCity: PropTypes.string,
  county: PropTypes.string,
  postCode: PropTypes.string,
  useSiteAddress: PropTypes.bool,
  clientId: PropTypes.string.isRequired,
  clientName: PropTypes.string.isRequired,
  siteName: PropTypes.string.isRequired,
  created: PropTypes.number.isRequired,
};

export const sitePropTypes = {
  ...addressPropTypes,
  buildings: PropTypes.arrayOf(PropTypes.shape(buildingPropTypes)),
};

export const userDataPropTypes = {
  nameFirst: PropTypes.string.isRequired,
  nameLast: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
  created: PropTypes.number.isRequired,
  clientId: PropTypes.string.isRequired,
};

export const roomSchedulePropTypes = {
  data: PropTypes.array,
  fileName: PropTypes.string,
  url: PropTypes.string,
};

export const floorPlanPropTypes = {
  fileName: PropTypes.string,
  url: PropTypes.string,
};

export const markerPropTypes = {
  id: PropTypes.string,
  roomId: PropTypes.string,
  lat: PropTypes.number,
  lng: PropTypes.number,
};

export const surveyPropTypes = {
  assetListId: PropTypes.string,
  buildingId: PropTypes.string,
  buildingName: PropTypes.string,
  clientId: PropTypes.string,
  created: PropTypes.number,
  external: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  floorPlan: PropTypes.shape(floorPlanPropTypes),
  gifa: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  markers: PropTypes.arrayOf(PropTypes.shape(markerPropTypes)),
  name: PropTypes.string,
  roomSchedule: PropTypes.shape(roomSchedulePropTypes),
  siteId: PropTypes.string,
  siteName: PropTypes.string,
  surveyorEmail: PropTypes.string,
  surveyorId: PropTypes.string,
  surveyorName: PropTypes.string,
  surveyType: PropTypes.string,
};

export const partialPropTypes = {
  costPercentage: PropTypes.number.isRequired,
  created: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  imageURL: PropTypes.string,
  nextRenewal: PropTypes.number.isRequired,
  reccurs: PropTypes.bool,
  reccursPeriod: PropTypes.number,
};

export const assetAttributesPropTypes = {
  code0: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  code1: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  code2: PropTypes.string,
  code3: PropTypes.string,
  label0: PropTypes.string,
  label1: PropTypes.string,
  label2: PropTypes.string,
  label3: PropTypes.string,
  costPerUnit: PropTypes.number,
  expectedLife: PropTypes.number,
  uom: PropTypes.string,
  uplift: PropTypes.number,
};

export const assetValuesPropTypes = {
  condition: PropTypes.string.isRequired,
  healthAndSafetyIssue: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
  remainingLife: PropTypes.number.isRequired,
  assetDescription: PropTypes.string.isRequired,
  additionalComments: PropTypes.string,
};

export const assetPropTypes = {
  attributes: PropTypes.shape(assetAttributesPropTypes).isRequired,
  clientId: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
  confidence: PropTypes.number.isRequired,
  created: PropTypes.number.isRequired,
  imageWidth: PropTypes.string.isRequired,
  imageHeight: PropTypes.string.isRequired,
  imageURL: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  partials: PropTypes.objectOf(PropTypes.shape(partialPropTypes)),
  roomId: PropTypes.string.isRequired,
  siteId: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  surveyId: PropTypes.string.isRequired,
  surveyorId: PropTypes.string.isRequired,
  values: PropTypes.shape(assetValuesPropTypes).isRequired,
};
