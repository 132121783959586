import { Grid } from '@mui/material';
import { FormFieldRowElementProps } from '../../../../../../types/form';
import { DECIMAL_REGEX, DECIMAL_VALIDATION_MESSAGE } from '../constants';
import FormFieldDefaultTextInput from './FormFieldDefaultTextInput';
import FormFieldMinMax from './FormFieldMinMax';

export default function FormFieldRowDecimal({
  formField,
  index,
}: FormFieldRowElementProps) {
  return (
    <Grid container spacing={2} mt={1}>
      <Grid item xs={4}>
        <FormFieldDefaultTextInput
          value={formField.default}
          identifier="default"
          index={index}
          pattern={{
            value: DECIMAL_REGEX,
            message: DECIMAL_VALIDATION_MESSAGE,
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <FormFieldMinMax
          identifier="min"
          value={formField.min}
          index={index}
          label="Minimum Value"
          pattern={{
            value: DECIMAL_REGEX,
            message: DECIMAL_VALIDATION_MESSAGE,
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <FormFieldMinMax
          identifier="max"
          value={formField.max}
          index={index}
          label="Maximum Value"
          pattern={{
            value: DECIMAL_REGEX,
            message: DECIMAL_VALIDATION_MESSAGE,
          }}
        />
      </Grid>
    </Grid>
  );
}
