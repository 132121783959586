import { Box } from '@mui/material';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { NAV_ROUTES_MAP } from '../../config/routes';
import Add from './Add';
import View from './View';

const Admins = () => {
  return (
    <Box>
      <Switch>
        <Route {...NAV_ROUTES_MAP.ADMINS_ADD}>
          <Add />
        </Route>
        <Route {...NAV_ROUTES_MAP.ADMINS}>
          <View />
        </Route>
      </Switch>
    </Box>
  );
};

export default Admins;
