import {
  Box,
  Button,
  Chip,
  Grid,
  Link,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { NavLink, useParams } from 'react-router-dom';
import {
  assetImportRowSchema,
  partialImportRowSchema,
} from '../../../schemas/asset';
import {
  getSafeTextFromText,
  handlePlural,
  parseXLSX,
  readURLAsBinary,
} from '../../../shared/utilities';
import { useMemo, useRef, useState } from 'react';

import { AssetAttributes } from '../../../types/asset';
import { EMPTY_ARRAY } from '../../../shared/helpers';
import FileUploaderCard from '../../../components/FileUploaderCard';
import { FormField } from '../../../types/form';
import { SurveyProps } from '../../../types/survey';
import { UrlParams } from '../../../types/urlParams';
import cloneDeep from 'lodash.clonedeep';
import { colours } from '../../../config/theme';
import firebase from 'firebase/app';
import { getAssetListById } from '../../../hooks/useAssetList';
import { getFirebase } from 'react-redux-firebase';
import { getLastUpdated } from '../../../shared/logTools';
import isEqual from 'lodash.isequal';
import { makeStyles } from '@mui/styles';
import { useModal } from '../../../components/Modal';
import { z } from 'zod';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
}));

const IGNORE_COLUMNS = [
  'Created',
  'Last Updated',
  'Site Name',
  'Survey Name',
  'Building Name',
  'Room Name',
  'Surveyor Name',
  'Code 0',
  'Label 0',
  'Code 1',
  'Label 1',
  'Code 2',
  'Label 2',
  'Floor Name',
  'Name',
  'Renewal Cost',
  'Expected Life',
  'ID',
  'Relationship',
  'Parent ID',
  'Image 1',
  'Image 2',
  'Image 3',
  'Image 4',
  'Image 5',
];

const PARTIAL_IMPORT_MAP = {
  ['Reccurs']: 'reccurs',
  ['Expected Life']: 'reccursPeriod',
  ['Remaining Life']: 'nextRenewal',
  ['UOM']: 'uom',
  ['Renewal Multiplier']: 'costPercentage',
  ['Description']: 'description',
};

const ASSET_ATTRIBUTES_MAP = {
  ['Label 3']: 'label3',
  ['Code 3']: 'code3',
  ['Cost']: 'costPerUnit',
  ['UOM']: 'uom',
  ['Uplift Multiplier']: 'uplift',
};

const ASSET_VALUES_MAP = {};

const NOT_APPLICABLE = 'N/A';
const CHILD_ROW = 'Child';

type InitialData = {
  surveyId?: string;
  formFields?: FormField[];
  assetList?: AssetAttributes[];
  errors: ImportError[];
};

type ImportRow = {
  Created: string;
  'Last Updated': string;
  Relationship: string;
  'Site Name': string;
  'Building Name': string;
  'Survey Name': string;
  'Room Name': string;
  'Floor Name': string;
  'Surveyor Name': string;
  'Code 0': string;
  'Label 0': string;
  'Code 1': string;
  'Label 1': string;
  'Code 2': string;
  'Label 2': string;
  'Code 3': string;
  'Label 3': string;
  Name: string;
  Description: string;
  Quantity: number;
  UOM: string;
  Cost: number;
  'Uplift Multiplier': number;
  'Renewal Multiplier': number;
  'Renewal Cost': number;
  'Remaining Life': number;
  'Expected Life': number;
  Reccurs: string;
  ID: string;
  'Parent ID': string;
  'Image 1': string;
  'Image 2': string;
  'Image 3': string;
  'Image 4': string;
  'Image 5': string;
};

type ImportError = {
  row: string | number;
  column: string | number;
  message: string;
};

type UpdateResult = {
  success?: { type: 'asset' | 'partial' };
  error?: {
    row: number;
    column: string;
    message: string;
  };
  warning?: {
    row: number;
    column: string;
    message: string;
  };
};

type ImportData = {
  name: string;
  mappedRows: Map<string, ImportRow[]>;
  allRows: Partial<ImportRow>[];
  counters: {
    assets: number;
    partials: number;
  };
} & InitialData;

const errorMap: z.ZodErrorMap = (error, ctx) => {
  /*
  This is where you override the various error codes
  */
  const [row, column] = error.path;

  switch (error.code) {
    case z.ZodIssueCode.invalid_type:
      return {
        message: `Expected a ${error.expected} but received a ${error.received}`,
      };
    case z.ZodIssueCode.invalid_union: {
      const firstIssue = error.unionErrors[0].issues[0];

      if (
        firstIssue.code === z.ZodIssueCode.invalid_type &&
        column !== 'Code 3'
      ) {
        return {
          message: `Expected a ${firstIssue.expected}`,
        };
      }
      break;
    }
    case z.ZodIssueCode.invalid_enum_value:
      if (column === 'Code 3') {
        return {
          message: `${ctx.data} does not match any Level 3 asset codes in your taxonomy`,
        };
      }
      if (error.options.length <= 10) {
        return {
          message: `${
            ctx.data
          } does not match any of the valid options - ${error.options.join(
            ', ',
          )}`,
        };
      }
      break;
  }

  // fall back to default message!
  return { message: ctx.defaultError };
};

async function checkForMultipleSurveys(data) {
  // const surveyNames = new Set(data.rows.map((r) => r['Survey Name']));
  // if (surveyNames.size > 1) {
  //   data.errors.push(
  //     `Multiple surveys - You can only import assets for a single survey, you currently have assets for ${Array.from(
  //       surveyNames,
  //     ).join(', ')}`,
  //   );
  // }
}

function removeDeletedAssets(docs) {
  return docs.filter((doc) => doc.data().deleted === 0);
}

async function getInitialData(rows, clientId): Promise<InitialData> {
  const data: InitialData = { errors: [] };
  const fireStore = firebase.firestore();
  const firstRow = rows[0];
  const firstRowId = firstRow['ID'];
  if (!firstRowId) {
    window.alert('your data is malformed');
    throw new Error('your data is malformed');
  }

  const firstAssetDoc = await fireStore
    .collectionGroup('assets')
    .where('clientId', '==', clientId)
    .where('id', '==', firstRowId)
    .get();

  const deletedAssetsRemoved = removeDeletedAssets(firstAssetDoc.docs)?.[0];
  const assetData = deletedAssetsRemoved?.data();
  if (!assetData) {
    data.errors.push({
      row: 0,
      column: 'ID',
      message: `No asset with id ${firstRowId} found`,
    });
    return data;
  }
  const { surveyId } = assetData;
  const surveyDoc = await fireStore
    .collection('clients')
    .doc(clientId)
    .collection('surveys')
    .doc(surveyId)
    .get();
  data.surveyId = surveyDoc.id;
  const surveyData = surveyDoc.data() as SurveyProps;
  data.formFields = surveyData.formFields;
  const assetListDoc = await fireStore
    .collection('clients')
    .doc(clientId)
    .collection('assetLists')
    .doc(surveyData.assetListId)
    .get();
  // data.assetList = assetListDoc.data()?.data as AssetAttributes[];

  data.assetList = await getAssetListById(clientId, surveyData.assetListId);

  return data;
}

function parseAssetUpdate(
  existingAsset,
  assetUpdate,
  allowedValues,
  formFields,
) {
  const parsed = { ...existingAsset };
  for (const [key, value] of Object.entries(assetUpdate)) {
    // This is for a very edge case where one of the form field options is
    // N/A, in which case we don't want to ignore updating the value

    const formField = formFields.find((ff) => {
      return ff.label === key;
    });
    const hasNotApplicableOption = formField?.options?.find(
      (o) => o.value === NOT_APPLICABLE,
    );
    if (
      (value === NOT_APPLICABLE && !hasNotApplicableOption) ||
      IGNORE_COLUMNS.includes(key) ||
      // formfield might not exist if it's not a form field ie Cost, UOM
      (!value && formField?.required)
    ) {
      continue;
    }

    const transformedAttributeProp = ASSET_ATTRIBUTES_MAP[key];
    if (transformedAttributeProp) {
      parsed.attributes[transformedAttributeProp] = value;
    } else if (Object.keys(allowedValues).includes(key)) {
      parsed.values[getSafeTextFromText(key)] = value;
    }
  }
  return parsed;
}

function parsePartialUpdate(existingPartial, partialUpdate) {
  const parsed = { ...existingPartial };
  for (const [key, value] of Object.entries(partialUpdate)) {
    if (value === NOT_APPLICABLE) {
      continue;
    }
    const transformedProp = PARTIAL_IMPORT_MAP[key];
    if (transformedProp) {
      parsed[transformedProp] = value;
    }
  }
  return parsed;
}

async function updateAssetFromImportRow(
  assetId: string,
  clientId: string,
  data: ImportData,
): Promise<UpdateResult[]> {
  const updates = data.mappedRows.get(assetId);

  const fireStore = getFirebase().firestore();
  const queryResult = await fireStore
    .collectionGroup('assets')
    .where('clientId', '==', clientId)
    .where('id', '==', assetId)
    .get();
  const updateResults: UpdateResult[] = [];
  return new Promise((resolve) => {
    if (!queryResult.size) {
      //need to add 2 to cater for 0 index of array and also header row
      const rowNum = data.allRows.findIndex((r) => r['ID'] === assetId) + 2;
      updateResults.push({
        error: {
          row: rowNum,
          column: 'ID',
          message: `No asset with id ${assetId} found`,
        },
      });
    }
    const assetDoc = removeDeletedAssets(queryResult?.docs)?.[0];
    const assetData = assetDoc?.data();

    if (assetData && updates) {
      let assetUpdateObj = cloneDeep(assetData);
      updates.forEach((update) => {
        const isPartial = update['Relationship'] === CHILD_ROW;
        const id = update['ID'];
        //need to add 2 to cater for 0 index of array and also header row
        const rowNum = data.allRows.findIndex((r) => r['ID'] === id) + 2;
        if (isPartial) {
          const existingPartial = assetData.partials[id];
          if (!existingPartial) {
            return updateResults.push({
              error: {
                row: rowNum,
                column: 'ID',
                message: `No partial renewal with id ${id} found`,
              },
            });
          }

          assetUpdateObj.partials[update['ID']] = parsePartialUpdate(
            existingPartial,
            update,
          );

          if (isEqual(assetUpdateObj.partials[update['ID']], existingPartial)) {
            updateResults.push({
              warning: {
                row: rowNum,
                column: 'ID',
                message: `Partial renewal with id ${id} was not updated as there were no changes to update`,
              },
            });
          } else {
            updateResults.push({
              success: { type: 'partial' },
            });
          }
        } else {
          const allowedValues = data.formFields?.reduce((acc, ff) => {
            return { ...acc, [ff.label]: ff.id };
          }, {});
          assetUpdateObj = parseAssetUpdate(
            assetUpdateObj,
            update,
            allowedValues,
            data.formFields,
          );
        }

        if (!isPartial && !isEqual(assetData, assetUpdateObj)) {
          assetUpdateObj.label = assetUpdateObj.attributes.label3;
          assetUpdateObj.code = assetUpdateObj.attributes.code3;
          assetDoc.ref.update({
            ...assetUpdateObj,
            ...getLastUpdated(),
          });

          updateResults.push({
            success: { type: 'asset' },
          });
        } else if (!isPartial) {
          updateResults.push({
            warning: {
              row: rowNum,
              column: 'ID',
              message: `Asset with id ${assetId} was not updated as there were no changes to update`,
            },
          });
        }
      });
    }
    resolve(updateResults);
  });
}

function checkForValidAssetType(row, assetList) {
  if (assetList) {
    return assetList.find((item) => item.code3 === row['Code 3']);
  }
}

function sortByRow(a, b) {
  return a.row - b.row;
}

const AssetDataImport = () => {
  const classes = useStyles();
  const fileUploaderRef = useRef();
  const { clientId } = useParams<UrlParams>();
  const [initialErrors, setInitialErrors] = useState<ImportError[]>();
  const [importData, setImportData] = useState<ImportData>();
  const [updateResults, setUpdateResults] = useState<UpdateResult[]>([]);
  const { showModal } = useModal();

  const setFileData = async (file) => {
    const { name } = file;
    const data = await readURLAsBinary(file);

    setUpdateResults([]);

    // pass empty string for default value so empty columns will have something in them.
    // means non-required fields can be set to empty values
    const assetImports = await parseXLSX<ImportRow>(data, undefined, '');

    const assetImportMap = new Map();

    const initialData = await getInitialData(assetImports.rows, clientId);

    const { assetList, formFields } = initialData;

    if (!assetList || !formFields) {
      return;
    }

    const allIssues: z.ZodIssue[] = [];

    const assets: ImportRow[] = [];
    const partials: ImportRow[] = [];

    assetImports.rows.forEach((row) => {
      if (row.Relationship === CHILD_ROW) {
        partials.push(row);
      } else {
        assets.push(row);
      }
    });

    const partialsSchemaCheck = z
      .array(partialImportRowSchema)
      .safeParse(partials, { errorMap });

    if (!partialsSchemaCheck.success) {
      allIssues.push(...partialsSchemaCheck.error.issues);
    }

    const code3Array = assetList.map((item) => String(item.code3));

    const code3CheckSchema = z.object({
      // @ts-ignore
      'Code 3': z.preprocess(String, z.enum(code3Array)),
    });

    const convertFieldsToZod = formFields.reduce((acc, ff) => {
      let baseType;
      // FIXED - TRIM whitespace from options as it was causing issues with validation
      // It's now fixed at source but this should remain here to catch surveys
      // That have already been created with whitespace at the end of the field options
      const options = ff.options?.map((o) => o.value?.trim());
      // FROM DEBUGGING WHITE SPACE AT END - LEFT IN AS MAY NEED IN FUTURE
      // const hasSpace = options?.filter((o) => o.charAt(o.length - 1) === ' ');
      // if (hasSpace) {
      //   console.log(ff.label, ': ', hasSpace);
      // }
      switch (ff.type) {
        case 'Decimal':
        case 'Number':
        case 'Slider':
          baseType = z.coerce
            .number()
            .nonnegative()
            .or(z.literal(NOT_APPLICABLE))
            .nullish();
          break;
        case 'Barcode':
        case 'Text':
          baseType = z.coerce
            .string({ errorMap })
            .or(z.literal(NOT_APPLICABLE))
            .nullish();
          break;
        case 'Select':
        case 'Radio':
          baseType = z
            .preprocess(
              // as it stands, select and radio output string values in the app
              // so value should be forced to String before validating against the enums
              String,
              // @ts-ignore
              z.enum(options),
            )
            .or(z.literal(NOT_APPLICABLE))
            .nullish();
          break;
      }
      if (!ff.required || ff.disabled || ff.hidden) {
        // baseType = baseType.optional();
      }
      return { ...acc, [ff.label]: baseType };
    }, {});

    const ffSchema = z.object({ ...convertFieldsToZod });

    const assetsSchemaCheck = z
      .array(assetImportRowSchema.merge(code3CheckSchema).merge(ffSchema))
      .safeParse(assets, { errorMap });

    if (!assetsSchemaCheck.success) {
      allIssues.push(...assetsSchemaCheck.error.issues);
    }

    if (allIssues.length) {
      const parsed = allIssues.map((issue) => {
        const [row, column] = issue.path;
        return {
          row: typeof row === 'number' ? row + 1 : row,
          column,
          message: issue.message,
        };
      });
      return setInitialErrors(parsed);
    } else {
      setInitialErrors([]);
    }

    if (partialsSchemaCheck.success && assetsSchemaCheck.success) {
      [...partialsSchemaCheck.data, ...assetsSchemaCheck.data].forEach(
        (row, index) => {
          const validAssetType = checkForValidAssetType(
            row,
            initialData.assetList,
          );
          if (validAssetType) {
            row['Code 0'] = validAssetType.code0;
            row['Label 0'] = validAssetType.label0;
            row['Code 1'] = validAssetType.code1;
            row['Label 1'] = validAssetType.label1;
            row['Code 2'] = validAssetType.code2;
            row['Label 2'] = validAssetType.label2;
            row['Code 3'] = validAssetType.code3;
            row['Label 3'] = validAssetType.label3;
            row['Name'] = validAssetType.label3;
          }

          const isPartial = row['Relationship'] === CHILD_ROW;
          const assetId = isPartial ? row['Parent ID'] : row['ID'];
          const existingOrNew = assetImportMap.get(assetId) || [];
          assetImportMap.set(assetId, [...existingOrNew, row]);
        },
      );
    } else {
      return;
    }

    const counters = { partials: 0, assets: 0 };

    assetImports.rows.forEach((row) => {
      if (row.Relationship === 'Parent') {
        counters.assets++;
      } else if (row.Relationship === 'Child') {
        counters.partials++;
      }
    });

    setImportData({
      ...initialData,
      allRows: assetImports.rows,
      mappedRows: assetImportMap,
      name,
      counters,
    });
  };

  const onClickedRemove = () => {
    setImportData(undefined);
  };

  const onClickStartImport = () => {
    if (clientId && importData?.mappedRows) {
      const assetIds = importData.mappedRows.keys();

      importData?.mappedRows.forEach(async (rowMap) => {
        const results = await updateAssetFromImportRow(
          assetIds.next().value,
          clientId,
          importData,
        );
        setUpdateResults((prev: UpdateResult[]) => [...prev, ...results]);
      });
    }
  };

  const warnings = useMemo(() => {
    return updateResults
      .filter((u) => !!u.warning)
      .map((u) => u.warning)
      .sort(sortByRow);
  }, [updateResults]);

  const errors = useMemo(() => {
    return updateResults
      .filter((u) => !!u.error)
      .map((u) => u.error)
      .sort(sortByRow);
  }, [updateResults]);

  const assetSuccesses: UpdateResult[] = useMemo(() => {
    return updateResults
      .filter((u) => u.success?.type === 'asset')
      .sort(sortByRow);
  }, [updateResults]);

  const partialSuccesses: UpdateResult[] = useMemo(() => {
    return updateResults
      .filter((u) => u.success?.type === 'partial')
      .sort(sortByRow);
  }, [updateResults]);

  return (
    <Grid container spacing={2} alignItems="stretch">
      <Grid item xs={12}>
        <Paper sx={{ mb: 2 }} id="main-content">
          <Box
            p={2}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
          >
            <Chip
              sx={{ mb: 2 }}
              variant="outlined"
              label="Step 1"
              color="primary"
              role="presentation"
            />

            <Typography color="secondary" variant="body1">
              Export asset data for a single survey{' '}
              <Link
                to={`/${clientId}/asset-data-export`}
                component={NavLink}
                underline="hover"
              >
                here
              </Link>
              .
            </Typography>

            <Chip
              sx={{ my: 2 }}
              variant="outlined"
              label="Step 2"
              color="primary"
              role="presentation"
            />

            <Typography color="secondary" variant="body1" align="left">
              Ensure any data you amend is valid for the survey.
            </Typography>
            <Typography color="secondary" variant="body1">
              The following columns may be updated:
            </Typography>
            <Grid container>
              <Grid item xs={12} sm={6} lg={3}>
                <Stack>
                  <Typography mt={2} color="primary" align="left">
                    Asset:
                  </Typography>
                  <Typography color="secondary" variant="caption" align="left">
                    Code 3<br />
                    Description
                    <br />
                    Quantity
                    <br />
                    UOM
                    <br />
                    Cost
                    <br />
                    Uplift Multiplier
                    <br />
                    + Any bespoke fields added for this survey
                    <br />
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <Stack>
                  <Typography mt={2} color="primary" align="left">
                    Partial Renewal:
                  </Typography>
                  <Typography color="secondary" variant="caption" align="left">
                    Description
                    <br />
                    Renewal Multiplier
                    <br />
                    Remaining Life
                    <br />
                    Expected Life
                    <br />
                    Reccurs
                    <br />
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
            <Chip
              sx={{ my: 2 }}
              variant="outlined"
              label="Step 3"
              color="primary"
              role="presentation"
            />

            <Typography color="secondary" variant="body1">
              Upload your edited asset data below and follow the prompts.
            </Typography>
          </Box>
        </Paper>
        <Paper>
          <FileUploaderCard
            ref={fileUploaderRef}
            // @ts-ignore
            title="Asset Data Import"
            onChange={(file) => setFileData(file)}
            onRemove={importData && onClickedRemove}
            fileName={importData?.name}
          />
        </Paper>
        {initialErrors?.length ? (
          <Paper sx={{ mt: 2, p: 2 }}>
            <Typography sx={{ color: 'red' }}>
              The following errors occured, please correct them and try again:
            </Typography>
            <Typography sx={{ color: 'red' }} mt={2}>
              No asset data has been changed.
            </Typography>
            <Box mt={2}>
              {initialErrors.map((error) => (
                <Box key={`${error.row}${error.column}`} mt={2}>
                  <Typography variant="body2" color="secondary">
                    Row: {Number(error.row) + 1} - Column: {error.column} -{' '}
                    {error.message}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Paper>
        ) : null}
        {!updateResults.length && importData?.allRows?.length ? (
          <Paper sx={{ mt: 2, p: 2 }}>
            <Typography color="primary" mb={2}>
              Asset data is valid - Ready for import:
            </Typography>

            <Typography my={2} color="secondary" variant="body2">
              {handlePlural(importData.counters.assets, 'assets', true)}{' '}
            </Typography>
            {importData.counters.partials ? (
              <Typography mb={2} color="secondary" variant="body2">
                {handlePlural(
                  importData.counters.partials,
                  'partial renewals',
                  true,
                )}
              </Typography>
            ) : null}

            <Button onClick={onClickStartImport} variant="contained">
              Start Import
            </Button>
          </Paper>
        ) : null}
        {importData?.name && updateResults.length ? (
          <Paper sx={{ mt: 2, p: 2 }}>
            <Typography color="primary" mb={2}>
              Asset data import complete
            </Typography>

            {assetSuccesses.length ? (
              <Stack direction="row" mb={2}>
                <Typography sx={{ fontWeight: 700 }} color="primary" mr={1}>
                  {assetSuccesses.length}
                </Typography>
                <Typography color="secondary">
                  {handlePlural(assetSuccesses.length, 'assets', false)}{' '}
                  successfully updated
                </Typography>
              </Stack>
            ) : null}

            {partialSuccesses.length ? (
              <Stack direction="row" mb={2}>
                <Typography sx={{ fontWeight: 700 }} color="primary" mr={1}>
                  {partialSuccesses.length}
                </Typography>
                <Typography color="secondary">
                  {handlePlural(
                    partialSuccesses.length,
                    'partial renewals',
                    false,
                  )}{' '}
                  successfully updated
                </Typography>
              </Stack>
            ) : null}

            {errors.length ? (
              <Typography mb={1} sx={{ color: colours.red }}>
                Errors:
              </Typography>
            ) : null}
            {errors.length
              ? errors.map((e) => (
                  <Typography variant="caption" paragraph mb={1} key={e?.row}>
                    Row {e?.row}: {e?.message}
                  </Typography>
                ))
              : null}

            {warnings.length ? (
              <Typography mb={1} sx={{ color: colours.orange }}>
                Warnings:
              </Typography>
            ) : null}
            {warnings.length
              ? warnings.map((w) => (
                  <Typography variant="caption" paragraph mb={1} key={w?.row}>
                    Row {w?.row}: {w?.message}
                  </Typography>
                ))
              : null}
          </Paper>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default AssetDataImport;
