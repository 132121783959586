import { Box, Button, FormControl, Grid, Paper } from '@mui/material';
import { Controller, useForm, useFormState } from 'react-hook-form';
import {
  autoSizeWorksheetWidth,
  getSafeExcelSheetName,
  getStyledExportHeaders,
  getStyledRows,
} from '../../shared/utilities';

import { ALL_ROLES } from '../../config/roles';
import { CommentsDisabledSharp } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers';
import TitleBar from '../../components/MainContent/TitleBar';
import XLSX from 'xlsx-js-style';
import dayjs from 'dayjs';
import firebase from 'firebase/app';

function roleFromRoleNumber(roleNumber) {
  return (
    Object.keys(ALL_ROLES).find((key) => ALL_ROLES[key] === roleNumber) ||
    'NONE'
  );
}

const Reports = () => {
  const { handleSubmit, reset, watch, control, setValue, getValues } =
    useForm();

  const { errors } = useFormState({
    control,
  });

  const dateFromWatcher = watch('dateFrom');
  const dateToWatcher = watch('dateTo');

  async function exportRoleUpdates() {
    const firestore = firebase.firestore();
    console.log(
      'Exporting Role Updates',
      dateFromWatcher.toDate(),
      dateToWatcher.toDate(),
    );
    const snapshot = await firestore
      .collection('role-updates')
      .where('timestamp', '>=', dateFromWatcher.toDate())
      .where('timestamp', '<=', dateToWatcher.toDate())
      .get();

    const workbook = XLSX.utils.book_new();

    const SHOW_COLUMNS = [
      'Portal',
      'Timestamp',
      'User Name',
      'User Email',
      'Role',
      'Role Before',
      'Type',
    ];

    const ALL_COLUMNS = getStyledExportHeaders(SHOW_COLUMNS);

    const rows = snapshot.docs.map((updateDoc) => {
      const update = updateDoc.data();

      return [
        {
          v: update.clientName,
          t: 's',
        },
        {
          // v: dayjs(update.timestamp.toDate()).format('DD/MM/YYYY HH:mm:ss'),
          v: dayjs(update.timestamp.toDate()).format('DD/MM/YYYY HH:mm:ss'),
          t: 's',
        },
        {
          v: `${update.nameFirst} ${update.nameLast}`,
          t: 's',
        },
        {
          v: `${update.email}`,
          t: 's',
        },
        {
          v: roleFromRoleNumber(update.role),
          t: 's',
        },
        {
          v: roleFromRoleNumber(update.previousRole),
          t: 's',
        },
        {
          v: update.type,
          t: 's',
        },
      ];
    });

    const styledRows = getStyledRows(rows);

    const worksheet = XLSX.utils.aoa_to_sheet([ALL_COLUMNS, ...styledRows]);
    XLSX.utils.sheet_add_aoa(worksheet, [ALL_COLUMNS], {
      origin: 'A1',
    });

    const fixedWorksheet = await autoSizeWorksheetWidth(worksheet);
    const workbookName = getSafeExcelSheetName(`Role Report`);

    XLSX.utils.book_append_sheet(workbook, fixedWorksheet, workbookName);
    XLSX.writeFile(workbook, `${workbookName}.xlsx`);
  }

  return (
    <Grid container spacing={2} pt={2}>
      <Grid item alignItems="flex-end" spacing={2} justifyContent="center">
        <Paper>
          <Box p={2}>
            <TitleBar title="Export Role Report"></TitleBar>
          </Box>
          <Box p={2}>
            <FormControl
              variant="outlined"
              fullWidth
              error={!!errors.dateFrom}
              className=".MuiFormControl-marginNormal"
            >
              <Controller
                control={control}
                name="dateFrom"
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <DatePicker
                    maxDate={dateToWatcher}
                    onChange={field.onChange}
                    label="Date From"
                    sx={{ width: '100%' }}
                  />
                )}
              />
            </FormControl>
          </Box>
          <Box p={2}>
            <FormControl
              variant="outlined"
              fullWidth
              error={!!errors.dateTo}
              className=".MuiFormControl-marginNormal"
            >
              <Controller
                control={control}
                name="dateTo"
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <DatePicker
                    minDate={dateFromWatcher}
                    maxDate={dayjs(Date.now())}
                    onChange={(value) =>
                      field.onChange(dayjs(value).endOf('day'))
                    }
                    label="Date To"
                    sx={{ width: '100%' }}
                  />
                )}
              />
            </FormControl>
          </Box>
          <Box p={2}>
            <Button
              disabled={
                Object.keys(errors).length > 0 ||
                !dateFromWatcher ||
                !dateToWatcher
              }
              fullWidth
              variant="contained"
              color="primary"
              onClick={exportRoleUpdates}
            >
              Export Role Updates
            </Button>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Reports;
