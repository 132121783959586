import 'leaflet/dist/leaflet.css';

import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useDispatch, useSelector } from 'react-redux';

import ErrorBoundary from '../../../../components/ErrorBoundary';
import { Grid } from '@mui/material';
import UserForm from '../components/UserForm';
import { getUserRolePretty } from '../../../../config/roles';
import { getUserRoleStringId } from '../../../../shared/utilities';
import { onClientUserUpdate } from '../../../../state/user';
import { useParams } from 'react-router-dom';

const Edit = () => {
  const dispatch = useDispatch();
  const { clientId, userId } = useParams();
  const user = useSelector((state) => state.firestore.data.user);
  const role = useSelector((state) => state.firestore.data.role);

  useFirestoreConnect({
    collection: 'roles',
    doc: getUserRoleStringId(userId, clientId),
    storeAs: 'role',
  });

  const handleUserSubmit = async (formData) => {
    const { user: updatedUser } = await dispatch(
      onClientUserUpdate({ formData }),
    );
    if (updatedUser) {
      // w00t
    } else {
      // must be an error
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {isLoaded(role) && (
          <ErrorBoundary>
            <UserForm onSubmit={handleUserSubmit} formData={role} editMode />
          </ErrorBoundary>
        )}
      </Grid>
    </Grid>
  );
};

export default Edit;
