import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { setLoading, setSnackMessage } from '../../state/ui';

import ChangePassword from '../../components/ChangePassword';
import DynamicIcon from '../../components/DynamicIcon';
import LockIcon from '@mui/icons-material/Lock';
import { colours } from '../../config/theme';
import { getFirebase } from 'react-redux-firebase';
import { makeStyles } from '@mui/styles';
import { passwordRegex } from '../../shared/utilities';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.secondary.main,
    height: '100vh',
    width: '100vw',
  },
  card: {
    maxWidth: 480,
    overflow: 'visible',
  },
  form: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  iconContainer: {
    transform: `translateY(-50px)`,
    width: '60px',
    height: '60px',
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    stroke: theme.palette.primary.main,
    height: '40px',
    marginRight: '10px',
  },
  companyName: {
    fontFamily: 'Open Sans',
  },
}));

const searchParams = new URLSearchParams(window.location.search);

const PasswordReset = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({ defaultValues: { username: '' } });
  const classes = useStyles();
  const dispatch = useDispatch();
  const firebase = getFirebase();
  const [resetObj, setResetObj] = useState({ email: '' });

  useEffect(() => {
    // Get the action to complete.
    const mode = searchParams.get('mode');
    // Get the one-time code from the query parameter.
    const actionCode = searchParams.get('oobCode');
    // (Optional) Get the continue URL from the query parameter if available.
    const continueUrl = searchParams.get('continueUrl');
    // (Optional) Get the language code if available.
    const lang = searchParams.get('lang') || 'en';

    setResetObj({ ...resetObj, mode, actionCode, continueUrl, lang });
  }, [searchParams]);

  useEffect(() => {
    const verifyCode = async () => {
      try {
        const email = await firebase
          .auth()
          .verifyPasswordResetCode(resetObj.actionCode);
        if (email) {
          setResetObj({ ...resetObj, email });
        }
      } catch (error) {
        console.error(error);
      }
    };
    if (resetObj.actionCode) {
      verifyCode();
    }
  }, [resetObj.actionCode]);

  const onSubmit = async (formData) => {
    try {
      const { newPassword } = formData;
      if (passwordRegex.test(newPassword) && resetObj.email) {
        dispatch(
          setLoading({
            loadingMessage: 'Resetting password...Please wait',
          }),
        );
        await firebase
          .auth()
          .confirmPasswordReset(resetObj.actionCode, newPassword);
        dispatch(
          setLoading({
            isLoading: false,
          }),
        );
        dispatch(setSnackMessage({ message: 'Your password has been reset' }));
        await firebase
          .auth()
          .signInWithEmailAndPassword(resetObj.email, newPassword);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Box
      className={classes.container}
      display="flex"
      justifyContent="center"
      alignItems="center"
      p={2}
    >
      <Card className={classes.card} raised>
        <CardContent>
          <Box display="flex" justifyContent="space-between" mb={1}>
            <Box
              bgcolor="secondary.main"
              color="white"
              boxShadow={1}
              className={classes.iconContainer}
            >
              <LockIcon fontSize="large" />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <DynamicIcon
                className={classes.logo}
                name="logo-square"
                colour="primary"
              />
              <Typography
                variant="h6"
                color="primary"
                className={classes.companyName}
              >
                Asseticom
              </Typography>
            </Box>
          </Box>

          <Box minWidth="50%">
            <Typography variant="h5" align="center" paragraph>
              Password Reset
            </Typography>
            <Divider />
            {resetObj.email ? (
              <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
                <input
                  type="email"
                  autoComplete="username"
                  id="username"
                  defaultValue={resetObj.email}
                  hidden
                />

                <ChangePassword control={control} errors={errors} />
                <Box my={2} justifyContent="space-between" display="flex">
                  <Button
                    variant="contained"
                    onClick={handleSubmit}
                    color="primary"
                    type="submit"
                  >
                    Submit
                  </Button>
                </Box>
              </form>
            ) : null}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default PasswordReset;
