import { Box, Grid } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';

import Steps from '../../steps';
import TitleBar from '../../../../../components/MainContent/TitleBar';
import { makeStyles } from '@mui/styles';
import { onSurveySubmitted } from '../../../../../state/survey';
import { recordError } from '../../../../../shared/logTools';
import { setLoading } from '../../../../../state/ui';
import { useAppDispatch } from '../../../../../config/store';
import { useModal } from '../../../../../components/Modal';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(2),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
}));

const Issue = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { clientId, surveyId } = useParams();
  const history = useHistory();
  const { showModal } = useModal();

  const survey = useSelector((state) => state.firestore.data.survey);

  const handleSubmit = async () => {
    dispatch(
      setLoading({
        loadingMessage: 'Updating survey, please wait...',
      }),
    );

    const handleDialogClose = () => {
      history.push(`/${clientId}/surveys`);
    };

    const result = await dispatch(onSurveySubmitted({ surveyId }));
    dispatch(setLoading(false));
    if (result?.id) {
      await showModal({
        title: 'Success!',
        buttons: {
          confirm: [{ text: 'Ok' }],
        },
        messages: [`You successfully saved the survey`],
      });
      handleDialogClose();
    } else {
      recordError(new Error('Survey Save Error'));
      // must be an error
      showModal({
        title: 'There has been an error',
        buttons: {
          confirm: [{ text: 'Ok' }],
        },
        messages: result.error?.cause?.type
          ? [result.error.message]
          : [
              'Sorry, there has been an error saving your survey.',
              'We have been notified and are looking into it.',
              'Please contact us for more information',
            ],
      });
    }
  };

  return (
    <Box className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <TitleBar title={`Edit Survey`} />
          <Box mt={2}>
            <Steps onSubmit={handleSubmit} initialData={survey} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Issue;
