import Grid from '@mui/material/Grid';
import React from 'react';
import TitleBar from '../../components/MainContent/TitleBar';

const View = () => {
  return (
    <Grid container spacing={2}>
      <TitleBar title="View Asseticom Admins" />
    </Grid>
  );
};

export default View;
