import { AppState, useAppDispatch } from '../config/store';
import { Box, Button, Link, Paper, Typography } from '@mui/material';

import InfoIcon from '@mui/icons-material/Info';
import { newColours } from '../config/theme';
import { setHideBusyBar } from '../state/ui';
import { useSelector } from 'react-redux';

const BUSY_BAR_TEXT = `You can now generate Life-Cycle and Photographic Schedule reports. Click the 'Create' link in the Reporting section to get started.`;

export default function WeveBeenBusyBar() {
  const hideBusyBar = useSelector((state: AppState) => state.ui.hideBusyBar);
  const dispatch = useAppDispatch();

  if (hideBusyBar === BUSY_BAR_TEXT) {
    return <Box p={1}></Box>;
  }
  return (
    <Box
      sx={{
        py: 2,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: newColours.light3,
          borderRadius: 2,
          p: 2,
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Paper
            sx={{
              width: 50,
              height: 50,
              mr: 2,
              p: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <InfoIcon />
          </Paper>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography sx={{ fontWeight: 500 }}>New Feature</Typography>
            <Typography variant="caption">{BUSY_BAR_TEXT}</Typography>
            <Typography variant="caption">
              For any queries, you can reach us at {''}
              <Link href="mailto:support@asseticom.co.uk">
                support@asseticom.co.uk
              </Link>
            </Typography>
          </Box>
        </Box>
        <Button
          sx={{ mt: { xs: 2, sm: 0 } }}
          variant="contained"
          onClick={() => dispatch(setHideBusyBar(BUSY_BAR_TEXT))}
        >
          Dismiss
        </Button>
      </Box>
    </Box>
  );
}
