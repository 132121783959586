import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'react-redux-firebase';
import { generatePath } from 'react-router-dom';
import { ROUTES_MAP } from '../config/routes';

export default function useRedirect(redirect) {
  const [redirectTo, setRedirectTo] = useState(ROUTES_MAP.NOT_AUTHED.path);
  const ids = useSelector((state) => state.params.ids);

  useEffect(() => {
    if (!redirect || isEmpty(ids)) {
      return;
    }
    setRedirectTo(generatePath(redirect || '', ids));
  }, [redirect]);
  return redirectTo;
}
