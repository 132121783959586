import { AddEditRoomProps, RoomProps } from '../../../../../../types/room';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  TextField,
} from '@mui/material';
import { Controller, useForm, useFormState, useWatch } from 'react-hook-form';
import { useEffect, useState } from 'react';

import ErrorText from '../../../../../../components/Form/ErrorText';

const defaultsForAddRoom = {
  name: '',
  floor: '',
  areaFloor: 0,
  areaWall: 0,
  notes: '',
};

const disableEnterToSubmit = (e) => {
  const keyCode = e.keyCode ? e.keyCode : e.which;
  if (keyCode === 13) {
    e.preventDefault();
  }
};

interface AddorEditRoomFormProps {
  floors: string[];
  onRoomSaved: (room: AddEditRoomProps) => void;
  onCancel: () => void;
  room?: RoomProps;
}

export default function AddorEditRoomForm({
  floors,
  onRoomSaved,
  room,
  onCancel,
}: AddorEditRoomFormProps) {
  if (!floors) {
    return null;
  }

  const { handleSubmit, control, setValue, reset } = useForm<AddEditRoomProps>({
    defaultValues: room || defaultsForAddRoom,
  });
  const { errors, isSubmitSuccessful } = useFormState({
    control,
  });

  const onCancelSave = () => {
    reset(defaultsForAddRoom);
    onCancel?.();
  };

  useEffect(() => {
    if (room) {
      reset(room);
    } else {
      reset(defaultsForAddRoom);
    }
  }, [room]);

  const areaFloorWatcher = useWatch({ control, name: 'areaFloor' });

  useEffect(() => {
    if (!areaFloorWatcher) {
      return;
    }
    const areaWall = Math.sqrt(Number(areaFloorWatcher)) * 2.4 * 4;
    setValue('areaWall', parseFloat(areaWall.toFixed(2)), {
      shouldDirty: true,
      shouldValidate: true,
    });
  }, [areaFloorWatcher]);

  useEffect(() => {
    reset(defaultsForAddRoom);
  }, [isSubmitSuccessful]);

  return (
    <Box p={2} border="1px solid lightgray" borderRadius={2}>
      <Box mb={2}>
        <Divider variant="middle">
          <Chip
            variant="outlined"
            label={room ? `Edit ${room.name}` : 'Add a Room'}
            color="primary"
          />
        </Divider>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                autoComplete="off"
                fullWidth
                label="Room Name"
                variant="outlined"
                onKeyPress={disableEnterToSubmit}
                error={!!errors.name}
              />
            )}
            control={control}
            name="name"
            rules={{
              required: true,
            }}
          />
          {errors.name && <ErrorText />}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            control={control}
            name="floor"
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <Autocomplete
                value={field.value}
                id="select-floor"
                options={floors}
                freeSolo
                autoSelect
                onKeyPress={disableEnterToSubmit}
                onChange={(e, value) => field.onChange(value)}
                onInputChange={(_, data, reason) => field.onChange(data)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Floor"
                    variant="outlined"
                    autoComplete="off"
                    error={!!errors.floor}
                  />
                )}
              />
            )}
          />
          {errors.floor && <ErrorText />}
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                autoComplete="off"
                fullWidth
                label="Area (Floor)"
                variant="outlined"
                onKeyPress={disableEnterToSubmit}
                error={!!errors.areaFloor}
              />
            )}
            control={control}
            name="areaFloor"
            rules={{
              pattern: /^\d+\.?\d*$/,
            }}
          />
          {errors.areaFloor && <ErrorText text="Please input a valid number" />}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                disabled
                autoComplete="off"
                fullWidth
                label="Area (Wall)"
                variant="outlined"
                onKeyPress={disableEnterToSubmit}
                error={!!errors.areaFloor}
              />
            )}
            control={control}
            name="areaWall"
          />
          {errors.areaWall && <ErrorText />}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                disabled
                autoComplete="off"
                fullWidth
                label="Notes"
                variant="outlined"
                onKeyPress={disableEnterToSubmit}
                error={!!errors.notes}
              />
            )}
            control={control}
            name="notes"
          />
        </Grid>
      </Grid>
      <Box textAlign="right" pt={2}>
        {room ? (
          <Button
            variant="contained"
            color="error"
            type="button"
            sx={{ mr: 2 }}
            onClick={onCancelSave}
          >
            Cancel
          </Button>
        ) : null}
        <Button
          variant="contained"
          color="secondary"
          type="button"
          onClick={handleSubmit(onRoomSaved)}
        >
          {room ? 'Save' : 'Add'}
        </Button>
      </Box>
    </Box>
  );
}
