import {
  MRT_ColumnFiltersState,
  MRT_SortingState,
  MaterialReactTable,
} from 'material-react-table';
import { useEffect, useState } from 'react';

import { QueryParams } from '../types/urlParams';
import { ThemeProvider } from '@mui/material';
import useQueryParams from '../hooks/useQueryParams';
import useQueryParamsUpdater from '../hooks/useQueryParamsUpdater';
import useTableTheme from '../hooks/useTableTheme';

type TableProps = {
  columns: any[];
  rows: any[];
  onClickRow?: (row: any) => void;
  tableProps?: any;
  sortBy?: { id: string; desc: boolean };
  rowsPerPage?: number;
  disableSearchParams?: boolean;
  onPerPageChange?: (perPage: number) => void;
};

export type Updater<T> = T | ((old: T) => T);
export type OnChangeFn<T> = (updaterOrValue: Updater<T>) => void;
export function functionalUpdate<T>(updater: Updater<T>, input: T): T {
  return typeof updater === 'function'
    ? (updater as (input: T) => T)(input)
    : updater;
}

type Filters = {
  id: string;
  value: string | number;
}[];

function parseFilters(filterString) {
  if (!filterString?.length) {
    return [];
  }
  const filters: Filters = [];
  const split = filterString?.split('**');

  split.forEach((s) => {
    const [name, val] = s.split('*');
    filters.push({ id: name, value: val });
  });
  return filters;
}

const DEFAULT_ROWS_PER_PAGE = 20;
const DEFAULT_COLUMN_SIZE = 5;

function Table({
  rows,
  columns,
  onClickRow,
  sortBy,
  tableProps,
  rowsPerPage = DEFAULT_ROWS_PER_PAGE,
  disableSearchParams = false,
  onPerPageChange,
}: TableProps) {
  const { page, perpage, filters } = useQueryParams<QueryParams>();

  const { updateQueryParams, deleteQueryParam } = useQueryParamsUpdater();
  const tableTheme = useTableTheme();

  const [pagination, setPagination] = useState({
    pageIndex: page || 0,
    pageSize: perpage || rowsPerPage,
  });

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    parseFilters(filters),
  );

  const [sorting, setSorting] = useState<MRT_SortingState>(
    sortBy ? [sortBy] : [],
  );

  useEffect(() => {
    if (!disableSearchParams) {
      updateQueryParams({
        page: pagination.pageIndex,
        perpage: pagination.pageSize,
      });
    }
    onPerPageChange?.(pagination.pageSize);
  }, [pagination.pageIndex, pagination.pageSize]);

  useEffect(() => {
    if (columnFilters.length) {
      setPagination((p) => ({ ...p, pageIndex: 0 }));
      if (!disableSearchParams) {
        const filterString = columnFilters.map((f) => `${f.id}*${f.value}`);
        updateQueryParams({ filters: filterString.join('**') });
      }
    } else {
      if (!disableSearchParams) {
        deleteQueryParam('filters');
      }
    }
  }, [columnFilters]);

  useEffect(() => {
    if (sorting.length) {
      setPagination((p) => ({ ...p, pageIndex: 0 }));
    }
  }, [sorting]);

  return (
    <ThemeProvider theme={tableTheme}>
      <MaterialReactTable
        {...tableProps}
        defaultColumn={{
          size: DEFAULT_COLUMN_SIZE,
          minSize: DEFAULT_COLUMN_SIZE,
        }}
        renderFallbackValue="N/A"
        enableColumnFilters
        columns={columns}
        data={rows}
        enableColumnActions={false}
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enableGlobalFilter={false}
        onPaginationChange={setPagination}
        onColumnFiltersChange={setColumnFilters}
        onSortingChange={setSorting}
        autoResetPageIndex={false}
        state={{
          pagination,
          columnFilters,
          sorting,
          ...tableProps?.state,
        }}
        initialState={{
          density: 'compact',
          pagination,
          columnFilters,
          sorting,
          ...tableProps?.initialState,
        }}
        muiTableBodyCellProps={({ cell, row }) => ({
          onClick: (event) => {
            if (cell.column.id === 'mrt-row-actions') {
              return;
            }
            onClickRow?.(row.original);
          },
          sx: {
            cursor: 'pointer',
          },
        })}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align:
                typeof tableProps?.renderRowActionMenuItems === 'undefined'
                  ? 'center'
                  : 'left',
            },
            muiTableBodyCellProps: {
              align:
                typeof tableProps?.renderRowActionMenuItems === 'undefined'
                  ? 'center'
                  : 'left',
            },
          },
        }}
      />
    </ThemeProvider>
  );
}

export default Table;
