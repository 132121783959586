import {
  Box,
  IconButton,
  ImageList,
  ImageListItem,
  Paper,
  Typography,
} from '@mui/material';
import {
  convertHeic,
  handleLocalImage,
  isLocalImage,
  reduceImage,
} from '../../../../../../shared/utilities';

import CardTitle from './CardTitle';
import DynamicIcon from '../../../../../../components/DynamicIcon';
import ImageZoomer from '../../../../../../components/ImageZoomer';
import { colours } from '../../../../../../config/theme';
import { getImageOrPlaceholder } from '../../../../../../components/DataTable';
import { makeStyles } from '@mui/styles';
import { setLoading } from '../../../../../../state/ui';
import { useDispatch } from 'react-redux';
import { useModal } from '../../../../../../components/Modal';

const MAX_IMAGE_DIMENSION = 2500;

const useStyles = makeStyles((theme) => ({
  imageHolder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    borderRadius: '25%',
    width: 100,
    height: 100,
    backgroundColor: colours.lightGrey,
  },
}));

export default function ImageGrid({
  title = '',
  images = [],
  onUpdateImage,
  onDeleteImage,
}) {
  const styles = useStyles();
  let numImageColumns = 3;
  if (images?.length < 3) {
    numImageColumns = images.length === 0 ? 1 : images.length;
  }

  const { showModal } = useModal();

  const dispatch = useDispatch();

  async function handleImageSelected(image, index, adding) {
    let processedImage = image;
    if (image.type === 'image/heic') {
      dispatch(
        setLoading({
          loadingMessage: 'Converting image, please wait...',
        }),
      );
      try {
        const conversionResult = await convertHeic(image);
        processedImage = conversionResult;
      } catch (error) {
        if (error.code === 1) {
          // image was already usable
          processedImage = image;
        } else {
          return showModal({
            title: 'There has been an error',
            buttons: {
              confirm: [{ text: 'Ok' }],
            },
            messages: [
              'There was an error converting the image. Please try manually converting it to a jpeg or png and then try uploading again',
            ],
          });
        }
      } finally {
        dispatch(setLoading(false));
      }
    }
    onUpdateImage(await reduceImage(processedImage), index, adding);
  }

  return (
    <Paper>
      <CardTitle text={title} />
      <Box padding={2}>
        <ImageList cols={numImageColumns}>
          {images?.map((item, index) => {
            return (
              <ImageListItem key={item.id}>
                {isLocalImage(item.uri) ? (
                  <Box
                    display="flex"
                    flex={1}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box className={styles.imageHolder}>
                      {getImageOrPlaceholder(item.uri, 50)}
                    </Box>
                  </Box>
                ) : (
                  <ImageZoomer
                    onDelete={() => onDeleteImage(index)}
                    onReplace={(image) => handleImageSelected(image, index)}
                    alt=""
                    src={handleLocalImage(item.uri)}
                    width={180}
                    style={{
                      height: '100%',
                      width: '100%',
                      objectFit: 'cover',
                    }}
                  />
                )}
              </ImageListItem>
            );
          })}
          <ImageListItem
            style={{
              opacity: images.length === 5 ? 0.25 : 1,
              pointerEvents: images.length === 5 ? 'none' : 'auto',
            }}
          >
            <Box
              width="100%"
              height="100%"
              alignItems="center"
              justifyContent="center"
              display="flex"
            >
              <Box display="flex" flexDirection="column" alignItems="center">
                <IconButton component="label" htmlFor={title} size="large">
                  <DynamicIcon
                    name="add-photo"
                    colour="secondary"
                    width="50px"
                    height="50px"
                  />
                  <input
                    type="file"
                    hidden
                    name={title}
                    id={title}
                    onChange={(event) =>
                      handleImageSelected(
                        event.target.files[0],
                        images.length,
                        true,
                      )
                    }
                  />
                </IconButton>
                <Typography variant="caption" color="secondary" align="center">
                  Add an image
                </Typography>
              </Box>
            </Box>
          </ImageListItem>
        </ImageList>
      </Box>
    </Paper>
  );
}
