import { IconButton, Typography } from '@mui/material';

import AccessibleSwitcher from '../../../../../../components/AccessibleSwitcher';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { colours } from '../../../../../../config/theme';
import { useNicheFieldsCopier } from '../hooks/useNicheFieldsCopier';

export default function NicheFieldsCopierOpenButton() {
  const { open, enabled } = useNicheFieldsCopier();

  return (
    <IconButton
      disabled={!enabled}
      onClick={open}
      size="small"
      aria-label="copy"
      sx={{
        borderRadius: 0,
        minWidth: 50,
        flexShrink: 0,
        color: colours.brand1,
      }}
    >
      <AccessibleSwitcher
        accessibleChild={<Typography>Copy Niche Fields</Typography>}
      >
        <ContentCopyIcon />
      </AccessibleSwitcher>
    </IconButton>
  );
}
