import { Box, Grid } from '@mui/material';

import ErrorBoundary from './ErrorBoundary';
import PropTypes from 'prop-types';
import ViewAllCard from './ViewAllCard';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  viewMoreContainer: {
    position: 'relative',
  },
  sizer: {
    opacity: 0,
    pointerEvents: 'none',
  },
}));

const CardGrid = ({ max, maxTo, render, dataArray }) => {
  const classes = useStyles();
  return (
    <ErrorBoundary>
      <Box p={3}>
        <Grid
          container
          item
          alignItems="flex-end"
          spacing={2}
          justifyContent="flex-start"
        >
          {dataArray && dataArray.length
            ? dataArray.map((item, index) => {
                return (
                  <Grid key={item.id || index} item xs={12} sm={6} lg={4}>
                    {render(item)}
                  </Grid>
                );
              })
            : null}
          {max && dataArray?.length >= max && (
            <Grid
              key="viewAll"
              item
              xs={12}
              sm={6}
              lg={4}
              className={classes.viewMoreContainer}
            >
              <ViewAllCard to={maxTo} />
              <Box className={classes.sizer}>{render(dataArray[0])}</Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </ErrorBoundary>
  );
};

CardGrid.defaultProps = {
  max: null,
  maxTo: null,
  dataArray: [],
};

CardGrid.propTypes = {
  max: PropTypes.number,
  maxTo: PropTypes.string,
  render: PropTypes.func.isRequired,
  dataArray: PropTypes.arrayOf(PropTypes.any),
};

export default CardGrid;
