import { Box, useTheme } from '@mui/material';

import { StandardTheme } from '../config/theme';

type DynamicIconProps = {
  name: string;
  colour: keyof typeof StandardTheme['palette'];
  width?: number;
  height?: number;
  size?: number;
  inCircle?: boolean;
};

const DynamicIcon = ({
  name,
  inCircle = false,
  colour = 'primary',
  width,
  height,
  size,
  ...rest
}: DynamicIconProps) => {
  const symbolId = `#${name}`;

  const theme = useTheme();

  const colourFromTheme = theme.palette[colour]?.main;

  const Icon = (
    <svg
      {...rest}
      aria-hidden="true"
      width={width || size}
      height={height || size}
      preserveAspectRatio="xMidYMid meet"
    >
      <use href={symbolId} fill={colourFromTheme} />
    </svg>
  );

  if (!colourFromTheme) {
    return null;
  }

  if (inCircle) {
    return (
      <Box
        sx={{
          border: `2px solid ${colourFromTheme}`,
          borderRadius: '50%',
          width: width || size || 40,
          height: height || size || 40,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 1,
        }}
      >
        {Icon}
      </Box>
    );
  }
  return Icon;
};

export default DynamicIcon;
