import { Box, Grid, Paper, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import RoleSelect from '../../components/RoleSelect';

const NotFound = () => {
  return (
    <Paper>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box p={2}>
            <Typography
              variant="h3"
              color="primary"
              align="center"
              gutterBottom
            >
              Whoops!
            </Typography>
            <Typography variant="body1" color="secondary" align="left">
              Looks like you have accessed a page that does not exist
            </Typography>
            <RoleSelect />
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default NotFound;
