import { Box, Card, CardContent, Divider, Typography } from '@mui/material';

import DynamicIcon from '../../components/DynamicIcon';
import LockIcon from '@mui/icons-material/Lock';
import React from 'react';
import RoleSelect from '../../components/RoleSelect';
import { colours } from '../../config/theme';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.secondary.main,
    height: '100vh',
    width: '100vw',
  },
  card: {
    maxWidth: 480,
    overflow: 'visible',
  },
  form: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  iconContainer: {
    transform: `translateY(-50px)`,
    width: '60px',
    height: '60px',
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    stroke: theme.palette.primary.main,
    height: '40px',
    marginRight: '10px',
  },
  companyName: {
    fontFamily: 'Open Sans',
  },
}));

const NotAuthed = () => {
  const classes = useStyles();
  return (
    <Box
      className={classes.container}
      display="flex"
      justifyContent="center"
      alignItems="center"
      p={2}
    >
      <Card className={classes.card} raised>
        <CardContent>
          <Box display="flex" justifyContent="space-between" mb={1}>
            <Box
              bgcolor="secondary.main"
              color="white"
              boxShadow={1}
              className={classes.iconContainer}
            >
              <LockIcon fontSize="large" />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <DynamicIcon
                className={classes.logo}
                name="logo-square"
                colour="primary"
              />
              <Typography
                variant="h6"
                color="primary"
                className={classes.companyName}
              >
                Asseticom
              </Typography>
            </Box>
          </Box>

          <Box>
            <Typography variant="body1" align="center" paragraph>
              You are not authorised to view that page
            </Typography>
            <RoleSelect />
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default NotAuthed;
